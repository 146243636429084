import { Box, Card, CardContent, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const KabanBoardTicketSkeleton = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Card
            className="KabanBoardTodoTicketSkeleton"
            sx={{
                width: '100%',
                display: 'flex',
                flexFlow: matches ? 'column' : 'row',
                justifyContent: 'space-between',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
            }}
        >
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
                    <Skeleton width={100} />
                </Typography>
                <Typography color="text.secondary">
                    <Skeleton width={120} />
                </Typography>
                <Typography color="text.secondary">
                    <Skeleton width={!matches ? 200 : 100} />
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', flexFlow: matches ? 'column' : 'row' }}>
                <Skeleton
                    variant="rectangular"
                    width={75}
                    height={34}
                    sx={{ alignSelf: 'center', mr: matches ? 0 : 2, mb: matches ? 2 : 0 }}
                />
            </Box>
        </Card>
    );
};

export default KabanBoardTicketSkeleton;

