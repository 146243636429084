import { Box, Button, FormHelperText, LinearProgress, TextField } from '@mui/material';
import { login } from 'features/Authentication/slice';
import { Formik } from 'formik';
import useApplyTranslation from 'hooks/useApplyTranslation';
import useMounted from 'hooks/useMounted';
import { authSelector } from 'hooks/useSelector';
import { FC } from 'react';
import { useDispatch, useSelector } from 'store';
import * as Yup from 'yup';

const FormLogin: FC = (props) => {
    const mounted = useMounted();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const currentLocation = window.location.href;
    const url = new URL(currentLocation);
    const userEmail = url.searchParams.get('email');
    const translatedWords = useApplyTranslation();

    /**
     * Validate Form
     */
    const validate = Yup.object().shape({
        email: Yup.string()
            .email(translatedWords.require_validemail)
            .max(255, 'Email must be at most 255 characters')
            .required(translatedWords.require_email),
        password: Yup.string()
            .min(7, translatedWords.pass_7characters)
            .max(255, 'Password must be at most 255 characters')
            .required(translatedWords.require_pass),
    });

    const initialValues = {
        email: userEmail == null ? '' : userEmail,
        password: '',
        submit: null,
    };

    /**
     * Handle Submit Login Form
     * @param values
     * @param params
     */
    const handleSubmitForm = async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
            await dispatch(login(values.email, values.password));

            if (mounted.current) {
                setStatus({ success: auth.isError ? false : true });
                setErrors({ submit: auth.isError });
                setSubmitting(auth.isError ? false : true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validate} onSubmit={handleSubmitForm}>
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }): JSX.Element => (
                <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                        autoFocus
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label={translatedWords.email_add}
                        placeholder="Enter email address"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        name="email"
                        onBlur={(e) => {
                            handleBlur(e);
                            setFieldValue(e.target.name, e.target.value?.trim());
                        }}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label={translatedWords.pass}
                        placeholder="Enter password"
                        InputLabelProps={{ shrink: true }}
                        margin="normal"
                        name="password"
                        onBlur={(e) => {
                            handleBlur(e);
                            setFieldValue(e.target.name, e.target.value?.trim());
                        }}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                    />
                    {auth.isError && (
                        <Box sx={{ mt: 2, ml: 1 }}>
                            <FormHelperText error>
                                {typeof auth?.message && auth?.message?.toLowerCase() === 'unauthorized'
                                    ? 'Wrong email or password'
                                    : 'Server is not responding'}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box sx={{ mt: 3, px: 1 }} display={!isSubmitting && 'none'}>
                        <LinearProgress />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                            Log In
                        </Button>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default FormLogin;
