import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import type { Action } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
import { cachedApi } from 'features/Candidate/api';

const store = configureStore({
    reducer: rootReducer,
    devTools: import.meta.env.VITE_APP_ENABLE_REDUX_DEV_TOOLS === 'true',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // disable serialization checks altogether
            immutableCheck: false,
        }).concat(cachedApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<TReturn = any> = ThunkAction<TReturn, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
