import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';

import useGetListLanguage from 'hooks/useGetListLanguage';
import { configLanguage } from 'utils/config';
import { DEFAULT_LANGUAGE } from 'utils/constants';
import objFromArray from 'utils/objFromArray';

export const handleTranslateLanguageName = (t: TFunction<string>, languageName: string) => {
    if (!languageName) return;
    const languageArr = languageName.split(';');
    const languageConstant = languageArr[0].split(' ').join('').toLowerCase();

    return t(languageConstant, languageName);
};

interface LanguagePopoverProps {
    textColor?: string;
}

const LanguagePopover: FC<LanguagePopoverProps> = ({ textColor = 'textPrimary' }) => {
    const { i18n, t } = useTranslation();

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [language, setLanguage] = useState(JSON.parse(localStorage.getItem('countryCode')) === 'VN' ? 'vi' : DEFAULT_LANGUAGE);

    const listLanguage = useGetListLanguage();
    const languages = objFromArray(listLanguage, 'code');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    /**
     * Handle Change Language
     * @param lang
     */
    const handleChangeLanguage = (lang: string): void => {
        setLanguage(lang);
        configLanguage.set(lang);
        i18n.changeLanguage(lang);
        setOpen(false);
    };

    useEffect(() => {
        /**
         * Set Init Language For Popover
         */
        let storageLanguage = configLanguage.get();
        if (storageLanguage) {
            setLanguage(storageLanguage);
            i18n.changeLanguage(storageLanguage);
        }
    }, [i18n]);

    return (
        <>
            <Box sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
                <Button onClick={handleOpen} ref={anchorRef}>
                    <Box sx={{ display: 'flex', width: 30, '& img': { width: '100%', objectFit: 'contain' }, mr: 1 }}>
                        <img alt={languages[language]?.name} src={languages[language]?.icon} />
                    </Box>
                    <Typography color={textColor} variant="subtitle2">
                        {handleTranslateLanguageName(t, languages[language]?.name)}
                    </Typography>
                </Button>
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{ sx: { width: 240 } }}
            >
                {Object.keys(languages).map((lngCode) => (
                    <MenuItem
                        onClick={() => handleChangeLanguage(lngCode)}
                        key={lngCode}
                        sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}
                    >
                        <ListItemIcon>
                            <Box sx={{ display: 'flex', width: 30, '& img': { width: '100%', objectFit: 'contain' } }}>
                                <img alt={languages[lngCode].name} src={languages[lngCode].icon} />
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography color="textPrimary" variant="subtitle2">
                                    {handleTranslateLanguageName(t, languages[lngCode].name)}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
};

export default LanguagePopover;
