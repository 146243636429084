export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
    NATURE: 'NATURE',
};

export const SUPPORTED_IMAGE_FORMATS = [
    'image/apng',
    'image/avif',
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/svg+xml',
    'image/webp',
    'application/octet-stream',
];

export const SUPPORTED_VIDEO_FORMATS = [
    'video/ogm',
    'video/wmv',
    'video/mpg',
    'video/webm',
    'video/ogv',
    'video/mov',
    'video/mpeg',
    'video/mp4',
    'video/m4v',
    'video/avi',
    'application/octet-stream',
];

export const DEFAULT_LANGUAGE = 'en';

export const GENERAL_MENU_CONSTANT = 'general_menu';
export const LIBRARY_MENU_CONSTANT = 'library_menu';

export const STANDARD_PACKAGE_NAME = 'Standard Plan';
export const RECOMMENT_PACKAGE_NAME = 'Buy 2 get 1 free';
export const PAYMENT_HISTORY_NAME = 'Payment History';
export const UPDATE_PAYMENT_INFO = 'Update Payment Information';
export const PAYMENT_RESULT_NAME = 'Payment Result';
export const CONTACT_SALES_NAME = 'Contact Sales';
export const CONTACT_INFORMATION_NAME = 'Contact Information';
export const CONTACT_SALES_RESULT = 'Contact sales result';

export const CHANGE_PLAN_NAME = 'Change plan';
export const TRANSACTION_RESULT_NAME = 'Transaction result';

export const OPEN_PAYMENT_PACKAGE_PARAM = 'package';

export const DEFAULT_CODEMIRROR_CONFIG = {
    theme: '3024-day',
    tabSize: 4,
    keyMap: 'sublime',
    mode: 'string',
    comment: 'comment',
    display: 'autorefresh',
    edit: 'matchbrackets',
    lineSeparator: '\n',
    firstLineNumber: 1,
    readOnly: true,
    lineWrapping: true,
    lineNumbers: true,
};

export const MIN_HOUR = 0;
export const MAX_HOUR = 23;

export const NOTIFICATION_RECEIVE_EVENT = 'onWorkspaceNotification';

export const DETECT_LANGUAGE_API_KEY = 'f87c5140e0c8c39d1c98f395a50eb5bc';
