import axiosClient from 'lib/axios';
import { Question } from 'types/question';

const prefixUrl = 'question-templates';

const api = {
    /**
     * Get all Questions
     */
    getAll: (): Promise<Question[]> => {
        return axiosClient.get(`shop/${prefixUrl}`);
    },

    /* Create Question */
    create: (request: any) => {
        return axiosClient.post(`shop/${prefixUrl}`, request);
    },

    /* Update Question */
    update: (questionId: string, request: any) => {
        return axiosClient.patch(`shop/${prefixUrl}/${questionId}`, request);
    },

    /* Delete Question */
    delete: (questionId: string) => {
        return axiosClient.delete(`shop/${prefixUrl}/${questionId}`);
    },
};

export default api;
