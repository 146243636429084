import { getAllQuotaPackages } from 'features/Payment/slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';

import { paymentSelector } from './useSelector';

const useGetQuotaPackage = () => {
    const dispatch = useDispatch();
    const payment = useSelector(paymentSelector);

    useEffect(() => {
        const getQuotaPackages = async () => {
            try {
                await dispatch(getAllQuotaPackages());
            } catch (error) {
                console.log('The following error occurred:', error.message || 'Can not get payment');
            }
        };
        if (!payment.quotaPackages) {
            getQuotaPackages();
        }
    }, [payment.quotaPackages]);

    return payment.quotaPackages;
};

export default useGetQuotaPackage;
