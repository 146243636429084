import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'store';
import { RunningFlowsSelector, workspaceSelector } from 'hooks/useSelector';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { listAllFlows } from 'features/RunningFlow/slice';
import OpenPositionCard from './OpenPositionCard';
import { DashboardOpenPosition } from '../types';
import OpenPositionCardSkeleton from './OpenPositionCard.skeleton';

const OpenPositions = () => {
    const dispatch = useDispatch();
    const translatedWords = useApplyTranslation();
    const workspace = useSelector(workspaceSelector);
    const runningFlows = useSelector(RunningFlowsSelector);

    const [isLoading, setIsLoading] = useState(false);

    const positions: DashboardOpenPosition[] =
        runningFlows.flows
            ?.filter((flow) => !flow.draft)
            .map((flow) => ({
                flowId: flow.id,
                position: flow.name,
                isHiring: flow.status === 'active',
                cvUntouched: flow.traffic?.filter?.untouched ?? 0,
                videoUntouched: flow.traffic?.autoInterview?.untouched ?? 0,
            })) ?? [];

    useEffect(() => {
        async function getRunningFlowData() {
            try {
                setIsLoading(true);
                await dispatch(listAllFlows(workspace?.activated.id));
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        if (workspace.activated?.id) {
            getRunningFlowData();
        }
    }, [dispatch, workspace.activated?.id]);

    return (
        <Card className="open-positions" sx={{ mt: 3 }}>
            <CardHeader title={translatedWords.open_positions} />
            <Divider />
            <CardContent>
                <Grid container spacing={2}>
                    {isLoading
                        ? Array.from({ length: 3 }).map((e, i) => (
                              <Grid key={i} item xs={6}>
                                  <OpenPositionCardSkeleton />
                              </Grid>
                          ))
                        : positions.map(({ flowId, position, isHiring, cvUntouched, videoUntouched }, i) => (
                              <Grid key={i} item xs={6}>
                                  <OpenPositionCard
                                      flowId={flowId}
                                      position={position}
                                      isHiring={isHiring}
                                      cvUntouched={cvUntouched}
                                      videoUntouched={videoUntouched}
                                  />
                              </Grid>
                          ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OpenPositions;

