import React, { FC, useEffect, useRef } from 'react';

import { ControlBar, PlaybackRateMenuButton, Player, PlayToggle, VolumeMenuButton } from 'video-react';

interface VideoPlayerProps {
    videoSource: string;
    autoPlay?: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = (props) => {
    const { videoSource, autoPlay = false } = props;
    const videoRef = useRef<any>();

    useEffect(() => {
        if (videoSource && videoRef) {
            videoRef.current.load();
        }
    }, [videoSource]);

    return (
        <Player src={videoSource} aspectRatio="4:3" ref={videoRef} autoPlay={autoPlay}>
            <ControlBar autoHide>
                <PlayToggle />
                <VolumeMenuButton vertical />
                <PlaybackRateMenuButton rates={[2, 1.5, 1, 0.5]} />
            </ControlBar>
        </Player>
    );
};

export default VideoPlayer;
