import axiosClient from 'lib/axios';

const prefixUrl = 'analytic';
const subUrl = 'advanced-flow';

const api = {
    /**
     * Get all Questions
     * @param workspaceId
     */
    getAnalyticsOverview: (workspaceId: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${subUrl}/overview`);
    },

    getAnalyticsDetailFlow: (workspaceId: string, params = {}) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${subUrl}/detail-flow`, { params });
    },
    getTimeDetailFlow: (workspaceId: string, params = {}) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${subUrl}/time-detail-flow`, { params });
    },
    getAnalyticTimeOverview: (workspaceId: string, params = {}) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${subUrl}/time-overview`, { params });
    },
};

export default api;
