import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { QuestionIdsPack } from 'types/questionsPack';
import api from './api';

interface QuestionPackState {
    questionPacks: QuestionIdsPack[];
    isLoading: boolean;
    error: boolean;
}

const initialState: QuestionPackState = {
    questionPacks: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'questionIdsPack',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Question */
        list: (state, action) => {
            state.questionPacks = action.payload;
            state.isLoading = false;
        },

        /* Create Question */
        create: (state, action) => {
            state.questionPacks.unshift(action.payload);
            state.isLoading = false;
        },

        /* Update Question */
        update: (state, action) => {
            const questionPack = action.payload;
            const index = state.questionPacks.findIndex((x) => x.id === questionPack.id);

            if (index > -1) {
                state.questionPacks[index] = questionPack;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove Question */
        remove: (state, action) => {
            const index = state.questionPacks.findIndex((x) => x.id === action.payload);
            if (index > -1) {
                state.questionPacks.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List All QuestionPacks
 * @param workspaceId
 * @returns
 */
export const listQuestionPacks =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response = await api.getAll(workspaceId);
            dispatch(slice.actions.list(response));
        } catch (error) {
            console.log(error.message);
        }
    };

/**
 * Create QuestionPack
 * @param workspaceId
 * @param request
 * @returns
 */
export const createQuestionPack =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.create(workspaceId, request);
        dispatch(slice.actions.create(response));
        return response;
    };

/**
 * Update QuestionPack
 * @param id
 * @param request
 * @returns
 */
export const updateQuestionPack =
    (workspaceId: string, questionPackId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(workspaceId, questionPackId, request);
        dispatch(slice.actions.update(response));
        return response;
    };

export const deleteQuestionPack =
    (workspaceId: string, questionPackId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.delete(workspaceId, questionPackId);
        dispatch(slice.actions.remove(questionPackId));
    };
export const { reducer } = slice;
export default slice;
