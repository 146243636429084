import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { AdvancedFlow } from 'types/flow';
import api from './api';

interface AdvancedFlowState {
    listTemplate: AdvancedFlow[];
    isLoading: boolean;
    error: boolean;
}

const initialState: AdvancedFlowState = {
    listTemplate: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'flow_template',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Flow Template */
        list: (state, action) => {
            state.listTemplate = action.payload;
            state.isLoading = false;
        },

        /* Update Flow Status */
        update: (state, action) => {
            const listTemplate = action.payload;
            const index = state.listTemplate.findIndex((x) => x.id === listTemplate.id);

            if (index > -1) {
                state.listTemplate[index] = listTemplate;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Create a Flow Template */
        create: (state, action) => {
            state.listTemplate.unshift(action.payload);
            state.isLoading = false;
        },

        /* Remove a Flow Template */
        remove: (state, action) => {
            const flowId = action.payload;
            const index = state.listTemplate.findIndex((x) => x.id === flowId);
            if (index > -1) {
                state.listTemplate.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List all Flows
 * @param workspaceId
 * @returns
 */
export const listFlows =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setLoading(true));
        const response: any = await api.getAll(workspaceId);
        dispatch(slice.actions.list(response));
        dispatch(slice.actions.setLoading(false));
    };

/**
 * Create Flows
 * @param workspaceId
 * @param request
 * Request includes: "Flow Name and Draft = true"
 * @returns
 */
export const createFlows =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.create(workspaceId, request);
        dispatch(slice.actions.create(response));
        return response;
    };

/**
 * Delete Flows
 * @param id
 * @returns
 */

export const deleteFlows =
    (workspaceId: string, flowsId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.delete(workspaceId, flowsId);
        dispatch(slice.actions.remove(flowsId));
    };

export const { reducer } = slice;
export default slice;
