import styled from '@emotion/styled';
import { Box, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const PrimaryTooltip = styled(({ className, ref, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
        <Box>{children}</Box>
    </Tooltip>
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgb(196,216,240,0.92)',
        maxWidth: '600px',
    },
}));

export default PrimaryTooltip;

