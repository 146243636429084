import { SendingEmailPayload } from './interviewSchedule';

export interface AdvancedFlowStep {
    draft?: boolean;
    enable?: boolean;
}

export interface AFApplicationCollection extends AdvancedFlowStep {
    info?: string;
}

export interface AFFilter extends AdvancedFlowStep {
    info?: string;
}

export interface AFAutoInterview extends AdvancedFlowStep {
    info?: string;
}

export interface AFScoreAutoInterview extends AdvancedFlowStep {}

export interface AFDirectInterview extends AdvancedFlowStep {
    info?: string;
}

export enum AdvancedFlowSteps {
    APPLICATION_COLLECTION = 'applyCollection',
    FILTER = 'filter',
    AUTO_INTERVIEW = 'autoInterview',
    SCORE_AUTO_INTERVIEW = 'scoreAutoInterview',
    DIRECT_INTERVIEW = 'directInterview',
}

export interface AdvancedFlowTraffic {
    totalReceived: number;
    manualUpload: number;

    [AdvancedFlowSteps.FILTER]?: {
        passed: number;
        rejected: number;
        untouched: number;
    };
    [AdvancedFlowSteps.AUTO_INTERVIEW]?: {
        untouched: number;
        finished: number;
        overdue: number;
    };
    humanScoreAutoInterview?: {
        passed: number;
        rejected: number;
    };
    [AdvancedFlowSteps.DIRECT_INTERVIEW]?: {
        voting: number;
        unsuccess: number;
        scheduleDone: number;
        passed: number;
        rejected: number;
    };
    offerBoard: {
        untouched: number;
        sent: number;
        responded: number;
    };
}

export interface AFFlowEmail {
    email: string;
    lock: boolean;
    name: string;
    received: number;
    status: string;
}

export type AdvancedFlowStatus = 'active' | 'inactive';

export interface AdvancedFlow {
    status: AdvancedFlowStatus;
    position: string;
    workspace: string;
    name: string;
    draft: boolean;
    flowEmail: AFFlowEmail[];
    id: string;
    [AdvancedFlowSteps.APPLICATION_COLLECTION]?: AFApplicationCollection;
    [AdvancedFlowSteps.FILTER]?: AFFilter;
    [AdvancedFlowSteps.AUTO_INTERVIEW]?: AFAutoInterview;
    [AdvancedFlowSteps.SCORE_AUTO_INTERVIEW]?: AFScoreAutoInterview;
    [AdvancedFlowSteps.DIRECT_INTERVIEW]?: AFDirectInterview;
    traffic: AdvancedFlowTraffic;
}

export interface AdvancedFlowActions {
    getInitialInfo: (workspaceId: string, flowId: string) => Promise<void>;
    updateApplyCollectionInfo: (payload: AFApplicationCollection, positionId?: string) => void;
    updateFilterInfo: (payload: AFFilter) => void;
    updateAutoInterviewInfo: (payload: AFAutoInterview) => void;
    updateHumanScorer: (payload: AFScoreAutoInterview) => void;
    updateDirectInterview: (payload: AFDirectInterview) => void;
    updateFlowStatus: (workspaceId: string, flowId: string, request: { active: boolean }) => Promise<void>;
    updateFlowInfo: (workspaceId: string, flowId: string, payload: Partial<AdvancedFlow>) => Promise<void>;
    updateStepStatus: (workspaceId: string, flowId, request: { component: AdvancedFlowSteps; enable: boolean }) => Promise<void>;
}

export type FilterType = 'pass' | 'hold' | 'reject';

export interface AFFilterPayload {
    emailScorer?: string;
    high: FilterType;
    moderate: FilterType;
    low: FilterType;
}

export type FilterResultType = 'passed' | 'untouched' | 'failed';

export type VideoInterviewProgress = 'untouched' | 'overdue' | 'finished' | 'incomplete';

export type HumanScoreProgress = 'scored' | 'pass' | 'fail';

export interface DirectInterviewConfig {
    duration: number;
    timezone: string;
    countryCode: string;
    panelists: { email: string }[];
    rangeVoteTime: number;
    workingHour: { from: number; to: number };
    preLocation?: {
        pType: 0 | 1 | 2;
        caType: 0 | 1;
        address?: string;
    };
}

export type ApplicationCollectionEmail = Pick<SendingEmailPayload, 'subject' | 'content' | 'replyTo' | 'cc' | 'themeId'>;

export type ApplicationCollectionEmailTemplate = Omit<ApplicationCollectionEmail, 'replyTo' | 'cc'> & {
    replyToEmail: string[];
    cc?: string[];
};

export interface ApplicationCollectionPayload {
    jobDescription: string;
    positionId: string;
    emailTemplate: ApplicationCollectionEmailTemplate;
}

export interface ApplicationCollectionResponse {
    draft: boolean;
    emailTemplate: ApplicationCollectionEmailTemplate;
    id: string;
    jobDescription: string;
    position: string;
}
