import useMounted from 'hooks/useMounted';
import { FC, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
    AFApplicationCollection,
    AFFilter,
    AFAutoInterview,
    AFScoreAutoInterview,
    AdvancedFlow,
    AdvancedFlowActions,
    AdvancedFlowSteps,
    AFDirectInterview,
} from 'types/flow';
import { AdvancedFlowContext } from '.';
import { advancedFlowApi } from '../api';

interface AdvancedFlowProviderProps {}

export interface AFProviderValue {
    listStep: { name: AdvancedFlowSteps; enabled: boolean }[];
    flowInfo: AdvancedFlow;
    loadingFlow: boolean;
    actions: AdvancedFlowActions;
}

const AdvancedFlowProvider: FC<AdvancedFlowProviderProps> = ({ children }) => {
    const [flowInfo, setFlowInfo] = useState<AdvancedFlow>(null);
    const mounted = useMounted();

    const [loadingFlow, setLoadingFlow] = useState(false);
    const advancedFlowActions: AdvancedFlowActions = {
        // Get a flow
        getInitialInfo: async (workspaceId: string, flowId: string) => {
            try {
                setLoadingFlow(true);
                const response: AdvancedFlow = await advancedFlowApi.getFlow(workspaceId, flowId);

                if (mounted.current) {
                    setFlowInfo(response);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                if (mounted.current) setLoadingFlow(false);
            }
        },

        // Update application collection ID
        updateApplyCollectionInfo: (payload: AFApplicationCollection, positionId?: string) => {
            setFlowInfo((prev) => {
                const oldStepData = prev?.applyCollection ? { ...prev.applyCollection } : {};
                return { ...prev, applyCollection: { ...oldStepData, ...payload }, ...(positionId ? { position: positionId } : {}) };
            });
        },

        // Update auto filter ID
        updateFilterInfo: (payload: AFFilter) => {
            setFlowInfo((prev) => {
                const oldAutoFilterData = prev?.filter ? { ...prev.filter } : {};
                //Enabled step if the first set up
                return {
                    ...prev,
                    filter: {
                        ...oldAutoFilterData,
                        ...payload,
                        enable: typeof flowInfo?.filter?.enable === 'boolean' ? flowInfo?.filter?.enable : true,
                    },
                };
            });
        },

        // Update auto interview ID
        updateAutoInterviewInfo: (payload: AFAutoInterview) => {
            setFlowInfo((prev) => {
                const oldData = prev?.autoInterview ? { ...prev.autoInterview } : {};
                //Enabled step if the first set up
                return {
                    ...prev,
                    autoInterview: {
                        ...oldData,
                        ...payload,
                        enable: typeof flowInfo?.autoInterview?.enable === 'boolean' ? flowInfo?.autoInterview?.enable : true,
                    },
                };
            });
        },

        // Update human scorer info
        updateHumanScorer: (payload: AFScoreAutoInterview) => {
            setFlowInfo((prev) => {
                return {
                    ...prev,
                    scoreAutoInterview: {
                        ...(flowInfo?.scoreAutoInterview ? flowInfo.scoreAutoInterview : {}),
                        ...payload,
                    },
                };
            });
        },

        // Update direct interview info
        updateDirectInterview: (payload: AFDirectInterview) => {
            setFlowInfo((prev) => {
                //Enabled step if the first set up
                return {
                    ...prev,
                    directInterview: {
                        ...(flowInfo?.directInterview ? flowInfo.directInterview : {}),
                        ...payload,
                        enable: typeof flowInfo?.directInterview?.enable === 'boolean' ? flowInfo?.directInterview?.enable : true,
                    },
                };
            });
        },

        updateFlowStatus: async (workspaceId: string, flowId: string, request: { active: boolean }) => {
            try {
                setLoadingFlow(true);

                const response = await advancedFlowApi.updateFlowStatus(workspaceId, flowId, request);
                if (mounted.current) {
                    setFlowInfo(response);
                    toast.success(`Successfully ${request.active ? 'Activate' : 'Deactivate'} Flow`);
                    // if (request.active) {
                    //     navigate(`/workspace/${workspaceId}/advanced-flow`);
                    // }
                }
            } catch (error) {
                if (error.statusCode === 403) {
                    toast.error('You do not have permission to perform this action on this workspace');
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            } finally {
                if (mounted.current) {
                    setLoadingFlow(false);
                }
            }
        },

        updateStepStatus: async (workspaceId: string, flowId, request: { component: AdvancedFlowSteps; enable: boolean }) => {
            try {
                const response = await advancedFlowApi.updateStepStatus(workspaceId, flowId, request);

                if (mounted.current) {
                    setFlowInfo(response);
                    toast.success('Saved change');
                }
            } catch (error) {
                toast.error(error.message);
                console.log('The following error occurred: ', error);
            }
        },

        // Get and Update flow information
        updateFlowInfo: async (workspaceId: string, flowId: string, payload: Partial<AdvancedFlow>): Promise<void> => {
            try {
                setLoadingFlow(true);
                const response: any = await advancedFlowApi.updateFlow(workspaceId, flowId, payload);

                if (mounted.current) {
                    setFlowInfo(response);
                }
            } catch (error) {
                if (error.statusCode === 403) {
                    toast.error('You do not have permission to perform this action on this workspace');
                } else {
                    toast.error(`Error: ${error.message}`);
                }
            } finally {
                if (mounted.current) {
                    setLoadingFlow(false);
                }
            }
        },
    };

    const listStep: { name: AdvancedFlowSteps; enabled: boolean }[] = useMemo(() => {
        let listStep: { name: AdvancedFlowSteps; enabled: boolean }[] = [];
        if (flowInfo) {
            listStep = [
                {
                    name: AdvancedFlowSteps.APPLICATION_COLLECTION,
                    enabled: flowInfo?.applyCollection?.enable ?? true,
                },
                {
                    name: AdvancedFlowSteps.FILTER,
                    enabled: flowInfo?.filter?.enable ?? false,
                },
                {
                    name: AdvancedFlowSteps.AUTO_INTERVIEW,
                    enabled: flowInfo?.autoInterview?.enable ?? false,
                },
                {
                    name: AdvancedFlowSteps.SCORE_AUTO_INTERVIEW,
                    // Human-scorer's status is belong to auto-interview's status
                    enabled: flowInfo?.autoInterview?.enable ?? false,
                },
                {
                    name: AdvancedFlowSteps.DIRECT_INTERVIEW,
                    // Human-scorer's status is belong to auto-interview's status
                    enabled: flowInfo?.directInterview?.enable ?? false,
                },
            ];
        }
        return listStep;
    }, [flowInfo]);

    return (
        <AdvancedFlowContext.Provider value={{ listStep, flowInfo, actions: advancedFlowActions, loadingFlow }}>
            {children}
        </AdvancedFlowContext.Provider>
    );
};

export default AdvancedFlowProvider;
