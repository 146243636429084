import React, { FC } from 'react';
import { Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import { useNavigate } from 'react-router';

interface UpcomingInterviewCardProps {
    name: string;
    position: string;
    link: string;
    fromInUnix?: number;
    duration: number;
}

const UpcomingInterviewCard: FC<UpcomingInterviewCardProps> = ({ name, position, link, fromInUnix, duration }) => {
    const navigate = useNavigate();

    const handleMoveClick = () => {
        navigate('schedule?tabValue=5');
    };

    return (
        <Card className="upcoming-interview-card">
            <Grid container>
                <Grid
                    item
                    xs={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1,
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                    }}
                >
                    <Typography variant="h5">{moment.unix(fromInUnix).format('DD')}</Typography>
                    <Typography variant="body1">{moment.unix(fromInUnix).format('MMM')}</Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                    item
                    xs={7}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        px: 2,
                        py: 1,
                    }}
                >
                    <Typography color="textPrimary">{name}</Typography>
                    <Typography color="textSecondary" variant="body2" gutterBottom>
                        {position}
                    </Typography>
                    {fromInUnix && (
                        <Typography
                            variant="body2"
                            color="error"
                            sx={{
                                fontStyle: 'italic',
                            }}
                        >{`${moment.unix(fromInUnix).format('HH:mm')} (${duration}')`}</Typography>
                    )}
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton onClick={handleMoveClick}>
                        <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    );
};

export default UpcomingInterviewCard;

