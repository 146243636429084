import React, { Dispatch, FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Fuse from 'fuse.js';

import { WorkspaceType } from 'types/workspace';

const fuseOptions = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ['name'],
};

interface SearchWorkspaceProps {
    initialData: WorkspaceType[];
    setSearchResults: Dispatch<React.SetStateAction<WorkspaceType[]>>;
}

// Source:
// https://www.daily.co/blog/implementing-client-side-search-in-a-react-app-with-fuse-js/

const SearchWorkspace: FC<SearchWorkspaceProps> = ({ initialData, setSearchResults }) => {
    const fuse = new Fuse(initialData, fuseOptions);

    const handleSearch = (event) => {
        const { value } = event.target;

        // If the user searched for an empty string,
        // display all data.
        if (value.length === 0) {
            setSearchResults(initialData);
            return;
        }

        const results = fuse.search(value);
        const items = results.map((result) => result.item);
        setSearchResults(items);
    };

    return (
        <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            sx={{ mx: 2 }}
            onChange={handleSearch}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default SearchWorkspace;
