import { Box, Card, CardContent, Container, Divider, Grid, Link, Typography } from '@mui/material';
import { authSelector } from 'hooks/useSelector';
import gtm from 'lib/gtm';
import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import FormLogin from './Form';
import useApplyTranslation from 'hooks/useApplyTranslation';
import GoogleAuth from '../GoogleAuth/GoogleAuth';

const Login: FC = () => {
    const auth = useSelector(authSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const translatedWords = useApplyTranslation();
    console.log(translatedWords);

    const queryParams = new URLSearchParams(window.location.search);
    const currentLocation = window.location.href;
    const url = new URL(currentLocation);
    const userEmail = url.searchParams.get('email');

    const token = queryParams.get('token');

    /* useEffect(() => {
        if (token) {
            const request = {
                token: token.toString(),
            };
            // post /auth/login-magic-link
            dispatch(loginMagicLink(request));

            //redirect ve homepange
            navigate('/');
        }
    }, [token]); */

    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    return (
        <>
            <Helmet>
                <title>Login | RecruitGenius</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Container maxWidth="sm" sx={{ py: '80px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                        <RouterLink to="/">
                            <Box sx={{ height: 98, '& > img': { height: '100%', width: 'auto' } }}>
                                <img alt="Application Logo" src="/static/white-none-bg.svg" />
                            </Box>
                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
                            <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                                <div>
                                    <Typography color="textPrimary" gutterBottom variant="h4">
                                        {auth.isAuthenticated ? `${translatedWords.wrksp}` : translatedWords.login}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2">
                                        {auth.isAuthenticated ? translatedWords.choose_workspace : translatedWords.login_existedacc}
                                    </Typography>
                                </div>
                            </Box>
                            <Box sx={{ flexGrow: 1, mt: 0 }}>
                                <FormLogin />
                                <Box sx={{ mt: 2 }}>
                                    <Divider>or</Divider>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <GoogleAuth />
                                </Box>
                            </Box>
                            <Divider sx={{ my: 3 }} />
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <Link color="textSecondary" component={RouterLink} to="/authentication/register" variant="body2">
                                        {translatedWords.create_new}
                                    </Link>
                                </Grid>
                                <Grid item textAlign="right" xs={6}>
                                    {userEmail == null ? (
                                        <Link
                                            color="textSecondary"
                                            component={RouterLink}
                                            sx={{ mt: 1 }}
                                            to="/authentication/password-recovery"
                                            variant="body2"
                                        >
                                            {translatedWords.forgot_pass}
                                        </Link>
                                    ) : (
                                        <Link
                                            color="textSecondary"
                                            component={RouterLink}
                                            sx={{ mt: 1 }}
                                            to={`/authentication/password-recovery?email=${userEmail}`}
                                            variant="body2"
                                        >
                                            {translatedWords.forgot_pass}
                                        </Link>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Login;
