import axiosClient from 'lib/axios';
import { Notification, NotificationQuery } from 'types/notification';

const prefixUrl = 'notifications';

const api = {
    getAll: (
        workspaceId: string,
        params?: NotificationQuery
    ): Promise<{ notiList: Notification[]; page: number; limit: number; totalCount: number; totalPages: number }> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/self`, {
            params: params ?? {},
        });
    },
    markReadOne: (workspaceId: string, notificationId: string) => {
        return axiosClient.patch(`/workspaces/${workspaceId}/${prefixUrl}/${notificationId}/read`);
    },
    markAllRead: (workspaceId: string) => {
        return axiosClient.patch(`/workspaces/${workspaceId}/${prefixUrl}/read-all`);
    },
};

export default api;
