import axiosClient from 'lib/axios';
import { AIGeneratePayload, Question, QuestionRequest } from 'types/question';

const prefixUrl = 'questions';

const api = {
    /**
     * Get all Questions
     * @param workspaceId
     */
    getAll: (workspaceId: string): Promise<Question[]> => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`);
    },
    getQuestion: (workspaceId: string, questionId: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${questionId}`);
    },

    /* Get Media file of question */
    getMedia: (workspaceId: string, fileName: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/file/${fileName}`, {
            responseType: 'blob',
        });
    },

    /* Create Question */
    create: (workspaceId: string, request: any): Promise<Question> => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /** UI AI to generate question */
    aiGenerate: (workspaceId: string, request: AIGeneratePayload): Promise<Question[]> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/ai-generate`, request);
    },

    createMultiQuestions: (workspaceId: string, request: { questions: QuestionRequest[] }): Promise<string[]> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/many`, request);
    },

    /* Import question template to workspace */
    importQuestionTemplateToWorkspace: (workspaceId: string, questionTemplateId: string): Promise<Question> => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/import/${questionTemplateId}`);
    },

    /* Update Question */
    update: (workspaceId: string, questionId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${questionId}`, request);
    },

    //* Update Image/ Add Image
    updateImage: (workspaceId: string, questionId: string, request: any): Promise<Question> => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/${questionId}/image`, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    //* Update Video/ Add Video
    updateVideo: (workspaceId: string, questionId: string, request: any): Promise<Question> => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/${questionId}/video`, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    /* Delete Question  */
    delete: (workspaceId: string, questionId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${questionId}`);
    },

    /* Delete Question's Video */
    deleteQuestionVideo: (workspaceId: string, questionId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${questionId}/video`);
    },

    /* Delete Question's Image */
    deleteQuestionImage: (workspaceId: string, questionId: string, imageName: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/questions/${questionId}/image`, { params: { filename: imageName } });
    },
};

export default api;
