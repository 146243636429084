import React, { FC, useState } from 'react';
import {
    Backdrop,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import MissedVideoCallIcon from '@mui/icons-material/MissedVideoCall';
import EventIcon from '@mui/icons-material/Event';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

import { useDispatch, useSelector } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { workspaceSelector } from 'hooks/useSelector';
import { activateAndDeactivateFlow } from 'features/RunningFlow/slice';

interface OpenPositionCardProps {
    flowId: string;
    position: string;
    isHiring: boolean;
    cvUntouched: number;
    videoUntouched: number;
}

const OpenPositionCard: FC<OpenPositionCardProps> = ({ flowId, position, isHiring, cvUntouched, videoUntouched }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspace = useSelector(workspaceSelector);
    const translatedWords = useApplyTranslation();

    const [active, setActive] = useState(isHiring);
    const [isLoading, setIsLoading] = useState(false);

    const handleSwitchToggle = async () => {
        setIsLoading(true);
        try {
            const payload = {
                active: !isHiring,
            };
            await dispatch(activateAndDeactivateFlow(workspace?.activated?.id, flowId, payload));
            setActive((prev) => !prev);
        } catch (error) {
            if (error.statusCode === 403) {
                toast.error('You do not have permission to perform this action on this workspace');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleDetailClick = () => {
        navigate(`flows/advanced-flow/detail/${flowId}`);
    };

    return (
        <Card className="open-position-card" sx={{ position: 'relative' }}>
            <CardHeader
                title={position}
                titleTypographyProps={{ variant: 'body1' }}
                action={
                    <Switch
                        checked={active}
                        sx={{
                            '&.MuiSwitch-root .MuiSwitch-switchBase': {
                                color: 'primary.contrastText',
                            },
                            '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track ': {
                                backgroundColor: 'success.main',
                                opacity: 1,
                            },
                        }}
                        onClick={handleSwitchToggle}
                    />
                }
                sx={{
                    backgroundColor: isHiring ? 'primary.main' : '#d9d9d9',
                    color: 'primary.contrastText',
                    py: 1,
                }}
            />
            <Divider />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={translatedWords.untouched_cvs}>
                            <Stack direction="row" gap={1}>
                                <AssignmentLateIcon />
                                <Typography>{cvUntouched}</Typography>
                            </Stack>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={translatedWords.untouched_videos}>
                            <Stack direction="row" gap={1}>
                                <MissedVideoCallIcon />
                                <Typography>{videoUntouched}</Typography>
                            </Stack>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title={translatedWords.setup_venue}>
                            <Stack direction="row" gap={1}>
                                <EventIcon />
                            </Stack>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={handleDetailClick} sx={{ p: 1 }}>
                            <ArrowForwardIosIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>

            <Backdrop
                open={isLoading}
                sx={{
                    position: 'absolute',
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Card>
    );
};

export default OpenPositionCard;

