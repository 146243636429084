import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { MemberType } from 'types/member';
import api from './api';

interface MemberManagementState {
    members: MemberType[];
    isLoading: boolean;
    error: boolean;
}

const initialState: MemberManagementState = {
    members: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'workspaceMember',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All members */
        listWorkspaceMember: (state, action) => {
            state.members = action.payload;
            state.isLoading = false;
        },

        /* Invite Member Question */
        invite: (state, action) => {
            state.members.unshift(action.payload);
            state.isLoading = false;
        },

        /** Update Policy */
        updatePolicyMember: (state, action) => {
            const policy = action.payload;

            const index = state.members.findIndex((x) => x?.policy?.id === policy.id);

            if (index > -1) {
                state.members[index].policy = policy;
                state.error = false;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove member */
        remove: (state, action) => {
            const index = state.members.findIndex((x) => x.recruiter.id === action.payload);
            if (index > -1) {
                state.members.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List All Members
 * @param workspaceId
 * @returns
 */
export const listMembers =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response: any = await api.getMembers(workspaceId);
            dispatch(slice.actions.listWorkspaceMember(response));
        } catch (error) {
            console.log(error.message);
        }
    };

/**
 * Invite Member
 * @param email
 * @param role
 * @returns
 */
export const inviteMember =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.inviteMember(workspaceId, request);

        const dispatchData = response.members;

        if (dispatchData) {
            dispatch(slice.actions.invite(dispatchData[dispatchData.length - 1]));
        }
    };

/**
 * Update Policy
 * @param workspaceId
 * @param policyId
 * @param request
 * @returns
 */
export const updatePolicy =
    (workspaceId: string, policyId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.updatePolicy(workspaceId, policyId, request);

        dispatch(slice.actions.updatePolicyMember(response));
    };

/**
 * Remove member
 * @param id
 *
 * @returns
 */
export const deleteMember =
    (workspaceId: string, recruiterId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.removeMember(workspaceId, recruiterId);
        dispatch(slice.actions.remove(recruiterId));
    };

export const changeOwner =
    (workspaceId: string, request: { otp: string; receiverId: string }): AppThunk =>
    async (dispatch) => {
        const response = await api.changeOwner(workspaceId, request);
        return response;
    };

export const { reducer } = slice;
export default slice;
