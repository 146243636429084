import { lazy, Suspense } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'layout/AuthGuard';
import GuestGuard from 'layout/GuestGuard';
import Layout from 'layout/Layout';
import Dashboard from 'features/Dashboard';
import DraftFlow from 'features/AdvancedFlowTemplate/components/DraftFlow';
import { AdvancedFlowProvider } from 'features/AdvancedFlowTemplate/components/DraftFlow/FlowContext';
import RedirectOldLink from 'components/RedirectOldLink';

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<LoadingScreen />}>
            <Component {...props} />
        </Suspense>
    );

// Authentication pages
const Login = Loadable(lazy(() => import('./features/Authentication/Login/Login')));
const Register = Loadable(lazy(() => import('./features/Authentication/Register/Register')));
const PasswordRecovery = Loadable(lazy(() => import('./features/Authentication/Password/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./features/Authentication/Password/PasswordReset')));
const PasswordSentLinkConfirm = Loadable(lazy(() => import('./features/Authentication/Password/PasswordSentLinkConfirm')));
const VerifyCode = Loadable(lazy(() => import('./features/Authentication/Password/VerifyCode')));
const InviteWorkSpace = Loadable(lazy(() => import('./features/Authentication/InviteMember/index')));
const InviteRegister = Loadable(lazy(() => import('./features/Authentication/InviteMemberRegister/inviteRegister')));
const AcceptOwner = Loadable(lazy(() => import('./features/Authentication/AcceptOwner')));
const VerifyEmail = Loadable(lazy(() => import('./features/Authentication/VerifyEmail')));
const InviteNewMember = Loadable(lazy(() => import('./features/InviteNewMember/index')));
const VerifyAuth = Loadable(lazy(() => import('./features/Authentication/VerifyAuth')));

// Setting
const MyAccount = Loadable(lazy(() => import('./features/MyAccount')));
const AccountGeneral = Loadable(lazy(() => import('./features/MyAccount/components/General')));
const AccountWorkspace = Loadable(lazy(() => import('./features/MyAccount/components/Workspace')));
const AccountQuota = Loadable(lazy(() => import('./features/MyAccount/components/Quota')));

const Candidate = Loadable(lazy(() => import('./features/Candidate')));
const CandidateList = Loadable(lazy(() => import('./features/Candidate/components/CandidatesList')));
const EmployeeList = Loadable(lazy(() => import('./features/Candidate/components/EmployeeList')));
const ShopQuestion = Loadable(lazy(() => import('features/QuestionShop')));
const QuestionPack = Loadable(lazy(() => import('features/QuestionPack')));
const Position = Loadable(lazy(() => import('features/Position')));
const EmailTemplateShop = Loadable(lazy(() => import('features/EmailTemplateShop')));
const Email = Loadable(lazy(() => import('features/EmailTemplate')));
const TimeConvert = Loadable(lazy(() => import('features/TimeConvert')));
const Interview = Loadable(lazy(() => import('features/Interview')));
const InterviewInvitation = Loadable(lazy(() => import('features/Interview/components/InterviewInvitation')));
const InterviewResult = Loadable(lazy(() => import('features/Interview/components/InterviewResult')));
const DecisionScreen = Loadable(lazy(() => import('features/Interview/components/result/DecisionScreen')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));
const FeedBackForm = Loadable(lazy(() => import('features/FeedbackForm/index')));
const ContactUs = Loadable(lazy(() => import('features/ContactUsPage/index')));

// AI Flow
// const AdvancedFlowTemplate = Loadable(lazy(() => import('features/AdvancedFlowTemplate/index')));
const CustomizeFlow = Loadable(lazy(() => import('features/AdvancedFlowTemplate/components/DraftFlow/component/CustomizeFlow')));
const RunningFlows = Loadable(lazy(() => import('features/RunningFlow/index')));
const RunningFlowsDetail = Loadable(lazy(() => import('features/RunningFlowDetail/index')));

// Analytics
const CandidateAnalytics = Loadable(lazy(() => import('features/Analytics/CandidateOverView')));
const CandidateThroughputAnalytics = Loadable(lazy(() => import('features/Analytics/CandidateThroughput')));
const InterviewAnalytics = Loadable(lazy(() => import('features/Analytics/Interview')));

// Booking Interview Slot
const ScheduleInterview = Loadable(lazy(() => import('features/ScheduleInterview')));
const MeetingResultList = Loadable(lazy(() => import('features/ScheduleInterview/components/flows/ReviewMeeting/MeetingResultList')));
const MeetingResultDetail = Loadable(lazy(() => import('features/ScheduleInterview/components/flows/ReviewMeeting/MeetingResultDetail')));

// Embedded Page
const UploadCVEmbedded = Loadable(lazy(() => import('features/UploadCVEmbedded')));

const routes: PartialRouteObject[] = [
    {
        path: '/upload-cv-embedded',
        element: <UploadCVEmbedded />,
    },
    {
        path: `/time-convert/epoch=:epoch&zoneregion=:region`,
        element: <TimeConvert />,
    },
    {
        path: `/verify`,
        element: <VerifyEmail />,
    },
    {
        path: '/verify-authentication',
        element: <VerifyAuth />,
    },
    {
        path: `/invite/:id`,
        element: <InviteNewMember />,
    },
    {
        path: 'authentication',
        children: [
            {
                path: '/',
                element: <Navigate to="/login" replace />,
            },
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'password-recovery',
                element: <PasswordRecovery />,
            },
            {
                path: 'password-reset',
                element: <PasswordReset />,
            },
            {
                path: 'password-sent-link-confirm',
                element: <PasswordSentLinkConfirm />,
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                ),
            },
            {
                path: 'register-invite',
                element: <InviteRegister />,
            },
            {
                path: 'verify-code',
                element: <VerifyCode />,
            },
            {
                path: 'invite-workspace',
                element: <InviteWorkSpace />,
            },
            {
                path: 'accept-owner',
                element: <AcceptOwner />,
            },
        ],
    },
    {
        path: '*',
        element: (
            <AuthGuard>
                <Layout />
            </AuthGuard>
        ),
        children: [
            {
                path: `/`,
                element: <div></div>,
            },
            {
                path: `/workspace`,
                element: <div></div>,
            },
            {
                path: 'workspace/:wp',
                children: [
                    {
                        path: `/`,
                        element: <Dashboard />,
                    },
                    {
                        path: `my-account`,
                        element: <MyAccount />,
                        children: [
                            {
                                path: 'general',
                                element: <AccountGeneral />,
                            },
                            {
                                path: 'workspace',
                                element: <AccountWorkspace />,
                            },
                            {
                                path: 'quota',
                                element: <AccountQuota />,
                            },
                        ],
                    },
                    {
                        path: 'candidate',
                        element: <RedirectOldLink type="CANDIDATE" />,
                    },
                    {
                        path: `candidates`,
                        element: <Candidate />,
                        children: [
                            {
                                path: 'candidate',
                                element: <CandidateList />,
                            },
                            {
                                path: 'employee',
                                element: <EmployeeList />,
                            },
                        ],
                    },

                    {
                        path: `position`,
                        element: <Position />,
                    },

                    {
                        path: `shop-question`,
                        element: <ShopQuestion />,
                    },
                    {
                        path: `library-pack`,
                        element: <QuestionPack />,
                    },

                    {
                        path: `feedback`,
                        element: <FeedBackForm />,
                    },
                    {
                        path: `contact`,
                        element: <ContactUs />,
                    },

                    {
                        path: `email-example`,
                        element: <EmailTemplateShop />,
                    },
                    {
                        path: `advanced-flow/detail/:id`,
                        element: <RedirectOldLink type="AI_FLOW" />,
                    },
                    {
                        path: `flows`,
                        children: [
                            // Temporarily disabled
                            // {
                            //     path: `template-advanced-flow`,
                            //     children: [
                            //         {
                            //             path: '/',
                            //             element: <AdvancedFlowTemplate />,
                            //         },
                            //     ],
                            // },
                            {
                                path: `advanced-flow`,
                                children: [
                                    {
                                        path: '/',
                                        element: <RunningFlows />,
                                    },
                                    {
                                        path: 'detail/:id',
                                        element: <RunningFlowsDetail />,
                                    },
                                    {
                                        path: '/customize',
                                        children: [
                                            {
                                                path: ':flowId',
                                                element: (
                                                    <AdvancedFlowProvider>
                                                        <CustomizeFlow isRunningFlow={true} />
                                                    </AdvancedFlowProvider>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: `draft-advanced-flow`,
                                children: [
                                    { path: '/', element: <DraftFlow /> },
                                    {
                                        path: '/customize',
                                        children: [
                                            {
                                                path: '/:flowId',
                                                element: (
                                                    <AdvancedFlowProvider>
                                                        <CustomizeFlow />
                                                    </AdvancedFlowProvider>
                                                ),
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: `analytics`,
                        children: [
                            {
                                path: '/candidate',
                                element: <CandidateAnalytics />,
                            },
                            { path: '/candidate-throughput', element: <CandidateThroughputAnalytics /> },
                            { path: '/interview', element: <InterviewAnalytics /> },
                        ],
                    },

                    {
                        path: `email`,
                        element: <Email />,
                    },
                    {
                        path: `interview`,
                        children: [
                            {
                                path: '/',
                                element: <Interview />,
                            },
                            {
                                path: 'invitation',
                                element: <InterviewInvitation />,
                            },
                            {
                                path: 'result/:id',
                                element: <InterviewResult />,
                            },
                            {
                                path: 'result/:id/decision/:id',
                                element: <DecisionScreen />,
                            },
                        ],
                    },
                    {
                        path: 'schedule',
                        children: [
                            {
                                path: '/',
                                element: <ScheduleInterview />,
                            },
                            {
                                path: '/position/:positionId',
                                children: [
                                    {
                                        path: '/',
                                        element: <MeetingResultList />,
                                    },
                                    {
                                        path: 'meetingResult/:meetingResultId',
                                        element: <MeetingResultDetail />,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: '*',
                element: <NotFound />,
            },
        ],
    },
];

export default routes;
