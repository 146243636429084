import { combineReducers } from '@reduxjs/toolkit';
import { reducer as draftFlowReducer } from 'features/AdvancedFlowTemplate/components/DraftFlow/slice';
import { reducer as flowTemplateReducer } from 'features/AdvancedFlowTemplate/slice';
import { reducer as candidateAnalyticsReducer } from 'features/Analytics/CandidateOverView/slice';
import { reducer as authReducer } from 'features/Authentication/slice';
import { reducer as candidateReducer } from 'features/Candidate/slice';
import { reducer as emailReducer } from 'features/EmailTemplate/slice';
import { reducer as emailShopReducer } from 'features/EmailTemplateShop/slice';
import { reducer as interviewReducer } from 'features/Interview/slice';
import { reducer as paymentReducer } from 'features/Payment/slice';
import { reducer as positionReducer } from 'features/Position/slice';
import { reducer as questionPackReducer } from 'features/QuestionPack/slice';
import { reducer as shopQuestionReducer } from 'features/QuestionShop/slice';
import { reducer as questionReducer } from 'features/QuestionTemplate/slice';
import { reducer as RunningFlowReducer } from 'features/RunningFlow/slice';
import { reducer as meetingResultReducer } from 'features/ScheduleInterview/components/flows/ReviewMeeting/slice';
import { reducer as directInterviewReducer } from 'features/ScheduleInterview/components/flows/slice';
import tourUIReducer from 'features/TourUI/slice';
import { reducer as memberReducer } from 'features/Workspace/member-slice';
import { reducer as workspaceReducer } from 'features/Workspace/slice';
import { reducer as questionIdsPackReducer } from 'features/QuestionIdsPack/slice';
import { cachedApi } from 'features/Candidate/api';
import { reducer as settingsReducer } from 'layout/slice';

const rootReducer = combineReducers({
    settings: settingsReducer,
    auth: authReducer,
    workspace: workspaceReducer,
    question: questionReducer,
    questionIdsPack: questionIdsPackReducer,
    email: emailReducer,
    candidate: candidateReducer,
    interview: interviewReducer,
    shopQuestion: shopQuestionReducer,
    questionPack: questionPackReducer,
    position: positionReducer,
    emailShop: emailShopReducer,
    member: memberReducer,
    tourUI: tourUIReducer,
    flowTemplate: flowTemplateReducer,
    draftFlow: draftFlowReducer,
    runningFlow: RunningFlowReducer,
    candidateAnalytics: candidateAnalyticsReducer,
    payment: paymentReducer,
    meetingResult: meetingResultReducer,
    directInterview: directInterviewReducer,
    [cachedApi.reducerPath]: cachedApi.reducer,
});

export default rootReducer;
