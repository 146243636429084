import { Button, Container, Stack, Typography } from '@mui/material';
import { Step } from '@pry-financials/react-joyride';
import VideoPlayer from '../../../components/VideoPlayer';

export const CREATE_INTERVIEW_BTN = 'create-interview__btn';
export const WALKTHROUGH_CHECKLIST_ICON = 'walk-through_checklistIcon';

export const steps: Step[] = [
    {
        content: (
            <Container maxWidth="lg" sx={{ padding: '0 !important' }}>
                <Stack spacing={3}>
                    <VideoPlayer videoSource="/static/videos/Video_Inteview_How_to_use.mp4" autoPlay={true} />
                    <Stack spacing={1}>
                        <Typography color="#172b4d" variant="h5" fontWeight="00">
                            Video Interview
                        </Typography>
                        <Typography variant="h6" fontWeight="500">
                            Set up and keep track of your candidates' recorded video interviews of your candidates
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        ),
        locale: {
            next: (
                <Button variant="contained" color="primary" component="span" sx={{ width: 'fit-content' }}>
                    Let's go
                </Button>
            ),
        },
        styles: {
            options: {
                width: 400,
            },
            tooltip: {
                maxHeight: '90vh',
                overflow: 'scroll',
                paddingBottom: '67.5px',
            },
            tooltipFooter: {
                backgroundColor: '#fff',
                position: 'absolute',
                padding: '0 15px 15px',
                bottom: 0,
                left: 0,
                right: 0,
            },
            spotlight: {
                borderRadius: '999px',
            },
        },
        spotlightClicks: false,
        disableBeacon: true,
        placement: 'top-start',
        target: `#${WALKTHROUGH_CHECKLIST_ICON}`,
    },
    {
        content: (
            <Typography color="#172b4d" variant="h5" fontWeight={600}>
                Click Here
            </Typography>
        ),

        styles: {
            tooltip: {
                padding: '0 15px',
                minWidth: '200px',
                width: 'initial',
            },
            tooltipContent: {
                padding: '10px 10px 0',
            },
            spotlight: {
                borderRadius: '10px',
            },
        },
        isFixed: true,
        spotlightClicks: true,
        disableBeacon: true,
        target: `#${CREATE_INTERVIEW_BTN}`,
    },
];

