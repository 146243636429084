import React from 'react';
import { Card, Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const UpcomingInterviewCardSkeleton = () => {
    return (
        <Card className="upcoming-interview-card">
            <Grid container>
                <Grid
                    item
                    xs={3}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1,
                    }}
                >
                    <Typography variant="h5">
                        <Skeleton width={40} />
                    </Typography>
                    <Typography variant="body1">
                        <Skeleton width={30} />
                    </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid
                    item
                    xs={7}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        px: 2,
                        py: 1,
                    }}
                >
                    <Typography color="textPrimary">
                        <Skeleton />
                    </Typography>
                    <Typography color="textSecondary" variant="body2" gutterBottom>
                        <Skeleton />
                    </Typography>
                    <Typography
                        variant="body2"
                        color="error"
                        sx={{
                            fontStyle: 'italic',
                        }}
                    >
                        <Skeleton width="50%" />
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <IconButton>
                        <ArrowForwardIosIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Card>
    );
};

export default UpcomingInterviewCardSkeleton;

