import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { QuestionPack, QuestionPackReq } from 'types/questionsPack';
import api from './api';

interface QuestionPackState {
    questionPacks: QuestionPack[];
    isLoading: boolean;
    error: boolean;
}

const initialState: QuestionPackState = {
    questionPacks: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'questionPack',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Question */
        list: (state, action) => {
            state.questionPacks = action.payload;
            state.isLoading = false;
        },

        /* Create Question */
        create: (state, action) => {
            state.questionPacks.unshift(action.payload);
            state.isLoading = false;
        },

        /* Update Question */
        update: (state, action) => {
            const question = action.payload;
            const index = state.questionPacks.findIndex((x) => x.id === question.id);

            if (index > -1) {
                state.questionPacks[index] = question;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove Question */
        remove: (state, action) => {
            const index = state.questionPacks.findIndex((x) => x.id === action.payload);
            if (index > -1) {
                state.questionPacks.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List All Question Pack
 *
 * @returns
 */
export const listQuestionPack =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response = await api.getAll();
            dispatch(slice.actions.list(response));
        } catch (error) {
            console.log(error.message);
        }
    };

/**
 * Create Position
 *
 * @param request
 * @returns
 */
export const createQuestionPack =
    (request: QuestionPackReq): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.create(request);
        const allQuestionTemplateList: any = await api.getQuestionTemplate();

        const filteredResponse = {
            id: response.id,
            name: response.name,
            questions: response.questions.map((v) => {
                return allQuestionTemplateList.find((q) => q.id === v);
            }),
        };

        dispatch(slice.actions.create(filteredResponse));
    };

/**
 * Update Position
 * @param id
 * @param request
 * @returns
 */
export const updateQuestionPack =
    (questionId: string, request: QuestionPackReq): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(questionId, request);

        const allQuestionTemplateList: any = await api.getQuestionTemplate();

        const filteredResponse = {
            id: response.id,
            name: response.name,
            questions: response.questions.map((v) => {
                return allQuestionTemplateList.find((q) => q.id === v);
            }),
        };
        dispatch(slice.actions.update(filteredResponse));
    };

export const deleteQuestionPack =
    (questionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.delete(questionId);
        dispatch(slice.actions.remove(questionId));
    };

export const importQuestionPackToWorkspace =
    (workspaceId: string, questionPackId: string): AppThunk =>
    async (): Promise<any> => {
        const response = api.importQuestionPackToWorkspace(workspaceId, questionPackId);

        return response;
    };

export const { reducer } = slice;
export default slice;
