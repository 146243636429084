import createSvgIcon from '@mui/material/utils/createSvgIcon';

const LightingIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
            xmlns="http://www.w3.org/2000/svg"
            d="M7.75 18.167q-.188.187-.448.177-.26-.011-.448-.198-.166-.167-.187-.417-.021-.25.125-.437l4.041-5.625-6.75-.646q-.541-.042-.718-.563-.177-.52.239-.875l8.646-7.75q.188-.187.448-.177.26.011.448.198.166.167.187.417.021.25-.125.437L9.167 8.333l6.75.646q.541.042.718.563.177.52-.239.875Z"
        />
    </svg>,
    'LightingIcon'
);

export default LightingIcon;

