import api from 'layout/api';
import { useEffect, useState } from 'react';
import { Language } from 'types/language';
import useMounted from './useMounted';
import { generateFlagLink } from 'utils/generateFlagLink';

const useGetListLanguage = (): Language[] => {
    const [languages, setLanguages] = useState<Language[]>([]);
    const mounted = useMounted();

    useEffect(() => {
        const getAllLanguage = async () => {
            try {
                const languages: any = await api.getLanguages();
                if (mounted.current) {
                    if (Array.isArray(languages)) {
                        //Replace language flags with static resource if exist
                        const updatedLanguage = languages.map((language) => ({
                            ...language,
                            icon: generateFlagLink(language.icon),
                        }));
                        setLanguages(updatedLanguage);
                    }
                }
            } catch (error) {
                console.log('The following error occurred:', error);
            }
        };

        getAllLanguage();
    }, [mounted]);

    return languages;
};

export default useGetListLanguage;
