import axiosClient from 'lib/axios';
import { Quota } from 'types/quota';

const prefixUrl = 'recruiter';

const api = {
    /* Get Profile */
    profile: () => {
        return axiosClient.get(`${prefixUrl}/self`);
    },

    /* Create Workspace */
    createWorkpsace: (request) => {
        return axiosClient.post(`${prefixUrl}/new-workspace`, request);
    },

    /* Request for email confirmation */
    emailRequest: (request: any) => {
        return axiosClient.post(`/auth/send-email-verification`, request);
    },

    /* Update recruiter Profile */
    updateProfile: (request: any) => {
        return axiosClient.patch(`${prefixUrl}/self`, request);
    },

    /* Get Invite Link  */
    getInviteLink: () => {
        return axiosClient.get(`/invite/rg`);
    },

    /* Invite through Forms  */
    postInvite: (request: any) => {
        return axiosClient.post(`/invite/rg`, request);
    },
    getQuotaWorkspace: (workspaceId: string) => {
        return axiosClient.get<Quota, Quota>(`/workspaces/${workspaceId}/quota`);
    },
};

export default api;
