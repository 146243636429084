import { Grid, Card, Box, CircularProgress, Typography, CardContent, CardActions, Button, Skeleton } from '@mui/material';
import useApplyTranslation from 'hooks/useApplyTranslation';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'store';
import { Ticket, TodoType } from '../types';
import api from '../api';
import { workspaceSelector } from 'hooks/useSelector';
import KabanBoardProcessingTicket from './KabanBoardProcessingTicket';
import KabanBoardTicket from './KabanBoardTicket';
import KabanBoardTicketSkeleton from './KabanBoardTicket.skeleton';

const KabanBoard = () => {
    const translatedWords = useApplyTranslation();
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [todosTickets, setTodoTickets] = useState<Ticket[]>([]);
    const [processingTickets, setProcessingTickets] = useState<Ticket[]>([]);
    const workspace = useSelector(workspaceSelector);

    useEffect(() => {
        async function getTickets() {
            setLoadingTickets(true);
            try {
                const [resForToDo, resForProcessing] = await Promise.all([
                    api.getKabanBoardTickets(workspace.activated?.id, TodoType.NeedDoing),
                    api.getKabanBoardTickets(workspace.activated?.id, TodoType.Processing),
                ]);
                if (resForToDo.docs.length > 0) setTodoTickets((values) => (values = resForToDo.docs));
                if (resForProcessing.docs.length > 0) setProcessingTickets((values) => (values = resForProcessing.docs));
            } catch (err) {
                console.error(err);
            } finally {
                setLoadingTickets(false);
            }
        }
        if (workspace.activated) getTickets();
    }, [workspace.activated]);

    return (
        <Card sx={{ height: '100%' }}>
            <CardContent>
                <Grid
                    className="KabanBoard"
                    container
                    sx={{
                        display: 'flex',
                        flexFlow: 'row',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'space-around',
                        pr: 2,
                        flexWrap: 'wrap',
                    }}
                >
                    <Grid item xs={6} sx={{ mb: 2 }}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: 'inline-block',
                                px: 2,
                                fontStyle: 'italic',
                                background: '#E1E4E8',
                                borderRadius: 20,
                            }}
                        >
                            {translatedWords.to_do}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ mb: 2, alignSelf: 'start' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                display: 'inline-block',
                                px: 2,
                                fontStyle: 'italic',
                                background: '#F0E7F6',
                                borderRadius: 20,
                            }}
                        >
                            {translatedWords.processing}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{ display: 'flex', maxHeight: '380px', overflow: 'auto', flexFlow: 'row', flexWrap: 'wrap' }}
                    >
                        <Grid item container xs={6} sx={{ alignSelf: 'start', pt: 1, pl: 1 }}>
                            {loadingTickets
                                ? Array.from({ length: 3 }).map((item, index) => (
                                      <Grid item xs={12} sx={{ mb: 2, mr: 1 }} key={index}>
                                          <KabanBoardTicketSkeleton />
                                      </Grid>
                                  ))
                                : todosTickets.map((ticket, index) => (
                                      <Grid item xs={12} key={index} sx={{ mb: 2, mr: 2 }}>
                                          <KabanBoardTicket
                                              key={index}
                                              position={ticket.positionName}
                                              action={ticket.action}
                                              candidate={ticket.candidateName}
                                              id={ticket.id}
                                              component={ticket.component}
                                              componentID={ticket.componentId}
                                              positionId={ticket.positionId}
                                          />
                                      </Grid>
                                  ))}
                        </Grid>
                        <Grid item container xs={6} sx={{ alignSelf: 'start', pt: 1, pl: 1 }}>
                            {loadingTickets
                                ? Array.from({ length: 2 }).map((item, index) => (
                                      <Grid item xs={12} sx={{ mb: 2, mr: 1 }} key={index}>
                                          <KabanBoardTicketSkeleton />
                                      </Grid>
                                  ))
                                : processingTickets.map((ticket, index) => (
                                      <Grid item xs={12} key={index} sx={{ mb: 2, mr: 1 }}>
                                          <KabanBoardTicket
                                              key={index}
                                              position={ticket.positionName}
                                              action={ticket.action}
                                              candidate={ticket.candidateName}
                                              id={ticket.id}
                                              component={ticket.component}
                                              componentID={ticket.componentId}
                                              positionId={ticket.positionId}
                                          />
                                      </Grid>
                                  ))}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default KabanBoard;

