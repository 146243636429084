import { PollingWithPosibleSlot } from 'hooks/scheduleInterview/useGetPossibleSlots';
import axiosClient from 'lib/axios';
import { Pagination } from 'types/general';
import { MeetingResult, MeetingScoreResult, ReviewMeetingPosition } from 'types/interviewMeeting';
import {
    LockedInterviewPayloadReq,
    LockedInvitationPayload,
    PollingExtendedInformation,
    PollingMeeting,
    PollingPayloadReq,
    QueryPollingByTimeReq,
    QueryPollingReq,
    SchedulePolling,
} from 'types/interviewSchedule';

const prefixUrl = 'polling';

export const scheduleApi = {
    /**Get polling with query */
    getPollings: (workspaceId: string, query: QueryPollingReq): Promise<SchedulePolling> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}`, { params: query });
    },

    getPolling: (workspaceId: string, pollingId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/${pollingId}`);
    },
    getUnschedulePollings: (workspaceId: string, query: Pick<QueryPollingReq, 'page' | 'limit'>): Promise<any> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/unschedule`, { params: query });
    },
    getUnscheduleNUnsuccessPollings: (workspaceId: string, query: Pick<QueryPollingReq, 'page' | 'limit'>): Promise<any> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/unschedule-unsuccess`, { params: query });
    },

    getPollingBaseOnTimes: (workspaceId: string, query: QueryPollingByTimeReq): Promise<any> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/all`, { params: query });
    },
    getPollingWithPossibleSlot: (workspaceId: string): Promise<PollingWithPosibleSlot[]> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/arrange`);
    },

    /** Get locked-polling's configured information */
    getExtendedInformation: (workspaceId: string, pollingId: string): Promise<PollingExtendedInformation> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/extended/${pollingId}`);
    },

    /** Get positions that has appeared in meeting call in direct interview */
    getReviewPollingPosition: (workspaceId: string): Promise<ReviewMeetingPosition[]> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/count-scored`);
    },

    /** Get meeting call result that take place in direct interview base on position */
    getDirectInterviewCallResults: (
        workspaceId: string,
        positionId: string,
        paginationQuery: Pick<Pagination, 'page' | 'limit'>
    ): Promise<{ scoredList: MeetingResult[] } & Pagination> => {
        const params = { positionId, ...paginationQuery };
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/scored-by-position`, {
            params,
        });
    },

    getScoringDetail: (workspaceId: string, meetingResultId: string): Promise<MeetingScoreResult[]> => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/scoring/${meetingResultId}`);
    },

    /** Create polling */
    createPolling: (workspaceId: string, request: PollingPayloadReq): Promise<SchedulePolling> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    createUnschedulePolling: (workspaceId: string, request: { candidateId: string; positionId: string }): Promise<SchedulePolling> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/unschedule`, request);
    },

    createLockedInterview: (workspaceId: string, request: LockedInterviewPayloadReq): Promise<SchedulePolling> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/locked`, request);
    },

    /**Lock slot for direct interview */
    lockSlots: (workspaceId: string, lockList: { id: string; slotId: string }[]) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/lock`, { lockList });
    },

    /**After all panelists have chose their slots, send these slots to candidate */
    sendInviteCandidate: (workspaceId: string, pollingId: string) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/invite-candidate/${pollingId}`);
    },

    /** Create and get the video call link for direct interview */
    createMeeting: (workspaceId: string, pollingId: string): Promise<PollingMeeting> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/internal-meeting/${pollingId}`);
    },

    /** Decision candidate per meeting  */
    makeDecisionPerMeeting: (workspaceId: string, meetingId: string, request: { result: boolean }) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/decision/${meetingId}`, request);
    },

    updatePolling: (workspaceId: string, pollingId: string, request: Partial<PollingPayloadReq>): Promise<SchedulePolling> => {
        return axiosClient.patch(`/workspaces/${workspaceId}/${prefixUrl}/${pollingId}`, request);
    },

    /** Update locked interview configured information */
    updateLockedInvitation: (workspaceId: string, pollingId: string, request: LockedInvitationPayload) => {
        return axiosClient.patch(`/workspaces/${workspaceId}/${prefixUrl}/${pollingId}/final`, request);
    },
};
