import React from 'react';
import { Card, CardContent, CardHeader, Divider, Grid, Skeleton, Stack, Switch } from '@mui/material';

const OpenPositionCardSkeleton = () => {
    return (
        <Card className="open-position-card">
            <CardHeader
                title={<Skeleton variant="rectangular" height={25} width={200} />}
                action={
                    <Stack>
                        <Switch
                            checked={false}
                            sx={{
                                '&.MuiSwitch-root .MuiSwitch-switchBase': {
                                    color: 'primary.contrastText',
                                },
                                '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track ': {
                                    backgroundColor: 'success.main',
                                    opacity: 1,
                                },
                            }}
                        />
                    </Stack>
                }
            />
            <Divider />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Skeleton />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton />
                    </Grid>
                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Skeleton variant="rectangular" width={30} height={25} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default OpenPositionCardSkeleton;

