import axiosClient from 'lib/axios';
import { AdvancedFlow, AdvancedFlowSteps } from 'types/flow';

const prefixUrl = 'flow';

export const advancedFlowApi = {
    /* Get all flow */
    getAll: (workspaceId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/draft`);
    },

    /* Get a flow */
    getFlow: (workspaceId: string, flowId: string): Promise<AdvancedFlow> => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`);
    },

    /* Create a flow */
    createFlow: (workspaceId: string, request: any) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /* Activate/Deactivate a flow. Request:
    {
           "active": true/false
    }  */
    updateFlowStatus: (workspaceId: string, flowId: string, request: { active: boolean }): Promise<AdvancedFlow> => {
        return axiosClient.patch(`/workspaces/${workspaceId}/${prefixUrl}/${flowId}/status`, request);
    },

    updateFlow: (workspaceId: string, flowId: string, request: Partial<AdvancedFlow>): Promise<AdvancedFlow> => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`, request);
    },
    updateStepStatus: (
        workspaceId: string,
        flowId: string,
        request: { component: AdvancedFlowSteps; enable: boolean }
    ): Promise<AdvancedFlow> => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${flowId}/status-component`, request);
    },

    /* Delete flow */
    deleteFlow: (workspaceId: string, flowId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`);
    },
};
