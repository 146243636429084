import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearAuthTokens, setAuthTokens } from 'axios-jwt';
import accountApi from 'features/MyAccount/api';
import { AppThunk } from 'store';
import { AuthenticationKey, GoogleOAuth, User } from 'types/user';
import { configAppInit } from 'utils/config';
import api from './api';
import { recruiterKeyApi } from 'api';

interface AuthState {
    isInitialized: boolean;
    isAuthenticated: boolean;
    isLoaded: boolean;
    isError: boolean;
    message: string;
    user: User | null;
    authenticationKeys: AuthenticationKey[];
}

const initialState: AuthState = {
    isInitialized: false,
    isAuthenticated: false,
    isLoaded: false,
    isError: false,
    message: '',
    user: null,
    authenticationKeys: [],
};

/**
 * Authentication Init
 * Check Authenticate
 */
export const authInit = createAsyncThunk('auth/init', async () => {
    let initState: AuthState = {
        ...initialState,
        isInitialized: true,
    };

    try {
        const response: any = await accountApi.profile();

        const user = response;

        initState.user = user;
        initState.isAuthenticated = true;

        return initState;
    } catch (error) {
        return initState;
    }
});

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        /**
         * Set Error
         * @param state
         * @param action
         */
        setError: (state: AuthState, action: PayloadAction<any>) => {
            state.isError = true;
            state.message = action.payload.message;
        },
        // reset error
        resetError: (state: AuthState) => {
            state.isError = false;
            state.message = '';
        },
        /* Set Authenticated */
        setLoaded: (state, action) => {
            state.isLoaded = action.payload;
        },

        getAuthenticationKeys: (state: AuthState, action: PayloadAction<AuthenticationKey[]>) => {
            state.authenticationKeys = action.payload;
        },

        createAuthenticationKey: (state: AuthState, action: PayloadAction<AuthenticationKey>) => {
            state.authenticationKeys.unshift(action.payload);
        },

        updateAuthenticationKey: (state: AuthState, action: PayloadAction<AuthenticationKey>) => {
            const updatedItem = action.payload;
            state.authenticationKeys = state.authenticationKeys.map((authenticationKey) =>
                authenticationKey._id == updatedItem._id ? updatedItem : authenticationKey
            );
        },

        deleteAuthenticationKey: (state: AuthState, action: PayloadAction<string>) => {
            const deletedId = action.payload;

            state.authenticationKeys = state.authenticationKeys.filter((authenticationKey) => authenticationKey._id !== deletedId);
        },

        /* Login */
        login: (state: AuthState, action: PayloadAction<any>) => {
            const data = action.payload;
            setAuthTokens({
                accessToken: data.token.access_token,
                refreshToken: data.token.refresh_token,
            });
            state.isAuthenticated = true;
            state.user = data.user;
        },

        /* Logout */
        logout: (state: AuthState, action: PayloadAction<string | null>) => {
            const path = action.payload;
            state.isAuthenticated = false;
            state.isLoaded = false;
            state.user = null;
            configAppInit.remove();
            clearAuthTokens();
            localStorage.clear();
            window.location.href = path || '/';
        },
        clearSession: (state) => {
            state.isAuthenticated = false;
            state.isLoaded = false;
            state.user = null;
            configAppInit.remove();
            clearAuthTokens();
            localStorage.clear();
        },

        update: (state, action) => {
            const user = action.payload;

            state.user = user;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authInit.fulfilled, (state: AuthState, action: PayloadAction<any>) => {
            state = Object.assign(state, action.payload);
        });
    },
});

/**
 * Save authentication key on behalf token
 * @returns
 */
export const getAuthenticationKeys = (): AppThunk<Promise<AuthenticationKey[]>> => async (dispatch) => {
    const keys = await recruiterKeyApi.getAuthenticationKeys();
    if (Array.isArray(keys) && keys.length > 0) dispatch(slice.actions.getAuthenticationKeys(keys));

    return keys;
};

/**
 * Create authentication key on behalf token
 * @returns
 */
export const createAuthenticationKey = (): AppThunk<Promise<AuthenticationKey>> => async (dispatch) => {
    const key = await recruiterKeyApi.createAuthenticationKey();
    dispatch(slice.actions.createAuthenticationKey(key));

    return key;
};

/**
 * Create authentication key on behalf token
 */
export const deleteAuthenticationKey =
    (key: string): AppThunk<Promise<string>> =>
    async (dispatch) => {
        await recruiterKeyApi.deleteAuthenticationKey(key);
        dispatch(slice.actions.deleteAuthenticationKey(key));

        return key;
    };

/**
 * Google Login
 * @param token
 * @returns
 */
export const googleLogin =
    (request: GoogleOAuth, inviterId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            const response: any = await api.googleLogin(request.token);
            dispatch(slice.actions.login(response));
        } catch (error) {
            if (error.statusCode === 401) {
                dispatch(googleRegister(request, inviterId));
            } else {
                dispatch(slice.actions.setError(error));
            }
        }
    };

export const googleRegister =
    (request: GoogleOAuth, inviterId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            const response = await api.googleRegister(request.token, request.email, request.firstName, request.lastName, inviterId);
            dispatch(slice.actions.login(response));
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };

/**
 * Login
 * @param email
 * @param password
 * @returns
 */
export const login =
    (email: string, password: string): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            const response: any = await api.login(email, password);

            dispatch(slice.actions.login(response));
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };
export const loginWithFastRegister =
    (email: string, inviterId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            const response: any = await api.loginFastRegister(email, inviterId);

            dispatch(slice.actions.login(response));
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };

export const register =
    (email: string, password: string, firstName: string, lastName: string, inviterId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response = await api.register(email, password, firstName, lastName, inviterId);
        return response;
    };

export const registerInvite =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<any> => {
        const response = await api.inviteRegister(workspaceId, request);
        return response;
    };

export const updateProfile =
    (request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.updateProfile(request);
        dispatch(slice.actions.update(response));
    };

export const sendMagicLink =
    (request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            await api.sendMagicLink(request);
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };

export const loginMagicLink =
    (request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response: any = await api.loginMagicLink(request);

            dispatch(slice.actions.login(response));
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };

export const sendPasswordResetLinkOTP =
    (request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            await api.requestPasswordResetOTP(request);
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };

export const resetPasswordWithOTP =
    (request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            await api.resetPasswordWithOTP(request);
            dispatch(slice.actions.resetError());
        } catch (error) {
            dispatch(slice.actions.setError(error));
        }
    };

export const checkAccount =
    (email: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.checkExistAccount(email);
        return response;
    };

export const { reducer } = slice;
export default slice;

