import { Box } from '@mui/material';
import DashIcon from 'assets/icons/DashIcon';
import { FC } from 'react';

interface DashedLineProps {
    lineLength: number;
}

const DashedLine: FC<DashedLineProps> = (props) => {
    const { lineLength } = props;

    return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
            {Array(lineLength)
                .fill('DashIcon')
                .map((x, index) => (
                    <DashIcon key={index} />
                ))}
        </Box>
    );
};

export default DashedLine;

