import axiosClient from 'lib/axios';
import { Email, EmailPayload } from 'types/email';

const prefixUrl = 'email-template';

const api = {
    /**
     * Get all Emails Template from Shop
     * @param workspaceId
     */
    getAll: (): Promise<Email[]> => {
        return axiosClient.get(`shop/${prefixUrl}`);
    },

    /* Create Email */
    create: (request: EmailPayload) => {
        return axiosClient.post(`shop/${prefixUrl}`, request);
    },

    /* Update Email */
    update: (emailId: string, request: EmailPayload) => {
        return axiosClient.patch(`shop/${prefixUrl}/${emailId}`, request);
    },
    /* Delete Email */
    delete: (emailId: string) => {
        return axiosClient.delete(`shop/${prefixUrl}/${emailId}`);
    },
};

export default api;
