import React, { ReactNode } from 'react';
import { ListItemProps } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import DraftsIcon from '@mui/icons-material/Drafts';
import FeedbackIcon from '@mui/icons-material/Feedback';
import UseAnimations from 'react-useanimations';
import activity from 'react-useanimations/lib/activity';

import ChartSquareBarIcon from 'assets/icons/ChartSquareBar';
import CogIcon from 'assets/icons/Cog';
import Calendar from 'assets/icons/Calendar';
import CandidateIcon from 'assets/icons/CandidateIcon';
import CandidateResumeIcon from 'assets/icons/CandidateResume';
import InterviewAnalyticsIcon from 'assets/icons/InterviewAnalyticsIcon';
import DotIcon from 'assets/icons/Dot';
import { GENERAL_MENU_CONSTANT, LIBRARY_MENU_CONSTANT } from 'utils/constants';
import { VIDEO_INTERVIEW_MENU } from 'features/TourUI/steps/dashboardSteps';

export interface Item {
    path?: string;
    icon?: ReactNode;
    info?: ReactNode;
    children?: Item[];
    title: { constant: string; defaultValue: string };
    action?: () => void;
    noEnd?: boolean;
    ListItemProps?: ListItemProps;
}

interface MenuItem {
    title: { constant: string; defaultValue: string };
    items: Item[];
}

export const menus = (workspaceId): MenuItem[] => {
    return [
        {
            title: { constant: GENERAL_MENU_CONSTANT, defaultValue: 'GENERAL' },
            items: [
                {
                    title: { constant: 'general_dash', defaultValue: 'Dashboard' },
                    path: `/workspace/${workspaceId}`,
                    icon: <ChartSquareBarIcon fontSize="small" />,
                },
                {
                    title: { constant: 'general_setting', defaultValue: 'Settings' },
                    path: `/workspace/${workspaceId}/my-account`,
                    icon: <CogIcon />,
                    children: [
                        {
                            title: { constant: 'info', defaultValue: 'Candidate' },
                            path: `/workspace/${workspaceId}/my-account/general`,
                            icon: <DotIcon />,
                        },
                        {
                            title: { constant: 'wrksp', defaultValue: 'Employee' },
                            path: `/workspace/${workspaceId}/my-account/workspace`,
                            icon: <DotIcon />,
                        },
                        {
                            title: { constant: 'package_payment', defaultValue: 'Employee' },
                            path: `/workspace/${workspaceId}/my-account/quota`,
                            icon: <DotIcon />,
                        },
                    ],
                },
            ],
        },

        {
            title: { constant: 'wrksp_menu', defaultValue: 'YOUR WORKSPACE' },
            items: [
                {
                    title: { constant: 'af_menu', defaultValue: 'AI Flows' },
                    path: `/workspace/${workspaceId}/flows`,
                    icon: <UseAnimations animation={activity} strokeColor="#6012BA" speed={0.3} />,
                    children: [
                        // Temporarily disabled, its component is ListFlowTemplate
                        // {
                        //     title: { constant: 'af_flowtemplate', defaultValue: 'Flow Template' },
                        //     path: `/workspace/${workspaceId}/flows/template-advanced-flow`,
                        //     icon: <DotIcon />,
                        // },
                        {
                            title: { constant: 'af_runningflows', defaultValue: 'Running Flows' },
                            path: `/workspace/${workspaceId}/flows/advanced-flow`,
                            icon: <DotIcon />,
                            noEnd: true,
                        },
                        {
                            title: { constant: 'af_draftflows', defaultValue: 'Draft Flows' },
                            path: `/workspace/${workspaceId}/flows/draft-advanced-flow`,
                            icon: <DotIcon />,
                            noEnd: true,
                        },
                    ],
                },
                {
                    title: { constant: 'wrksp_candidate', defaultValue: 'Talent Pool' },
                    path: `/workspace/${workspaceId}/candidates`,
                    icon: <ContactMailIcon />,
                    children: [
                        {
                            title: { constant: 'candidate_keyword', defaultValue: 'Candidate' },
                            path: `/workspace/${workspaceId}/candidates/candidate`,
                            icon: <DotIcon />,
                        },
                        {
                            title: { constant: 'employee', defaultValue: 'Employee' },
                            path: `/workspace/${workspaceId}/candidates/employee`,
                            icon: <DotIcon />,
                        },
                    ],
                },
                {
                    title: { constant: 'wrksp_interview', defaultValue: 'Video Interviews' },
                    path: `/workspace/${workspaceId}/interview`,
                    icon: <ImportantDevicesIcon fontSize="small" />,
                    noEnd: true,
                    ListItemProps: { id: VIDEO_INTERVIEW_MENU },
                },
                {
                    title: { constant: 'Di_Inter_Schedule', defaultValue: 'Direct Interview Scheduling' },
                    path: `/workspace/${workspaceId}/schedule`,
                    icon: <Calendar fontSize="small" />,
                },
                {
                    title: { constant: 'position_question_menu', defaultValue: 'Position & Question' },
                    path: `/workspace/${workspaceId}/position`,
                    icon: <BusinessCenterIcon fontSize="small" />,
                },
                {
                    title: { constant: 'wrksp_template', defaultValue: 'Email Templates' },
                    path: `/workspace/${workspaceId}/email`,
                    icon: <DraftsIcon fontSize="small" />,
                },
            ],
        },

        {
            title: { constant: 'analytics_menu', defaultValue: 'ANALYTICS' },
            items: [
                {
                    title: { constant: 'candidate', defaultValue: 'Candidate' },
                    path: `/workspace/${workspaceId}/analytics/candidate`,
                    icon: <CandidateIcon fontSize="small" />,
                },
                {
                    title: { constant: 'analytics_thput', defaultValue: 'Candidate Throughput' },
                    path: `/workspace/${workspaceId}/analytics/candidate-throughput`,
                    icon: <CandidateResumeIcon fontSize="small" />,
                },
                {
                    title: { constant: 'interview', defaultValue: 'Interview' },
                    path: `/workspace/${workspaceId}/analytics/interview`,
                    icon: <InterviewAnalyticsIcon fontSize="small" />,
                },
            ],
        },

        {
            title: { constant: LIBRARY_MENU_CONSTANT, defaultValue: 'LIBRARY' },
            items: [
                {
                    title: { constant: 'library_question', defaultValue: 'Questions' },
                    path: `/workspace/${workspaceId}/shop-question`,
                    icon: <QuestionAnswerIcon fontSize="small" />,
                },
                {
                    title: { constant: 'SamplePosition_Library', defaultValue: 'Question Packs' },
                    path: `/workspace/${workspaceId}/library-pack`,
                    icon: <BusinessCenterIcon fontSize="small" />,
                },
                {
                    title: { constant: 'library_email_template', defaultValue: 'Email Template' },
                    path: `/workspace/${workspaceId}/email-example`,
                    icon: <DraftsIcon fontSize="small" />,
                },
            ],
        },

        {
            title: { constant: 'support_menu', defaultValue: 'SUPPORT' },
            items: [
                {
                    title: { constant: 'support_feedback', defaultValue: 'Feedback' },
                    path: `/workspace/${workspaceId}/feedback`,
                    icon: <FeedbackIcon fontSize="small" />,
                },
                {
                    title: { constant: 'support_contactinfo', defaultValue: 'Contact us' },
                    path: `/workspace/${workspaceId}/contact`,
                    icon: <ContactPhoneIcon fontSize="small" />,
                },

                // {
                //     title: 'Email Example',
                //     path: `/workspace/${workspaceId}/email-example`,
                //     icon: <DraftsIcon fontSize="small" />,
                // },
            ],
        },
    ];
};

