import { Box, Fab, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import QuizIcon from '@mui/icons-material/Quiz';
import Calendar from 'assets/icons/Calendar';
import XIcon from 'assets/icons/X';
import useApplyTranslation from 'hooks/useApplyTranslation';
import React, { MouseEvent, ReactNode, useMemo, useState } from 'react';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { useDispatch, useSelector } from 'store';
import { workspaceSelector } from 'hooks/useSelector';
import { useNavigate } from 'react-router';
import {
    confirmFinishedAllTourUI,
    resetAdvancedFlowState,
    resetDirectInterviewState,
    resetPositionState,
    resetVideoInterviewState,
    updateIsPauseTourUi,
} from 'features/TourUI/slice';
import { WALKTHROUGH_CHECKLIST_ICON } from 'features/TourUI/steps/InterviewSteps';
import ConfirmModal from './ConfirmModal';
import { detectCompleteTourUI } from 'features/TourUI/utils/detectCompleteTourUI';

interface IWalkThroughChecklistProps {}

interface WalkThroughItem {
    icon: JSX.Element;
    link: string;
    title: string;
    isCompleted: boolean;
    action: () => void;
}

const WalkThroughChecklist: React.FunctionComponent<IWalkThroughChecklistProps> = (props) => {
    const translatedWords = useApplyTranslation();
    const navigate = useNavigate();
    const workspace = useSelector(workspaceSelector);
    const dispatch = useDispatch();
    const tourUIState = useSelector((state) => state.tourUI);
    const { isCompleteVideoInterview, isCompleteDirectInterview, isCompleteAdvancedFlow, isCompletePosition } =
        detectCompleteTourUI(tourUIState);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(updateIsPauseTourUi(true));
    };

    const handleClose = () => {
        setAnchorEl(null);
        dispatch(updateIsPauseTourUi(false));
    };

    const open = Boolean(anchorEl);

    const walkThroughs = useMemo<WalkThroughItem[]>(() => {
        const workspaceId = workspace.activated?.id;
        if (!workspaceId) return [];

        return [
            {
                icon: <ImportantDevicesIcon fontSize="small" color="inherit" />,
                title: "Video Interview: Set up and keep track of your candidates' recorded video interviews",
                link: `/workspace/${workspaceId}/interview`,
                isCompleted: isCompleteVideoInterview,
                action: () => dispatch(resetVideoInterviewState()),
            },
            // {
            //     icon: <ContactMailIcon fontSize="small" color="inherit" />,
            //     title: "Candidates/ Talent Pool: All your candidates' profile is stored here",
            //     link: `/workspace/${workspaceId}/candidate`,
            //     isCompleted: false,
            // },
            {
                icon: <BusinessCenterIcon fontSize="small" color="inherit" />,
                title: 'Positions & Questions: Setting up for each position on its own questions pack and scoring criteria to evaluate candidates for each specific position.',
                link: `/workspace/${workspaceId}/position?tabValue=0`,
                isCompleted: isCompletePosition,
                action: () => dispatch(resetPositionState()),
            },
            {
                icon: <Calendar fontSize="small" color="inherit" />,
                title: 'Direct Interview: Setting up a face-2-face between panelists and candidates easily',
                link: `/workspace/${workspaceId}/schedule`,
                isCompleted: isCompleteDirectInterview,
                action: () => dispatch(resetDirectInterviewState()),
            },
            {
                icon: <AutoGraphIcon fontSize="small" color="inherit" />,
                title: 'Automated recruitment: Do those above things automatically with AI',
                link: `/workspace/${workspaceId}/flows/advanced-flow`,
                isCompleted: isCompleteAdvancedFlow,
                action: () => dispatch(resetAdvancedFlowState()),
            },
        ];
    }, [
        workspace.activated?.id,
        isCompleteVideoInterview,
        isCompleteDirectInterview,
        isCompleteAdvancedFlow,
        isCompletePosition,
        dispatch,
    ]);

    const [openConfirm, setOpenConfirm] = useState(false);
    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleConfirmFinishAllWalkThrough = () => {
        dispatch(confirmFinishedAllTourUI());
    };

    return (
        <>
            <Box
                sx={{
                    bottom: 0,
                    margin: (theme) => theme.spacing(4),
                    position: 'fixed',
                    right: 0,
                    zIndex: (theme) => theme.zIndex.speedDial,
                    '&:hover #instructionIcon': {
                        display: 'block',
                    },
                }}
            >
                <Tooltip title="Instruction">
                    <Fab color="primary" onClick={handleClick} size="medium" id={WALKTHROUGH_CHECKLIST_ICON}>
                        <QuizIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            </Box>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        maxWidth: '100%',
                        width: 512,
                    },
                }}
            >
                <Stack spacing={1} sx={{ pt: 1, px: 2, pb: 2 }}>
                    <Typography variant="h5" fontWeight="700">
                        Welcome to RecruitGenius
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                        Let's get up and running
                    </Typography>
                </Stack>
                {walkThroughs.map((walkThrough, index) => {
                    return (
                        <MenuItem
                            key={walkThrough.title}
                            onClick={() => {
                                navigate(walkThrough.link);
                                handleClose();
                                walkThrough.action();
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    color: walkThrough.isCompleted ? 'success.main' : '#6b778c',
                                }}
                            >
                                {walkThrough.icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    sx: {
                                        whiteSpace: 'pre-line',
                                        wordBreak: 'break-word',
                                        textDecoration: walkThrough.isCompleted ? 'line-through' : 'none',
                                    },
                                    color: walkThrough.isCompleted ? 'success.main' : 'textPrimary',
                                }}
                                primary={walkThrough.title}
                            />
                        </MenuItem>
                    );
                })}
                <MenuItem onClick={handleOpenConfirm}>
                    <ListItemText
                        primaryTypographyProps={{
                            color: 'primary.main',
                            variant: 'subtitle2',
                            sx: {
                                whiteSpace: 'pre-line',
                                wordBreak: 'break-word',
                            },
                        }}
                        primary="No thanks, I'll figure it out myself"
                    />
                </MenuItem>
            </Menu>
            <ConfirmModal
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                onConfirm={handleConfirmFinishAllWalkThrough}
                title={translatedWords.warning_dialog}
                content={'Are you sure that you want to exit this instruction?'}
            />
        </>
    );
};

export default WalkThroughChecklist;

