export const regionOptions: { Country: string; alpha2Code: string; alpha3Code: string }[] = [
	{
		Country: 'Afghanistan',
		alpha2Code: 'AF',
		alpha3Code: 'AFG',
	},
	{
		Country: 'Albania',
		alpha2Code: 'AL',
		alpha3Code: 'ALB',
	},
	{
		Country: 'Algeria',
		alpha2Code: 'DZ',
		alpha3Code: 'DZA',
	},
	{
		Country: 'American Samoa',
		alpha2Code: 'AS',
		alpha3Code: 'ASM',
	},
	{
		Country: 'Andorra',
		alpha2Code: 'AD',
		alpha3Code: 'AND',
	},
	{
		Country: 'Angola',
		alpha2Code: 'AO',
		alpha3Code: 'AGO',
	},
	{
		Country: 'Anguilla',
		alpha2Code: 'AI',
		alpha3Code: 'AIA',
	},
	{
		Country: 'Antarctica',
		alpha2Code: 'AQ',
		alpha3Code: 'ATA',
	},
	{
		Country: 'Antigua and Barbuda',
		alpha2Code: 'AG',
		alpha3Code: 'ATG',
	},
	{
		Country: 'Argentina',
		alpha2Code: 'AR',
		alpha3Code: 'ARG',
	},
	{
		Country: 'Armenia',
		alpha2Code: 'AM',
		alpha3Code: 'ARM',
	},
	{
		Country: 'Aruba',
		alpha2Code: 'AW',
		alpha3Code: 'ABW',
	},
	{
		Country: 'Australia',
		alpha2Code: 'AU',
		alpha3Code: 'AUS',
	},
	{
		Country: 'Austria',
		alpha2Code: 'AT',
		alpha3Code: 'AUT',
	},
	{
		Country: 'Azerbaijan',
		alpha2Code: 'AZ',
		alpha3Code: 'AZE',
	},
	{
		Country: 'Bahamas (the)',
		alpha2Code: 'BS',
		alpha3Code: 'BHS',
	},
	{
		Country: 'Bahrain',
		alpha2Code: 'BH',
		alpha3Code: 'BHR',
	},
	{
		Country: 'Bangladesh',
		alpha2Code: 'BD',
		alpha3Code: 'BGD',
	},
	{
		Country: 'Barbados',
		alpha2Code: 'BB',
		alpha3Code: 'BRB',
	},
	{
		Country: 'Belarus',
		alpha2Code: 'BY',
		alpha3Code: 'BLR',
	},
	{
		Country: 'Belgium',
		alpha2Code: 'BE',
		alpha3Code: 'BEL',
	},
	{
		Country: 'Belize',
		alpha2Code: 'BZ',
		alpha3Code: 'BLZ',
	},
	{
		Country: 'Benin',
		alpha2Code: 'BJ',
		alpha3Code: 'BEN',
	},
	{
		Country: 'Bermuda',
		alpha2Code: 'BM',
		alpha3Code: 'BMU',
	},
	{
		Country: 'Bhutan',
		alpha2Code: 'BT',
		alpha3Code: 'BTN',
	},
	{
		Country: 'Bolivia (Plurinational State of)',
		alpha2Code: 'BO',
		alpha3Code: 'BOL',
	},
	{
		Country: 'Bonaire, Sint Eustatius and Saba',
		alpha2Code: 'BQ',
		alpha3Code: 'BES',
	},
	{
		Country: 'Bosnia and Herzegovina',
		alpha2Code: 'BA',
		alpha3Code: 'BIH',
	},
	{
		Country: 'Botswana',
		alpha2Code: 'BW',
		alpha3Code: 'BWA',
	},
	{
		Country: 'Bouvet Island',
		alpha2Code: 'BV',
		alpha3Code: 'BVT',
	},
	{
		Country: 'Brazil',
		alpha2Code: 'BR',
		alpha3Code: 'BRA',
	},
	{
		Country: 'British Indian Ocean Territory (the)',
		alpha2Code: 'IO',
		alpha3Code: 'IOT',
	},
	{
		Country: 'Brunei Darussalam',
		alpha2Code: 'BN',
		alpha3Code: 'BRN',
	},
	{
		Country: 'Bulgaria',
		alpha2Code: 'BG',
		alpha3Code: 'BGR',
	},
	{
		Country: 'Burkina Faso',
		alpha2Code: 'BF',
		alpha3Code: 'BFA',
	},
	{
		Country: 'Burundi',
		alpha2Code: 'BI',
		alpha3Code: 'BDI',
	},
	{
		Country: 'Cabo Verde',
		alpha2Code: 'CV',
		alpha3Code: 'CPV',
	},
	{
		Country: 'Cambodia',
		alpha2Code: 'KH',
		alpha3Code: 'KHM',
	},
	{
		Country: 'Cameroon',
		alpha2Code: 'CM',
		alpha3Code: 'CMR',
	},
	{
		Country: 'Canada',
		alpha2Code: 'CA',
		alpha3Code: 'CAN',
	},
	{
		Country: 'Cayman Islands (the)',
		alpha2Code: 'KY',
		alpha3Code: 'CYM',
	},
	{
		Country: 'Central African Republic (the)',
		alpha2Code: 'CF',
		alpha3Code: 'CAF',
	},
	{
		Country: 'Chad',
		alpha2Code: 'TD',
		alpha3Code: 'TCD',
	},
	{
		Country: 'Chile',
		alpha2Code: 'CL',
		alpha3Code: 'CHL',
	},
	{
		Country: 'China',
		alpha2Code: 'CN',
		alpha3Code: 'CHN',
	},
	{
		Country: 'Christmas Island',
		alpha2Code: 'CX',
		alpha3Code: 'CXR',
	},
	{
		Country: 'Cocos (Keeling) Islands (the)',
		alpha2Code: 'CC',
		alpha3Code: 'CCK',
	},
	{
		Country: 'Colombia',
		alpha2Code: 'CO',
		alpha3Code: 'COL',
	},
	{
		Country: 'Comoros (the)',
		alpha2Code: 'KM',
		alpha3Code: 'COM',
	},
	{
		Country: 'Congo (the Democratic Republic of the)',
		alpha2Code: 'CD',
		alpha3Code: 'COD',
	},
	{
		Country: 'Congo (the)',
		alpha2Code: 'CG',
		alpha3Code: 'COG',
	},
	{
		Country: 'Cook Islands (the)',
		alpha2Code: 'CK',
		alpha3Code: 'COK',
	},
	{
		Country: 'Costa Rica',
		alpha2Code: 'CR',
		alpha3Code: 'CRI',
	},
	{
		Country: 'Croatia',
		alpha2Code: 'HR',
		alpha3Code: 'HRV',
	},
	{
		Country: 'Cuba',
		alpha2Code: 'CU',
		alpha3Code: 'CUB',
	},
	{
		Country: 'Curaçao',
		alpha2Code: 'CW',
		alpha3Code: 'CUW',
	},
	{
		Country: 'Cyprus',
		alpha2Code: 'CY',
		alpha3Code: 'CYP',
	},
	{
		Country: 'Czechia',
		alpha2Code: 'CZ',
		alpha3Code: 'CZE',
	},
	{
		Country: "Côte d'Ivoire",
		alpha2Code: 'CI',
		alpha3Code: 'CIV',
	},
	{
		Country: 'Denmark',
		alpha2Code: 'DK',
		alpha3Code: 'DNK',
	},
	{
		Country: 'Djibouti',
		alpha2Code: 'DJ',
		alpha3Code: 'DJI',
	},
	{
		Country: 'Dominica',
		alpha2Code: 'DM',
		alpha3Code: 'DMA',
	},
	{
		Country: 'Dominican Republic (the)',
		alpha2Code: 'DO',
		alpha3Code: 'DOM',
	},
	{
		Country: 'Ecuador',
		alpha2Code: 'EC',
		alpha3Code: 'ECU',
	},
	{
		Country: 'Egypt',
		alpha2Code: 'EG',
		alpha3Code: 'EGY',
	},
	{
		Country: 'El Salvador',
		alpha2Code: 'SV',
		alpha3Code: 'SLV',
	},
	{
		Country: 'Equatorial Guinea',
		alpha2Code: 'GQ',
		alpha3Code: 'GNQ',
	},
	{
		Country: 'Eritrea',
		alpha2Code: 'ER',
		alpha3Code: 'ERI',
	},
	{
		Country: 'Estonia',
		alpha2Code: 'EE',
		alpha3Code: 'EST',
	},
	{
		Country: 'Eswatini',
		alpha2Code: 'SZ',
		alpha3Code: 'SWZ',
	},
	{
		Country: 'Ethiopia',
		alpha2Code: 'ET',
		alpha3Code: 'ETH',
	},
	{
		Country: 'Falkland Islands (the) [Malvinas]',
		alpha2Code: 'FK',
		alpha3Code: 'FLK',
	},
	{
		Country: 'Faroe Islands (the)',
		alpha2Code: 'FO',
		alpha3Code: 'FRO',
	},
	{
		Country: 'Fiji',
		alpha2Code: 'FJ',
		alpha3Code: 'FJI',
	},
	{
		Country: 'Finland',
		alpha2Code: 'FI',
		alpha3Code: 'FIN',
	},
	{
		Country: 'France',
		alpha2Code: 'FR',
		alpha3Code: 'FRA',
	},
	{
		Country: 'French Guiana',
		alpha2Code: 'GF',
		alpha3Code: 'GUF',
	},
	{
		Country: 'French Polynesia',
		alpha2Code: 'PF',
		alpha3Code: 'PYF',
	},
	{
		Country: 'French Southern Territories (the)',
		alpha2Code: 'TF',
		alpha3Code: 'ATF',
	},
	{
		Country: 'Gabon',
		alpha2Code: 'GA',
		alpha3Code: 'GAB',
	},
	{
		Country: 'Gambia (the)',
		alpha2Code: 'GM',
		alpha3Code: 'GMB',
	},
	{
		Country: 'Georgia',
		alpha2Code: 'GE',
		alpha3Code: 'GEO',
	},
	{
		Country: 'Germany',
		alpha2Code: 'DE',
		alpha3Code: 'DEU',
	},
	{
		Country: 'Ghana',
		alpha2Code: 'GH',
		alpha3Code: 'GHA',
	},
	{
		Country: 'Gibraltar',
		alpha2Code: 'GI',
		alpha3Code: 'GIB',
	},
	{
		Country: 'Greece',
		alpha2Code: 'GR',
		alpha3Code: 'GRC',
	},
	{
		Country: 'Greenland',
		alpha2Code: 'GL',
		alpha3Code: 'GRL',
	},
	{
		Country: 'Grenada',
		alpha2Code: 'GD',
		alpha3Code: 'GRD',
	},
	{
		Country: 'Guadeloupe',
		alpha2Code: 'GP',
		alpha3Code: 'GLP',
	},
	{
		Country: 'Guam',
		alpha2Code: 'GU',
		alpha3Code: 'GUM',
	},
	{
		Country: 'Guatemala',
		alpha2Code: 'GT',
		alpha3Code: 'GTM',
	},
	{
		Country: 'Guernsey',
		alpha2Code: 'GG',
		alpha3Code: 'GGY',
	},
	{
		Country: 'Guinea',
		alpha2Code: 'GN',
		alpha3Code: 'GIN',
	},
	{
		Country: 'Guinea-Bissau',
		alpha2Code: 'GW',
		alpha3Code: 'GNB',
	},
	{
		Country: 'Guyana',
		alpha2Code: 'GY',
		alpha3Code: 'GUY',
	},
	{
		Country: 'Haiti',
		alpha2Code: 'HT',
		alpha3Code: 'HTI',
	},
	{
		Country: 'Heard Island and McDonald Islands',
		alpha2Code: 'HM',
		alpha3Code: 'HMD',
	},
	{
		Country: 'Holy See (the)',
		alpha2Code: 'VA',
		alpha3Code: 'VAT',
	},
	{
		Country: 'Honduras',
		alpha2Code: 'HN',
		alpha3Code: 'HND',
	},
	{
		Country: 'Hong Kong',
		alpha2Code: 'HK',
		alpha3Code: 'HKG',
	},
	{
		Country: 'Hungary',
		alpha2Code: 'HU',
		alpha3Code: 'HUN',
	},
	{
		Country: 'Iceland',
		alpha2Code: 'IS',
		alpha3Code: 'ISL',
	},
	{
		Country: 'India',
		alpha2Code: 'IN',
		alpha3Code: 'IND',
	},
	{
		Country: 'Indonesia',
		alpha2Code: 'ID',
		alpha3Code: 'IDN',
	},
	{
		Country: 'Iran (Islamic Republic of)',
		alpha2Code: 'IR',
		alpha3Code: 'IRN',
	},
	{
		Country: 'Iraq',
		alpha2Code: 'IQ',
		alpha3Code: 'IRQ',
	},
	{
		Country: 'Ireland',
		alpha2Code: 'IE',
		alpha3Code: 'IRL',
	},
	{
		Country: 'Isle of Man',
		alpha2Code: 'IM',
		alpha3Code: 'IMN',
	},
	{
		Country: 'Israel',
		alpha2Code: 'IL',
		alpha3Code: 'ISR',
	},
	{
		Country: 'Italy',
		alpha2Code: 'IT',
		alpha3Code: 'ITA',
	},
	{
		Country: 'Jamaica',
		alpha2Code: 'JM',
		alpha3Code: 'JAM',
	},
	{
		Country: 'Japan',
		alpha2Code: 'JP',
		alpha3Code: 'JPN',
	},
	{
		Country: 'Jersey',
		alpha2Code: 'JE',
		alpha3Code: 'JEY',
	},
	{
		Country: 'Jordan',
		alpha2Code: 'JO',
		alpha3Code: 'JOR',
	},
	{
		Country: 'Kazakhstan',
		alpha2Code: 'KZ',
		alpha3Code: 'KAZ',
	},
	{
		Country: 'Kenya',
		alpha2Code: 'KE',
		alpha3Code: 'KEN',
	},
	{
		Country: 'Kiribati',
		alpha2Code: 'KI',
		alpha3Code: 'KIR',
	},
	{
		Country: "Korea (the Democratic People's Republic of)",
		alpha2Code: 'KP',
		alpha3Code: 'PRK',
	},
	{
		Country: 'Korea (the Republic of)',
		alpha2Code: 'KR',
		alpha3Code: 'KOR',
	},
	{
		Country: 'Kuwait',
		alpha2Code: 'KW',
		alpha3Code: 'KWT',
	},
	{
		Country: 'Kyrgyzstan',
		alpha2Code: 'KG',
		alpha3Code: 'KGZ',
	},
	{
		Country: "Lao People's Democratic Republic (the)",
		alpha2Code: 'LA',
		alpha3Code: 'LAO',
	},
	{
		Country: 'Latvia',
		alpha2Code: 'LV',
		alpha3Code: 'LVA',
	},
	{
		Country: 'Lebanon',
		alpha2Code: 'LB',
		alpha3Code: 'LBN',
	},
	{
		Country: 'Lesotho',
		alpha2Code: 'LS',
		alpha3Code: 'LSO',
	},
	{
		Country: 'Liberia',
		alpha2Code: 'LR',
		alpha3Code: 'LBR',
	},
	{
		Country: 'Libya',
		alpha2Code: 'LY',
		alpha3Code: 'LBY',
	},
	{
		Country: 'Liechtenstein',
		alpha2Code: 'LI',
		alpha3Code: 'LIE',
	},
	{
		Country: 'Lithuania',
		alpha2Code: 'LT',
		alpha3Code: 'LTU',
	},
	{
		Country: 'Luxembourg',
		alpha2Code: 'LU',
		alpha3Code: 'LUX',
	},
	{
		Country: 'Macao',
		alpha2Code: 'MO',
		alpha3Code: 'MAC',
	},
	{
		Country: 'Madagascar',
		alpha2Code: 'MG',
		alpha3Code: 'MDG',
	},
	{
		Country: 'Malawi',
		alpha2Code: 'MW',
		alpha3Code: 'MWI',
	},
	{
		Country: 'Malaysia',
		alpha2Code: 'MY',
		alpha3Code: 'MYS',
	},
	{
		Country: 'Maldives',
		alpha2Code: 'MV',
		alpha3Code: 'MDV',
	},
	{
		Country: 'Mali',
		alpha2Code: 'ML',
		alpha3Code: 'MLI',
	},
	{
		Country: 'Malta',
		alpha2Code: 'MT',
		alpha3Code: 'MLT',
	},
	{
		Country: 'Marshall Islands (the)',
		alpha2Code: 'MH',
		alpha3Code: 'MHL',
	},
	{
		Country: 'Martinique',
		alpha2Code: 'MQ',
		alpha3Code: 'MTQ',
	},
	{
		Country: 'Mauritania',
		alpha2Code: 'MR',
		alpha3Code: 'MRT',
	},
	{
		Country: 'Mauritius',
		alpha2Code: 'MU',
		alpha3Code: 'MUS',
	},
	{
		Country: 'Mayotte',
		alpha2Code: 'YT',
		alpha3Code: 'MYT',
	},
	{
		Country: 'Mexico',
		alpha2Code: 'MX',
		alpha3Code: 'MEX',
	},
	{
		Country: 'Micronesia (Federated States of)',
		alpha2Code: 'FM',
		alpha3Code: 'FSM',
	},
	{
		Country: 'Moldova (the Republic of)',
		alpha2Code: 'MD',
		alpha3Code: 'MDA',
	},
	{
		Country: 'Monaco',
		alpha2Code: 'MC',
		alpha3Code: 'MCO',
	},
	{
		Country: 'Mongolia',
		alpha2Code: 'MN',
		alpha3Code: 'MNG',
	},
	{
		Country: 'Montenegro',
		alpha2Code: 'ME',
		alpha3Code: 'MNE',
	},
	{
		Country: 'Montserrat',
		alpha2Code: 'MS',
		alpha3Code: 'MSR',
	},
	{
		Country: 'Morocco',
		alpha2Code: 'MA',
		alpha3Code: 'MAR',
	},
	{
		Country: 'Mozambique',
		alpha2Code: 'MZ',
		alpha3Code: 'MOZ',
	},
	{
		Country: 'Myanmar',
		alpha2Code: 'MM',
		alpha3Code: 'MMR',
	},
	{
		Country: 'Namibia',
		alpha2Code: 'NA',
		alpha3Code: 'NAM',
	},
	{
		Country: 'Nauru',
		alpha2Code: 'NR',
		alpha3Code: 'NRU',
	},
	{
		Country: 'Nepal',
		alpha2Code: 'NP',
		alpha3Code: 'NPL',
	},
	{
		Country: 'Netherlands (the)',
		alpha2Code: 'NL',
		alpha3Code: 'NLD',
	},
	{
		Country: 'New Caledonia',
		alpha2Code: 'NC',
		alpha3Code: 'NCL',
	},
	{
		Country: 'New Zealand',
		alpha2Code: 'NZ',
		alpha3Code: 'NZL',
	},
	{
		Country: 'Nicaragua',
		alpha2Code: 'NI',
		alpha3Code: 'NIC',
	},
	{
		Country: 'Niger (the)',
		alpha2Code: 'NE',
		alpha3Code: 'NER',
	},
	{
		Country: 'Nigeria',
		alpha2Code: 'NG',
		alpha3Code: 'NGA',
	},
	{
		Country: 'Niue',
		alpha2Code: 'NU',
		alpha3Code: 'NIU',
	},
	{
		Country: 'Norfolk Island',
		alpha2Code: 'NF',
		alpha3Code: 'NFK',
	},
	{
		Country: 'Northern Mariana Islands (the)',
		alpha2Code: 'MP',
		alpha3Code: 'MNP',
	},
	{
		Country: 'Norway',
		alpha2Code: 'NO',
		alpha3Code: 'NOR',
	},
	{
		Country: 'Oman',
		alpha2Code: 'OM',
		alpha3Code: 'OMN',
	},
	{
		Country: 'Pakistan',
		alpha2Code: 'PK',
		alpha3Code: 'PAK',
	},
	{
		Country: 'Palau',
		alpha2Code: 'PW',
		alpha3Code: 'PLW',
	},
	{
		Country: 'Palestine, State of',
		alpha2Code: 'PS',
		alpha3Code: 'PSE',
	},
	{
		Country: 'Panama',
		alpha2Code: 'PA',
		alpha3Code: 'PAN',
	},
	{
		Country: 'Papua New Guinea',
		alpha2Code: 'PG',
		alpha3Code: 'PNG',
	},
	{
		Country: 'Paraguay',
		alpha2Code: 'PY',
		alpha3Code: 'PRY',
	},
	{
		Country: 'Peru',
		alpha2Code: 'PE',
		alpha3Code: 'PER',
	},
	{
		Country: 'Philippines (the)',
		alpha2Code: 'PH',
		alpha3Code: 'PHL',
	},
	{
		Country: 'Pitcairn',
		alpha2Code: 'PN',
		alpha3Code: 'PCN',
	},
	{
		Country: 'Poland',
		alpha2Code: 'PL',
		alpha3Code: 'POL',
	},
	{
		Country: 'Portugal',
		alpha2Code: 'PT',
		alpha3Code: 'PRT',
	},
	{
		Country: 'Puerto Rico',
		alpha2Code: 'PR',
		alpha3Code: 'PRI',
	},
	{
		Country: 'Qatar',
		alpha2Code: 'QA',
		alpha3Code: 'QAT',
	},
	{
		Country: 'Republic of North Macedonia',
		alpha2Code: 'MK',
		alpha3Code: 'MKD',
	},
	{
		Country: 'Romania',
		alpha2Code: 'RO',
		alpha3Code: 'ROU',
	},
	{
		Country: 'Russian Federation (the)',
		alpha2Code: 'RU',
		alpha3Code: 'RUS',
	},
	{
		Country: 'Rwanda',
		alpha2Code: 'RW',
		alpha3Code: 'RWA',
	},
	{
		Country: 'Réunion',
		alpha2Code: 'RE',
		alpha3Code: 'REU',
	},
	{
		Country: 'Saint Barthélemy',
		alpha2Code: 'BL',
		alpha3Code: 'BLM',
	},
	{
		Country: 'Saint Helena, Ascension and Tristan da Cunha',
		alpha2Code: 'SH',
		alpha3Code: 'SHN',
	},
	{
		Country: 'Saint Kitts and Nevis',
		alpha2Code: 'KN',
		alpha3Code: 'KNA',
	},
	{
		Country: 'Saint Lucia',
		alpha2Code: 'LC',
		alpha3Code: 'LCA',
	},
	{
		Country: 'Saint Martin (French part)',
		alpha2Code: 'MF',
		alpha3Code: 'MAF',
	},
	{
		Country: 'Saint Pierre and Miquelon',
		alpha2Code: 'PM',
		alpha3Code: 'SPM',
	},
	{
		Country: 'Saint Vincent and the Grenadines',
		alpha2Code: 'VC',
		alpha3Code: 'VCT',
	},
	{
		Country: 'Samoa',
		alpha2Code: 'WS',
		alpha3Code: 'WSM',
	},
	{
		Country: 'San Marino',
		alpha2Code: 'SM',
		alpha3Code: 'SMR',
	},
	{
		Country: 'Sao Tome and Principe',
		alpha2Code: 'ST',
		alpha3Code: 'STP',
	},
	{
		Country: 'Saudi Arabia',
		alpha2Code: 'SA',
		alpha3Code: 'SAU',
	},
	{
		Country: 'Senegal',
		alpha2Code: 'SN',
		alpha3Code: 'SEN',
	},
	{
		Country: 'Serbia',
		alpha2Code: 'RS',
		alpha3Code: 'SRB',
	},
	{
		Country: 'Seychelles',
		alpha2Code: 'SC',
		alpha3Code: 'SYC',
	},
	{
		Country: 'Sierra Leone',
		alpha2Code: 'SL',
		alpha3Code: 'SLE',
	},
	{
		Country: 'Singapore',
		alpha2Code: 'SG',
		alpha3Code: 'SGP',
	},
	{
		Country: 'Sint Maarten (Dutch part)',
		alpha2Code: 'SX',
		alpha3Code: 'SXM',
	},
	{
		Country: 'Slovakia',
		alpha2Code: 'SK',
		alpha3Code: 'SVK',
	},
	{
		Country: 'Slovenia',
		alpha2Code: 'SI',
		alpha3Code: 'SVN',
	},
	{
		Country: 'Solomon Islands',
		alpha2Code: 'SB',
		alpha3Code: 'SLB',
	},
	{
		Country: 'Somalia',
		alpha2Code: 'SO',
		alpha3Code: 'SOM',
	},
	{
		Country: 'South Africa',
		alpha2Code: 'ZA',
		alpha3Code: 'ZAF',
	},
	{
		Country: 'South Georgia and the South Sandwich Islands',
		alpha2Code: 'GS',
		alpha3Code: 'SGS',
	},
	{
		Country: 'South Sudan',
		alpha2Code: 'SS',
		alpha3Code: 'SSD',
	},
	{
		Country: 'Spain',
		alpha2Code: 'ES',
		alpha3Code: 'ESP',
	},
	{
		Country: 'Sri Lanka',
		alpha2Code: 'LK',
		alpha3Code: 'LKA',
	},
	{
		Country: 'Sudan (the)',
		alpha2Code: 'SD',
		alpha3Code: 'SDN',
	},
	{
		Country: 'Suriname',
		alpha2Code: 'SR',
		alpha3Code: 'SUR',
	},
	{
		Country: 'Svalbard and Jan Mayen',
		alpha2Code: 'SJ',
		alpha3Code: 'SJM',
	},
	{
		Country: 'Sweden',
		alpha2Code: 'SE',
		alpha3Code: 'SWE',
	},
	{
		Country: 'Switzerland',
		alpha2Code: 'CH',
		alpha3Code: 'CHE',
	},
	{
		Country: 'Syrian Arab Republic',
		alpha2Code: 'SY',
		alpha3Code: 'SYR',
	},
	{
		Country: 'Taiwan (Province of China)',
		alpha2Code: 'TW',
		alpha3Code: 'TWN',
	},
	{
		Country: 'Tajikistan',
		alpha2Code: 'TJ',
		alpha3Code: 'TJK',
	},
	{
		Country: 'Tanzania, United Republic of',
		alpha2Code: 'TZ',
		alpha3Code: 'TZA',
	},
	{
		Country: 'Thailand',
		alpha2Code: 'TH',
		alpha3Code: 'THA',
	},
	{
		Country: 'Timor-Leste',
		alpha2Code: 'TL',
		alpha3Code: 'TLS',
	},
	{
		Country: 'Togo',
		alpha2Code: 'TG',
		alpha3Code: 'TGO',
	},
	{
		Country: 'Tokelau',
		alpha2Code: 'TK',
		alpha3Code: 'TKL',
	},
	{
		Country: 'Tonga',
		alpha2Code: 'TO',
		alpha3Code: 'TON',
	},
	{
		Country: 'Trinidad and Tobago',
		alpha2Code: 'TT',
		alpha3Code: 'TTO',
	},
	{
		Country: 'Tunisia',
		alpha2Code: 'TN',
		alpha3Code: 'TUN',
	},
	{
		Country: 'Turkey',
		alpha2Code: 'TR',
		alpha3Code: 'TUR',
	},
	{
		Country: 'Turkmenistan',
		alpha2Code: 'TM',
		alpha3Code: 'TKM',
	},
	{
		Country: 'Turks and Caicos Islands (the)',
		alpha2Code: 'TC',
		alpha3Code: 'TCA',
	},
	{
		Country: 'Tuvalu',
		alpha2Code: 'TV',
		alpha3Code: 'TUV',
	},
	{
		Country: 'Uganda',
		alpha2Code: 'UG',
		alpha3Code: 'UGA',
	},
	{
		Country: 'Ukraine',
		alpha2Code: 'UA',
		alpha3Code: 'UKR',
	},
	{
		Country: 'United Arab Emirates (the)',
		alpha2Code: 'AE',
		alpha3Code: 'ARE',
	},
	{
		Country: 'United Kingdom of Great Britain and Northern Ireland (the)',
		alpha2Code: 'GB',
		alpha3Code: 'GBR',
	},
	{
		Country: 'United States Minor Outlying Islands (the)',
		alpha2Code: 'UM',
		alpha3Code: 'UMI',
	},
	{
		Country: 'United States of America (the)',
		alpha2Code: 'US',
		alpha3Code: 'USA',
	},
	{
		Country: 'Uruguay',
		alpha2Code: 'UY',
		alpha3Code: 'URY',
	},
	{
		Country: 'Uzbekistan',
		alpha2Code: 'UZ',
		alpha3Code: 'UZB',
	},
	{
		Country: 'Vanuatu',
		alpha2Code: 'VU',
		alpha3Code: 'VUT',
	},
	{
		Country: 'Venezuela (Bolivarian Republic of)',
		alpha2Code: 'VE',
		alpha3Code: 'VEN',
	},
	{
		Country: 'Viet Nam',
		alpha2Code: 'VN',
		alpha3Code: 'VNM',
	},
	{
		Country: 'Virgin Islands (British)',
		alpha2Code: 'VG',
		alpha3Code: 'VGB',
	},
	{
		Country: 'Virgin Islands (U.S.)',
		alpha2Code: 'VI',
		alpha3Code: 'VIR',
	},
	{
		Country: 'Wallis and Futuna',
		alpha2Code: 'WF',
		alpha3Code: 'WLF',
	},
	{
		Country: 'Western Sahara',
		alpha2Code: 'EH',
		alpha3Code: 'ESH',
	},
	{
		Country: 'Yemen',
		alpha2Code: 'YE',
		alpha3Code: 'YEM',
	},
	{
		Country: 'Zambia',
		alpha2Code: 'ZM',
		alpha3Code: 'ZMB',
	},
	{
		Country: 'Zimbabwe',
		alpha2Code: 'ZW',
		alpha3Code: 'ZWE',
	},
	{
		Country: 'Åland Islands',
		alpha2Code: 'AX',
		alpha3Code: 'ALA',
	},
];
