import { Dialog } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import WalkThroughChecklist from 'components/WalkThroughChecklist';
import authSlice from 'features/Authentication/slice';
import emailSlice, { listEmails } from 'features/EmailTemplate/slice';
import emailShopSlice, { listEmailsShop } from 'features/EmailTemplateShop/slice';
import positionSlice, { listPositions } from 'features/Position/slice';
import questionPackSlice, { listQuestionPack as listLibraryQuestionPacks } from 'features/QuestionPack/slice';
import questionShopSlice, { listShopQuestions } from 'features/QuestionShop/slice';
import questionSlice, { listQuestions } from 'features/QuestionTemplate/slice';
import TimeDefaultDialog from 'features/TimeConvert/components/DefaultTz/TimeDefaultDialog';
import { initTourUI } from 'features/TourUI/slice';
import { detectCompleteTourUI } from 'features/TourUI/utils/detectCompleteTourUI';
import ModalFormWorkspace from 'features/Workspace/components/ModalForm';
import memberSlice, { listMembers } from 'features/Workspace/member-slice';
import workspaceSlice, { getPolicy } from 'features/Workspace/slice';
import questionIsdPackSlice, { listQuestionPacks } from 'features/QuestionIdsPack/slice';
import useMounted from 'hooks/useMounted';
import { authSelector, settingsSelector, workspaceSelector } from 'hooks/useSelector';
import settingsSlice from 'layout/slice';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import DashboardNavbar from './Navbar';
import DashboardSidebar from './Sidebar';
import * as layoutSlice from './slice';

interface LayoutProps {
    children?: ReactNode;
}

const LayoutRoot = experimentalStyled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
}));

const LayoutWrapper = experimentalStyled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '64px',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: '280px',
    },
}));

const LayoutContainer = experimentalStyled('div')({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
});

const LayoutContent = experimentalStyled('div')({
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto !important',
    position: 'relative',
    WebkitOverflowScrolling: 'touch',
});

const Layout: FC<LayoutProps> = () => {
    const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
    const settings = useSelector(settingsSelector);
    const workspace = useSelector(workspaceSelector);
    const auth = useSelector(authSelector);
    const tourUIState = useSelector((state) => state.tourUI);

    const dispatch = useDispatch();
    const mounted = useMounted();
    const params = useParams();

    const [hasWorkspaceParam, setHasWorkspaceParam] = useState(true);
    const [openDefaultDialogTz, setOpenDefaultDialogTz] = useState(false);

    const { isCompleteWalkThrough } = detectCompleteTourUI(tourUIState);

    useEffect(() => {
        if (auth.isAuthenticated && !auth.isLoaded) {
            if (mounted.current) {
                dispatch(workspaceSlice.actions.gets({ workspaces: auth.user.workspaces, activated: null }));
            }

            /**
             * Get Params
             */
            const url = params['*'].substring(1);
            const arrUrl = url.split('/');

            if (params['*'] === '/' || arrUrl.length < 2) {
                if (mounted.current) {
                    dispatch(settingsSlice.actions.toggleModalWorkspace(true));
                }
            } else {
                const activated = auth.user.workspaces.find((x) => x.id === arrUrl[1]);
                const pendingConfirm = auth.user?.workspacesPending.find((x) => x.id === arrUrl[1]);

                // Note- fix this if bugs happen
                if (mounted.current) {
                    dispatch(authSlice.actions.setLoaded(true));
                    if (!activated && !pendingConfirm) {
                        setHasWorkspaceParam(false);
                        dispatch(settingsSlice.actions.toggleModalWorkspace(true));
                    } else if (activated) {
                        dispatch(workspaceSlice.actions.active(activated));
                    } else if (pendingConfirm) {
                        dispatch(workspaceSlice.actions.active(pendingConfirm));
                    }
                }
            }
        }
    }, [auth.isAuthenticated]);

    useEffect(() => {
        async function getInit() {
            try {
                dispatch(getPolicy(workspace.activated.id, auth?.user?.id));
                dispatch(listQuestions(workspace.activated.id));
                dispatch(listQuestionPacks(workspace.activated.id));
                dispatch(listShopQuestions());
                dispatch(listPositions(workspace.activated.id));
                dispatch(listLibraryQuestionPacks());
                dispatch(listEmails(workspace.activated.id));
                dispatch(listEmailsShop());
                dispatch(listMembers(workspace.activated.id));
            } catch (error) {
                console.log(error.message);
            }
        }
        if (workspace.activated?.id) {
            getInit();
        }

        return () => {
            //Clean up previous state
            dispatch(workspaceSlice.actions.setPolicy(null));
            dispatch(questionSlice.actions.list([]));
            dispatch(questionIsdPackSlice.actions.list([]));
            dispatch(questionShopSlice.actions.list([]));
            dispatch(questionPackSlice.actions.list([]));
            dispatch(positionSlice.actions.list([]));
            dispatch(emailSlice.actions.list([]));
            dispatch(emailShopSlice.actions.list([]));
            dispatch(memberSlice.actions.listWorkspaceMember([]));
        };
    }, [workspace.activated?.id, auth.user?.id, dispatch]);

    useEffect(() => {
        //Tour UI trigger
        const getInititalTourUI = async (workspaceId: string, workspaceCountry: string, workspaceTimezone: string) => {
            try {
                if (!workspaceId) return;
                if (!workspaceCountry && !workspaceTimezone) {
                    setOpenDefaultDialogTz(true);
                }

                await dispatch(initTourUI(workspaceId));
            } catch (error) {}
        };

        getInititalTourUI(workspace.activated?.id, workspace.activated?.defaultCountry, workspace.activated?.defaultRegionTimezone);
    }, [dispatch, workspace.activated?.id, workspace.activated?.defaultCountry, workspace.activated?.defaultRegionTimezone]);

    return (
        <LayoutRoot>
            <DashboardSidebar onMobileClose={(): void => setIsSidebarMobileOpen(false)} openMobile={isSidebarMobileOpen} />
            <LayoutWrapper>
                <LayoutContainer>
                    <LayoutContent>
                        <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
                        <Outlet />
                        <Dialog
                            open={settings.modalWorkspace}
                            fullWidth
                            maxWidth="sm"
                            onClose={() => {
                                workspace.activated && dispatch(layoutSlice.actions.toggleModalWorkspace(false));
                            }}
                        >
                            <ModalFormWorkspace hasWorkspaceParam={hasWorkspaceParam} />
                        </Dialog>
                        {workspace.activated && (
                            <TimeDefaultDialog
                                setLayOut={6}
                                openDefaultDialogTz={openDefaultDialogTz}
                                onCloseDialog={() => setOpenDefaultDialogTz(false)}
                            />
                        )}

                        {!isCompleteWalkThrough && <WalkThroughChecklist />}
                    </LayoutContent>
                </LayoutContainer>
            </LayoutWrapper>
        </LayoutRoot>
    );
};

export default Layout;

