import React, { forwardRef } from 'react';
import { alpha, experimentalStyled } from '@mui/material';
import { EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import timeGridPlugin from '@fullcalendar/timegrid';

import { useSelector } from 'store';
import { workspaceSelector } from 'hooks/useSelector';
import type { PollingEvent } from 'types/interviewSchedule';
import type { CalendarView } from 'types/calendar';
import SlotLockedView from './SlotLockedView';
import FullCalendar from '@fullcalendar/react';

interface LockedInterviewCalendarProps {
    events: PollingEvent[];
    view: CalendarView;
    date: Date;
    height?: number;
    handleClickEvent: (ev: EventClickArg) => void;
}

const SFullCalendarWrapper = experimentalStyled('div')(({ theme }) => ({
    '& .fc-license-message': {
        display: 'none',
    },
    '& .fc': {
        '--fc-bg-event-opacity': 1,
        '--fc-border-color': theme.palette.divider,
        '--fc-daygrid-event-dot-width': '10px',
        '--fc-event-text-color': theme.palette.text.primary,
        '--fc-list-event-hover-bg-color': theme.palette.background.default,
        '--fc-neutral-bg-color': theme.palette.background.default,
        '--fc-page-bg-color': theme.palette.background.default,
        '--fc-today-bg-color': alpha(theme.palette.primary.main, 0.25),
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
    },
    '& .fc .fc-col-header-cell-cushion': {
        paddingBottom: '10px',
        paddingTop: '10px',
    },
    '& .fc .fc-day-other .fc-daygrid-day-top': {
        color: theme.palette.text.secondary,
    },
    '& .fc-daygrid-event': {
        padding: '10px',
        cursor: 'pointer',
    },
    '& .fc-direction-ltr .fc-timegrid-col-events': {
        margin: '0 2px',
    },
}));

const LockedInterviewCalendar = forwardRef<FullCalendar, LockedInterviewCalendarProps>(
    ({ events, view, date, height, handleClickEvent }, ref) => {
        const workspace = useSelector(workspaceSelector);

        // Ideal height is 1200 when duration is 30 minutes, and time offset = slotMaxTime - slotMinTime = 11h, so the dynamic height is computed by
        const minDuration = Math.min(...events.map((event) => event.extendedProps.duration));

        return (
            <SFullCalendarWrapper>
                <FullCalendar
                    eventDisplay="block"
                    allDaySlot={false}
                    expandRows={true}
                    events={events}
                    headerToolbar={false}
                    eventContent={SlotLockedView}
                    timeZone={workspace.activated?.defaultRegionTimezone}
                    height={height}
                    dayHeaderFormat={view === 'timeGridWeek' ? 'ddd DD/MM' : 'ddd'}
                    initialDate={date}
                    initialView={view}
                    plugins={[dayGridPlugin, timeGridPlugin, momentPlugin, momentTimezonePlugin]}
                    ref={ref}
                    rerenderDelay={0}
                    weekends
                    slotDuration={{ minutes: minDuration >= 60 ? 60 : 30 }}
                    eventClick={handleClickEvent}
                />
            </SFullCalendarWrapper>
        );
    }
);

export default LockedInterviewCalendar;

