import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { getCandidates } from 'features/Candidate/slice';
import { getInterviews } from 'features/Interview/slice';
import { Link as RouterLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import TourUI from 'features/TourUI';
import { updateAccessDashboarState } from 'features/TourUI/slice';
import { steps } from 'features/TourUI/steps/dashboardSteps';
import useApplyTranslation from 'hooks/useApplyTranslation';
import {
    authSelector,
    candidateSelector,
    emailSelector,
    positionSelector,
    questionSelector,
    settingsSelector,
    workspaceSelector,
} from 'hooks/useSelector';
import gtm from 'lib/gtm';
import { FC, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { OverviewInbox, OverviewLatestTransactions, OverviewTotalBalance } from './components';
import Interviews from './components/Interviews';
import UpcomingInterviews from './components/UpcomingInterviews';
import OpenPositions from './components/OpenPositions';
import KabanBoard from './components/KabanBoard';

const Overview: FC = () => {
    const dispatch = useDispatch();
    const settings = useSelector(settingsSelector);
    const auth = useSelector(authSelector);
    const workspace = useSelector(workspaceSelector);
    const email = useSelector(emailSelector);
    const question = useSelector(questionSelector);
    const position = useSelector(positionSelector);
    const candidate = useSelector(candidateSelector);
    const interview = useSelector((state) => state.interview);
    const {
        dashboard: { isFirstAccess },
        isPauseTourUI,
    } = useSelector((state) => state.tourUI);

    const translatedWords = useApplyTranslation();

    const handleconfirmFirstAccess = () => {
        if (isFirstAccess) dispatch(updateAccessDashboarState(false));
    };

    const isNewUser = useMemo(() => {
        const listWorkspace = workspace.workspaces;
        return listWorkspace.length <= 1;
    }, [workspace.workspaces]);

    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    useEffect(() => {
        if (workspace.activated) {
            async function getInit() {
                await Promise.all([
                    dispatch(getCandidates(workspace.activated.id, 1, 5)),
                    dispatch(getInterviews(workspace.activated.id, 1, 5)),
                ]);
            }
            getInit();
        }
    }, [workspace.activated]);

    return (
        <>
            <Helmet>
                <title>{translatedWords.general_dash} | RecruitGenius</title>
            </Helmet>

            {isFirstAccess && !isPauseTourUI && (
                <TourUI
                    steps={steps}
                    isAllowSkip={!isNewUser}
                    canStart={isFirstAccess && !isPauseTourUI}
                    onConfirmFirstAccess={handleconfirmFirstAccess}
                />
            )}
            <Box
                className="dashboard"
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 2,
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid container spacing={3}>
                        <Grid alignItems="center" container justifyContent="space-between" spacing={3} item xs={12}>
                            <Grid item>
                                <Typography variant="h4">
                                    {translatedWords.welcome_title} {auth.user.firstName}
                                </Typography>
                                {auth?.user?.status === 'nonactive' ? (
                                    <Typography color="textSecondary" variant="subtitle2">
                                        You have not verified your email account. Click
                                        <Button color="inherit" component={RouterLink} to="my-account" size="large" sx={{ mb: 0.5 }}>
                                            here
                                        </Button>
                                        to view your profile and check your verification status
                                    </Typography>
                                ) : (
                                    <Typography color="textSecondary" variant="subtitle1">
                                        {translatedWords.subtitle_message}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            xs={12}
                            spacing={3}
                            sx={{ display: 'flex', flexFlow: 'row', alignItems: 'stretch', flexWrap: 'wrap' }}
                        >
                            <Grid item lg={4} xs={12}>
                                <Interviews />
                            </Grid>
                            <Grid item lg={8} xs={12}>
                                <KabanBoard />
                            </Grid>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <UpcomingInterviews />
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <OpenPositions />
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Overview;

