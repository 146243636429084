import { updateUserTransaction } from 'features/Payment/slice';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { paymentSelector } from './useSelector';

const useGetUserTransaction = () => {
    const dispatch = useDispatch();
    const payment = useSelector(paymentSelector);
    const [refetchData, setRefetchData] = useState<boolean>(false);

    useLayoutEffect(() => {
        const getUserTransaction = async () => {
            try {
                await dispatch(updateUserTransaction());
            } catch (error) {
                console.log('The following error occurred:', error.message);
            }
        };
        getUserTransaction();
    }, [refetchData]);

    const handleRefetchData = () => {
        setRefetchData((prev) => !prev);
    };

    return { userTransaction: payment.userTransaction, loadingTransaction: payment.isLoading, handleRefetchData };
};

export default useGetUserTransaction;
