import createSvgIcon from '@mui/material/utils/createSvgIcon';

const CandidateIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path
            xmlns="http://www.w3.org/2000/svg"
            d="M2.5 16q-.625 0-1.062-.438Q1 15.125 1 14.5v-9q0-.625.438-1.062Q1.875 4 2.5 4h9q.625 0 1.062.438Q13 4.875 13 5.5v9q0 .625-.438 1.062Q12.125 16 11.5 16Zm0-2.604q.958-.667 2.104-1.031Q5.75 12 7 12t2.396.365q1.146.364 2.104 1.031V5.5h-9ZM7 13.5q-.958 0-1.833.26-.875.261-1.625.74h6.916q-.75-.479-1.625-.74Q7.958 13.5 7 13.5ZM7 11q-1.042 0-1.771-.729Q4.5 9.542 4.5 8.5q0-1.042.729-1.771Q5.958 6 7 6q1.042 0 1.771.729.729.729.729 1.771 0 1.042-.729 1.771Q8.042 11 7 11Zm0-1.5q.417 0 .708-.292Q8 8.917 8 8.5t-.292-.708Q7.417 7.5 7 7.5t-.708.292Q6 8.083 6 8.5t.292.708Q6.583 9.5 7 9.5Zm7.5 6.5V4H16v12Zm3 0V4H19v12ZM7 8.5Zm0 6Z"
        />
    </svg>,
    'CandidateIcon'
);

export default CandidateIcon;

