import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

const GA_ID = import.meta.env.VITE_APP_GA_ID;

export const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    /**
     * Set Initialized
     */
    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
            ReactGA.initialize(GA_ID);
        }
        setInitialized(true);
    }, []);

    /**
     * Check location change
     */
    useEffect(() => {
        if (initialized) {
            ReactGA.send({ hitType: 'pageview', page: location.pathname, title: '' });
        }
    }, [initialized, location]);
};
