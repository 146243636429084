import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { VideoDuration } from 'types/video';
import { configSettings } from 'utils/config';
import { DEFAULT_LANGUAGE, THEMES } from 'utils/constants';
import api from './api';

export interface InitialLanguageResource {
    key: string;
    defaultValue: string;
}

export interface VideoSetting {
    speed: VideoDuration;
}

interface SettingsState {
    compact?: boolean;
    direction?: 'ltr' | 'rtl';
    responsiveFontSizes?: boolean;
    roundedCorners?: boolean;
    theme?: string;
    openSettings?: boolean;
    modalWorkspace?: boolean;
    initialLanguageResource?: InitialLanguageResource[];
    videoSettings?: VideoSetting;
}

const initialState: SettingsState = {
    compact: true,
    direction: 'ltr',
    responsiveFontSizes: true,
    roundedCorners: true,
    theme: THEMES.LIGHT,
    openSettings: false,
    modalWorkspace: false,
    initialLanguageResource: [],
    videoSettings: {
        speed: 1.5,
    },
};

/**
 * Get and Set Settings Init
 */
export const restoreSettings = createAsyncThunk('settings/init', async () => {
    let settings = null;

    const storageSettings = await configSettings.get();

    if (storageSettings) {
        settings = storageSettings;
    } else {
        settings = {
            compact: true,
            direction: 'ltr',
            responsiveFontSizes: true,
            roundedCorners: true,
            theme: THEMES.LIGHT,
            openSetting: false,
            modalWorkspace: false,
        };
    }

    return settings ? settings : initialState;
});

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        /* Save Setings */
        save: (state: SettingsState, action: PayloadAction<SettingsState>) => {
            state = Object.assign(state, action.payload);
            configSettings.set(state);
        },

        /* Toggle Open Settings Drawer */
        toggleSettings: (state: SettingsState, action: PayloadAction<boolean>) => {
            state.openSettings = action.payload;
        },

        /* Toggle Open Modal Workspace */
        toggleModalWorkspace: (state: SettingsState, action: PayloadAction<boolean>) => {
            state.modalWorkspace = action.payload;
        },

        getLanguageConstants: (state: SettingsState, action: PayloadAction<InitialLanguageResource[]>) => {
            state.initialLanguageResource = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(restoreSettings.fulfilled, (state: SettingsState, action) => {
            state = Object.assign(state, action.payload);
        });
    },
});

export const getLanguageConstants = (): AppThunk => async (dispatch) => {
    const enLngResource = await api.getLanguageResource(JSON.parse(localStorage.getItem('countryCode')) === 'VN' ? 'vi' : DEFAULT_LANGUAGE);
    console.log(JSON.parse(localStorage.getItem('countryCode')), enLngResource);
    const languageConstants: InitialLanguageResource[] = Object.entries(enLngResource).map(([key, value]) => {
        return { key, defaultValue: value };
    });
    dispatch(settingsSlice.actions.getLanguageConstants(languageConstants));
};

export const { reducer, actions } = settingsSlice;
export default settingsSlice;
