import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment';

import { useSelector } from 'store';
import { workspaceSelector } from 'hooks/useSelector';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { useGetPollingBaseOnTime } from 'hooks/scheduleInterview/useGetPollingBaseOnTime';
import { PollingProgress } from 'types/interviewSchedule';
import UpcomingInterviewCard from './UpcomingInterviewCard';
import UpcomingInterviewCardSkeleton from './UpcomingInterviewCard.skeleton';

const UpcomingInterviews = () => {
    const translatedWords = useApplyTranslation();
    const workspace = useSelector(workspaceSelector);

    const { schedulePollings, loading, actions } = useGetPollingBaseOnTime(null);

    useEffect(() => {
        if (workspace.activated?.id) {
            actions.getPollings(workspace.activated?.id, [
                {
                    progress: PollingProgress.LOCKED,
                    rangeFrom: moment().unix(),
                    rangeTo: moment().add(7, 'days').unix(),
                },
                {
                    progress: PollingProgress.CALL_SETUP,
                    rangeFrom: moment().unix(),
                    rangeTo: moment().add(7, 'days').unix(),
                },
            ]);
        }
    }, [workspace.activated?.id]);

    return (
        <Card className="upcoming-interviews" sx={{ mt: 3 }}>
            <CardHeader title={translatedWords.upcoming_interviews} />
            <Divider />
            <CardContent>
                <Stack gap={2}>
                    {loading ? (
                        Array.from({ length: 2 }).map((e, i) => <UpcomingInterviewCardSkeleton key={i} />)
                    ) : schedulePollings.length > 0 ? (
                        schedulePollings?.map(({ id, candidate, position, duration, rangeTiming }) => (
                            <UpcomingInterviewCard
                                key={id}
                                name={candidate.name}
                                position={position.name}
                                link=""
                                fromInUnix={rangeTiming?.start}
                                duration={duration}
                            />
                        ))
                    ) : (
                        <Typography textAlign="center">{translatedWords.no_upcoming_interviews}</Typography>
                    )}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default UpcomingInterviews;

