import React, { FC } from 'react';
import { Box } from '@mui/material';
import DashIcon from 'assets/icons/DashIcon';
import HeadArrow from 'assets/icons/HeadArrow';

const DashedArrow: FC = () => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'nowrap', transform: 'translateX(9px)' }}>
            <DashIcon fontSize="small" />
            <DashIcon fontSize="small" />
            <DashIcon fontSize="small" />
            <HeadArrow sx={{ transform: 'translateX(-18px)' }} fontSize="small" />
        </Box>
    );
};

export default DashedArrow;

