export enum NotificationType {
    VIDEO_INTERVIEW = 'videoInterview',
    POLLING = 'polling',
    CANDIDATE = 'candidate',
}

export interface Notification {
    id: string;
    content: string;
    createdAt: number;
    isRead: boolean;
    link: string;
    workspace: string;
    recruiter: string;
    notiType: NotificationType;
}

export interface NotificationQuery {
    isRead?: boolean;
    page?: number;
    limit?: number;
}
