import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { AdvancedFlow } from 'types/flow';
import api from './api';

interface AdvancedFlowEmailState {
    flows: AdvancedFlow[];
    isLoading: boolean;
    error: boolean;
}

const initialState: AdvancedFlowEmailState = {
    flows: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'running_flow',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get flow Email */
        list: (state, action) => {
            state.flows = action.payload;

            state.isLoading = false;
        },

        /* Update Flow */
        update: (state, action) => {
            const flows = action.payload;
            const index = state.flows.findIndex((x) => x.id === flows.id);

            if (index > -1) {
                state.flows[index] = flows;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },
    },
});

/**
 * List all Flows Email
 * @param workspaceId
 * @returns
 */
export const listAllFlows =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setLoading(true));
        const response: any = await api.getAll(workspaceId);
        dispatch(slice.actions.list(response));

        dispatch(slice.actions.setLoading(false));
    };

export const activateAndDeactivateFlow =
    (workspaceId: string, flowId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setLoading(true));
        const response: any = await api.update(workspaceId, flowId, request);

        dispatch(slice.actions.update(response));

        dispatch(slice.actions.setLoading(false));
    };

export const changeFlowName =
    (workspaceId: string, flowId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setLoading(true));
        const response: any = await api.changeFlowName(workspaceId, flowId, request);

        dispatch(slice.actions.update(response));

        dispatch(slice.actions.setLoading(false));
    };

export const { reducer } = slice;
export default slice;
