import { Box, Divider, Typography } from '@mui/material';
import HeadArrow from 'assets/icons/HeadArrow';
import { FC } from 'react';
import ContactSalesForm from './ContactSalesForm';

interface IContactSalesProps {
    onBack: () => void;
    onNext: () => void;
    onChangeContactRequest: (values: any) => void;
}

const ContactSales: FC<IContactSalesProps> = (props) => {
    const { onBack, onNext, onChangeContactRequest } = props;

    return (
        <Box>
            <Box sx={{ width: '100%' }}>
                <Box
                    sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', cursor: 'pointer', width: 'fit-content' }}
                    onClick={onBack}
                >
                    <HeadArrow
                        sx={{ color: 'grey.400', transform: 'rotate(180deg)', height: 15, width: 15, mt: '2px', mr: 1 }}
                        fontSize="small"
                    />

                    <Typography variant="subtitle1" color="textSecondary" fontWeight="600" sx={{ display: 'inline' }}>
                        Back
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ my: 2 }} />

            <Box>
                <Typography variant="h5" color="primary.main">
                    Contact our Sales team
                </Typography>
                <ContactSalesForm onNext={onNext} onChangeContactRequest={onChangeContactRequest} />
            </Box>
        </Box>
    );
};

export default ContactSales;

