import { Badge, Box, CircularProgress, Divider, IconButton, Popover, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import BellIcon from 'assets/icons/Bell';
import TabPanel from 'components/TabPanel';
import { NotificationContext } from 'context/notification';
import { updateIsPauseTourUi } from 'features/TourUI/slice';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'store';
import { a11yProps } from 'utils/a11yProps';
import NotificationList from './NotificationList';

const NOTIFICATION_POPOVER_TAB = 'notification_popover_tab';

const NotificationsPopover: FC = () => {
    const { loading, notifications } = useContext(NotificationContext) || {};
    const dispatch = useDispatch();

    const [tabValue, setTabValue] = useState(0);
    const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
        dispatch(updateIsPauseTourUi(true));
    };

    const handleClose = (): void => {
        setOpen(false);
        dispatch(updateIsPauseTourUi(false));
    };

    const unreadNotificaitons = useMemo(() => notifications.filter((notification) => !notification.isRead), [notifications]);

    const tabs: { label: string; component: JSX.Element }[] = [
        { label: 'All', component: <NotificationList notifications={notifications} onClose={handleClose} /> },
        { label: 'Unread', component: <NotificationList notifications={unreadNotificaitons} onClose={handleClose} /> },
    ];

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton ref={anchorRef} onClick={handleOpen}>
                    <Badge color="error" badgeContent={unreadNotificaitons.length}>
                        <BellIcon fontSize="small" />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: 320,
                        height: '70vh',
                    },
                    id: 'notification_container',
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            minHeight: '300px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{}}>
                        <Tabs
                            sx={{
                                '& .MuiTabs-scrollButtons': {
                                    position: 'relative',
                                    zIndex: 999,
                                    backgroundColor: 'background.default',
                                    opacity: 1,
                                    '&.Mui-disabled': {
                                        opacity: '0.4 !important',
                                    },
                                },
                            }}
                            value={tabValue}
                            onChange={handleChangeTab}
                            variant="scrollable"
                        >
                            {tabs.map((tab, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        label={tab.label}
                                        {...a11yProps(NOTIFICATION_POPOVER_TAB, index)}
                                        sx={{ minWidth: 'unset !important', flex: 1 }}
                                    />
                                );
                            })}
                        </Tabs>
                        <Divider sx={{ mb: 2 }} />
                        {tabs.map((tab, index) => {
                            return (
                                <TabPanel name={NOTIFICATION_POPOVER_TAB} value={tabValue} index={index} key={index}>
                                    {tab.component}
                                </TabPanel>
                            );
                        })}
                    </Box>
                )}
            </Popover>
        </>
    );
};

export default NotificationsPopover;

