import axiosClient from 'lib/axios';
import { QuestionPack, QuestionPackReq } from 'types/questionsPack';

const prefixUrl = 'question-packs';

const api = {
    /* Get all questions pack */
    getAll: (): Promise<QuestionPack[]> => {
        return axiosClient.get(`shop/${prefixUrl}`);
    },

    /* Create Question Pack */
    create: (request: QuestionPackReq) => {
        return axiosClient.post(`shop/${prefixUrl}`, request);
    },

    /* Update Question Pack */
    update: (questionPackId: string, request: QuestionPackReq) => {
        return axiosClient.patch(`shop/${prefixUrl}/${questionPackId}`, request);
    },

    /* delete Question Pack */
    delete: (questionPackId: string) => {
        return axiosClient.delete(`shop/${prefixUrl}/${questionPackId}`);
    },

    getQuestionTemplate: () => {
        return axiosClient.get(`shop/question-templates`);
    },
    importQuestionPackToWorkspace: (workspaceId: string, questionPackId: string) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/import/${questionPackId}`);
    },
};

export default api;
