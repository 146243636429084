import React, { FC, ReactNode, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem, ListItemProps } from '@mui/material';

import useApplyTranslation from 'hooks/useApplyTranslation';
import ChevronDownIcon from 'assets/icons/ChevronDown';
import ChevronRightIcon from 'assets/icons/ChevronRight';
import { Item } from 'menu';

/**
 * Navbar Item Component
 */

interface NavItemProps extends ListItemProps {
    active?: boolean;
    children?: ReactNode;
    depth: number;
    open?: boolean;
    item: Item;
}

const NavItem: FC<NavItemProps> = ({ active, children, depth, open: openProp, item, ...other }) => {
    const translatedWords = useApplyTranslation();

    const [open, setOpen] = useState(openProp);

    const handleToggle = () => setOpen((prev) => !prev);

    const paddingLeft = 16;
    const { icon = null, info = null, path = null, title, ListItemProps = {} } = item;
    const noIcon = <span style={{ width: 20, height: 20 }} />;

    // Branch
    if (children) {
        return (
            <ListItem
                disableGutters
                sx={{
                    display: 'block',
                    py: 0,
                }}
                {...other}
            >
                <Button
                    endIcon={!open ? <ChevronRightIcon /> : <ChevronDownIcon />}
                    onClick={handleToggle}
                    startIcon={icon}
                    sx={{
                        color: 'text.secondary',
                        fontWeight: 500,
                        justifyContent: 'flex-start',
                        pl: `${paddingLeft}px`,
                        pr: '8px',
                        py: '12px',
                        textAlign: 'left',
                        textTransform: 'none',
                        width: '100%',
                        ...(active && {
                            color: 'primary.main',
                            fontWeight: 'fontWeightBold',
                            '& svg': {
                                color: 'primary.main',
                            },
                        }),
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>{translatedWords[title.constant]}</Box>
                    {info}
                </Button>
                <Collapse in={open}>{children}</Collapse>
            </ListItem>
        );
    }

    // Leaf
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                py: 0,
            }}
            className={`menu-${title.constant}`}
            {...ListItemProps}
        >
            <Button
                component={path && RouterLink}
                startIcon={depth === 0 || active ? icon : noIcon}
                sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    justifyContent: 'flex-start',
                    textAlign: 'left',
                    pl: `${paddingLeft}px`,
                    pr: '8px',
                    py: '12px',
                    textTransform: 'none',
                    width: '100%',
                    ...(active && {
                        color: 'primary.main',
                        fontWeight: 'fontWeightBold',
                        '& svg': {
                            color: 'primary.main',
                        },
                    }),
                }}
                variant="text"
                to={path}
            >
                <Box sx={{ flexGrow: 1 }}>{translatedWords[title.constant]}</Box>
                {info}
            </Button>
        </ListItem>
    );
};

NavItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    depth: PropTypes.number.isRequired,
    open: PropTypes.bool,
};

NavItem.defaultProps = {
    active: false,
    open: false,
};

export default NavItem;

