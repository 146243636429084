import { Container, Stack, Typography } from '@mui/material';
import { Step } from '@pry-financials/react-joyride';
import VideoPlayer from '../../../components/VideoPlayer';

export const VIDEO_INTERVIEW_MENU = 'video-interviews__menu';

export const steps: Step[] = [
    {
        content: (
            <Container maxWidth="lg" sx={{ padding: '0 !important' }}>
                <Stack spacing={3}>
                    <VideoPlayer videoSource="/static/videos/Welcome.mp4" autoPlay={true} />
                    <Stack spacing={1}>
                        <Typography color="#172b4d" variant="h5" fontWeight="00">
                            Welcome to RecruitGenius.ai!
                        </Typography>
                        <Typography variant="h6" fontWeight="500">
                            Let revolutionize your recruitment process
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        ),
        styles: {
            options: {
                width: 500,
            },
            tooltip: {
                maxHeight: '90vh',
                overflow: 'scroll',
                paddingBottom: '67.5px',
                position: 'unset',
            },
            tooltipFooter: {
                backgroundColor: '#fff',
                position: 'absolute',
                padding: '0 15px 15px',
                bottom: 0,
                left: 0,
                right: 0,
            },
        },
        disableBeacon: true,
        placement: 'center',
        target: 'body',
    },
    {
        content: (
            <Typography color="#172b4d" variant="h4">
                Let's begin our journey!
            </Typography>
        ),
        disableBeacon: true,
        placement: 'center',
        target: 'body',
    },
    {
        content: (
            <Typography color="#172b4d" variant="h5">
                Let's create your first interview
            </Typography>
        ),
        disableBeacon: true,
        placement: 'right',
        spotlightClicks: true,
        target: `#${VIDEO_INTERVIEW_MENU}`,
        styles: {
            tooltipContent: {
                padding: '10px 10px 0',
            },
        },
    },
];

