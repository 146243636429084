export const amplifyConfig = {
    aws_project_region: import.meta.env.VITE_APP_AWS_PROJECT_REGION,
    aws_cognito_identity_pool_id: import.meta.env.VITE_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: import.meta.env.VITE_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: import.meta.env.VITE_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: import.meta.env.VITE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const gtmConfig = {
    containerId: import.meta.env.VITE_APP_GTM_CONTAINER_ID,
};
