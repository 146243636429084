import React, { FC, useEffect, useState } from 'react';
import { CustomContentGenerator, EventContentArg } from '@fullcalendar/core';
import { Avatar, Box, Link, Stack, Typography } from '@mui/material';

import useApplyTranslation from 'hooks/useApplyTranslation';
import { Candidate } from 'types/candidate';
import { PollingEventColor } from 'types/interviewSchedule';
import { Position } from 'types/position';
import PrimaryTooltip from 'components/PrimaryTooltip';
import getInitials from 'utils/getInitials';
import { scheduleApi } from 'features/ScheduleInterview/api';
import { useSelector } from 'react-redux';
import { workspaceSelector } from 'hooks/useSelector';

interface SlotLockedViewContentProps {
    candidate: Candidate;
    position: Position;
    eventInfo: EventContentArg;
}

const SlotLockedViewContent: FC<SlotLockedViewContentProps> = (props) => {
    const { candidate, position, eventInfo } = props;

    const translatedWords = useApplyTranslation();
    const [link, setLink] = useState<string>(null);
    const [address, setAddress] = useState<string>(null);
    const workspace = useSelector(workspaceSelector);
    useEffect(() => {
        const fetchCandidateAdditionalInfo = async () => {
            const response = await scheduleApi.getExtendedInformation(workspace.activated.id, eventInfo.event._def.publicId);
            response.candidateLocation.link ? setLink(response.candidateLocation.link) : setAddress(response.candidateLocation.address);
        };
        fetchCandidateAdditionalInfo();
    }, []);

    return (
        <PrimaryTooltip
            title={
                <Stack spacing={1} sx={{ p: 1 }}>
                    <Typography variant="body1" fontWeight="700" sx={{ color: '#000' }}>
                        {translatedWords.detail_infor}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Typography fontWeight="400" variant="body1" color={'#000'}>
                            {translatedWords.name}:
                        </Typography>
                        <Typography fontWeight="600" variant="body1" color={'primary.main'}>
                            {candidate?.name}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography fontWeight="400" variant="body1" color={'#000'}>
                            {translatedWords.email}:
                        </Typography>
                        <Typography fontWeight="600" variant="body1" color={'primary.main'}>
                            {candidate?.email}
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography fontWeight="400" variant="body1" color={'#000'}>
                            {translatedWords.position}:
                        </Typography>
                        <Typography fontWeight="600" variant="body1" color={'primary.main'}>
                            {position?.name}
                        </Typography>
                    </Stack>
                    {link && (
                        <Stack direction="row" spacing={1}>
                            <Typography fontWeight="400" variant="body1" color={'#000'}>
                                {translatedWords.link}:
                            </Typography>
                            <Typography fontWeight="600" variant="body1" color={'primary.main'}>
                                <Link href={link}>{link}</Link>
                            </Typography>
                        </Stack>
                    )}
                    {address && (
                        <Stack direction="row" spacing={1}>
                            <Typography fontWeight="400" variant="body1" color={'#000'}>
                                {translatedWords.address}:
                            </Typography>
                            <Typography fontWeight="600" variant="body1" color={'primary.main'}>
                                {address}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            }
            placement="top-start"
        >
            <Avatar
                alt="member"
                sx={{
                    width: '40px',
                    height: '40px',
                    fontSize: ' 12px',
                    backgroundColor: PollingEventColor.SELECTED,
                }}
            >
                <span>{getInitials(candidate?.name)}</span>
            </Avatar>
        </PrimaryTooltip>
    );
};

const SlotLockedView: CustomContentGenerator<EventContentArg> = (eventInfo) => {
    const candidate = eventInfo.event._def.extendedProps.candidate;
    const position = eventInfo.event._def.extendedProps.position;

    return (
        <Box
            sx={{
                width: '40px',
                height: '40px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: { xs: 'none', md: 'block' },
            }}
        >
            <SlotLockedViewContent candidate={candidate} position={position} eventInfo={eventInfo} />
        </Box>
    );
};

export default SlotLockedView;
