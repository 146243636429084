import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { scheduleApi } from 'features/ScheduleInterview/api';
import { AppThunk } from 'store';
import { Pagination } from 'types/general';
import { MeetingResult, ReviewMeetingPosition } from 'types/interviewMeeting';

interface MeetingResultState {
    positions: ReviewMeetingPosition[];
    meetingResults: Record<string, MeetingResult[]>;
}

const initialState: MeetingResultState = {
    positions: [],
    meetingResults: {},
};

const slice = createSlice({
    name: 'reviewInterview',
    initialState,
    reducers: {
        getPositions: (state: MeetingResultState, action: PayloadAction<ReviewMeetingPosition[]>) => {
            state.positions = action.payload;
        },
        udpatePosition: (
            state: MeetingResultState,
            action: PayloadAction<{ positionId: string; update: Partial<ReviewMeetingPosition> }>
        ) => {
            const { positionId, update } = action.payload;
            state.positions = state.positions.map((position) => (position.id === positionId ? { ...position, ...update } : position));
        },

        getMeetingResults: (state: MeetingResultState, action: PayloadAction<{ positionId: string; meetingResults: MeetingResult[] }>) => {
            const { positionId, meetingResults } = action.payload;

            state.meetingResults[positionId] = meetingResults;
        },
        updateMeetingResult: (
            state: MeetingResultState,
            action: PayloadAction<{ positionId: string; meetingResult: Partial<MeetingResult> }>
        ) => {
            const { positionId, meetingResult } = action.payload;
            if (!Array.isArray(state.meetingResults[positionId])) state.meetingResults[positionId] = [];
            const updatedIndex = state.meetingResults[positionId].findIndex((result) => result._id === meetingResult._id);
            if (updatedIndex !== -1) {
                state.meetingResults[positionId][updatedIndex] = { ...state.meetingResults[positionId][updatedIndex], ...meetingResult };
            } else {
                state.meetingResults[positionId].push(meetingResult as any);
            }
        },
    },
});

export const { actions, reducer } = slice;

/**
 * Get positions that have appear in direct interview meeting
 * @param workspaceId
 * @returns
 */
export const getReviewPositions =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        const response = await scheduleApi.getReviewPollingPosition(workspaceId);
        dispatch(actions.getPositions(response));
        return response;
    };

/**
 * Get meeting results base on position
 * @param workspaceId
 * @param positionId
 * @param paginationQuery
 * @returns
 */

export const getMeetingResults =
    (workspaceId: string, positionId: string, paginationQuery: Pick<Pagination, 'page' | 'limit'>): AppThunk =>
    async (dispatch) => {
        const response = await scheduleApi.getDirectInterviewCallResults(workspaceId, positionId, paginationQuery);

        const meetingResults = response.scoredList;
        if (Array.isArray(meetingResults)) {
            dispatch(actions.getMeetingResults({ positionId, meetingResults }));
        }
        return response;
    };

/**
 * Get scroing result detail
 * @param {stirng} workspaceId
 * @param {string} positionId
 * @param {string} mockMeetingResult
 * @returns
 */
export const getScoringResultDetail =
    (workspaceId: string, positionId: string, mockMeetingResult: string): AppThunk =>
    async (dispatch, getState) => {
        const response = await scheduleApi.getScoringDetail(workspaceId, mockMeetingResult);
        dispatch(actions.updateMeetingResult({ positionId, meetingResult: { _id: mockMeetingResult, scoreResult: response } }));
    };

export default slice;
