import axiosClient from 'lib/axios';
import { AIGeneratePayload, AIGeneratedCriteriaResponse, Position } from 'types/position';

const prefixUrl = 'positions';

const api = {
    /* Get all positions */
    getAll: (workspaceId: string): Promise<Position[]> => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`);
    },

    /* Create Position */
    create: (workspaceId: string, request: any) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /* AI generate criteria */
    AIGenerateCriteria: (workspaceId: string, request: AIGeneratePayload): Promise<AIGeneratedCriteriaResponse> => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/generate-ai-criteria`, request);
    },

    /* Update Positions */
    update: (workspaceId: string, positionId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${positionId}`, request);
    },

    /* Update Status */
    status: (workspaceId: string, positionId: string, status: boolean) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${positionId}/status`, {
            status: status ? 'active' : 'deactive',
        });
    },

    /* Delete Position  */
    delete: (workspaceId: string, positionId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${positionId}`);
    },
};

export default api;
