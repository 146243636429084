import { Box, Button } from '@mui/material';
import Joyride, { CallBackProps, STATUS, Step } from '@pry-financials/react-joyride';
import { FC, useEffect, useRef, useState } from 'react';
import { VIDEO_INTERVIEW_MENU } from 'features/TourUI/steps/dashboardSteps';
import { SIDEBARSCROLLBAR } from 'layout/Sidebar';
import { useDispatch, useSelector } from 'store';

interface TourUIProps {
    steps: Array<Step>;
    canStart: boolean;
    onConfirmFirstAccess?: () => void;
    isAllowSkip?: boolean;
}

const TourUI: FC<TourUIProps> = (props) => {
    const { steps, onConfirmFirstAccess, canStart, isAllowSkip = true } = props;
    const [isFinal, setIsFinal] = useState<boolean>(false);
    const joyRideRef = useRef<HTMLDivElement>();

    const {
        dashboard: { isFirstAccess },
        isPauseTourUI,
    } = useSelector((state) => state.tourUI);

    const handleJoyrideCallback = (data: CallBackProps): void => {
        const { status, index, size } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
        if (index === size - 1) {
            setIsFinal(true);
        }
        if (finishedStatuses.includes(status)) {
            if (onConfirmFirstAccess) onConfirmFirstAccess();
        }
    };

    useEffect(() => {
        return () => {
            if (canStart) onConfirmFirstAccess?.();
        };
    }, [canStart]);

    useEffect(() => {
        if (isFirstAccess && !isPauseTourUI) {
            if (window.innerHeight < 600) {
                const scrollBar = document.getElementById(SIDEBARSCROLLBAR);
                scrollBar.scrollTo(0, 200);
            }
        }
    }, []);

    return (
        <Box ref={joyRideRef}>
            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                scrollOffset={200}
                disableOverlayClose={true}
                disableCloseOnEsc={true}
                hideBackButton={true}
                run={canStart}
                showSkipButton={true}
                scrollToFirstStep={true}
                spotlightClicks={true}
                spotlightPadding={0}
                steps={steps}
                styles={{
                    options: {
                        zIndex: 10000,
                        primaryColor: 'transparent',
                    },
                    buttonClose: {
                        display: 'none',
                    },
                    buttonNext: { outline: 'none' },
                    buttonSkip: { outline: 'none' },
                    tooltipFooter: { display: isFinal && !isAllowSkip ? 'none' : 'flex' },
                }}
                locale={{
                    skip: (
                        <Button
                            variant="outlined"
                            color="primary"
                            component="span"
                            sx={{ display: isAllowSkip ? '' : 'none', width: 'fit-content' }}
                        >
                            Skip
                        </Button>
                    ),
                    back: (
                        <Button variant="outlined" color="primary" component="span" sx={{ width: 'fit-content' }}>
                            Back
                        </Button>
                    ),
                    next: (
                        <Button variant="contained" color="primary" component="span" sx={{ width: 'fit-content' }}>
                            Next
                        </Button>
                    ),
                    last: (
                        <Button variant="outlined" color="primary" component="span" sx={{ width: 'fit-content' }}>
                            End
                        </Button>
                    ),
                }}
                floaterProps={{
                    disableAnimation: true,
                    disableFlip: true,
                    showCloseButton: true,
                }}
            />
        </Box>
    );
};

export default TourUI;

