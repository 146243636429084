import React, { ChangeEvent, FC, ReactNode, SyntheticEvent } from 'react';
import { InputLabel, Grid, Select, MenuItem, GridSize, SelectChangeEvent } from '@mui/material';
import { CountryData } from '../CountryData';
import useApplyTranslation from 'hooks/useApplyTranslation';

interface DisplayCountryTzProps {
    setLayOut?: boolean | GridSize;
    valueCountry: string;
    handleCountry: (event: SelectChangeEvent<string>, child: ReactNode) => void;
    valueTz: string;
    handleTz: (event: SelectChangeEvent<string>, child: ReactNode) => void;
    listTz: any;
    setSize?: 'small' | 'medium';
}

const DisplayCountryTz: FC<DisplayCountryTzProps> = (props) => {
    const { setLayOut = 6, valueCountry, handleCountry, valueTz, handleTz, listTz, setSize = 'medium' } = props;
    const translatedWords = useApplyTranslation();

    // label styling config
    const stylingConfig: any = {
        position: 'absolute',
        top: '15px',
        left: '30px',
        fontSize: '0.7em',
        background: '#fff',
        zIndex: 1,
        padding: '0 5px',
    };

    return (
        <>
            <Grid item xs={setLayOut} sx={{ position: 'relative' }}>
                {/* Country label */}
                <InputLabel id="labelCountry" sx={stylingConfig}>
                    {translatedWords.country}
                </InputLabel>
                {/* Country select */}
                <Select labelId="labelCountry" id="labelCountry" fullWidth value={valueCountry} onChange={handleCountry} size={setSize}>
                    <MenuItem value="None" disabled>
                        None
                    </MenuItem>
                    {CountryData?.map((country, index) => (
                        <MenuItem value={country.countryCode} key={index}>
                            {country.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

            <Grid item xs={setLayOut} sx={{ position: 'relative' }}>
                {/* Region/offset label */}
                <InputLabel id="labelRegion" sx={stylingConfig}>
                    {translatedWords.timezone}
                </InputLabel>
                {/* Region/offset timezone select */}
                <Select fullWidth labelId="labelRegion" id="labelRegion" value={valueTz} onChange={handleTz} size={setSize}>
                    <MenuItem value="None" disabled>
                        None
                    </MenuItem>
                    {listTz?.map((city: any, index: any) => (
                        <MenuItem value={city?.city_time_zone} key={index}>
                            {`UTC(${city?.timezone_offset}) ${city?.city_time_zone}`}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </>
    );
};

export default DisplayCountryTz;

