import { TourUIState } from '../slice';

export const detectCompleteTourUI = (tourUIState: TourUIState) => {
    const isCompleteDashboard = Object.values(tourUIState.dashboard).every((state) => state === false);
    const isCompleteVideoInterview = Object.values(tourUIState.videoInterview).every((state) => state === false);
    const isCompleteDirectInterview = Object.values(tourUIState.directInterview).every((state) => state === false);
    const isCompleteAdvancedFlow = Object.values(tourUIState.advancedFlow).every((state) => state === false);
    const isCompletePosition = Object.values(tourUIState.position).every((state) => state === false);
    const isCompleteWalkThrough =
        isCompleteDashboard && isCompleteVideoInterview && isCompleteDirectInterview && isCompleteAdvancedFlow && isCompletePosition;

    return {
        isCompleteDashboard,
        isCompleteVideoInterview,
        isCompleteDirectInterview,
        isCompleteAdvancedFlow,
        isCompletePosition,
        isCompleteWalkThrough,
    };
};
