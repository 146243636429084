import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface ITabPanelProps {
    value: number;
    index: number;
    name: string;
    children: ReactNode;
    className?: string;
    style?: React.CSSProperties;
}

const TabPanel: FC<ITabPanelProps> = (props) => {
    const { children, value, index, name, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`${name}-tabpanel-${index}`} aria-labelledby={`${name}-tab-${index}`} {...other}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
};

export default TabPanel;

