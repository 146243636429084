import React, { FC } from 'react';
import { Box, Card, Slide, Typography, Zoom } from '@mui/material';

import resumeFolderIcon from 'assets/icons/flows/undraw_resume_folder.svg';
import reviewedDocsIcon from 'assets/icons/flows/undraw_reviewed_docs.svg';
import videoCallIcon from 'assets/icons/flows/undraw_video_call.svg';
import collaborationIcon from 'assets/icons/flows/undraw_collaboration.svg';
import interviewIcon from 'assets/icons/flows/undraw_interview.svg';
import jobOffersIcon from 'assets/icons/flows/undraw_job_offers.svg';
import DashedLine from './DashedLine';

interface StepInfoBoxProp {
    title: string | '';
    background?: string;
    activated?: boolean;
    canShow?: boolean;
    step: 1 | 2 | 3 | 4 | 5 | 6;
}

export const stepIcons = [resumeFolderIcon, reviewedDocsIcon, videoCallIcon, collaborationIcon, interviewIcon, jobOffersIcon];

const StepInfoBox: FC<StepInfoBoxProp> = (props) => {
    const { title, background = 'transparent', canShow, activated = true, step, ...other } = props;

    const displayIcon = <img src={stepIcons[step - 1]} alt={`flow-step-${step}`} style={{ height: 110, width: 110 }} />;

    return (
        <>
            <Card
                sx={{
                    py: 2,
                    boxShadow: 'none',
                    backgroundColor: background,
                }}
                {...other}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: '200ms',
                        color: !activated && 'GrayText',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            display: canShow ? 'flex' : 'none',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                    >
                        <Slide direction="right" in={!activated} appear={false}>
                            <Box sx={{ display: 'flex' }}>
                                <DashedLine lineLength={10} />
                            </Box>
                        </Slide>
                    </Box>

                    <Zoom in={activated} appear={false}>
                        <Box>
                            <Box
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#333',
                                    height: 'auto',
                                    width: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {displayIcon}
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" textAlign="center">
                                    {title}
                                </Typography>
                            </Box>
                        </Box>
                    </Zoom>
                </Box>
            </Card>
        </>
    );
};

export default StepInfoBox;

