import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import type { Direction, Theme, ThemeOptions } from '@mui/material';
import { THEMES } from 'utils/constants';
import { lightShadows, darkShadows } from './shadows';

interface ThemeConfig {
    direction?: Direction;
    responsiveFontSizes?: boolean;
    roundedCorners?: boolean;
    theme?: string;
}

const baseOptions: ThemeOptions = {
    direction: 'ltr',
    components: {
        MuiAvatar: {
            styleOverrides: {
                fallback: {
                    height: '75%',
                    width: '75%',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    MozOsxFontSmoothing: 'grayscale',
                    WebkitFontSmoothing: 'antialiased',
                    height: '100%',
                    width: '100%',
                },
                body: {
                    height: '100%',
                    overflow: 'hidden',
                },
                '#root': {
                    height: '100%',
                },
                '#nprogress .bar': {
                    zIndex: '2000 !important',
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h6',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                    marginRight: '16px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
    },
    typography: {
        button: {
            fontWeight: 600,
        },
        fontFamily: [
            '"Lexend Deca"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Helvetica',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
        ].join(','),
        h1: {
            fontWeight: 600,
            fontSize: '3.5rem',
        },
        h2: {
            fontWeight: 600,
            fontSize: '3rem',
        },
        h3: {
            fontWeight: 600,
            fontSize: '2.25rem',
        },
        h4: {
            fontWeight: 600,
            fontSize: '2rem',
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.5rem',
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.125rem',
        },
        overline: {
            fontWeight: 600,
        },
    },
};

const themesOptions: Record<string, ThemeOptions> = {
    [THEMES.LIGHT]: {
        components: {
            MuiInputBase: {
                defaultProps: {
                    autoComplete: 'off',
                },
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            mode: 'light',
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#f2f6f8',
                paper: '#fafdff',
            },
            error: {
                contrastText: '#fafdff',
                main: '#f44336',
                light: '#f22259',
                dark: '#A60303',
            },
            primary: {
                contrastText: '#fafdff',
                main: '#0A6ABF',
                light: '#d7e7f6',
            },
            secondary: {
                contrastText: '#04182a',
                main: '#c9a2f6',
                light: '#FCEAFF',
            },
            accent: {
                contrastText: '#fafdff',
                main: '#6012ba',
                light: '#FDE9FF',
            },
            revert: {
                contrastText: '#fafdff',
                main: '#04182a',
                light: '#585273',
            },
            success: {
                contrastText: '#fafdff',
                main: '#4caf50',
            },
            text: {
                primary: '#04182a',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#fafdff',
                main: '#ff9800',
                dark: '#F25C05',
            },
        },
        shadows: lightShadows,
    },
    [THEMES.DARK]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#171c24',
                paper: '#222b36',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#0A6ABF',
                light: '#00ABD6',
            },
            secondary: {
                contrastText: '#ffffff',
                main: '#F25C05',
                light: '#F22259',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
    },
    [THEMES.NATURE]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#1c2531',
                paper: '#293142',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#01ab56',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
    },
};

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
    let themeOptions = themesOptions[config.theme];

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`));
        themeOptions = themesOptions[THEMES.LIGHT];
    }

    let theme = createTheme(
        merge(
            {},
            baseOptions,
            themeOptions,
            {
                ...(config.roundedCorners && {
                    shape: {
                        borderRadius: 6,
                    },
                }),
            },
            {
                direction: config.direction,
            }
        )
    );

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme);
    }

    return theme;
};

// Source:
// https://mui.com/material-ui/customization/palette/
declare module '@mui/material' {
    interface Palette {
        accent: Palette['primary'];
        revert: Palette['primary'];
    }

    // allow configuration using `createTheme`
    interface PaletteOptions {
        accent?: PaletteOptions['primary'];
        revert?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        secondary: true;
        accent: true;
        revert: true;
        error: true;
        success: true;
        warning: true;
    }
}

