const domainAllows = ['tech', 'store', 'ai', 'shop'];
const excludeNames = ['gmail', 'yahoo', 'hotmail'];

const getEmailFragments = (email) => email.split(/@(?=[^@]*$)/);

const suggestNameByEmail = (email) => {
    let name = '';
    const fragments = getEmailFragments(email);

    if (fragments.length > 1) {
        let end = '';
        const arrFrag = fragments[1].split('.');

        /**
         * Last
         */
        if (domainAllows.indexOf(arrFrag[arrFrag.length - 1]) > -1) {
            end = capitalizeFirstLetter(arrFrag[arrFrag.length - 1]);
        }

        /**
         * Catch name
         */

        for (let i = 0; i < arrFrag.length - 1; i++) {
            if (excludeNames.indexOf(arrFrag[i]) < 0) {
                name = name + ' ' + capitalizeFirstLetter(arrFrag[i]);
            }
        }

        name = end ? name + ' ' + end : name;
    }

    return name;
};

/**
 * Upper Case First Letter
 * @param text
 * @returns
 */
const capitalizeFirstLetter = (text: string) => {
    return text.replace(/^./, text[0].toUpperCase());
};

export default suggestNameByEmail;
