import axiosClient from 'lib/axios';
import { InterviewComment } from 'types/comment';
import { Quota } from 'types/quota';

const prefixUrl = 'interviews';
const api = {
    getAll: (workspaceId: string, page: number = 1, limit: number, progress: string = null, position: string = null) => {
        if (progress === null) {
            if (position !== null)
                return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
                    params: { page: page, limit: limit, position: position },
                });
            return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
                params: { page: page, limit: limit },
            });
        }

        if (progress !== null) {
            if (position !== null)
                return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
                    params: { page: page, limit: limit, progress: progress, position: position },
                });
            return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
                params: { page: page, limit: limit, progress: progress },
            });
        }

        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
            params: { page: page, limit: limit, progress: progress },
        });
    },

    getSamePosition: (workspaceId: string, page: number = 1, limit: number, progress: string = null, position: string = null) => {
        if (progress === null) {
            return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
                params: { page: page, limit: limit, position: position },
            });
        }

        if (progress !== null) {
            return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
                params: { page: page, limit: limit, progress: progress },
            });
        }

        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
            params: { page: page, limit: limit, progress: progress },
        });
    },

    search: (workspaceId: string, keyword: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/ts/search?q=${keyword}`);
    },

    /* Create Interview */
    create: (workspaceId: string, request: any) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /**
     * Get Answers
     * @param workspaceId
     * @param interviewId
     * @returns
     */
    getAnswers: (workspaceId: string, interviewId: String) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${interviewId}/review`);
    },

    /**
     * Get Result Comments
     * @param workspaceId
     * @param questionId
     * @returns
     */
    getAnswerDetail: (workspaceId: string, questionId: String) => {
        return axiosClient.get(`workspaces/${workspaceId}/answers/${questionId}`);
    },

    /**
     * Create Result Comment
     * @param workspaceId
     * @param questionId
     * @param request
     * @returns
     */
    createResultComment: (workspaceId: string, questionId: String, request: InterviewComment) => {
        return axiosClient.post(`workspaces/${workspaceId}/answers/${questionId}/assessment`, request);
    },

    /**
     * Send Email
     * @param workspaceId
     * @param request
     * @returns
     */
    sendEmail: (workspaceId: string, request: any) => {
        return axiosClient.post(`/workspaces/${workspaceId}/interviews/send-email-invite`, request);
    },

    /**
     * get Current Quota
     *
     */
    getQuota: (): Promise<Quota> => {
        return axiosClient.get(`/quota`);
    },
    /**
     * get Current Workspace Quota
     *
     */
    getQuotaWorkspace: (workspaceId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/quota`);
    },

    /**
     * Interview Decision
     *
     */
    makeDecision: (workspaceId: string, interviewId: string, request: any) => {
        return axiosClient.post(`/workspaces/${workspaceId}/interviews/decition/${interviewId}`, request);
    },
    sendEmailAfterDecision: (workspaceId: string, request: any) => {
        return axiosClient.post(`/workspaces/${workspaceId}/interviews/send-email-decision/`, request);
    },

    //Get email data
    getEmailData: (workspaceId: string, emailId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/email`, {
            params: { id: emailId },
        });
    },

    //Cancel Schedule
    cancelScheduleSendEmail: (workspaceId: string, scheduleId: string) => {
        return axiosClient.delete(`/workspaces/${workspaceId}/email/cancel-schedule`, {
            data: {
                id: scheduleId,
            },
        });
    },
    getCloudflareVideoId: (workspaceId: string, videoCloudId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/questions/cloud-video/${videoCloudId}`);
    },
};

export default api;

