import { Dialog } from '@mui/material';
import PaymentDialog from 'components/payment/PaymentDialog';
import { workspaceSelector } from 'hooks/useSelector';
import queryString from 'query-string';
import { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'store';
import { configAppInit } from 'utils/config';
import { OPEN_PAYMENT_PACKAGE_PARAM } from 'utils/constants';

interface IAppWrapperProps {}

const AppWrapper: FC<IAppWrapperProps> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const workspace = useSelector(workspaceSelector);

    const queryParams = useMemo(() => {
        return queryString.parse(location.search);
    }, [location.search]);

    useEffect(() => {
        // if redirect from landing page when choose payment package, save 'package=open' param to localStorage and then remove it
        if (queryParams?.[OPEN_PAYMENT_PACKAGE_PARAM]) {
            configAppInit.set({ [OPEN_PAYMENT_PACKAGE_PARAM]: queryParams[OPEN_PAYMENT_PACKAGE_PARAM] });
            navigate(location.pathname);
        }
    }, [queryParams]);

    // Handle open payment dialog
    const [openPayment, setOpenPayment] = useState(false);
    const handlOpenPayment = () => {
        setOpenPayment(true);
    };
    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    useEffect(() => {
        const openPaymentDialog = !!configAppInit.get(OPEN_PAYMENT_PACKAGE_PARAM) || false;

        if (openPaymentDialog && !!workspace.activated) {
            handlOpenPayment();
            configAppInit.remove(OPEN_PAYMENT_PACKAGE_PARAM);
        }
    }, [workspace.activated]);

    return (
        <>
            {children}

            <Dialog
                open={openPayment}
                onClose={(e, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClosePayment();
                    }
                }}
                disableEscapeKeyDown
                fullWidth
                maxWidth="lg"
            >
                <PaymentDialog onClose={handleClosePayment} />
            </Dialog>
        </>
    );
};

export default AppWrapper;

