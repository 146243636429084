import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleFilled from 'assets/icons/CheckCircleFilled';
import useApplyTranslation from 'hooks/useApplyTranslation';
import React, { FC } from 'react';

interface IPremiumPaymentProps {
    type: 'normal' | 'special';
    onOpenForm: () => void;
}

const PremiumPayment: FC<IPremiumPaymentProps> = (props) => {
    const { type, onOpenForm } = props;
    const translatedWords = useApplyTranslation();

    const payment = {
        title: `${translatedWords.premium_backage}`,
        primaryColor: '#FE9701',
        darkColor: '#cd8114',
        benefit: [
            `${translatedWords.offering7}`,
            `${translatedWords.offering8}`,
            `${translatedWords.offering3}`,
            `${translatedWords.offering9}`,
            `${translatedWords.offering4}`,
        ],
    };
    const useStyles = makeStyles({
        root: {
            '&:hover span': {
                backgroundColor: payment.primaryColor,
            },
            '&:hover button > span': { backgroundColor: 'transparent' },
        },
    });

    const classes = useStyles();

    return (
        <Card
            className={classes.root}
            sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                border: `2px solid ${payment.primaryColor}`,
                height: '100%',
            }}
        >
            <Typography
                textAlign="center"
                variant="h6"
                sx={{ mt: '45px', color: payment.primaryColor, fontSize: '20px', fontWeight: 'bold' }}
            >
                {payment.title}
            </Typography>

            <CardContent>
                <Box sx={{ minHeight: '100px' }}>
                    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src="/static/images/payment/diamond.png"
                            alt=""
                            style={{ width: '50px', height: '50px', objectFit: 'contain', transform: 'translateY(-4px)' }}
                        />
                        <Typography fontSize="15px" fontWeight="600" sx={{ mx: 1 }}>
                            {translatedWords.unlimited}
                        </Typography>
                    </Box>
                </Box>
                {type === 'normal' && (
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: payment.primaryColor,
                                fontSize: '15px',
                                '&:hover': { backgroundColor: payment.darkColor },
                                mb: 1,
                            }}
                            onClick={onOpenForm}
                        >
                            {translatedWords.contact_sales}
                        </Button>
                    </Box>
                )}
                <Stack>
                    {payment.benefit.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', minWidth: '80%', mt: 1 }}>
                            <Box sx={{ mx: 4, display: 'flex' }}>
                                <CheckCircleFilled fontSize="small" sx={{ color: payment.primaryColor, m: 'auto' }} />
                            </Box>
                            <Typography fontWeight="600" fontSize="12px">
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            </CardContent>
            {type === 'special' && (
                <Box sx={{ width: '100%', textAlign: 'center', flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                    <Box
                        sx={{
                            fontSize: '15px',
                            width: '100%',
                            py: 2,
                            fontWeight: '600',
                            color: '#000',
                            cursor: 'pointer',
                        }}
                        component="span"
                        onClick={onOpenForm}
                    >
                        {translatedWords.choose_button}
                    </Box>
                </Box>
            )}
        </Card>
    );
};

export default PremiumPayment;

