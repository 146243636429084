import { EmailType } from './email';
import { Position } from './position';

export interface WorkingHourDateInfo {
    stringDate: string;
    workingHourFrom: number;
    workingHourTo: number;
}

export interface SendingEmailPayload {
    subject?: string;
    type?: EmailType;
    content: string;
    replyTo: string[];
    cc: string[];
    themeId?: string;
}

export interface Panelist {
    email: string;
    name?: string;
    token?: string;
    voted?: boolean;
}

export interface PollingInterviewPayloadBase {
    candidateInfo: { name: string; email: string };
    description?: string;
    duration: number;
    optionalPanelists?: Pick<Panelist, 'email' | 'name'>[];
    panelists: Pick<Panelist, 'email' | 'name'>[];
    positionId: string;
    timezone: string;
    title?: string;
    unscheduleId?: string;
}
export interface PollingPayloadReq extends PollingInterviewPayloadBase {
    autoSendCandidate: boolean;
    dateList: WorkingHourDateInfo[];
    showVoteTimeEveryone?: boolean;
    unschedule?: boolean;
}

export interface LockedInterviewPayloadReq extends PollingInterviewPayloadBase {
    startTime: number;
}

export interface CandidateKit {
    deadline: number;
    emailRecordId?: string;
    voted?: boolean;
    token?: string;
}

export interface SlotList {
    id: string;
    stringDate: string;
    primaryVoting: string[];
    secondVoting: string[];
    from: number;
    to: number;
}

export interface PollingMessage {
    panelist: string;
    content: string;
}

export enum PollingStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    ARCHIVE = 'archive',
    DELETE = 'delete',
    UNSCHEDULE = 'unschedule',
    UNSUCCESS = 'unsuccess',
    DROP = 'drop',
}

export enum PollingProgress {
    UNSCHEDULE = 'unschedule',
    PINVITED = 'pInvited',
    PCONFIRMED = 'pConfirmed',
    CAINVITED = 'caInvited',
    CACONFIRMED = 'caConfirmed',
    LOCKED = 'locked',
    CALL_SETUP = 'callSetup',
    SCORED = 'scored',
}

export interface SchedulePolling {
    autoSendCandidate: boolean;
    author: string;
    avgScore?: number;
    candidateKit: CandidateKit;
    candidate: { name: string; email: string; id: string };
    caMessage?: string;
    candidateSlots: { slotId: string; primary: boolean }[];
    createAt: string;
    dateList: WorkingHourDateInfo[];
    decision?: { author: string; result: boolean; timestamp: number };
    description: string;
    duration: number;
    extended?: string;
    failedVoting: number;
    finalPanelistSlots: string[];
    id: string;
    lockedSlot?: string;
    panelistMessage?: PollingMessage[];
    panelists: Panelist[];
    position: Position;
    progress: PollingProgress;
    isFailed?: boolean;
    optionalPanelists: Pick<Panelist, 'email' | 'name'>;
    updateAt: string;
    sendingEmail: SendingEmailPayload;
    seFinalPanelistSlots?: string[];
    showVoteTimeEveryOne: boolean;
    slotList: SlotList[];
    status: string;
    timezone: string;
    title: string;
    totalCurrentvoted: number;
    workspace: string;
    rangeTiming?: { start: number; end: number };
}

export type UnschedulePolling = Pick<SchedulePolling, 'candidate' | 'position' | 'workspace' | 'id'>;

export interface QueryPollingReq {
    limit?: number;
    page?: number;
    createFrom?: number;
    createTo?: number;
    progress: PollingProgress;
    rangeFrom?: number;
    rangeTo?: number;
}

export type QueryPollingByTimeReq = Pick<QueryPollingReq, 'progress' | 'rangeFrom' | 'rangeTo'>;

export interface PollingVoteReq {
    message: string;
    votingList: {
        primaryVotingList: string[];
        secondVotingList: string[];
    };
}

export type PollingMemberType = 'candidate' | 'panelist';

export type EventExtendedInfo = SlotList & SchedulePolling;

export interface PollingEvent {
    display?: string;
    start: number;
    end: number;
    color?: PollingEventColor;
    id?: string;
    extendedProps: EventExtendedInfo;
}

export enum PollingEventColor {
    PRIMARY = '#4caf50',
    SECONDARY = '#f5e559',
    UNSELECTED = '#22B4D4',
    TRYHARD = '#43DDFF',
    SELECTED = '#9929bd',
    EXPIRED = '#ccc',
}

export interface LockedInvitationPayload {
    panelists: { email: string }[];
    candidateLocation: { address?: string; link?: string };
    panelistLocation: {
        address?: string;
        link?: string;
    };
}

export interface PollingExtendedInformation {
    candidateLocation: { address?: string; link?: string };
    panelistLocation: {
        address?: string;
        link?: string;
    };
    id: string;
    workspace: string;
}

export interface PollingMeeting {
    panelists: string[];
    scoringCriteria: string[];
    candidate: {
        name: string;
        email: string;
        resume: string;
    };
    positionName: string;
    startingAt: number;
    panelistLink: string;
    candidateLink: string;
    workspace: string;
    polling: string;
    id: string;
}
