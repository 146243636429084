import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { AppThunk } from 'store';
import { CandidateAnalyticsOverview, DetailFlow, TimeDetailFlow, TimeOverView } from 'types/analytics';
import api from './api';

interface CandidateAnalyticsState {
    analytics: {
        overview: CandidateAnalyticsOverview;
        detailFlows: DetailFlow[];
        timeOverview: TimeOverView;
        timeDetailFlows: TimeDetailFlow[];
    };
    candidateThroughput: {
        timeDetailFlows: TimeDetailFlow[];
        detailFlows: DetailFlow[];
    };
    isLoading: boolean;
    error: boolean;
}

const initialState: CandidateAnalyticsState = {
    analytics: {
        overview: {
            flowTotal: 0,
            cvReceived: 0,
            happening: 0,
            reject: 0,
            passed: 0,
        },
        detailFlows: [],
        timeOverview: {
            totalAvgTime: 0,
            totalPassThread: 0,
            totalThread: 0,
        },
        timeDetailFlows: [],
    },
    candidateThroughput: {
        detailFlows: [],
        timeDetailFlows: [],
    },
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'candidateAnalytics',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        getCandidateAnalyticsOverview: (state: CandidateAnalyticsState, action: PayloadAction<CandidateAnalyticsOverview>) => {
            const analyticsOverview = action.payload;
            state.analytics.overview = analyticsOverview;
        },
        getTimeOverviewFlow: (state: CandidateAnalyticsState, action: PayloadAction<TimeOverView>) => {
            const timeOverview = action.payload;
            state.analytics.timeOverview = timeOverview;
        },
        getOverviewDetailFlow: (state: CandidateAnalyticsState, action: PayloadAction<DetailFlow[]>) => {
            const detailFlows = action.payload;

            state.analytics.detailFlows = detailFlows;
        },
        getThroughputDetailFlow: (state: CandidateAnalyticsState, action: PayloadAction<DetailFlow[]>) => {
            const detailFlows = action.payload;
            state.candidateThroughput.detailFlows = detailFlows;
        },
        getTimeDetailFlow: (state: CandidateAnalyticsState, action: PayloadAction<TimeDetailFlow[]>) => {
            const timeDetailFlows = action.payload;
            state.candidateThroughput.timeDetailFlows = timeDetailFlows;
            state.analytics.timeDetailFlows = timeDetailFlows;
        },
    },
});

/**
 * Candidate Analytics Overview
 * @param workspaceId
 * @returns
 */
export const getCandidateAnalyticsOverview =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.getAnalyticsOverview(workspaceId);
        dispatch(slice.actions.getCandidateAnalyticsOverview(response));
    };

export const getOverviewDetailFlow =
    (workspaceId: string, params = {}): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response: any = await api.getAnalyticsDetailFlow(workspaceId, params);
            dispatch(slice.actions.getOverviewDetailFlow(response));
        } catch (error) {
            console.log('The following error occurred:', error.message);
            toast.error('Error fetching data');

            dispatch(slice.actions.getOverviewDetailFlow([]));
        }
    };

export const getThroughputDetailFlow =
    (workspaceId: string, params = {}): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response: any = await api.getAnalyticsDetailFlow(workspaceId, params);
            dispatch(slice.actions.getThroughputDetailFlow(response));
        } catch (error) {
            console.log('The following error occurred:', error.message);
            toast.error('Error fetching data');

            dispatch(slice.actions.getThroughputDetailFlow([]));
        }
    };

export const getTimeDetailFlow =
    (workspaceId: string, params = {}): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response: any = await api.getTimeDetailFlow(workspaceId, params);
            dispatch(slice.actions.getTimeDetailFlow(response));
        } catch (error) {
            console.log('The following error occurred:', error.message);
            toast.error('Error fetching data');
            dispatch(slice.actions.getTimeDetailFlow([]));
        }
    };

export const getTimeOverviewFlow =
    (workspaceId: string, params = {}): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            dispatch(slice.actions.setLoading(true));
            const response: any = await api.getAnalyticTimeOverview(workspaceId, params);
            dispatch(slice.actions.getTimeOverviewFlow(response));
            dispatch(slice.actions.setLoading(false));
        } catch (error) {
            console.log('The following error occurred:', error.message);
            toast.error('Error fetching data');
            dispatch(slice.actions.setLoading(false));
            dispatch(slice.actions.getTimeOverviewFlow({ totalAvgTime: 0, totalPassThread: 0, totalThread: 0 }));
        }
    };

export const { reducer } = slice;
export default slice;
