import axiosClient from 'lib/axios';
import { Email, EmailPayload } from 'types/email';

const prefixUrl = 'email-templates';

const api = {
    /**
     * Get all Emails
     * @param workspaceId
     */
    getAll: (workspaceId: string): Promise<Email[]> => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`);
    },

    /* Create Email */
    create: (workspaceId: string, request: EmailPayload) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /* Update Email */
    update: (workspaceId: string, emailId: string, request: EmailPayload) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${emailId}`, request);
    },
    /* Delete Email */
    delete: (emailId: string, workspaceId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${emailId}`);
    },
    /* Insert Image */
    insertImages: (workspaceId: string, request: any) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/image`, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
};

export default api;
