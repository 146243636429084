import axiosClient from 'lib/axios';
import { RecruiterInformation } from 'types/recruiterInfo';
import { WorkspaceType } from 'types/workspace';

const prefixUrl = 'auth';

const api = {
    // Google LOGIN
    googleLogin: (token: string) => {
        return axiosClient.post(`${prefixUrl}/google`, { token: token });
    },

    // Google REGISTER
    googleRegister: (token: string, email: string, firstName: string, lastName: string, inviterId: string) => {
        return axiosClient.post(`${prefixUrl}/register/google?invited_by=${inviterId} `, {
            token: token,
            email: email,
            firstName: firstName,
            lastName: lastName,
        });
    },

    /* Login */
    login: (email: string, password: string) => {
        return axiosClient.post(`${prefixUrl}/login`, {
            email: email,
            password: password,
        });
    },

    /* Refresh Token */
    refreshToken: () => {
        return axiosClient.post(`${prefixUrl}/refresh`, {});
    },

    /* Send OTP Mail */
    otpMail: (email: string) => {
        return axiosClient.post(`${prefixUrl}/otp`, { email: email });
    },

    /* Register */
    register: (email: string, password: string, firstName: string = '', lastName: string = '', inviterId: string) => {
        return axiosClient.post(`${prefixUrl}/register?invited_by=${inviterId}`, {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            inviterId: inviterId,
        });
    },
    // Get Members
    getMembers: (workspaceId: String) => {
        return axiosClient.get(`workspaces/${workspaceId}/members`);
    },

    // Check for current account state
    checkAccount: (email: string) => {
        return axiosClient.get(`/recruiter/check-status-placeholder`, { params: { email: email } });
    },

    // Check Exist Account
    checkExistAccount: (email) => {
        return axiosClient.get(`/recruiter/check-account-exist`, { params: { email: email } });
    },

    // Confirm invitation to Workspace
    confirmInvitation: (workspaceId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/members/confirm-workspace`, request);
    },

    inviteRegister: (workspaceId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/members/confirm-workspace-create-account`, request);
    },

    // Verify Email
    verifyEmail: (request: any) => {
        return axiosClient.post(`${prefixUrl}/verify-email`, request);
    },

    /* Update recruiter Profile */
    updateProfile: (request: any) => {
        return axiosClient.patch(`recruiter/self`, request);
    },

    // request password reset with otp
    requestPasswordResetOTP: (request: any) => {
        return axiosClient.post(`${prefixUrl}/request-password-reset/otp`, request);
    },

    // reset password with otp
    resetPasswordWithOTP: (request: any) => {
        return axiosClient.post(`${prefixUrl}/reset-password/otp`, request);
    },

    // send magic link for log in
    sendMagicLink: (request: any) => {
        return axiosClient.post(`${prefixUrl}/send-magic-link`, request);
    },

    // login with magic link
    loginMagicLink: (request: any) => {
        return axiosClient.post(`${prefixUrl}/login-magic-link`, request);
    },

    // Login with fast register
    loginFastRegister: (email: string, inviterId: string) => {
        return axiosClient.post(`${prefixUrl}/register-fast?invited_by=${inviterId}`, {
            email: email,
        });
    },

    updateRecruiterName: (request, token: string): Promise<RecruiterInformation> => {
        return axiosClient.patch(`/recruiter/self`, request, {
            headers: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        });
    },
};

export default api;

