interface GTMConfig {
    containerId: string;
}

const warn = (...args) => {
    if (import.meta.env.NODE_ENV !== 'development') {
        return;
    }

    console.warn(...args);
};

class GTM {
    CONTAINER_ID = null;

    initialized = false;

    configure(config: GTMConfig) {
        this.CONTAINER_ID = config.containerId;
    }

    initialize(config) {
        if (this.initialized) {
            warn('GTM can only be initialized once.');
            return;
        }

        // Maybe you want to load events from server side (in NextJS apps for example),
        // those can be queued.
        // SSR queued events can be loaded in the initialize script.
        // For the moment we do not implement it, but in future we might add it.

        if (!document) {
            warn('GTM can be initialized only on client side.');
            return;
        }

        if (!config.containerId) {
            warn('GTM requires a GTM ID to be loaded.');
            return;
        }

        this.configure(config);

        const script1 = document.createElement('script');
        const script2 = document.createElement('script');

        script1.async = true;
        script1.src = `https://www.googletagmanager.com/gtag/js?id=${this.CONTAINER_ID}`;

        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${this.CONTAINER_ID}');
    `;

        document.head.insertBefore(script1, document.head.childNodes[0]);
        document.head.insertBefore(script2, document.head.childNodes[0]);
    }

    // eslint-disable-next-line class-methods-use-this
    push(...args) {
        if (!window) {
            warn('GTM push works only on client side.');
            return;
        }

        if (!(window as any).dataLayer) {
            (window as any).dataLayer = [];
        }

        (window as any).dataLayer.push(...args);
    }
}

// Singleton
const gtm = new GTM();

export default gtm;
