import axiosClient from 'lib/axios';

const langPrefixUrl = 'language';

const api = {
    getLanguages: () => {
        return axiosClient.get(`/${langPrefixUrl}`);
    },
    getLanguageResource: (languageCode: string) => {
        return axiosClient.get(`/${langPrefixUrl}/${languageCode}?type=hr`);
    },
};

export default api;
