import { createSlice } from '@reduxjs/toolkit';

import { AppThunk } from 'store';
import { Candidate } from 'types/candidate';
import { Employee } from 'types/employee';
import api from './api';

interface CandidateState {
    candidates: Candidate[];
    employees: Employee[];
    isLoading: boolean;
    error: boolean;
}

const initialState: CandidateState = {
    candidates: [],
    employees: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'candidate',
    initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get Candidates */
        list: (state, action) => {
            state.candidates = action.payload;
            state.isLoading = false;
        },

        /* Create Candidate */
        create: (state, action) => {
            state.candidates.unshift(action.payload);
            state.isLoading = false;
        },

        /* Update Candidate */
        update: (state, action) => {
            const candidate = action.payload;
            const index = state.candidates.findIndex((x) => x.id === candidate.id);

            if (index > -1) {
                state.candidates[index] = candidate;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove Candidate */
        remove: (state, action) => {
            const index = state.candidates.findIndex((x) => x.id === action.payload);
            if (index > -1) {
                state.candidates.splice(index, 1);
            }

            state.isLoading = false;
        },

        setEmployees: (state, action) => {
            state.employees = action.payload;
            state.isLoading = false;
        },
    },
});

/**
 * Get all Candidates
 * @param workspaceId
 * @param page
 * @param limit
 * @returns
 */
export const getCandidates =
    (workspaceId: string, page: number = 1, limit: number = 12): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.gets(workspaceId, page, limit);
        dispatch(slice.actions.list(response.candidates));
        return response;
    };

/**
 * Search Candidates
 * @param workspaceId
 * @param keyword
 * @param page
 * @param limit
 * @returns
 */
export const searchCandidates =
    (workspaceId: string, keyword: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.search(workspaceId, keyword);
        dispatch(slice.actions.list(response));
        return response;
    };

/**
 * Create Candidate
 * @param workspaceId
 * @param request
 * @returns
 */
export const createCandidate =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.create(workspaceId, request);
    };

/**
 * Update Candidate
 * @param workspaceId
 * @param candidateId
 * @param request
 * @returns
 */
export const updateCandidate =
    (workspaceId: string, candidateId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(workspaceId, candidateId, request);
        dispatch(slice.actions.update(response));
    };

/**
 * Import Candidates's CV => PDF files
 * @param workspaceId
 * @param files
 * @returns
 */
export const importCandidates =
    (workspaceId: string, files): AppThunk =>
    async (dispatch): Promise<any> => {
        const response = await api.importCv(workspaceId, files);
        return response;
    };

export const getEmployees =
    (workspaceId: string, page: number = 1, limit: number = 12): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.getEmployees(workspaceId, page, limit);
        dispatch(slice.actions.setEmployees(response.staffs));
        return response;
    };

export const searchEmployees =
    (workspaceId: string, keyword: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.searchEmployees(workspaceId, keyword);
        dispatch(slice.actions.setEmployees(response));
        return response;
    };

export const changeToEmployee =
    (workspaceId: string, candidateId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.changeToEmployee(workspaceId, candidateId);
        return response;
    };

export const changeToCandidate =
    (workspaceId: string, employeeId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.changeToCandidate(workspaceId, employeeId);
        return response;
    };

export const { reducer } = slice;
export default slice;
