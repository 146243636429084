import { Box, Card, CardHeader, CircularProgress, Container, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import XIcon from 'assets/icons/X';
import api from 'features/Payment/api';
import { authSelector, settingsSelector } from 'hooks/useSelector';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'store';
import PreferPayment from './PreferPayment';
import PremiumPayment from './PremiumPayment';
import StandardPayment from './StandardPayment';
// Import Swiper React components
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import ConfirmPayment from 'components/ConfirmPayment';
import useGetQuotaPackage from 'hooks/useGetQuotaPackage';
import useGetUserTransaction from 'hooks/useGetUserTransaction';
import toast from 'react-hot-toast';
import { PaymentResponse, QuotaPackage } from 'types/payment';
import { CONTACT_SALES_NAME, PAYMENT_RESULT_NAME, RECOMMENT_PACKAGE_NAME, STANDARD_PACKAGE_NAME } from 'utils/constants';
import ContactInformationForm from './ContactInformationform';
import ContactSalesResult from './ContactSaleResult';
import ContactSales from './ContactSales';
import PayScreen from './PayScreen';
import useApplyTranslation from 'hooks/useApplyTranslation';

interface IPaymentDialogProps {
    onClose: () => void;
}

const PaymentDialog: FC<IPaymentDialogProps> = (props) => {
    const { onClose } = props;
    const settings = useSelector(settingsSelector);
    const auth = useSelector(authSelector);
    const quotaPackages = useGetQuotaPackage();
    const translatedWords = useApplyTranslation();

    const { userTransaction, loadingTransaction, handleRefetchData } = useGetUserTransaction();

    //Handle confirm pay
    const [openConfirm, setOpenConfirm] = useState(false);
    const [packageSelected, setPackageSelected] = useState<QuotaPackage>(null);

    const handleOpenConfirm = (quotaPackageId: string) => {
        setOpenConfirm(true);
        const quotaPackage = quotaPackages.find((quotaPackage) => quotaPackage.id === quotaPackageId);
        setPackageSelected(quotaPackage);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
        setPackageSelected(null);
    };

    // Handle payment with paddle
    const Paddle = (window as any).Paddle;

    const [isLoadingPopup, setIsLoadingPopup] = useState(false);
    const [paymentResponse, setPaymentResponse] = useState<PaymentResponse>(null);
    const handleOpenCustomPayment = async (packageId: string) => {
        try {
            handleCloseConfirm();
            setExtraSteps([PAYMENT_RESULT_NAME]);
            handleNextSlide();
            if (auth?.user?.payment) {
                setIsLoadingPopup(true);
                if (userTransaction?.paymentInformation.quotaPackage !== packageId) {
                    await api.changeQuotaPackage(packageId);
                }
                const payment: any = await api.payMore();
                setPaymentResponse(payment);

                setIsLoadingPopup(false);
                toast.success('Success');
            } else {
                const linkResponse: any = await api.getPaymentLink(packageId);
                Paddle.Checkout.open({
                    method: 'inline',
                    override: linkResponse.url,
                    frameTarget: 'checkout-container', // The className of your checkout <div>
                    frameInitialHeight: 416,
                    frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;border-radius: 10px',
                    eventCallback: function (data) {
                        if (data.event === 'Checkout.Loaded') {
                            setIsLoadingPopup(false);
                        }
                    },
                });
            }
            handleRefetchData();
        } catch (error) {
            toast.error('Transaction failed! Please check your card and try again');
            console.log('The following error occurred:', error);
            handlePrevSlide();
            handleRefetchData();
        }
    };
    const [activeIndex, setActiveIndex] = useState(0);
    const handleNextSlide = () => {
        setActiveIndex((prev) => prev + 1);
    };

    const handlePrevSlide = () => {
        setActiveIndex((prev) => prev - 1);
    };

    useEffect(() => {
        if (quotaPackages) {
            const swiper = (document.querySelector('#payment-dialog-slide') as any).swiper;
            if (activeIndex > swiper.realIndex) {
                swiper.slideNext();
            } else if (activeIndex < swiper.realIndex) {
                swiper.slidePrev();
                // Reset slide's extra steps
                if (activeIndex === 0) {
                    setExtraSteps([]);
                }
            }
        }
    }, [activeIndex, quotaPackages]);

    // Handle change Slide's extra steps
    const [extraSteps, setExtraSteps] = useState<string[]>([]);
    const [contactRequest, setContactRequest] = useState<any>(null);

    const handleGetSlideSteps = (): JSX.Element[] => {
        const steps: JSX.Element[] = [];

        extraSteps.forEach((stepName) => {
            if (stepName === PAYMENT_RESULT_NAME)
                steps.push(
                    <PayScreen
                        onBack={() => {
                            handlePrevSlide();
                            handleRefetchData();
                        }}
                        onClose={onClose}
                        isLoadingPopup={isLoadingPopup}
                        onChangePaymentResponse={setPaymentResponse}
                        paymentResponse={paymentResponse}
                    />
                );
            if (stepName === CONTACT_SALES_NAME) {
                steps.push(<ContactSales onBack={handlePrevSlide} onNext={handleNextSlide} onChangeContactRequest={setContactRequest} />);
                steps.push(<ContactInformationForm contactRequest={contactRequest} onBack={handlePrevSlide} onNext={handleNextSlide} />);
                steps.push(<ContactSalesResult onClose={onClose} />);
            }
        });

        return steps;
    };

    const matchMobile = useMediaQuery('(max-width: 60em)');

    return (
        <>
            {!quotaPackages ? (
                <Box sx={{ minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Card sx={{ overflowY: 'auto' }}>
                    <CardHeader
                        title={
                            <Box>
                                <Typography sx={{ fontSize: '24px', fontWeight: '700', color: '#0C6ABF' }}>
                                    {translatedWords.upgrade_title}
                                </Typography>
                                <Typography fontSize="15px" color="textSecondary" fontWeight="600">
                                    {translatedWords.upgrade_title2}
                                </Typography>
                            </Box>
                        }
                        action={
                            <IconButton onClick={onClose}>
                                <XIcon fontSize="small" />
                            </IconButton>
                        }
                    />
                    <Container maxWidth={settings.compact ? 'xl' : false} sx={{ visibility: loadingTransaction ? 'hidden' : 'visible' }}>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            navigation={true}
                            allowTouchMove={false}
                            slidesPerView={1}
                            slidesPerGroup={1}
                            loop={false}
                            loopFillGroupWithBlank={false}
                            scrollbar={{ draggable: false }}
                            centeredSlides={true}
                            id="payment-dialog-slide"
                        >
                            <SwiperSlide>
                                <Box
                                    sx={{
                                        mt: 3,
                                        mb: 2,
                                        visibility: activeIndex !== 0 ? 'hidden' : 'visible',
                                        height: activeIndex !== 0 ? '0px' : 'unset',
                                    }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item md={4} xs={12}>
                                            <StandardPayment
                                                type="normal"
                                                onSelectPackage={(packageId) => {
                                                    if (userTransaction?.paymentInformation?.quotaPackage) {
                                                        handleOpenConfirm(packageId);
                                                    } else {
                                                        handleOpenCustomPayment(packageId);
                                                    }
                                                }}
                                                quotaPackage={quotaPackages.find(
                                                    (quotaPackage) => quotaPackage.name === STANDARD_PACKAGE_NAME
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={4}
                                            xs={12}
                                            sx={{
                                                marginTop: matchMobile ? 3 : 0,
                                            }}
                                        >
                                            <PreferPayment
                                                type="normal"
                                                onSelectPackage={(packageId) => {
                                                    if (userTransaction?.paymentInformation?.quotaPackage) {
                                                        handleOpenConfirm(packageId);
                                                    } else {
                                                        handleOpenCustomPayment(packageId);
                                                    }
                                                }}
                                                quotaPackage={quotaPackages.find(
                                                    (quotaPackage) => quotaPackage.name === RECOMMENT_PACKAGE_NAME
                                                )}
                                            />
                                        </Grid>
                                        <Grid item md={4} xs={12}>
                                            <PremiumPayment
                                                onOpenForm={() => {
                                                    handleNextSlide();
                                                    setExtraSteps([CONTACT_SALES_NAME]);
                                                }}
                                                type="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </SwiperSlide>
                            {handleGetSlideSteps().map((element, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        {/*
                                         * Hide component when it is inactive
                                         * Because have already a init step before so start with index + 1
                                         */}
                                        <Box
                                            sx={{
                                                visibility: index + 1 !== activeIndex ? 'hidden' : 'visible',
                                                height: index + 1 !== activeIndex ? '0px' : 'unset',
                                            }}
                                        >
                                            {element}
                                        </Box>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </Container>
                </Card>
            )}
            <ConfirmPayment
                open={openConfirm}
                onClose={handleCloseConfirm}
                onConfirm={() => handleOpenCustomPayment(packageSelected.id)}
                title="Confirm Order"
                content={
                    <Typography color="textSecondary" sx={{ mt: 1 }} variant="body2">
                        You are about to purchase <strong>{packageSelected?.name}</strong> for <strong>${packageSelected?.price}</strong>.
                        Your package will be auto-renewed once you reach your account limit. You can disable auto-renewal at any time in
                        the&nbsp;
                        <strong>Setting {'>'} Package and Payment</strong>.
                    </Typography>
                }
            />
        </>
    );
};

export default PaymentDialog;

