import { Box, Button, Divider, Typography } from '@mui/material';
import HeadArrow from 'assets/icons/HeadArrow';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { workspaceSelector } from 'hooks/useSelector';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'store';

interface IContactSalesResultProps {
    onClose?: () => void;
    onBack?: () => void;
}

const ContactSalesResult: React.FunctionComponent<IContactSalesResultProps> = (props) => {
    const { onBack, onClose } = props;
    const workspace = useSelector(workspaceSelector);
    const translatedWords = useApplyTranslation();

    return (
        <Box>
            {onBack && (
                <>
                    <Box
                        sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => {
                            onBack();
                        }}
                    >
                        <HeadArrow
                            sx={{ color: 'grey.400', transform: 'rotate(180deg)', height: 15, width: 15, mt: '2px', mr: 1 }}
                            fontSize="small"
                        />

                        <Typography variant="subtitle1" color="textSecondary" fontWeight="600">
                            {translatedWords.back}
                        </Typography>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                </>
            )}
            <Typography variant="h5" color="primary.main" textAlign="center" sx={{ mt: 2 }}>
                {translatedWords.contact_sales2}
            </Typography>
            <Box>
                <Box sx={{ mt: 5, display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                    <img src="/static/images/payment/check-icon.png" alt="check" />
                    <Typography textAlign="center" variant="body1" fontWeight="bold" sx={{ mt: 2 }}>
                        {translatedWords.contact_success}
                    </Typography>
                    <Typography textAlign="center" color="textSecondary" variant="body2" sx={{ mt: 2 }}>
                        {translatedWords.contact_later}
                    </Typography>
                    <RouterLink
                        to={`/workspace/${workspace?.activated?.id}`}
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                            if (onClose) onClose();
                        }}
                    >
                        <Button color="primary" variant="contained" sx={{ mt: 5 }}>
                            {translatedWords.homepage}
                        </Button>
                    </RouterLink>
                </Box>
            </Box>

            {onClose && (
                <Box sx={{ my: 5 }} textAlign="right">
                    <Button color="primary" variant="contained" onClick={onClose}>
                        {translatedWords.close_button}
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default ContactSalesResult;

