import axiosClient from 'lib/paymentAxios';

const api = {
    getAllQuotaPackages: () => {
        return axiosClient.get('/quota-package');
    },
    getPaymentHistory: () => {
        return axiosClient.get('/invoice/my');
    },
    getUserTransaction: () => {
        return axiosClient.get(`/payment/my`);
    },
    getPaymentLink: (quotaPackageId: string) => {
        return axiosClient.post('/payment/pay-link', { quotaPackageId });
    },
    payMore: () => {
        return axiosClient.post('/payment/pay');
    },
    toggleRenewStatus: (state: boolean) => {
        return axiosClient.patch('/payment/auto-renew', { autoRenew: state });
    },
    changeQuotaPackage: (packageId: string) => {
        return axiosClient.patch(`/payment/quota-package`, {
            newQuotaPackageId: packageId,
        });
    },
    registerPremium: (request: any) => {
        return axiosClient.post(`/payment/register-premium`, request);
    },
};

export default api;
