import axiosClient from 'lib/axios';
import { AuthenticationKey } from 'types/user';

const prefixUrl = 'recruiter-key';

const api = {
    getAuthenticationKeys: (): Promise<AuthenticationKey[]> => {
        return axiosClient.get(`/${prefixUrl}`);
    },
    createAuthenticationKey: (): Promise<AuthenticationKey> => {
        return axiosClient.post(`/${prefixUrl}`);
    },
    deleteAuthenticationKey: (key: string) => {
        return axiosClient.delete(`/${prefixUrl}/${key}`);
    },
};

export default api;
