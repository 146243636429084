import React, { FC, useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Grid, GridSize } from '@mui/material';
import { useSelector, useDispatch } from 'store';
import { detectCurrentCountry, getListRegionTz } from '../../ConvertingTimeFunc';
import { workspaceSelector } from 'hooks/useSelector';
import { updateWorkspace } from 'features/Workspace/slice';
import DisplayCountryTz from '../DisplayCountryTz';
import toast from 'react-hot-toast';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { Trans } from 'react-i18next';
import { updateIsPauseTourUi } from 'features/TourUI/slice';
import { WorkspaceType } from 'types/workspace';

interface TimeDefaultDialogProps {
    otherWorkSpaceTz?: WorkspaceType;
    openDefaultDialogTz?: boolean;
    onCloseDialog?: () => void;
    setLayOut: GridSize;
    setSize?: 'small' | 'medium';
}

const TimeDefaultDialog: FC<TimeDefaultDialogProps> = (Props) => {
    const { openDefaultDialogTz, onCloseDialog, setLayOut, setSize, otherWorkSpaceTz } = Props;
    const dispatch = useDispatch();

    const [country, setCountry] = useState('');
    //keep track state country region
    const [countryRegion, setCountryRegion] = useState('');
    // state array of timezone for specific country
    const [listTimeZone, setListTimeZone] = useState([]);

    const workspace = useSelector(workspaceSelector);

    useEffect(() => {
        if (workspace.activated || otherWorkSpaceTz?.id) {
            reset();
        }
    }, [workspace.activated, otherWorkSpaceTz?.id]);

    useEffect(() => {
        if (!country) {
            currentCountry();
        }
    }, [country]);

    async function currentCountry() {
        try {
            let data: any;
            if (otherWorkSpaceTz) {
                data = await detectCurrentCountry(otherWorkSpaceTz?.defaultCountry, otherWorkSpaceTz?.defaultRegionTimezone);
            } else {
                data = await detectCurrentCountry(workspace.activated?.defaultCountry, workspace.activated?.defaultRegionTimezone);
            }

            const countryCode = data?.countryCode;
            if (countryCode) {
                const region = data?.region;

                setCountry(countryCode);

                const newCities: any = getListRegionTz(countryCode);

                if (Array.isArray(newCities) && newCities?.length > 0) {
                    setListTimeZone(newCities);
                    setCountryRegion(region);

                    localStorage.setItem('countryCode', JSON.stringify(countryCode));
                    localStorage.setItem('region', JSON.stringify(region));
                }
            } else {
                throw new Error('Country code not found');
            }
        } catch (error) {
            console.error(error);
            toast.error(error);
        }
    }

    const handleCountry = (e: any) => {
        e.preventDefault();

        setCountry(e.target.value);

        const newCities: any = getListRegionTz(e.target.value);

        if (Array.isArray(newCities) && newCities?.length > 0) {
            setListTimeZone(newCities);
            setCountryRegion(newCities[0]?.city_time_zone);
        }

        localStorage.setItem('countryCode', JSON.stringify(e.target.value));
        localStorage.setItem('region', JSON.stringify(newCities[0]?.city_time_zone));
    };

    const handleCountryRegion = (e: any) => {
        e.preventDefault();
        setCountryRegion(e.target.value);

        localStorage.setItem('region', JSON.stringify(e.target.value));
    };

    const handleOnSave = (e: any) => {
        try {
            e.preventDefault();

            if (!country && !countryRegion) {
                toast.error('Please set your default timezone to save');
                return;
            }
            //updatedWorkspace

            const request = {
                defaultCountry: country,
                defaultRegionTimezone: countryRegion,
                name: workspace?.activated?.name,
                company: workspace?.activated?.company,
            };

            dispatch(updateWorkspace(workspace?.activated?.id, request));

            handleClose();
        } catch (error) {
            toast.error(error);
        }
    };

    //close dialog
    const handleClose = () => {
        reset();
        dispatch(updateIsPauseTourUi(false));
        onCloseDialog?.();
    };

    //reset
    const reset = () => {
        setCountry('');
        setCountryRegion('');
        setListTimeZone([]);
    };

    const translatedWords = useApplyTranslation();

    return (
        <>
            {setSize ? (
                <>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Grid item xs={12} container justifyContent="space-between" spacing={3}>
                            <DisplayCountryTz
                                setLayOut={setLayOut}
                                valueCountry={country}
                                handleCountry={handleCountry}
                                valueTz={countryRegion}
                                handleTz={handleCountryRegion}
                                listTz={listTimeZone}
                                setSize={setSize}
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Dialog fullWidth maxWidth="md" open={openDefaultDialogTz}>
                    <DialogContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="h5">{translatedWords.set_default_timezone}</Typography>
                            </Grid>

                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="body2">
                                    <Trans
                                        i18nKey="choose_default_timezone"
                                        components={{ bold: <strong /> }}
                                        defaults="Since interview is <bold>time-sensitive</bold> please choose your
                                        correct default timezone"
                                    />
                                </Typography>
                            </Grid>

                            <DisplayCountryTz
                                setLayOut={setLayOut}
                                valueCountry={country}
                                handleCountry={handleCountry}
                                valueTz={countryRegion}
                                handleTz={handleCountryRegion}
                                listTz={listTimeZone}
                            />

                            <Grid item xs={12} container justifyContent="center">
                                <Typography variant="caption">
                                    <Trans
                                        i18nKey="change_default_timezone"
                                        components={{ bold: <strong /> }}
                                        defaults="You can change this in <bold>Settings > Workspace</bold>"
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            sx={{ mr: 1, backgroundColor: 'secondary.main', '&:hover': { backgroundColor: 'secondary.dark' } }}
                            onClick={handleClose}
                        >
                            {translatedWords.do_later}
                        </Button>
                        <Button
                            sx={{ backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' } }}
                            variant="contained"
                            onClick={handleOnSave}
                        >
                            {translatedWords.save}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default TimeDefaultDialog;

