import React, { FC, useRef, useState } from 'react';
import { Avatar, Box, Button, ButtonBase, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { authSelector } from 'hooks/useSelector';
import ChevronDown from 'assets/icons/ChevronDown';
import ChevronUp from 'assets/icons/ChevronUp';
import authSlice from 'features/Authentication/slice';
import settingsSlice from 'layout/slice';

const AccountPopover: FC = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const auth = useSelector(authSelector);
    const translatedWords = useApplyTranslation();

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    /**
     * Handle Open Settings
     */
    const handleOpenSetting = () => {
        dispatch(settingsSlice.actions.toggleSettings(true));
    };

    /**
     * Handle Logout
     */
    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            dispatch(authSlice.actions.logout());
        } catch (err) {
            console.error(err);
            toast.error('Unable to logout.');
        }
    };

    return (
        <>
            <Box component={ButtonBase} onClick={handleOpen} ref={anchorRef} sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
                <Avatar src={auth.user.avatar} sx={{ height: 32, width: 32 }} />
                <Typography color="textPrimary" variant="subtitle2">
                    {auth.user.firstName} {auth.user.lastName}
                </Typography>
                {open ? <ChevronUp color="action" /> : <ChevronDown color="action" />}
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: { width: 240 },
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography color="textPrimary" variant="subtitle2">
                        {auth.user.firstName} {auth.user.lastName}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2">
                        {auth.user.email}
                    </Typography>
                </Box>
                <Divider />
                {/* <Box sx={{ mt: 2 }}>
                    <MenuItem onClick={handleOpenSetting}>
                        <ListItemIcon>
                            <EmojiObjectsIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography color="textPrimary" variant="subtitle2">
                                    Display mode
                                </Typography>
                            }
                        />
                    </MenuItem>
                </Box> */}
                <Box sx={{ p: 2 }}>
                    <Button color="error" fullWidth onClick={handleLogout} variant="outlined">
                        {translatedWords.log_out}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default AccountPopover;

