import React, { FC } from 'react';
import { AppBar, AppBarProps, Box, Divider, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'store';
import { workspaceSelector } from 'hooks/useSelector';
import MenuIcon from 'assets/icons/Menu';
import { NotificationProvider } from 'context/notification';
import NotificationsPopover from 'features/Notification/components/NotificationsPopover';
import AccountPopover from './components/AccountPopover';
import LanguagePopover from './components/LanguagePopover';

interface DashboardNavbarProps extends AppBarProps {
    onSidebarMobileOpen?: () => void;
}

/**
 * Set theme's style
 */
const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
    ...(theme.palette.mode === 'light' && {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.contrastText,
    }),
    ...(theme.palette.mode === 'dark' && {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
        boxShadow: 'none',
    }),
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.up('lg')]: {
        width: 'calc(100% - 280px)',
    },
}));

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
    const { onSidebarMobileOpen, ...other } = props;
    const workspace = useSelector(workspaceSelector);

    return (
        <DashboardNavbarRoot {...other}>
            <Toolbar sx={{ minHeight: 64 }}>
                <IconButton onClick={onSidebarMobileOpen} sx={{ display: { lg: 'none' } }}>
                    <MenuIcon />
                </IconButton>

                {/* <RouterLink to={`/workspace/${workspace?.activated?.id}`}>
                    <Box
                        sx={{
                            display: { lg: 'inline-block', xs: 'none' },
                            height: 40,
                            mt: '3px',
                            '& > img': { height: '100%', width: 'auto' },
                        }}
                    >
                        <img alt="Application Logo" src="/static/blue-full.svg" />
                    </Box>
                </RouterLink> */}

                <Box sx={{ flexGrow: 1 }} />

                <LanguagePopover />

                {/* <Box sx={{ ml: 1 }}>
                    <ContentSearch />
                </Box> */}

                <Box>
                    <NotificationProvider>
                        <NotificationsPopover />
                    </NotificationProvider>
                </Box>

                <Divider orientation="vertical" sx={{ m: 2 }} flexItem />

                <Box>
                    <AccountPopover />
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
    );
};

DashboardNavbar.propTypes = {
    onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;

