import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { Position } from 'types/position';
import api from './api';

interface PositionState {
    positions: Position[];
    isLoading: boolean;
    error: boolean;
}

const initialState: PositionState = {
    positions: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'position',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Question */
        list: (state, action) => {
            state.positions = action.payload;
            state.isLoading = false;
        },

        /* Create Question */
        create: (state, action) => {
            state.positions.unshift(action.payload);
            state.isLoading = false;
        },

        /* Update Question */
        update: (state, action) => {
            const position = action.payload;
            const index = state.positions.findIndex((x) => x.id === position.id);

            if (index > -1) {
                state.positions[index] = position;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove Question */
        remove: (state, action) => {
            const index = state.positions.findIndex((x) => x.id === action.payload);
            if (index > -1) {
                state.positions.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List All Positions
 * @param workspaceId
 * @returns
 */
export const listPositions =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response = await api.getAll(workspaceId);
            dispatch(slice.actions.list(response));
        } catch (error) {
            console.log(error.message);
        }
    };

/**
 * Create Position
 * @param workspaceId
 * @param request
 * @returns
 */
export const createPosition =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<Position> => {
        const response: any = await api.create(workspaceId, request);
        dispatch(slice.actions.create(response));
        return response;
    };

/**
 * Update Position
 * @param id
 * @param request
 * @returns
 */
export const updatePosition =
    (workspaceId: string, positionId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(workspaceId, positionId, request);
        dispatch(slice.actions.update(response));
        return response;
    };

export const deletePosition =
    (workspaceId: string, positionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.delete(workspaceId, positionId);
        dispatch(slice.actions.remove(positionId));
    };
export const { reducer } = slice;
export default slice;
