import { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress } from '@mui/material';

const LoadingScreen: FC = () => {
    useEffect(() => {
        NProgress.start();

        return (): void => {
            NProgress.done();
        };
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                minHeight: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingScreen;

