import { createContext } from 'react';
import { Pagination } from 'types/general';
import { Notification, NotificationQuery } from 'types/notification';

export interface NotificationContextValue {
    loading: boolean;
    page: number;
    notifications: Notification[];
    pagination: Pagination;
    getNotifications: (workspaceId: string, params?: NotificationQuery, isLoadMore?: boolean) => Promise<void>;
    fetchMoreNotification: (workspaceId: string, params?: NotificationQuery) => Promise<void>;
    markNotificationRead: (workspaceId: string, notificationId: string) => Promise<void>;
    markAllRead: (workspaceId: string) => Promise<void>;
}

const Context = createContext<NotificationContextValue>({
    loading: false,
    page: 1,
    pagination: { page: 1, limit: 10, totalCount: 0, totalPages: 0 },
    notifications: [],
    getNotifications: () => Promise.resolve(),
    fetchMoreNotification: () => Promise.resolve(),
    markNotificationRead: () => Promise.resolve(),
    markAllRead: () => Promise.resolve(),
});

export default Context;
