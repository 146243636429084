import React, { FC } from 'react';
import { List, ListClasses, ListSubheader, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { matchPath } from 'react-router-dom';

import useApplyTranslation from 'hooks/useApplyTranslation';
import { Item } from 'menu';
import NavItem from './NavItem';

interface NavSectionProps {
    items: Item[];
    pathname: string;
    title: {
        constant: string;
        defaultValue: string;
    };
    children?: React.ReactNode;
    classes?: Partial<ListClasses>;
    sx?: SxProps<Theme>;
}

interface NavItemToRender {
    items: Item[];
    pathname: string;
    depth?: number;
}

interface ChildRoute {
    acc: JSX.Element[];
    pathname: string;
    item: Item;
    depth: number;
}

const renderNavItems = ({ depth = 0, items, pathname }: NavItemToRender): JSX.Element => (
    <List disablePadding>
        {items.reduce(
            (acc, item) =>
                reduceChildRoutes({
                    acc,
                    item,
                    pathname,
                    depth,
                }),
            []
        )}
    </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }: ChildRoute): Array<JSX.Element> => {
    const key = `${item.title.constant}-${depth}`;

    if (item.children) {
        const partialMatch = item.path ? !!matchPath({ path: item.path, end: false }, pathname) : false;

        acc.push(
            <NavItem active={partialMatch} depth={depth} key={key} open={partialMatch} item={item}>
                {renderNavItems({
                    depth: depth + 1,
                    items: item.children,
                    pathname,
                })}
            </NavItem>
        );
    } else {
        const exactMatch = item.path ? !!matchPath({ path: item.path, end: !item.noEnd }, pathname) : false;
        acc.push(<NavItem active={exactMatch} depth={depth} key={key} item={item} />);
    }

    return acc;
};

const NavSection: FC<NavSectionProps> = ({ items, pathname, title, ...other }) => {
    const translatedWords = useApplyTranslation();

    return (
        <List
            subheader={
                <ListSubheader disableGutters disableSticky>
                    {translatedWords[title.constant]}
                </ListSubheader>
            }
            {...other}
        >
            {renderNavItems({
                items,
                pathname,
            })}
        </List>
    );
};

export default NavSection;

