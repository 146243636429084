import { Box, Button, Grid, LinearProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { workspaceSelector } from 'hooks/useSelector';
import React from 'react';
import { useSelector } from 'store';
import * as Yup from 'yup';

interface IContactSalesFormProps {
    onNext: () => void;
    onChangeContactRequest: (values: any) => void;
}

const validate = Yup.object().shape({
    request: Yup.string().required().max(500),
    companyName: Yup.string().required(),
    companySize: Yup.string().required(),
    jobTitle: Yup.string().required(),
});

const ContactSalesForm: React.FunctionComponent<IContactSalesFormProps> = (props) => {
    const { onNext, onChangeContactRequest } = props;

    const workspace = useSelector(workspaceSelector);

    const initialValues = {
        request: '',
        companyName: workspace.activated?.company || '',
        companySize: '',
        jobTitle: '',
    };

    const handleSubmit = async (values, { setErrors }) => {
        onChangeContactRequest(values);
        onNext();
    };

    const companySizes = [
        { label: '1-10', value: '1-10' },
        { label: '11-50', value: '11-50' },
        { label: '51-100', value: '51-100' },
        { label: '101-200', value: '101-200' },
        { label: '200+', value: '200' },
    ];

    return (
        <Formik initialValues={initialValues} validationSchema={validate} onSubmit={handleSubmit}>
            {({ values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue, setErrors }) => {
                const size = values.companySize;

                return (
                    <Form>
                        <Grid container>
                            <Grid item xs={8} container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        How can we help you?*
                                    </Typography>
                                    <TextField
                                        name="request"
                                        value={values.request}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            handleBlur(e);
                                            setFieldValue(e.target.name, e.target.value?.trim());
                                        }}
                                        error={Boolean(touched.request && errors.request)}
                                        helperText={touched.request && errors.request}
                                        label=""
                                        size="small"
                                        placeholder="Please enter details of your request"
                                        multiline
                                        rows={4}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Company Name*
                                    </Typography>
                                    <TextField
                                        name="companyName"
                                        value={values.companyName}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            handleBlur(e);
                                            setFieldValue(e.target.name, e.target.value?.trim());
                                        }}
                                        error={Boolean(touched.companyName && errors.companyName)}
                                        helperText={touched.companyName && errors.companyName}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item lg={6} xs={12}>
                                    <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Company size*
                                    </Typography>

                                    <Select
                                        value={values.companySize}
                                        error={Boolean(touched.companySize && errors.companySize)}
                                        onBlur={(e) => {
                                            handleBlur(e);
                                            setFieldValue(e.target.name, e.target.value?.trim());
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        size="medium"
                                        name="companySize"
                                        variant="outlined"
                                        sx={{ mb: 3 }}
                                    >
                                        {companySizes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <OtherOptions
                                            onChange={(value) => {
                                                setCompanySizes((prev) => [...prev, { label: value, value }]);
                                                setFieldValue('companySize', value);
                                            }}
                                        /> */}
                                    </Select>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                        Job title*
                                    </Typography>
                                    <TextField
                                        value={values.jobTitle}
                                        error={Boolean(touched.jobTitle && errors.jobTitle)}
                                        onBlur={(e) => {
                                            handleBlur(e);
                                            setFieldValue(e.target.name, e.target.value?.trim());
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        size="medium"
                                        name="jobTitle"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
                                    <img
                                        src="/static/images/payment/ContactSale.png"
                                        style={{ width: 'min(250px, 80%)', objectFit: 'contain', margin: 'auto' }}
                                        alt=""
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ my: 4 }}>
                            <Box sx={{ my: 2, mx: 3, pb: 1 }} display={isSubmitting ? '' : 'none'}>
                                <LinearProgress />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', textAlign: 'right' }}>
                                <Button type="submit" variant="contained">
                                    Next
                                </Button>
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ContactSalesForm;

