import { RootState } from 'store';

export const settingsSelector = (state: RootState) => state.settings;
export const authSelector = (state: RootState) => state.auth;
export const workspaceSelector = (state: RootState) => state.workspace;
export const questionSelector = (state: RootState) => state.question;
export const questionIdsPackSelector = (state: RootState) => state.questionIdsPack;
export const positionSelector = (state: RootState) => state.position;
export const emailSelector = (state: RootState) => state.email;
export const candidateSelector = (state: RootState) => state.candidate;
export const interviewSelector = (state: RootState) => state.interview;
export const questionShopSelector = (state: RootState) => state.shopQuestion;
export const questionPackSelector = (state: RootState) => state.questionPack;
export const emailShopSelector = (state: RootState) => state.emailShop;
export const memberSelector = (state: RootState) => state.member;
export const draftFlowSelector = (state: RootState) => state.draftFlow;
export const flowTemplateSelector = (state: RootState) => state.flowTemplate;
export const RunningFlowsSelector = (state: RootState) => state.runningFlow;
export const candidateAnalyticsSelector = (state: RootState) => state.candidateAnalytics;
export const paymentSelector = (state: RootState) => state.payment;
export const meetingResultSelector = (state: RootState) => state.meetingResult;
export const directInterviewSelector = (state: RootState) => state.directInterview;
