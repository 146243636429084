import i18next from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { TRANSLATIONS } from 'utils/languageConstant';
import { settingsSelector } from './useSelector';

const useApplyTranslation = (): Record<string, string> => {
    const { t } = useTranslation();
    const { initialLanguageResource } = useSelector(settingsSelector);
    const handleGetTranslatedWords = useCallback(
        () =>
            [...TRANSLATIONS, ...initialLanguageResource].reduce((acc, curr) => {
                acc[curr.key] = t(curr.key, curr.defaultValue);
                return acc;
            }, {}),
        [t, initialLanguageResource]
    );

    const [translatedWords, setTranslatedWords] = useState(handleGetTranslatedWords);

    useEffect(() => {
        setTranslatedWords(handleGetTranslatedWords());
    }, [handleGetTranslatedWords]);

    useEffect(() => {
        const handleLanguageResourceAdd = (lng) => {
            console.log(lng, i18next.language, translatedWords);
            // Regenerate translated words when the resource of current language is add
            if (lng === i18next.language) setTranslatedWords(handleGetTranslatedWords);
        };

        i18next.store.on('added', handleLanguageResourceAdd);

        return () => {
            i18next.store.off('added', handleLanguageResourceAdd);
        };
    }, [handleGetTranslatedWords]);

    return translatedWords;
};

export default useApplyTranslation;
