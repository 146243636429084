import { Box, Breadcrumbs, Container, Divider, Grid, Link, Typography } from '@mui/material';
import ChevronRightIcon from 'assets/icons/ChevronRight';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { settingsSelector, workspaceSelector } from 'hooks/useSelector';
import gtm from 'lib/gtm';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'store';
import ListDraftFlow from './component/ListDraftFlow';

const DraftFlow: FC<any> = (props) => {
    const settings = useSelector(settingsSelector);
    const workspace = useSelector(workspaceSelector);

    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    const translatedWords = useApplyTranslation();

    return (
        <>
            <Helmet>
                <title>Advanced Automation Flow | RecruitGenius</title>
            </Helmet>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 2 }}>
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid container justifyContent="space-between" spacing={3} mb={2}>
                        <Grid item>
                            <Typography color="textPrimary" variant="h5">
                                Advanced Automation Flow
                            </Typography>
                            <Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={`/workspace/${workspace?.activated?.id}`}
                                    variant="subtitle2"
                                >
                                    {translatedWords.af_menu}
                                </Link>
                                <Typography color="textSecondary" variant="subtitle2">
                                    {translatedWords.af_draftflows}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>

                    <Divider />
                    <Box sx={{ mt: 3, ml: 1 }}>
                        <Typography variant="button">{translatedWords.draft_alert1}</Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <ListDraftFlow />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default DraftFlow;

