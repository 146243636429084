import { Box, Button, CircularProgress, Divider, Link, Typography } from '@mui/material';
import HeadArrow from 'assets/icons/HeadArrow';
import React from 'react';
import { PaymentResponse } from 'types/payment';
import { Link as RouterLink } from 'react-router-dom';
import { workspaceSelector } from 'hooks/useSelector';
import { useSelector } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';

interface IPayScreenProps {
    isLoadingPopup: boolean;
    paymentResponse: PaymentResponse;
    onClose?: () => void;
    onBack: () => void;
    onChangePaymentResponse: (paymentResponse: PaymentResponse) => void;
}

const PayScreen: React.FunctionComponent<IPayScreenProps> = (props) => {
    const { isLoadingPopup, paymentResponse, onBack, onClose, onChangePaymentResponse } = props;
    const workspace = useSelector(workspaceSelector);
    const translatedWords = useApplyTranslation();

    return (
        <Box>
            <Box
                sx={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', cursor: 'pointer' }}
                onClick={() => {
                    if (isLoadingPopup) return;
                    onBack();
                    onChangePaymentResponse(null);
                }}
            >
                <HeadArrow
                    sx={{ color: 'grey.400', transform: 'rotate(180deg)', height: 15, width: 15, mt: '2px', mr: 1 }}
                    fontSize="small"
                />

                <Typography variant="subtitle1" color="textSecondary" fontWeight="600">
                    {translatedWords.back}
                </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: isLoadingPopup ? 'flex' : 'none', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
                <CircularProgress />
            </Box>
            <Box sx={{ display: isLoadingPopup ? 'none' : '', pb: 20 }}>
                {paymentResponse ? (
                    <Box sx={{ mt: 10, display: 'flex', flexFlow: 'column nowrap', alignItems: 'center' }}>
                        <img src="/static/images/payment/check-icon.png" alt="check" />
                        <Typography textAlign="center" variant="body1" fontWeight="bold" sx={{ mt: 2 }}>
                            {translatedWords.order_success}
                        </Typography>
                        <Typography textAlign="center" color="textSecondary" variant="body2" sx={{ mt: 2 }}>
                            {translatedWords.order_success2}
                        </Typography>
                        <RouterLink
                            to={`/workspace/${workspace?.activated?.id}`}
                            style={{ textDecoration: 'none' }}
                            onClick={() => {
                                if (onClose) onClose();
                            }}
                        >
                            <Button color="primary" variant="contained" sx={{ mt: 5 }}>
                                {translatedWords.homepage}
                            </Button>
                        </RouterLink>
                        {paymentResponse.receiptUrl && (
                            <Link
                                href={paymentResponse.receiptUrl}
                                target="_blank"
                                rel="noopener"
                                sx={{
                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                }}
                            >
                                <Box sx={{ color: 'warning.main' }}>
                                    <Button
                                        color="inherit"
                                        variant="text"
                                        sx={{
                                            mt: 2,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        {translatedWords.transaction_receipt}
                                    </Button>
                                </Box>
                            </Link>
                        )}
                    </Box>
                ) : (
                    <div className="checkout-container"></div>
                )}
            </Box>
        </Box>
    );
};

export default PayScreen;

