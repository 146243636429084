import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { WorkspaceType } from 'types/workspace';
import { configAppInit } from 'utils/config';
import api from './api';

interface WorkspaceState {
    workspaces: WorkspaceType[];
    activated: WorkspaceType;
    policy: any;
}

const initialState: WorkspaceState = {
    workspaces: [],
    activated: null,
    policy: null,
};

const slice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        gets: (state, action) => {
            state.workspaces = action.payload.workspaces;
            state.activated = action.payload.activated;
        },

        /* Create Workspace */
        create: (state, action) => {
            state.workspaces.unshift(action.payload);
            state.activated = action.payload;
        },

        /* Update Workspace */
        update: (state, action) => {
            const item = action.payload;
            const index = state.workspaces.findIndex((x) => x.id === item.id);

            if (index >= 0) {
                state.workspaces[index] = item;

                if (state.activated.id === item.id) {
                    state.activated = item;
                }
            }
        },

        /* Remove Workspace */
        remove: (state, action) => {},

        /* Active Workspace */
        active: (state, action) => {
            state.activated = action.payload;
        },
        /* Set active Policy of a Workspace */
        setPolicy: (state, action) => {
            state.policy = action.payload;
        },
    },
});

/**
 * Create Workspace
 * @param workspaceName
 * @returns
 */
export const createWorkspace =
    (request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.create(request);

        dispatch(slice.actions.create(response));
        configAppInit.set({ workspace: response });

        // eslint-disable-next-line no-restricted-globals
        // window.location.href = `/workspace/${response?.id}/`;
        return response?.id;
    };

export const updateWorkspace =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(workspaceId, request);
        dispatch(slice.actions.update(response));
    };
export const getPolicy =
    (workspaceId: string, recruiterId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response: any = await api.getCurrentPolicy(workspaceId, recruiterId);

            if (response) {
                dispatch(slice.actions.setPolicy(response));
            }
            return response;
        } catch (error) {
            console.log(error);
        }
    };

export const { reducer } = slice;
export default slice;
