import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Backdrop, Box, Button, ButtonGroup, Card, CardContent, CircularProgress, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FullCalendar from '@fullcalendar/react';
import { sortBy, uniq } from 'lodash';
import moment from 'moment';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';

import { useDispatch, useSelector } from 'store';
import { workspaceSelector } from 'hooks/useSelector';
import { useGetPollingBaseOnTime } from 'hooks/scheduleInterview/useGetPollingBaseOnTime';
import useApplyTranslation from 'hooks/useApplyTranslation';
import LockedInterviewCalendar from 'features/ScheduleInterview/components/flows/LockedInterview/LockedInterviewCalendar';
import { getInterviews } from 'features/Interview/slice';
import { PollingEvent, PollingEventColor, PollingProgress } from 'types/interviewSchedule';

const Interviews = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translatedWords = useApplyTranslation();
    const workspace = useSelector(workspaceSelector);
    const { schedulePollings, loading, actions } = useGetPollingBaseOnTime(null);

    const [date, setDate] = useState<Date>(new Date());
    const calendarRef = useRef<FullCalendar | null>(null);

    const [filterPositions, setFilterPositions] = useState<string[]>(() => {
        const positions = schedulePollings.map((polling) => polling.position.name);
        return uniq(positions);
    });

    const handleGetPollingsBaseOnView = useCallback((workspaceId: string) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            // Save the view state
            const currentView = calendarApi.view;
            actions.getPollings(workspaceId, [
                {
                    progress: PollingProgress.LOCKED,
                    rangeFrom: moment(currentView.activeStart).unix(),
                    rangeTo: moment(currentView.activeEnd).unix(),
                },
                {
                    progress: PollingProgress.CALL_SETUP,
                    rangeFrom: moment(currentView.activeStart).unix(),
                    rangeTo: moment(currentView.activeEnd).unix(),
                },
            ]);
        }
    }, []);

    const events: PollingEvent[] = useMemo(() => {
        const newEvents = schedulePollings
            .filter((polling) => filterPositions.includes(polling.position.name))
            ?.map((polling) => {
                const lockedSlot = polling.slotList.find((slot) => slot.id === polling.lockedSlot);
                const isExpired = moment().isAfter(lockedSlot.from * 1000);
                return {
                    start: lockedSlot.from * 1000,
                    end: lockedSlot.to * 1000,
                    color: isExpired
                        ? PollingEventColor.EXPIRED
                        : polling.extended
                        ? PollingEventColor.PRIMARY
                        : PollingEventColor.UNSELECTED,
                    id: polling.id,
                    extendedProps: {
                        ...polling,
                        ...lockedSlot,
                    },
                };
            });

        return sortBy(newEvents, (event) => event.start);
    }, [schedulePollings, filterPositions]);

    const handleClickEvent = () => {
        navigate('schedule?tabValue=5');
    };

    const handleDateToday = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    useEffect(() => {
        if (workspace.activated) {
            async function fetchInterviews() {
                await dispatch(getInterviews(workspace.activated.id, 1, 5));
            }
            fetchInterviews();
        }
    }, [dispatch, workspace.activated]);

    useEffect(() => {
        setFilterPositions(() => {
            const positions = schedulePollings.map((polling) => polling.position.name);
            return uniq(positions);
        });
    }, [schedulePollings]);

    useEffect(() => {
        if (workspace.activated?.id) {
            handleGetPollingsBaseOnView(workspace.activated?.id);
        }
    }, [handleGetPollingsBaseOnView, workspace.activated?.id]);

    return (
        <Card className="interviews" sx={{ height: '100%' }}>
            <CardContent>
                <Stack direction="row" justifyContent="space-between" sx={{ mb: 2, gap: 1 }}>
                    <Typography color="textPrimary" variant="h6">
                        {format(date, 'MMMM y')}
                    </Typography>
                    <ButtonGroup size="small">
                        <Button onClick={handleDatePrev}>
                            <ChevronLeftIcon />
                        </Button>
                        <Button onClick={handleDateToday}>{translatedWords.today}</Button>
                        <Button onClick={handleDateNext}>
                            <ChevronRightIcon />
                        </Button>
                    </ButtonGroup>
                </Stack>

                <Box sx={{ position: 'relative' }}>
                    <LockedInterviewCalendar
                        events={events}
                        view="dayGridMonth"
                        date={new Date()}
                        ref={calendarRef}
                        handleClickEvent={handleClickEvent}
                    />

                    <Backdrop
                        open={loading}
                        sx={{
                            position: 'absolute',
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Interviews;

