import i18next from 'i18next';
import api from 'layout/api';
import { getLanguageConstants } from 'layout/slice';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'store';
import { configLanguage } from 'utils/config';
import { DEFAULT_LANGUAGE } from 'utils/constants';
import useGetListLanguage from './useGetListLanguage';
import useMounted from './useMounted';
import i18n from 'i18n';

const useInitLanguage = () => {
    const mounted = useMounted();
    const dispatch = useDispatch();

    const [isLoaded, setIsLoaded] = useState(false);
    const languages = useGetListLanguage();

    const addLanguageResource = async (languageCode: string) => {
        try {
            const lngResource = await api.getLanguageResource(languageCode);

            i18next.addResourceBundle(languageCode, 'translations', lngResource, true, true);
        } catch (error) {
            console.log('The following error occurred:', error);
        }
    };

    const initLanguage = useRef(configLanguage.get() || DEFAULT_LANGUAGE);
    useEffect(() => {
        const getInitLanguage = async () => {
            try {
                try {
                    const response = await fetch('https://get.geojs.io/v1/ip/geo.json');
                    /*
            {
                "country": "Vietnam",
                "timezone": "Asia/Ho_Chi_Minh",
                "ip": "116.102.32.199",
                "organization": "AS7552 Viettel Group",
                "asn": 7552,
                "area_code": "0",
                "organization_name": "Viettel Group",
                "country_code": "VN",
                "country_code3": "VNM",
                "continent_code": "AS",
                "latitude": "10.822",
                "region": "Ho Chi Minh",
                "city": "Ho Chi Minh City",
                "longitude": "106.6257",
                "accuracy": 5
                }
             */
                    const data = await response.json();
                    if (data.country_code === 'VN') {
                        localStorage.setItem('countryCode', JSON.stringify('VN'));
                        initLanguage.current = 'vi';
                        i18n.changeLanguage('vi');
                    }
                } catch (e) {
                    console.error(e);
                }
                await addLanguageResource(initLanguage.current);
                if (mounted.current) {
                    setIsLoaded(true);
                }
            } catch (error) {
                setIsLoaded(true);
                console.log('The following error occurred:', error);
            }
        };

        getInitLanguage();
    }, [mounted]);

    useEffect(() => {
        const getInitialLanguageConstants = async () => {
            try {
                await dispatch(getLanguageConstants());
            } catch (error) {
                console.log('The following error occurred:', error);
            }
        };

        getInitialLanguageConstants();
    }, [dispatch]);

    useEffect(() => {
        const getAllLanguageResources = async () => {
            try {
                try {
                    const response = await fetch('https://get.geojs.io/v1/ip/geo.json');
                    const data = await response.json();
                    if (data.country_code === 'VN') {
                        if (initLanguage.current !== 'vi') {
                            localStorage.setItem('countryCode', JSON.stringify('VN'));
                            initLanguage.current = 'vi';
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
                const languageCodes = languages
                    .map((language) => language.code)
                    .filter((languageCode) => languageCode !== initLanguage.current);
                const promiseArr = [];
                languageCodes.forEach((languageCode) => {
                    const lngPromise = addLanguageResource(languageCode);
                    promiseArr.push(lngPromise);
                });

                const lngResources: any[] = await Promise.all(promiseArr);
                lngResources.forEach((lngResource, index) => {
                    if (lngResource) i18next.addResourceBundle(languageCodes[index], 'translations', lngResource, true, true);
                });
            } catch (error) {
                console.log('The following error occurred:', error);
            }
        };

        getAllLanguageResources();
    }, [languages]);

    return { isLoaded };
};

export default useInitLanguage;
