export const TRANSLATIONS: { key: string; defaultValue: string }[] = [
    {
        key: '3_packages',
        defaultValue: 'for 3 standard packages',
    },
    {
        key: 'AF_day',
        defaultValue: 'days',
    },
    {
        key: 'AF_direct_interview_description',
        defaultValue:
            'The interview will be set up automatically and sent to the locked interview. You will need to go back here or the Direct Interview" menu to set up an offline interview location or a video call link for an online interview.',
    },
    {
        key: 'AF_interview_due_days',
        defaultValue: 'Interview due after',
    },
    {
        key: 'AI_comment_description',
        defaultValue: "ChatGPT's comment on this answer",
    },
    {
        key: 'AI_create_qp',
        defaultValue: 'Use ChatGPT AI to create a question pack for this position',
    },
    {
        key: 'AI_generated_questions',
        defaultValue: 'AI-generated questions',
    },
    {
        key: 'Adding_Question_Position',
        defaultValue: 'Adding interview questions for this position',
    },
    {
        key: 'Af_DI_canInterview',
        defaultValue: 'Set up for candidate side',
    },
    {
        key: 'Af_DI_panInterview',
        defaultValue: "Set up for panelists' side",
    },
    {
        key: 'Blue_update_lg',
        defaultValue: 'Not updated yet',
    },
    {
        key: 'Broken Detail',
        defaultValue: 'Broken Detail',
    },
    {
        key: 'CV_collect',
        defaultValue: 'Application Collection',
    },
    {
        key: 'CVquota_left',
        defaultValue: 'CV Parse',
    },
    {
        key: 'Content',
        defaultValue: 'Content',
    },
    {
        key: 'DI_AutoInviteCan',
        defaultValue: 'Automatically invite candidates if all panelists confirmed',
    },
    {
        key: 'DI_EndDate',
        defaultValue: 'End Date',
    },
    {
        key: 'DI_InterviewTime',
        defaultValue: 'Interview time',
    },
    {
        key: 'DI_InteviewDuration',
        defaultValue: 'Interview duration',
    },
    {
        key: 'DI_MaxRange',
        defaultValue: 'Max range: 14 days',
    },
    {
        key: 'DI_PanEmail',
        defaultValue: "Panelist's emails",
    },
    {
        key: 'DI_PanelistSetting',
        defaultValue: 'Panelist setting',
    },
    {
        key: 'DI_RangeBetween',
        defaultValue: 'Only interview between these times',
    },
    {
        key: 'DI_SendInvite',
        defaultValue: 'Send an invitation to',
    },
    {
        key: 'DI_StartDate',
        defaultValue: 'Start Date',
    },
    {
        key: 'DI_TentativeDates',
        defaultValue: 'Tentative dates',
    },
    {
        key: 'DI_start_time',
        defaultValue: 'Start time',
    },
    {
        key: 'Di_Inter_Schedule',
        defaultValue: 'Direct Interview',
    },
    {
        key: 'Drop',
        defaultValue: 'Drop',
    },
    {
        key: 'Earliest',
        defaultValue: 'Earliest',
    },
    {
        key: 'General',
        defaultValue: 'General',
    },
    {
        key: 'Green_update_lg',
        defaultValue: 'Updated',
    },
    {
        key: 'Latest',
        defaultValue: 'Latest',
    },
    {
        key: 'Manually_create_qp',
        defaultValue: 'Manually create question pack for this position',
    },
    {
        key: 'No_update_data',
        defaultValue: 'No update',
    },
    {
        key: 'Optional',
        defaultValue: 'Optional',
    },
    {
        key: 'Position_description',
        defaultValue: 'Job specific requirement',
    },
    {
        key: 'RG_signature',
        defaultValue: 'RecruitGenius.ai',
    },
    {
        key: 'SamplePosition_Library',
        defaultValue: 'Sample Question Pack',
    },
    {
        key: 'Scheduling',
        defaultValue: 'Scheduling',
    },
    {
        key: 'Type_email',
        defaultValue: 'Email Type',
    },
    {
        key: 'accept',
        defaultValue: 'Accept',
    },
    {
        key: 'action',
        defaultValue: 'Action',
    },
    {
        key: 'actions',
        defaultValue: 'Actions',
    },
    {
        key: 'activate_button',
        defaultValue: 'Activate',
    },
    {
        key: 'add',
        defaultValue: 'Add',
    },
    {
        key: 'add_another_invitation',
        defaultValue: 'Add another invitation',
    },
    {
        key: 'add_break_time',
        defaultValue: 'Add break time?',
    },
    {
        key: 'add_button',
        defaultValue: 'Add',
    },
    {
        key: 'add_external_polling',
        defaultValue: 'Add external polling',
    },
    {
        key: 'add_image',
        defaultValue: 'Add new image',
    },
    {
        key: 'add_manually',
        defaultValue: 'Add manually',
    },
    {
        key: 'add_media',
        defaultValue: 'Add media (optional)',
    },
    {
        key: 'add_more_scorers',
        defaultValue: 'Add more scorers',
    },
    {
        key: 'add_packs_to_position',
        defaultValue: 'Create a question pack for this position',
    },
    {
        key: 'add_person_reconsider_cvs',
        defaultValue: 'Add person in charge of reconsider CVs',
    },
    {
        key: 'add_person_scoring',
        defaultValue: "Add person in charge of scoring the candidate's interview performance",
    },
    {
        key: 'add_pic',
        defaultValue: "Add person in charge of scoring the candidate's CV",
    },
    {
        key: 'add_ques_pack',
        defaultValue: 'Save this question pack',
    },
    {
        key: 'add_video',
        defaultValue: 'Add new video',
    },
    {
        key: 'af_draftflows',
        defaultValue: 'Draft Flows',
    },
    {
        key: 'af_flowtemplate',
        defaultValue: 'Flow Template',
    },
    {
        key: 'af_menu',
        defaultValue: 'AI Flows',
    },
    {
        key: 'af_runningflows',
        defaultValue: 'Running Flows',
    },
    {
        key: 'af_title',
        defaultValue: 'Advanced Automation Flow',
    },
    {
        key: 'agree_and_save',
        defaultValue: 'Agree and save',
    },
    {
        key: 'agree_terms',
        defaultValue: 'By signing up, I agree with the T&C and Privacy Policy',
    },
    {
        key: 'ai_comment',
        defaultValue: "AI's Comment",
    },
    {
        key: 'analytics_applies',
        defaultValue: 'Applies',
    },
    {
        key: 'analytics_conversions',
        defaultValue: 'Conversions',
    },
    {
        key: 'analytics_cvreceived',
        defaultValue: 'CV Received',
    },
    {
        key: 'analytics_failed',
        defaultValue: 'Failed',
    },
    {
        key: 'analytics_interview',
        defaultValue: 'Interview Performance',
    },
    {
        key: 'analytics_menu',
        defaultValue: 'Analytics',
    },
    {
        key: 'analytics_passed',
        defaultValue: 'Passed',
    },
    {
        key: 'analytics_position',
        defaultValue: 'Position',
    },
    {
        key: 'analytics_source',
        defaultValue: 'Source',
    },
    {
        key: 'analytics_thput',
        defaultValue: 'Candidate Throughput',
    },
    {
        key: 'answer_no',
        defaultValue: 'No',
    },
    {
        key: 'answer_sumary',
        defaultValue: "Answer's summary",
    },
    {
        key: 'answer_yes',
        defaultValue: 'Yes',
    },
    {
        key: 'appear_after',
        defaultValue: 'Appear after',
    },
    {
        key: 'application_received',
        defaultValue: 'Application Received',
    },
    {
        key: 'applied_question_pack',
        defaultValue: 'Applied Question Packs',
    },
    {
        key: 'applied_scorecards',
        defaultValue: 'Applied scorecards',
    },
    {
        key: 'arrow_button',
        defaultValue: 'Select your question by clicking on the Arrow Button',
    },
    {
        key: 'ascending',
        defaultValue: 'Ascending',
    },
    {
        key: 'attach_file',
        defaultValue: 'Drag an image or upload a new one',
    },
    {
        key: 'audio_transcript',
        defaultValue: 'Audio Transcript',
    },
    {
        key: 'auto_classify_description',
        defaultValue: 'The AI will auto classify submitted into 3 categories',
    },
    {
        key: 'auto_filter',
        defaultValue: 'AI Filter',
    },
    {
        key: 'auto_generate_by_ai',
        defaultValue: 'Auto generate by AI',
    },
    {
        key: 'auto_interview',
        defaultValue: 'Auto Interview',
    },
    {
        key: 'auto_quest_AI',
        defaultValue: 'Automatically generate questions using AI',
    },
    {
        key: 'auto_renew',
        defaultValue: 'Auto-renewal',
    },
    {
        key: 'auto_renew2',
        defaultValue: 'This package will be auto-renewed once you reach your package limits',
    },
    {
        key: 'available_slot_legend',
        defaultValue: 'My available slots',
    },
    {
        key: 'available_slots',
        defaultValue: 'View their surely available slots',
    },
    {
        key: 'available_template',
        defaultValue: 'Available template',
    },
    {
        key: 'avg_conversionrate',
        defaultValue: 'AVG Conversion Rate',
    },
    {
        key: 'avg_interviewtime',
        defaultValue: 'Average Total Interview Time (hours)',
    },
    {
        key: 'avg_performance',
        defaultValue: 'Average Performance',
    },
    {
        key: 'back',
        defaultValue: 'Back',
    },
    {
        key: 'back_package',
        defaultValue: 'Back to Package & Payment',
    },
    {
        key: 'bahasa',
        defaultValue: 'Indonesian',
    },
    {
        key: 'billing_history',
        defaultValue: 'See Billing History',
    },
    {
        key: 'billing_title',
        defaultValue: 'Billing History',
    },
    {
        key: 'browse_to_add',
        defaultValue: 'Browse to add',
    },
    {
        key: 'bug_prod',
        defaultValue: 'Bug',
    },
    {
        key: 'buymore_button',
        defaultValue: 'Buy More Quota',
    },
    {
        key: 'buymorepackage_button',
        defaultValue: 'BUY MORE',
    },
    {
        key: 'buynow_button',
        defaultValue: 'BUY NOW',
    },
    {
        key: 'caConfirmed',
        defaultValue: 'Candidate Confirmed',
    },
    {
        key: 'caInvited',
        defaultValue: 'Candidate Invited',
    },
    {
        key: 'calendar_tip_click_availble',
        defaultValue: 'Drag to choose your available time.',
    },
    {
        key: 'calendar_tip_click_details',
        defaultValue: 'Click to view details.',
    },
    {
        key: 'calendar_tip_click_if_neeb_be',
        defaultValue: 'Drag to change to Available if try hard.',
    },
    {
        key: 'call_setup',
        defaultValue: 'Venue/Conference Call Set Up',
    },
    {
        key: 'cancel',
        defaultValue: 'Cancel',
    },
    {
        key: 'cancel_send_btn',
        defaultValue: 'Cancel Send',
    },
    {
        key: 'cand_answer',
        defaultValue: "Candidate's answer",
    },
    {
        key: 'cand_email',
        defaultValue: "Candidate's email",
    },
    {
        key: 'cand_feedbacks',
        defaultValue: 'Candidate Feedbacks',
    },
    {
        key: 'cand_information',
        defaultValue: "Candidate's information",
    },
    {
        key: 'cand_inputmanual',
        defaultValue: "Candidate's info is input manually, no CV to show",
    },
    {
        key: 'cand_name',
        defaultValue: "Candidate's name",
    },
    {
        key: 'cand_phone',
        defaultValue: "Candidate's phone",
    },
    {
        key: 'candidate',
        defaultValue: 'Sourcing Efficiency',
    },
    {
        key: 'candidate_email',
        defaultValue: "Candidate's Email",
    },
    {
        key: 'candidate_info',
        defaultValue: "Candidate's Information",
    },
    {
        key: 'candidate_input',
        defaultValue: "Candidate's Input",
    },
    {
        key: 'candidate_keyword',
        defaultValue: 'Candidate',
    },
    {
        key: 'candidate_mana',
        defaultValue: 'Candidate Management',
    },
    {
        key: 'candidate_name',
        defaultValue: "Candidate's Name",
    },
    {
        key: 'candidate_offer',
        defaultValue: 'Offer Now',
    },
    {
        key: 'candidate_offered2',
        defaultValue: 'Offered',
    },
    {
        key: 'candidate_responses',
        defaultValue: 'Candidate Responses',
    },
    {
        key: 'candidate_untouched',
        defaultValue: 'Untouched Candidate',
    },
    {
        key: 'cc_email',
        defaultValue: 'cc emails',
    },
    {
        key: 'cc_emails',
        defaultValue: 'cc emails',
    },
    {
        key: 'certifications',
        defaultValue: 'Certifications',
    },
    {
        key: 'change',
        defaultValue: 'Change',
    },
    {
        key: 'change_default_timezone',
        defaultValue: 'You can change this in <bold>Settings > Workspace</bold>',
    },
    {
        key: 'change_pass',
        defaultValue: 'Change Password',
    },
    {
        key: 'change_to_candidate',
        defaultValue: 'Change to candidate',
    },
    {
        key: 'change_to_candidate_confirm',
        defaultValue: 'You are about to change this employee to candidate. Are you sure?',
    },
    {
        key: 'change_to_candidate_failed',
        defaultValue: 'Failed to change to candidate',
    },
    {
        key: 'change_to_candidate_success',
        defaultValue: 'Change to candidate successfully',
    },
    {
        key: 'change_to_employee',
        defaultValue: 'Change to employee',
    },
    {
        key: 'change_to_employee_confirm',
        defaultValue: 'You are about to change this candidate to employee. Are you sure?',
    },
    {
        key: 'change_to_employee_failed',
        defaultValue: 'Failed to change to employee',
    },
    {
        key: 'change_to_employee_success',
        defaultValue: 'Change to employee successfully',
    },
    {
        key: 'check_spam1',
        defaultValue: '*If you have an account with us, please check your inbox and spam folder for One-time Login Link',
    },
    {
        key: 'check_spam2',
        defaultValue: '*If you have an account with us, please check your inbox and spam folder for Password reset OTP',
    },
    {
        key: 'choose_button',
        defaultValue: 'Choose Package',
    },
    {
        key: 'choose_candidates',
        defaultValue: 'Choose candidates',
    },
    {
        key: 'choose_default_timezone',
        defaultValue: 'Since interview is <bold>time-sensitive</bold>, please choose your correct default timezone',
    },
    {
        key: 'choose_email_tempalte',
        defaultValue: 'Choose Email Template',
    },
    {
        key: 'choose_email_theme',
        defaultValue: 'Choose Email Theme',
    },
    {
        key: 'choose_pack',
        defaultValue: "Choose Position's Question Pack",
    },
    {
        key: 'choose_reply_template',
        defaultValue: 'Choose a Reply Template',
    },
    {
        key: 'choose_source',
        defaultValue: 'Choose source',
    },
    {
        key: 'choose_template',
        defaultValue: 'Choose Email Template',
    },
    {
        key: 'choose_workspace',
        defaultValue: 'Choose workspace',
    },
    {
        key: 'clear_all',
        defaultValue: 'Clear All',
    },
    {
        key: 'click',
        defaultValue: 'Click',
    },
    {
        key: 'close_button',
        defaultValue: 'Close',
    },
    {
        key: 'code',
        defaultValue: 'Code',
    },
    {
        key: 'code_explain',
        defaultValue: 'Allow candidates show their real-world coding skills by solving realistic asynchronous challenges',
    },
    {
        key: 'code_notreceive',
        defaultValue: "Haven't received the code? Resend code",
    },
    {
        key: 'combo_package1',
        defaultValue: 'Buy 2 get 1 free',
    },
    {
        key: 'common_packs',
        defaultValue: 'Common Question Packs',
    },
    {
        key: 'common_questions',
        defaultValue: 'Common Questions',
    },
    {
        key: 'confirm',
        defaultValue: 'Confirm',
    },
    {
        key: 'confirm_cancel_schedule',
        defaultValue: 'Are you sure that you want to cancel this schedule?',
    },
    {
        key: 'confirm_delete',
        defaultValue: 'Are you sure that you want to delete this question?',
    },
    {
        key: 'confirm_delete2',
        defaultValue: 'Are you sure that you want to delete this email template?',
    },
    {
        key: 'confirm_delete_draft_flow',
        defaultValue: 'Are you sure that you want to delete this draft Flow?',
    },
    {
        key: 'confirm_delete_flow_email',
        defaultValue: 'Are you sure that you want to delete this Flow Email?',
    },
    {
        key: 'confirm_delete_hint',
        defaultValue: 'Are you sure that you want to delete this hint?',
    },
    {
        key: 'confirm_delete_pack',
        defaultValue: 'Are you sure that you want to delete this pack?',
    },
    {
        key: 'confirm_delete_position',
        defaultValue: 'Are you sure that you want to delete this position?',
    },
    {
        key: 'confirm_delete_theme',
        defaultValue: 'Are you sure that you want to delete this email theme?',
    },
    {
        key: 'confirm_delete_video',
        defaultValue: 'Are you sure that you want to delete this video?',
    },
    {
        key: 'confirm_fail_candidate',
        defaultValue: 'You are about to fail this candidate. Are you sure?',
    },
    {
        key: 'confirm_interviews',
        defaultValue: 'Confirm Interviews',
    },
    {
        key: 'confirm_lock_slots',
        defaultValue: 'Are you sure that you want to lock these slots?',
    },
    {
        key: 'confirm_order',
        defaultValue: 'Confirm Order',
    },
    {
        key: 'confirm_order2',
        defaultValue:
            'You are about to purchase for. Your package will be auto-renewed once you reach your account limit. You can disable auto-renewal at any time in the',
    },
    {
        key: 'confirm_order3',
        defaultValue: 'Confirm',
    },
    {
        key: 'confirm_pass_candidate',
        defaultValue: 'You are about to pass this candidate. Are you sure?',
    },
    {
        key: 'confirmed',
        defaultValue: 'Confirmed',
    },
    {
        key: 'contact',
        defaultValue: 'Contact',
    },
    {
        key: 'contact_add',
        defaultValue: 'Directly contact our customer support team via one of these emails',
    },
    {
        key: 'contact_bye',
        defaultValue: 'We would love to hear from you!',
    },
    {
        key: 'contact_company',
        defaultValue: 'Company Name',
    },
    {
        key: 'contact_confirm',
        defaultValue: 'Please confirm your contact information!',
    },
    {
        key: 'contact_enterdetail',
        defaultValue: 'Please enter details of your request',
    },
    {
        key: 'contact_firstname',
        defaultValue: 'First Name',
    },
    {
        key: 'contact_info',
        defaultValue: 'Contact Information',
    },
    {
        key: 'contact_jobtitle',
        defaultValue: 'Job title',
    },
    {
        key: 'contact_lastname',
        defaultValue: 'Last Name',
    },
    {
        key: 'contact_later',
        defaultValue: 'A member of our team will be in touch with you shortly.',
    },
    {
        key: 'contact_next',
        defaultValue: 'Next',
    },
    {
        key: 'contact_phone',
        defaultValue: 'Phone Number',
    },
    {
        key: 'contact_question',
        defaultValue: 'How can we help you?*',
    },
    {
        key: 'contact_region',
        defaultValue: 'Country or region',
    },
    {
        key: 'contact_sales',
        defaultValue: 'Contact Sales',
    },
    {
        key: 'contact_sales2',
        defaultValue: 'Contact our Sales team',
    },
    {
        key: 'contact_size',
        defaultValue: 'Company size',
    },
    {
        key: 'contact_success',
        defaultValue: 'Your request has been received!',
    },
    {
        key: 'contact_workmail',
        defaultValue: 'Work Email',
    },
    {
        key: 'contain',
        defaultValue: 'contains',
    },
    {
        key: 'content_creator',
        defaultValue: 'Content creator',
    },
    {
        key: 'content_feedback',
        defaultValue: 'Feedback Content',
    },
    {
        key: 'content_require',
        defaultValue: 'content is a required field',
    },
    {
        key: 'content_template',
        defaultValue: 'Content Template',
    },
    {
        key: 'country',
        defaultValue: 'Country',
    },
    {
        key: 'craft_message',
        defaultValue: 'Use these components to personalize your message',
    },
    {
        key: 'create',
        defaultValue: 'Create',
    },
    {
        key: 'create_button',
        defaultValue: 'Create Question',
    },
    {
        key: 'create_email_example',
        defaultValue: 'Create Email Template Example',
    },
    {
        key: 'create_emailtemp',
        defaultValue: 'Create Email Template',
    },
    {
        key: 'create_interview',
        defaultValue: 'Create Video Interview',
    },
    {
        key: 'create_interview_invite',
        defaultValue: 'Create Interview Invitation',
    },
    {
        key: 'create_locked_interview',
        defaultValue: 'Create a locked interview"',
    },
    {
        key: 'create_new',
        defaultValue: 'Create a new account',
    },
    {
        key: 'create_pack',
        defaultValue: 'Create Question Pack',
    },
    {
        key: 'create_pack2',
        defaultValue: 'or create question pack',
    },
    {
        key: 'create_packs',
        defaultValue: 'Create Question Pack',
    },
    {
        key: 'create_poll',
        defaultValue: 'Create a poll',
    },
    {
        key: 'create_position',
        defaultValue: 'Create Position',
    },
    {
        key: 'create_tags',
        defaultValue: 'You can create tags like marketing-intern" or "hiring-2022" to search later.',
    },
    {
        key: 'create_template',
        defaultValue: 'or create email template',
    },
    {
        key: 'create_theme',
        defaultValue: 'Create new theme',
    },
    {
        key: 'create_wrksp',
        defaultValue: 'Create Workspace',
    },
    {
        key: 'criteria_create',
        defaultValue: 'Create new criteria',
    },
    {
        key: 'criteria_form',
        defaultValue: 'Criteria Form',
    },
    {
        key: 'criteria_name',
        defaultValue: 'Criteria name',
    },
    {
        key: 'criteria_type',
        defaultValue: 'Criteria type',
    },
    {
        key: 'current_pass',
        defaultValue: 'Current Password',
    },
    {
        key: 'current_quota',
        defaultValue: 'Current Usage',
    },
    {
        key: 'current_wrksp',
        defaultValue: 'Current Workspace',
    },
    {
        key: 'custom_scorecard_list',
        defaultValue: 'Customize scorecard list',
    },
    {
        key: 'custom_theme',
        defaultValue: 'Custom theme',
    },
    {
        key: 'customize_button',
        defaultValue: 'Customize',
    },
    {
        key: 'customize_list',
        defaultValue: 'Customize question list',
    },
    {
        key: 'customize_queslist',
        defaultValue: 'Customize question list',
    },
    {
        key: 'customize_source',
        defaultValue: 'Customize Source',
    },
    {
        key: 'customize_subject',
        defaultValue: 'Customize Email Subject',
    },
    {
        key: 'cv_is_being_parsed',
        defaultValue: 'CV is being parsed',
    },
    {
        key: 'cv_passed',
        defaultValue: 'Passed',
    },
    {
        key: 'cv_received',
        defaultValue: 'Received',
    },
    {
        key: 'cv_rejected',
        defaultValue: 'Rejected',
    },
    {
        key: 'days',
        defaultValue: 'days',
    },
    {
        key: 'days_after_video_interview',
        defaultValue: 'days after the candidate successfully passes the previous round.',
    },
    {
        key: 'days_ago',
        defaultValue: 'days ago',
    },
    {
        key: 'deadline',
        defaultValue: 'Deadline',
    },
    {
        key: 'deadline_required',
        defaultValue: 'Deadline is required',
    },
    {
        key: 'decided_status',
        defaultValue: 'Decided',
    },
    {
        key: 'decision_fail',
        defaultValue: 'Your decision is to fail this candidate',
    },
    {
        key: 'decision_maker',
        defaultValue: 'Decision maker',
    },
    {
        key: 'decision_pass',
        defaultValue: 'Your decision is to pass this candidate',
    },
    {
        key: 'default_lang',
        defaultValue: "Default interview's language",
    },
    {
        key: 'default_timezone',
        defaultValue: 'Default timezone',
    },
    {
        key: 'delete_button',
        defaultValue: 'Delete',
    },
    {
        key: 'delete_draft',
        defaultValue: 'Are you sure that you want to delete this draft flow?',
    },
    {
        key: 'delete_theme',
        defaultValue: 'Delete theme',
    },
    {
        key: 'descending',
        defaultValue: 'Descending',
    },
    {
        key: 'description_title',
        defaultValue: 'Set up a Description',
    },
    {
        key: 'detail_infor',
        defaultValue: 'Detail Information',
    },
    {
        key: 'details',
        defaultValue: 'Details',
    },
    {
        key: 'direct_interview_panelist',
        defaultValue: 'The Panelist list that always responsible for this position Direct interview',
    },
    {
        key: 'disable_renew',
        defaultValue: "Your package won't be auto-renewed once you use up your quota",
    },
    {
        key: 'disable_step',
        defaultValue: 'Disable',
    },
    {
        key: 'disabled',
        defaultValue: 'Disabled',
    },
    {
        key: 'dm_comments',
        defaultValue: 'Comments of the decision maker',
    },
    {
        key: 'do_later',
        defaultValue: 'Do it later',
    },
    {
        key: 'download_csv',
        defaultValue: 'Download CSV',
    },
    {
        key: 'download_png',
        defaultValue: 'Download PNG',
    },
    {
        key: 'download_svg',
        defaultValue: 'Download SVG',
    },
    {
        key: 'draft_alert',
        defaultValue: 'Automated Recruitment helps you automate most steps since applications arrive',
    },
    {
        key: 'draft_offer',
        defaultValue: 'Draft Offer',
    },
    {
        key: 'drop_file',
        defaultValue: 'Drag & Drop to upload or browse on your device',
    },
    {
        key: 'duplicate_pack',
        defaultValue: 'Duplicate Pack',
    },
    {
        key: 'duration',
        defaultValue: 'Duration',
    },
    {
        key: 'duration_min',
        defaultValue: 'minutes',
    },
    {
        key: 'duration_minutes',
        defaultValue: 'Duration',
    },
    {
        key: 'edit',
        defaultValue: 'Edit',
    },
    {
        key: 'edit_button',
        defaultValue: 'Clone & Customize',
    },
    {
        key: 'edit_setup',
        defaultValue: 'Edit Setup',
    },
    {
        key: 'edit_theme',
        defaultValue: 'Edit theme',
    },
    {
        key: 'educations',
        defaultValue: 'Educations',
    },
    {
        key: 'efficiency',
        defaultValue: 'Efficiency',
    },
    {
        key: 'email',
        defaultValue: 'Email',
    },
    {
        key: 'email_add',
        defaultValue: 'Email Address',
    },
    {
        key: 'email_add',
        defaultValue: 'Email Address',
    },
    {
        key: 'email_opened',
        defaultValue: 'Email last opened {{time_distance}}',
    },
    {
        key: 'email_sent',
        defaultValue: 'An email has been sent to',
    },
    {
        key: 'email_sent2',
        defaultValue: "An email will be sent to each Candidate's email (extracted from CV) when the flow receive a CV",
    },
    {
        key: 'email_subject',
        defaultValue: "Email's subject",
    },
    {
        key: 'email_template',
        defaultValue: 'Email template',
    },
    {
        key: 'email_template_example',
        defaultValue: 'Email templates examples',
    },
    {
        key: 'email_theme',
        defaultValue: 'Email Theme',
    },
    {
        key: 'email_unopened',
        defaultValue: 'Email Unopened',
    },
    {
        key: 'emailtemp_1',
        defaultValue: 'Interview Invitation Email',
    },
    {
        key: 'emailtemp_2',
        defaultValue: 'Generic Email',
    },
    {
        key: 'emailtemp_3',
        defaultValue: 'Confirm Pass Interview Email',
    },
    {
        key: 'emailtemp_4',
        defaultValue: 'Confirm Reject Email',
    },
    {
        key: 'embed_code_button_af',
        defaultValue: 'Embed code',
    },
    {
        key: 'employee',
        defaultValue: 'Employee',
    },
    {
        key: 'employee_information',
        defaultValue: 'Employee information',
    },
    {
        key: 'empty',
        defaultValue: 'Empty',
    },
    {
        key: 'enable_again',
        defaultValue: 'If you want to enable this step, please Deactivate Flow, then go to Customize Flow',
    },
    {
        key: 'enable_step',
        defaultValue: 'Enable',
    },
    {
        key: 'enabled',
        defaultValue: 'Enabled',
    },
    {
        key: 'english',
        defaultValue: 'English',
    },
    {
        key: 'enter_emailtitle',
        defaultValue: 'Tell us your email address',
    },
    {
        key: 'enter_tags',
        defaultValue: 'Press Enter after trying to create a tag.',
    },
    {
        key: 'example_first_candidate',
        defaultValue: "Example with first candidate's information",
    },
    {
        key: 'expired_slot',
        defaultValue: 'Expired',
    },
    {
        key: 'expired_slot_description',
        defaultValue:
            'You can only choose a time slot that is 8 hours ahead of the current time for the panelists and 2 hours ahead for the contestants.',
    },
    {
        key: 'export_btn',
        defaultValue: 'Export CV',
    },
    {
        key: 'fail_button',
        defaultValue: 'Fail',
    },
    {
        key: 'fail_cv',
        defaultValue: 'Fail this CV',
    },
    {
        key: 'fail_email_description',
        defaultValue: 'This email is used to inform a candidate who has failed the video interview',
    },
    {
        key: 'fail_review',
        defaultValue: 'Failed! Go to review',
    },
    {
        key: 'fail_status',
        defaultValue: 'Failed',
    },
    {
        key: 'failed_cvs',
        defaultValue: 'Failed CVs',
    },
    {
        key: 'feature_prod',
        defaultValue: 'Feature',
    },
    {
        key: 'files_attached',
        defaultValue: 'This question contains media files',
    },
    {
        key: 'filter_applied_cvs',
        defaultValue: 'Filter the Applied CVs',
    },
    {
        key: 'filter_by',
        defaultValue: 'Filter by',
    },
    {
        key: 'filter_by_language',
        defaultValue: 'Filter by language',
    },
    {
        key: 'filter_by_tag_name',
        defaultValue: 'Filter by tag name',
    },
    {
        key: 'filter_fail',
        defaultValue: 'Filtered Out CVs',
    },
    {
        key: 'filter_options',
        defaultValue: 'Filter Options',
    },
    {
        key: 'filter_pass',
        defaultValue: 'Pass-through CVs',
    },
    {
        key: 'filter_tagname',
        defaultValue: 'Search Tags (industry, skill)',
    },
    {
        key: 'filter_tagname',
        defaultValue: 'Search Tags (industry, skill)',
    },
    {
        key: 'filtered_by',
        defaultValue: 'Filtered by',
    },
    {
        key: 'filters',
        defaultValue: 'Filters',
    },
    {
        key: 'finish',
        defaultValue: 'Finish',
    },
    {
        key: 'finish_disable',
        defaultValue: 'Finish all steps or disable unset steps to activate flow',
    },
    {
        key: 'first_name',
        defaultValue: 'First name',
    },
    {
        key: 'flow_activate',
        defaultValue: 'Activate Flow',
    },
    {
        key: 'flow_customize',
        defaultValue: 'Customize Flow',
    },
    {
        key: 'flow_deactivate',
        defaultValue: 'Deactivate',
    },
    {
        key: 'flow_details',
        defaultValue: 'Flow Details',
    },
    {
        key: 'flow_emails',
        defaultValue: "Flow's Email and Source",
    },
    {
        key: 'flow_inactive',
        defaultValue: 'Flow is Inactive',
    },
    {
        key: 'flow_running',
        defaultValue: 'Flow is running',
    },
    {
        key: 'flow_template1',
        defaultValue: 'Flow Template 1',
    },
    {
        key: 'footer',
        defaultValue: 'Footer',
    },
    {
        key: 'forgot_pass',
        defaultValue: 'Forgot password?',
    },
    {
        key: 'forgot_yourpass',
        defaultValue: 'Forgot Your Password?',
    },
    {
        key: 'from',
        defaultValue: 'From',
    },
    {
        key: 'from_external',
        defaultValue: 'From external source',
    },
    {
        key: 'from_unscheduled',
        defaultValue: 'From unscheduled interview',
    },
    {
        key: 'full_access',
        defaultValue: 'Full access',
    },
    {
        key: 'fwd_email',
        defaultValue: 'You can add candidates by forwarding emails with their attached CVs to',
    },
    {
        key: 'fwd_email2',
        defaultValue: 'It works, like magic!',
    },
    {
        key: 'general_dash',
        defaultValue: 'Dashboard',
    },
    {
        key: 'general_menu',
        defaultValue: 'General',
    },
    {
        key: 'general_setting',
        defaultValue: 'Settings',
    },
    {
        key: 'generate_question_language',
        defaultValue: 'The language which is used for generating questions',
    },
    {
        key: 'generic_email_description',
        defaultValue:
            "This email is to be used in the case of an auto-recruitment flow setup. When RecruitGenius receives a candidate's CV, this email will be sent to that candidate",
    },
    {
        key: 'grade',
        defaultValue: 'Point',
    },
    {
        key: 'header',
        defaultValue: 'Header',
    },
    {
        key: 'here',
        defaultValue: 'Here',
    },
    {
        key: 'hide_list',
        defaultValue: 'Hide question list',
    },
    {
        key: 'hide_queslist',
        defaultValue: 'Hide question list',
    },
    {
        key: 'high_performance',
        defaultValue: 'High Performance',
    },
    {
        key: 'highlight',
        defaultValue: 'Highlights',
    },
    {
        key: 'hint_explain',
        defaultValue: 'Hints help your candidates crack the tough questions',
    },
    {
        key: 'hint_placeholder',
        defaultValue: 'Add hints to help your candidates crack the tough questions',
    },
    {
        key: 'hint_reply_to_email',
        defaultValue: 'Which email address the candidate will contact when they encounter problem',
    },
    {
        key: 'hint_tooltip',
        defaultValue:
            'Hints will appear chronologically according to your setting. If not, hints will be accessible at the beginning of the question.',
    },
    {
        key: 'hints',
        defaultValue: 'Hints',
    },
    {
        key: 'hiring_status',
        defaultValue: 'Hiring Status',
    },
    {
        key: 'homepage',
        defaultValue: 'Homepage',
    },
    {
        key: 'hours',
        defaultValue: 'Hours',
    },
    {
        key: 'hours_ago',
        defaultValue: 'hours ago',
    },
    {
        key: 'human_filter',
        defaultValue: 'Human Filter',
    },
    {
        key: 'human_score',
        defaultValue: 'Human Score',
    },
    {
        key: 'hybrid',
        defaultValue: 'Hybrid',
    },
    {
        key: 'if_cv',
        defaultValue: 'if a CV',
    },
    {
        key: 'if_try_hard_slot_legend',
        defaultValue: 'I will be available if try hard',
    },
    {
        key: 'if_try_hard_slots',
        defaultValue: "View all possible slots, including panelist's try hard time",
    },
    {
        key: 'import_btn',
        defaultValue: 'Import CV',
    },
    {
        key: 'import_workspace',
        defaultValue: 'Import to workspace',
    },
    {
        key: 'increase_quota_instruction',
        defaultValue: 'You can increase quota by inviting more users. Go to Settings => Quota',
    },
    {
        key: 'indonesian',
        defaultValue: 'Indonesian',
    },
    {
        key: 'industry',
        defaultValue: 'Industry',
    },
    {
        key: 'info',
        defaultValue: 'Information',
    },
    {
        key: 'inform_confirm',
        defaultValue: 'You are about to send an inform email to candidate. Are you sure?',
    },
    {
        key: 'inform_no',
        defaultValue: 'No, I do not want to send an email to candidate',
    },
    {
        key: 'inform_result',
        defaultValue: 'Send the candidate an email to inform his result?',
    },
    {
        key: 'information',
        defaultValue: 'Information',
    },
    {
        key: 'insert_breaktime',
        defaultValue: 'Automatically insert break time after some long questions',
    },
    {
        key: 'insert_candidate_info',
        defaultValue: 'Insert Candidates information',
    },
    {
        key: 'insufficient_balance',
        defaultValue: 'Your account does not have sufficient balance for this purchase. Please top up your account and try again.',
    },
    {
        key: 'internet_interuption',
        defaultValue: 'Multiple recordings happen when candidate encounter Internet disruption',
    },
    {
        key: 'interview',
        defaultValue: 'Interview Performance',
    },
    {
        key: 'interview_conducted_within',
        defaultValue: 'The Direct Interview will be scheduled at the earliest',
    },
    {
        key: 'interview_creator',
        defaultValue: 'Interview creator',
    },
    {
        key: 'interview_decision',
        defaultValue: 'Interview Decision',
    },
    {
        key: 'interview_decision',
        defaultValue: 'Interview Decision',
    },
    {
        key: 'interview_left',
        defaultValue: 'Interview',
    },
    {
        key: 'interview_mana',
        defaultValue: 'Interview Management',
    },
    {
        key: 'interview_mana2',
        defaultValue: 'Management',
    },
    {
        key: 'interview_overdue',
        defaultValue: 'Overdue',
    },
    {
        key: 'interview_position',
        defaultValue: 'Position',
    },
    {
        key: 'interview_result',
        defaultValue: 'Results of Interview',
    },
    {
        key: 'interview_review',
        defaultValue: 'Review',
    },
    {
        key: 'interview_status',
        defaultValue: 'All',
    },
    {
        key: 'interview_status2',
        defaultValue: 'Finished',
    },
    {
        key: 'interview_status3',
        defaultValue: 'Untouched',
    },
    {
        key: 'interview_status4',
        defaultValue: 'Overdue',
    },
    {
        key: 'interview_status5',
        defaultValue: 'Incomplete',
    },
    {
        key: 'interview_status6',
        defaultValue: 'Completed',
    },
    {
        key: 'interview_status7',
        defaultValue: 'Scored',
    },
    {
        key: 'interview_summary',
        defaultValue: 'Interview Summary',
    },
    {
        key: 'interview_unscored',
        defaultValue: 'Finished and Unscored',
    },
    {
        key: 'interview_untouched',
        defaultValue: 'Untouched',
    },
    {
        key: 'invitation_email_description',
        defaultValue: 'This email is used to invite candidates to a video conference interview',
    },
    {
        key: 'invite_content',
        defaultValue: 'Invitation Content',
    },
    {
        key: 'invite_count',
        defaultValue: 'Successful Invitation',
    },
    {
        key: 'invite_friends',
        defaultValue: 'Invite Friends & Earn Quotas',
    },
    {
        key: 'invite_guide',
        defaultValue: 'Share your unique referral link',
    },
    {
        key: 'invite_guide2',
        defaultValue: '03 new sign-ups with their work emails will earn you a Standard package',
    },
    {
        key: 'invite_guide3',
        defaultValue: 'Invite via email',
    },
    {
        key: 'invite_name',
        defaultValue: 'Name',
    },
    {
        key: 'invite_polling_email_description',
        defaultValue:
            'This email is used in direct interview process. It must contain a link (via $LINK$) for panelists to choose their available slots.',
    },
    {
        key: 'invite_to_polling',
        defaultValue: 'Invite to polling',
    },
    {
        key: 'invite_user',
        defaultValue: 'Invite User',
    },
    {
        key: 'job_description',
        defaultValue: 'Job Description',
    },
    {
        key: 'job_name',
        defaultValue: 'Job Name',
    },
    {
        key: 'job_offer_email',
        defaultValue: 'Job Offer Email',
    },
    {
        key: 'job_specification',
        defaultValue:
            'The more detailed the job specification you type in, the better ChatGPT AI will be able to help you generate questions.',
    },
    {
        key: 'jobinfo_required',
        defaultValue: 'To facilitates following steps in the flow, job name and job description is needed',
    },
    {
        key: 'keywords_list',
        defaultValue: 'of the following keywords',
    },
    {
        key: 'keywords_separate',
        defaultValue: 'Separate your keywords with commas or press Enter',
    },
    {
        key: 'label_date',
        defaultValue: 'Date',
    },
    {
        key: 'label_time',
        defaultValue: 'Time',
    },
    {
        key: 'language',
        defaultValue: 'Language',
    },
    {
        key: 'language_display',
        defaultValue: 'Select the language that you are using to compile this question',
    },
    {
        key: 'languages',
        defaultValue: 'Languages',
    },
    {
        key: 'last_name',
        defaultValue: 'Last name',
    },
    {
        key: 'latest_interview',
        defaultValue: 'Latest Interviews',
    },
    {
        key: 'lead_filled',
        defaultValue: '% Lead Filled',
    },
    {
        key: 'leadtime_insight',
        defaultValue: 'Lead Time Insights',
    },
    {
        key: 'leave_message',
        defaultValue: 'Leave a comment (optional)',
    },
    {
        key: 'library_email_template',
        defaultValue: 'Sample Email Templates',
    },
    {
        key: 'library_menu',
        defaultValue: 'Library',
    },
    {
        key: 'library_question',
        defaultValue: 'Sample Questions',
    },
    {
        key: 'link_copied',
        defaultValue: 'Get Interview URL',
    },
    {
        key: 'link_copied2',
        defaultValue: 'Link copied to clipboard',
    },
    {
        key: 'list_candidates',
        defaultValue: 'List of candidates',
    },
    {
        key: 'list_email_example',
        defaultValue: 'List of Email Examples to customize',
    },
    {
        key: 'list_emailtemp',
        defaultValue: 'List of Email templates',
    },
    {
        key: 'list_flows',
        defaultValue: 'List of Running flows',
    },
    {
        key: 'list_packs',
        defaultValue: 'List of Available Positions',
    },
    {
        key: 'list_question',
        defaultValue: 'List of Questions',
    },
    {
        key: 'list_templates',
        defaultValue: 'List of Question Templates',
    },
    {
        key: 'location',
        defaultValue: 'Location',
    },
    {
        key: 'lock_success_message',
        defaultValue: 'Interview schedule have been sent to all interviews participants',
    },
    {
        key: 'lockedInterview',
        defaultValue: 'Locked Interview',
    },
    {
        key: 'locked_interview_description',
        defaultValue: 'This is a locked interview, no negotiation on time.',
    },
    {
        key: 'log_offer_outside',
        defaultValue: 'Log offers made outside this system',
    },
    {
        key: 'log_out',
        defaultValue: 'Logout',
    },
    {
        key: 'login',
        defaultValue: 'Log In',
    },
    {
        key: 'login_already',
        defaultValue: 'Already have an account?',
    },
    {
        key: 'login_existedacc',
        defaultValue: 'Log in if you already have an account',
    },
    {
        key: 'login_link',
        defaultValue: 'Send a one-time login link',
    },
    {
        key: 'login_linksent',
        defaultValue: 'One-time Login Link Sent',
    },
    {
        key: 'loginfail_banner',
        defaultValue: 'Wrong email or password',
    },
    {
        key: 'loom_video',
        defaultValue: 'Describe your issue by inserting a Loom video link. That helps us a lot 😄',
    },
    {
        key: 'low_match',
        defaultValue: 'Low match',
    },
    {
        key: 'low_match_descritption',
        defaultValue: 'These CVs are not eligible with the provided job description',
    },
    {
        key: 'low_match_option',
        defaultValue: 'Low Match" CVs will be',
    },
    {
        key: 'manage_candidate',
        defaultValue: 'Manage Candidates',
    },
    {
        key: 'manual_filter',
        defaultValue: 'Manual Filter',
    },
    {
        key: 'manual_offer',
        defaultValue: 'Add Manual Offer',
    },
    {
        key: 'mark_answers',
        defaultValue: 'How to mark answers?',
    },
    {
        key: 'mark_by_fixed_scale',
        defaultValue: 'On a fixed scale of 1-5, the best answer receives 5',
    },
    {
        key: 'mark_by_question_grade',
        defaultValue: "Strictly using each question's point, higher point means better",
    },
    {
        key: 'maximum_select_3',
        defaultValue: 'You can only select maximum 3 candidates',
    },
    {
        key: 'member',
        defaultValue: 'Member',
    },
    {
        key: 'members',
        defaultValue: 'Members',
    },
    {
        key: 'memeber_invite',
        defaultValue: 'Invite Members',
    },
    {
        key: 'minute_ago',
        defaultValue: 'minutes ago',
    },
    {
        key: 'moderate_match_option',
        defaultValue: 'Moderate Match" CVs will be',
    },
    {
        key: 'months_ago',
        defaultValue: 'months ago',
    },
    {
        key: 'more',
        defaultValue: 'More',
    },
    {
        key: 'more_options',
        defaultValue: 'More options',
    },
    {
        key: 'name',
        defaultValue: 'Name',
    },
    {
        key: 'new',
        defaultValue: 'New',
    },
    {
        key: 'new_candidate',
        defaultValue: 'New Candidates',
    },
    {
        key: 'new_pass',
        defaultValue: 'New Password',
    },
    {
        key: 'new_passconfirm',
        defaultValue: 'New Password Confirmation',
    },
    {
        key: 'new_theme',
        defaultValue: 'New Theme',
    },
    {
        key: 'next',
        defaultValue: 'Next',
    },
    {
        key: 'no_appropriate_slot',
        defaultValue: 'There is no appropriate slot',
    },
    {
        key: 'no_candidate_selected',
        defaultValue: 'No candidate selected',
    },
    {
        key: 'no_comment',
        defaultValue: 'No comment',
    },
    {
        key: 'no_data',
        defaultValue: 'No data to show!',
    },
    {
        key: 'no_extra_input',
        defaultValue: 'Candidate did not input any extra information',
    },
    {
        key: 'no_infomation',
        defaultValue: 'No Information',
    },
    {
        key: 'not_contain',
        defaultValue: 'not contain',
    },
    {
        key: 'not_sure',
        defaultValue: 'Moderate match',
    },
    {
        key: 'not_sure_description',
        defaultValue: 'You should consider this list when you are in a human resources shortage',
    },
    {
        key: 'not_take',
        defaultValue: 'Not Take',
    },
    {
        key: 'note',
        defaultValue: 'Note',
    },
    {
        key: 'notsaved_content',
        defaultValue: 'This content is not saved. Do you want to close this form?',
    },
    {
        key: 'offer_board',
        defaultValue: 'Offer Board',
    },
    {
        key: 'offer_email_description',
        defaultValue:
            'This email is to be used in the case of an auto-recruitment flow setup. When a candidate has passed through all steps and successfully went into the offer phase',
    },
    {
        key: 'offer_outside_explain_text',
        defaultValue:
            "If you send offers outside this system, please mark all the candidates you've done that. This will help produce correct reports.",
    },
    {
        key: 'offer_responded',
        defaultValue: 'Responded',
    },
    {
        key: 'offer_sent',
        defaultValue: 'Offer Sent',
    },
    {
        key: 'offer_status',
        defaultValue: 'Status',
    },
    {
        key: 'offer_status1',
        defaultValue: 'Rejected',
    },
    {
        key: 'offer_status2',
        defaultValue: 'Accepted',
    },
    {
        key: 'offer_status3',
        defaultValue: 'Pending',
    },
    {
        key: 'offering1',
        defaultValue: '50 CV parsers',
    },
    {
        key: 'offering2',
        defaultValue: '30 interviews',
    },
    {
        key: 'offering3',
        defaultValue: 'Access to thousands of interview questions',
    },
    {
        key: 'offering4',
        defaultValue: '24/7 support',
    },
    {
        key: 'offering5',
        defaultValue: '150 CV parsers',
    },
    {
        key: 'offering6',
        defaultValue: '60 interviews',
    },
    {
        key: 'offering7',
        defaultValue: 'Unlimited number of CV Parsers',
    },
    {
        key: 'offering8',
        defaultValue: 'Unlimited number of interviews',
    },
    {
        key: 'offering9',
        defaultValue: 'Get support in question design',
    },
    {
        key: 'offline',
        defaultValue: 'Offline',
    },
    {
        key: 'online',
        defaultValue: 'Online',
    },
    {
        key: 'open_positions',
        defaultValue: 'Open Positions',
    },
    {
        key: 'or',
        defaultValue: 'or',
    },
    {
        key: 'order_success',
        defaultValue: 'Your order has been completed!',
    },
    {
        key: 'order_success2',
        defaultValue: 'You can now create more interviews and parse more CVs',
    },
    {
        key: 'other_panelists_legend',
        defaultValue: 'Other panelists',
    },
    {
        key: 'others_question_packs',
        defaultValue: 'Other available question packs',
    },
    {
        key: 'otp_sent',
        defaultValue: 'Password reset OTP sent',
    },
    {
        key: 'out_of_quota',
        defaultValue: 'Out of quota',
    },
    {
        key: 'overview',
        defaultValue: 'Overview',
    },
    {
        key: 'owner',
        defaultValue: 'Owner',
    },
    {
        key: 'pConfirmed',
        defaultValue: 'Panelists All Confirmed',
    },
    {
        key: 'pInvited',
        defaultValue: 'Panelists Invited',
    },
    {
        key: 'pack_mana',
        defaultValue: 'Question Pack Management',
    },
    {
        key: 'package_change',
        defaultValue: 'Change Package',
    },
    {
        key: 'package_choose',
        defaultValue: 'Choose the package that works best for you',
    },
    {
        key: 'package_combo1',
        defaultValue: 'Buy 2 get 1 free',
    },
    {
        key: 'package_details',
        defaultValue: 'Package Details',
    },
    {
        key: 'package_free',
        defaultValue: 'Free Package',
    },
    {
        key: 'package_payment',
        defaultValue: 'Package & Payment',
    },
    {
        key: 'package_premium',
        defaultValue: 'Premium Package',
    },
    {
        key: 'package_standard',
        defaultValue: 'Standard Package',
    },
    {
        key: 'panelists',
        defaultValue: 'Panelists',
    },
    {
        key: 'pass',
        defaultValue: 'Password',
    },
    {
        key: 'pass_7characters',
        defaultValue: 'Password must be at least 7 characters',
    },
    {
        key: 'pass_button',
        defaultValue: 'Pass',
    },
    {
        key: 'pass_confirm',
        defaultValue: 'Password Confirmation',
    },
    {
        key: 'pass_cv',
        defaultValue: 'Pass this CV',
    },
    {
        key: 'pass_dialog',
        defaultValue: 'Pass Confirm',
    },
    {
        key: 'pass_dialog2',
        defaultValue: 'You are about to pass a candidate. This is a very important decision, are you sure?',
    },
    {
        key: 'pass_email_description',
        defaultValue: 'This email is used to inform a candidate who has passed the video interview',
    },
    {
        key: 'pass_review',
        defaultValue: 'Passed! Go to review',
    },
    {
        key: 'pass_scheduling_dialog',
        defaultValue: 'Pass Confirm & Scheduling',
    },
    {
        key: 'pass_scheduling_dialog2',
        defaultValue: 'You are about to pass a candidate and schedule live interview. This is a very important decision, are you sure?',
    },
    {
        key: 'pass_status',
        defaultValue: 'Passed',
    },
    {
        key: 'payment_card',
        defaultValue: 'Card',
    },
    {
        key: 'payment_change',
        defaultValue: 'Change Payment Method',
    },
    {
        key: 'payment_date',
        defaultValue: 'Latest Payment Date',
    },
    {
        key: 'payment_edit',
        defaultValue: 'Edit your payment details, add a backup, or switch your preferred payment method',
    },
    {
        key: 'payment_info',
        defaultValue: 'Credit or Debit Card',
    },
    {
        key: 'payment_unsucccess',
        defaultValue: 'Payment Unsuccessful',
    },
    {
        key: 'payment_update',
        defaultValue: 'Update Payment Information',
    },
    {
        key: 'per_package',
        defaultValue: 'per package',
    },
    {
        key: 'permission_list',
        defaultValue: 'List of permission',
    },
    {
        key: 'permit_create',
        defaultValue: 'create',
    },
    {
        key: 'permit_delete',
        defaultValue: 'delete',
    },
    {
        key: 'permit_read',
        defaultValue: 'read',
    },
    {
        key: 'permit_update',
        defaultValue: 'update',
    },
    {
        key: 'phone_number',
        defaultValue: 'Phone',
    },
    {
        key: 'poor_performance',
        defaultValue: 'Poor Performance',
    },
    {
        key: 'position',
        defaultValue: 'Position',
    },
    {
        key: 'position_infor',
        defaultValue: 'Position Information',
    },
    {
        key: 'position_name',
        defaultValue: 'Position Title',
    },
    {
        key: 'position_packs',
        defaultValue: 'Question packs of this position',
    },
    {
        key: 'position_question_menu',
        defaultValue: 'Position & Question',
    },
    {
        key: 'position_workspace',
        defaultValue: 'Positions of workspace',
    },
    {
        key: 'positions',
        defaultValue: 'Positions',
    },
    {
        key: 'premium_backage',
        defaultValue: 'Premium',
    },
    {
        key: 'prev',
        defaultValue: 'Prev',
    },
    {
        key: 'preview',
        defaultValue: 'Preview',
    },
    {
        key: 'previous',
        defaultValue: 'Previous',
    },
    {
        key: 'problem_identification',
        defaultValue: 'Problem Identification',
    },
    {
        key: 'problem_solving',
        defaultValue: 'Problem-solving',
    },
    {
        key: 'proceed',
        defaultValue: 'Proceed',
    },
    {
        key: 'proceed_to_next',
        defaultValue: 'Proceed to next step',
    },
    {
        key: 'process_take_time',
        defaultValue: 'This process takes over 1 minute',
    },
    {
        key: 'processing',
        defaultValue: 'Processing',
    },
    {
        key: 'processings',
        defaultValue: 'Processing',
    },
    {
        key: 'profile',
        defaultValue: 'Profile',
    },
    {
        key: 'quantity_cv',
        defaultValue: 'Quantity (CVs)',
    },
    {
        key: 'ques_content',
        defaultValue: 'Question Content:',
    },
    {
        key: 'ques_description',
        defaultValue: 'Description',
    },
    {
        key: 'ques_info',
        defaultValue: "Question's Information",
    },
    {
        key: 'ques_list',
        defaultValue: 'Question List',
    },
    {
        key: 'ques_mana',
        defaultValue: 'Question Management',
    },
    {
        key: 'ques_name',
        defaultValue: "Question's Title",
    },
    {
        key: 'ques_number',
        defaultValue: 'Question',
    },
    {
        key: 'ques_pack_name',
        defaultValue: "Question pack's name",
    },
    {
        key: 'ques_title',
        defaultValue: 'Question Title:',
    },
    {
        key: 'quespack_info',
        defaultValue: 'Question Pack Information',
    },
    {
        key: 'quespack_mana',
        defaultValue: 'Position Setting',
    },
    {
        key: 'question',
        defaultValue: 'Question',
    },
    {
        key: 'question_attributes',
        defaultValue: 'Question Attributes',
    },
    {
        key: 'question_detail',
        defaultValue: 'Mouse-over for details',
    },
    {
        key: 'question_language',
        defaultValue: "Question's Language",
    },
    {
        key: 'question_list',
        defaultValue: 'Questions list',
    },
    {
        key: 'question_pack',
        defaultValue: 'Question Packs',
    },
    {
        key: 'questions',
        defaultValue: 'Questions',
    },
    {
        key: 'quota',
        defaultValue: 'Quota',
    },
    {
        key: 'rate_answer',
        defaultValue: 'Rate the answer',
    },
    {
        key: 'rec',
        defaultValue: 'Record',
    },
    {
        key: 'recommended',
        defaultValue: 'Recommended!',
    },
    {
        key: 'reconsider_cv',
        defaultValue: 'Reconsider This CV',
    },
    {
        key: 'reconsider_pass',
        defaultValue: 'Reconsider pass this candidate?',
    },
    {
        key: 'reconsidered',
        defaultValue: 'Reconsidered',
    },
    {
        key: 'record_number',
        defaultValue: 'Record',
    },
    {
        key: 'recover_pass',
        defaultValue: 'Recover Password',
    },
    {
        key: 'refresh_data',
        defaultValue: 'Refresh data',
    },
    {
        key: 'register_button',
        defaultValue: 'Register',
    },
    {
        key: 'register_new',
        defaultValue: 'Register a new account',
    },
    {
        key: 'register_title',
        defaultValue: 'Register',
    },
    {
        key: 'reject_dialog',
        defaultValue: 'Rejection Confirm',
    },
    {
        key: 'reject_dialog2',
        defaultValue: 'You are about to reject a candidate. This is a very important decision, are you sure?',
    },
    {
        key: 'rejection_type',
        defaultValue: 'Rejection',
    },
    {
        key: 'remain_cvquota',
        defaultValue: 'Remaining CV Parse Quota',
    },
    {
        key: 'remain_interviews',
        defaultValue: 'Remaining Interviews',
    },
    {
        key: 'reminder_1',
        defaultValue: 'This is just a sample. Try creating one at Flow Template"!',
    },
    {
        key: 'reminder_2',
        defaultValue: 'No saved draft!',
    },
    {
        key: 'reminder_3',
        defaultValue: 'Saving a draft enables you to keep flow you are not ready to activate yet.',
    },
    {
        key: 'reminder_fail',
        defaultValue: 'This candidate has been marked Failed',
    },
    {
        key: 'reminder_pass',
        defaultValue: 'This candidate has been marked Passed',
    },
    {
        key: 'remove',
        defaultValue: 'Remove',
    },
    {
        key: 'remove_all',
        defaultValue: 'Remove All',
    },
    {
        key: 'remove_pack_from_position',
        defaultValue: 'Are you sure that you want to remove this pack from current position?',
    },
    {
        key: 'remove_picture',
        defaultValue: 'Remove Picture',
    },
    {
        key: 'reply_templates',
        defaultValue: 'Reply Templates',
    },
    {
        key: 'reply_to_email',
        defaultValue: 'Reply-to-emails',
    },
    {
        key: 'replyto_email',
        defaultValue: 'Reply-to-emails',
    },
    {
        key: 'require_email',
        defaultValue: 'Email is required',
    },
    {
        key: 'require_firstname',
        defaultValue: 'First name is required',
    },
    {
        key: 'require_lastname',
        defaultValue: 'Last name is required',
    },
    {
        key: 'require_pass',
        defaultValue: 'Password is required',
    },
    {
        key: 'require_validemail',
        defaultValue: 'Must be a valid email',
    },
    {
        key: 'required_filed',
        defaultValue: 'Required',
    },
    {
        key: 'required_question_pack_noti',
        defaultValue: 'To proceed, please ensure that you have at least one question pack!',
    },
    {
        key: 'resend_email',
        defaultValue: 'Resend email',
    },
    {
        key: 'reset',
        defaultValue: 'Reset',
    },
    {
        key: 'reset_banner',
        defaultValue: 'Reset Password Successfully',
    },
    {
        key: 'reset_button',
        defaultValue: 'Reset Password',
    },
    {
        key: 'reset_pass',
        defaultValue: 'Password Reset',
    },
    {
        key: 'reset_withcode',
        defaultValue: 'Reset your account password using your code',
    },
    {
        key: 'result',
        defaultValue: 'Result',
    },
    {
        key: 'result_comment',
        defaultValue: 'Comments on this result',
    },
    {
        key: 'resutl_sent',
        defaultValue: 'Result Sent',
    },
    {
        key: 'rethink_decision',
        defaultValue: 'Rethink your decision?',
    },
    {
        key: 'review_answer',
        defaultValue: 'No review on this question yet, go to review',
    },
    {
        key: 'review_cv',
        defaultValue: "Review Candidate's CV",
    },
    {
        key: 'review_direct_interview',
        defaultValue: 'Review Interview',
    },
    {
        key: 'review_interview',
        defaultValue: 'Reviews on this interview',
    },
    {
        key: 'rg_signature',
        defaultValue: 'RecruitGenius.ai',
    },
    {
        key: 'role',
        defaultValue: 'Role',
    },
    {
        key: 'round',
        defaultValue: 'Round',
    },
    {
        key: 'round_1',
        defaultValue: 'Round 1',
    },
    {
        key: 'rows_display',
        defaultValue: 'Rows per page',
    },
    {
        key: 'sample_candidate',
        defaultValue: 'Sample Candidate',
    },
    {
        key: 'sample_position',
        defaultValue: 'Sample Position',
    },
    {
        key: 'save',
        defaultValue: 'Save',
    },
    {
        key: 'save_49',
        defaultValue: 'Save $49',
    },
    {
        key: 'save_button',
        defaultValue: 'Save',
    },
    {
        key: 'save_change',
        defaultValue: 'Saved change',
    },
    {
        key: 'save_change',
        defaultValue: 'Saved change',
    },
    {
        key: 'save_changes',
        defaultValue: 'Save Changes',
    },
    {
        key: 'save_close',
        defaultValue: 'Save & Close',
    },
    {
        key: 'save_create',
        defaultValue: 'Save & Create Another',
    },
    {
        key: 'save_draft',
        defaultValue: 'Save to draft',
    },
    {
        key: 'save_pass',
        defaultValue: 'Save Password',
    },
    {
        key: 'save_this_change',
        defaultValue: 'Save this change',
    },
    {
        key: 'schedule_send',
        defaultValue: 'Schedule send',
    },
    {
        key: 'schedule_send_noti',
        defaultValue: 'An Email has been scheduled sending for',
    },
    {
        key: 'scorecard',
        defaultValue: 'Scorecard',
    },
    {
        key: 'scorecard_list',
        defaultValue: 'Scorecard List',
    },
    {
        key: 'scorer_access',
        defaultValue: 'Scorer',
    },
    {
        key: 'scorer_comment',
        defaultValue: 'How do you think about this candidate?',
    },
    {
        key: 'screen_review',
        defaultValue: 'Jump to decision screen',
    },
    {
        key: 'search_candidate',
        defaultValue: 'By info, tags, skills, name, email...',
    },
    {
        key: 'search_emailtemp',
        defaultValue: 'Search Email Template',
    },
    {
        key: 'search_interview',
        defaultValue: 'Search Interviews',
    },
    {
        key: 'search_packs',
        defaultValue: 'Search for Packs',
    },
    {
        key: 'search_position',
        defaultValue: 'Search Position',
    },
    {
        key: 'search_question',
        defaultValue: 'Search Question',
    },
    {
        key: 'search_workspace',
        defaultValue: 'Search workspace',
    },
    {
        key: 'select',
        defaultValue: 'Select',
    },
    {
        key: 'select_files',
        defaultValue: 'Select files - maximum {{file_number}} files',
    },
    {
        key: 'select_language',
        defaultValue: 'Select language',
    },
    {
        key: 'select_pack',
        defaultValue: 'Select question pack',
    },
    {
        key: 'select_permission',
        defaultValue: 'Select permission',
    },
    {
        key: 'select_position',
        defaultValue: 'Select Position',
    },
    {
        key: 'select_round',
        defaultValue: "Select Invitation's round",
    },
    {
        key: 'select_tag',
        defaultValue: 'Select tag',
    },
    {
        key: 'send_button',
        defaultValue: 'Send Invitation',
    },
    {
        key: 'send_button2',
        defaultValue: 'Send Email',
    },
    {
        key: 'send_email',
        defaultValue: 'Send an email to candidate',
    },
    {
        key: 'send_feedback',
        defaultValue: 'Send Feedback',
    },
    {
        key: 'send_thought',
        defaultValue: 'Send us your thoughts!',
    },
    {
        key: 'sent_invitation',
        defaultValue: 'Invitation Sent',
    },
    {
        key: 'sent_notification',
        defaultValue: 'An email has been sent to this candidate.',
    },
    {
        key: 'sent_offer',
        defaultValue: 'Sent Offer',
    },
    {
        key: 'separate_tags_by_comma',
        defaultValue: 'Please separate your tags by comma',
    },
    {
        key: 'seperate_CV_description',
        defaultValue: 'The AI will separate applied CVs into 3 levels of matching compared to JD',
    },
    {
        key: 'set_default',
        defaultValue: 'Set as default',
    },
    {
        key: 'set_default_timezone',
        defaultValue: 'You can change this in Settings > Workspace',
    },
    {
        key: 'set_default_timezone2',
        defaultValue: 'Set default timezones',
    },
    {
        key: 'set_point',
        defaultValue: 'Assign points to this question based on difficulty',
    },
    {
        key: 'set_time',
        defaultValue: 'Set time alloted to answer this question',
    },
    {
        key: 'set_up_DI',
        defaultValue: 'Set Up Direct Interview',
    },
    {
        key: 'set_up_application_collection_first',
        defaultValue: 'You need to set up Application Collection first!',
    },
    {
        key: 'set_up_noti',
        defaultValue: 'You need set up first',
    },
    {
        key: 'set_up_video_interview_first',
        defaultValue: 'You need set up video interview first',
    },
    {
        key: 'setup',
        defaultValue: 'Set Up',
    },
    {
        key: 'setup_alert',
        defaultValue: 'You need to set up first',
    },
    {
        key: 'setup_application',
        defaultValue: 'Set up for Application Collection',
    },
    {
        key: 'setup_interview',
        defaultValue: 'Set Up Auto Interview',
    },
    {
        key: 'setup_scorers',
        defaultValue: 'Set up Scorers',
    },
    {
        key: 'shortcut',
        defaultValue: 'Shortcut',
    },
    {
        key: 'shortlist_keywords',
        defaultValue: 'Shortlist CVs using the following rules',
    },
    {
        key: 'signature',
        defaultValue: 'Signature',
    },
    {
        key: 'signin_google',
        defaultValue: 'Sign in with Google',
    },
    {
        key: 'singular_position',
        defaultValue: 'Position',
    },
    {
        key: 'skill',
        defaultValue: 'Skill',
    },
    {
        key: 'sort_question',
        defaultValue: 'Drag and Drop to sort',
    },
    {
        key: 'source_create',
        defaultValue: 'Create',
    },
    {
        key: 'source_efficiency',
        defaultValue: 'Source Efficiency',
    },
    {
        key: 'source_generte',
        defaultValue: 'Generate a Source',
    },
    {
        key: 'source_guide',
        defaultValue:
            'Generated email addresses below enable direct CV submission without downloading/uploading. Simply forward emails to these addresses, bypassing the need to manually upload CVs to this flow.',
    },
    {
        key: 'source_input',
        defaultValue: 'Input source name',
    },
    {
        key: 'source_insights',
        defaultValue: 'Source Insights',
    },
    {
        key: 'source_list',
        defaultValue: 'Source List',
    },
    {
        key: 'source_name',
        defaultValue: 'Give a name to this source of applications',
    },
    {
        key: 'source_vsemail',
        defaultValue:
            'If you want to separate your candidate sources, like LinkedIn or Referrals, for better management, you can create multiple email addresses for each different candidate source.',
    },
    {
        key: 'sources',
        defaultValue: 'Sources',
    },
    {
        key: 'spanish',
        defaultValue: 'Spanish',
    },
    {
        key: 'standard_package',
        defaultValue: 'Standard',
    },
    {
        key: 'start_time',
        defaultValue: 'Interview start time',
    },
    {
        key: 'status',
        defaultValue: 'Status',
    },
    {
        key: 'status_done',
        defaultValue: 'Done for',
    },
    {
        key: 'status_overdue',
        defaultValue: 'Overdue',
    },
    {
        key: 'step',
        defaultValue: 'Step',
    },
    {
        key: 'step_disabled',
        defaultValue: 'This step is disabled',
    },
    {
        key: 'strongly_match',
        defaultValue: 'Strongly match',
    },
    {
        key: 'strongly_match_description',
        defaultValue: 'The CVs will be auto-sent to the next step',
    },
    {
        key: 'strongly_match_option',
        defaultValue: 'Strongly Match" CVs will be',
    },
    {
        key: 'submit_button',
        defaultValue: 'Submit',
    },
    {
        key: 'submit_schedule',
        defaultValue: 'Submit & Start Scheduling',
    },
    {
        key: 'subtitle_message',
        defaultValue: "Here's what's happening with your projects today",
    },
    {
        key: 'success_delete',
        defaultValue: 'Deleted Successfully',
    },
    {
        key: 'success_delete2',
        defaultValue: 'Delete question successfully',
    },
    {
        key: 'success_dialog',
        defaultValue: 'Decision Made Successfully',
    },
    {
        key: 'support_contactinfo',
        defaultValue: 'Contact us',
    },
    {
        key: 'support_feedback',
        defaultValue: 'Feedback',
    },
    {
        key: 'support_menu',
        defaultValue: 'Support',
    },
    {
        key: 'tag',
        defaultValue: 'Tags',
    },
    {
        key: 'tag_explain',
        defaultValue: 'Add tags to search your questions easily',
    },
    {
        key: 'take',
        defaultValue: 'Take',
    },
    {
        key: 'template_info',
        defaultValue: 'Information of Email Template',
    },
    {
        key: 'template_name',
        defaultValue: "Template's name",
    },
    {
        key: 'template_type',
        defaultValue: 'Type of Email Template',
    },
    {
        key: 'text',
        defaultValue: 'Text',
    },
    {
        key: 'text_explain',
        defaultValue: 'A longer answer type for candidates to write their opinions or provide an essay prompt',
    },
    {
        key: 'theme_collection',
        defaultValue: 'Theme Collection',
    },
    {
        key: 'theme_design',
        defaultValue: 'Theme Design',
    },
    {
        key: 'then',
        defaultValue: 'Then',
    },
    {
        key: 'time',
        defaultValue: 'Time',
    },
    {
        key: 'time_2fill',
        defaultValue: 'days Time to Fill',
    },
    {
        key: 'time_ago1',
        defaultValue: 'seconds ago',
    },
    {
        key: 'time_ago2',
        defaultValue: 'minutes ago',
    },
    {
        key: 'time_ago3',
        defaultValue: 'hours ago',
    },
    {
        key: 'time_ago4',
        defaultValue: 'days ago',
    },
    {
        key: 'time_ago5',
        defaultValue: 'months ago',
    },
    {
        key: 'time_ago6',
        defaultValue: 'years ago',
    },
    {
        key: 'timezone',
        defaultValue: 'Timezone',
    },
    {
        key: 'tips_used',
        defaultValue: 'Tips used by candidate for this question',
    },
    {
        key: 'title',
        defaultValue: 'Title',
    },
    {
        key: 'title_feedback',
        defaultValue: 'Feedback Title',
    },
    {
        key: 'title_feedback2',
        defaultValue: 'Title',
    },
    {
        key: 'title_required',
        defaultValue: 'title is a required field',
    },
    {
        key: 'to',
        defaultValue: 'To',
    },
    {
        key: 'to_do',
        defaultValue: 'To do',
    },
    {
        key: 'today',
        defaultValue: 'Today',
    },
    {
        key: 'total_applicants',
        defaultValue: 'Total Applicants',
    },
    {
        key: 'total_applications',
        defaultValue: 'Total Applications',
    },
    {
        key: 'total_candidate',
        defaultValue: 'Total Candidates',
    },
    {
        key: 'total_duration',
        defaultValue: 'Total Duration',
    },
    {
        key: 'total_employee',
        defaultValue: 'Total employees',
    },
    {
        key: 'total_grade',
        defaultValue: 'Total Grade',
    },
    {
        key: 'total_interviews',
        defaultValue: 'Total interviews',
    },
    {
        key: 'total_passapplicants',
        defaultValue: 'Total Pass Applicant',
    },
    {
        key: 'total_question_pack',
        defaultValue: 'Total Question Packs',
    },
    {
        key: 'total_questions',
        defaultValue: 'Total Questions',
    },
    {
        key: 'total_score',
        defaultValue: 'total score',
    },
    {
        key: 'total_time',
        defaultValue: 'Total time',
    },
    {
        key: 'transaction_amount',
        defaultValue: 'Total',
    },
    {
        key: 'transaction_date',
        defaultValue: 'Transaction Date',
    },
    {
        key: 'transaction_description',
        defaultValue: 'Description',
    },
    {
        key: 'transaction_info',
        defaultValue: 'More Info',
    },
    {
        key: 'transaction_method',
        defaultValue: 'Payment Method',
    },
    {
        key: 'transaction_receipt',
        defaultValue: 'View Receipt',
    },
    {
        key: 'transcription_ready_later',
        defaultValue: 'Transcript will be ready in several minutes',
    },
    {
        key: 'try_again',
        defaultValue: 'Try Again',
    },
    {
        key: 'type',
        defaultValue: 'Type',
    },
    {
        key: 'type_feedback',
        defaultValue: 'Feedback Type',
    },
    {
        key: 'type_question',
        defaultValue: 'Type',
    },
    {
        key: 'unlimited',
        defaultValue: 'Unlimited',
    },
    {
        key: 'unscheduled',
        defaultValue: 'Unscheduled',
    },
    {
        key: 'unselect',
        defaultValue: 'Unselect',
    },
    {
        key: 'untouched_cvs',
        defaultValue: 'CVs that need reconsideration',
    },
    {
        key: 'untouched_videos',
        defaultValue: 'Videos that need reconsideration',
    },
    {
        key: 'upcoming_interviews',
        defaultValue: 'Upcoming Interviews',
    },
    {
        key: 'update_button',
        defaultValue: 'Update',
    },
    {
        key: 'update_card',
        defaultValue: 'Update Card Information',
    },
    {
        key: 'update_cv',
        defaultValue: 'Update CV Info',
    },
    {
        key: 'updated_at',
        defaultValue: 'Updated at',
    },
    {
        key: 'updated_time',
        defaultValue: 'Updated at',
    },
    {
        key: 'upgrade_button',
        defaultValue: 'Upgrade Package',
    },
    {
        key: 'upgrade_title',
        defaultValue: 'Upgrade your package to unlock these features',
    },
    {
        key: 'upgrade_title2',
        defaultValue: 'Choose the right package for you',
    },
    {
        key: 'upload',
        defaultValue: 'Upload',
    },
    {
        key: 'upload_cv',
        defaultValue: 'Upload CV',
    },
    {
        key: 'upload_types',
        defaultValue: 'Drag, upload a video or record a new one',
    },
    {
        key: 'upload_types2',
        defaultValue: 'File size no larger than {{file_size}} MB',
    },
    {
        key: 'use_default_theme',
        defaultValue: 'Use RecruitGenius default theme',
    },
    {
        key: 'verbal',
        defaultValue: 'Video Response',
    },
    {
        key: 'verbal_explain',
        defaultValue: 'Give candidates the chance to visually express themselves and test their presentation skills',
    },
    {
        key: 'verify_account',
        defaultValue: 'Verify your account',
    },
    {
        key: 'verify_button',
        defaultValue: 'Send Verification Email',
    },
    {
        key: 'verify_code',
        defaultValue: 'Verification Code',
    },
    {
        key: 'verify_reminder',
        defaultValue: 'Please verify your email address for more Interviews and CV Parse quotas by clicking the button',
    },
    {
        key: 'verify_reminder',
        defaultValue: 'Please verify your email address for more Interviews and CV Parse quotas by clicking the button',
    },
    {
        key: 'verify_reminder',
        defaultValue: 'Please verify your email address for more Interviews and CV Parse quotas by clicking the button',
    },
    {
        key: 'verify_to_get_quota',
        defaultValue: 'Verify your account to get more quota',
    },
    {
        key: 'video_available',
        defaultValue: 'Video record available for this question',
    },
    {
        key: 'video_call_link',
        defaultValue: 'Video Call Link (Readonly)',
    },
    {
        key: 'video_interview',
        defaultValue: 'Video Interview',
    },
    {
        key: 'vietnamese',
        defaultValue: 'Vietnamese',
    },
    {
        key: 'view',
        defaultValue: 'View',
    },
    {
        key: 'view_all_slots',
        defaultValue: 'View all slots',
    },
    {
        key: 'view_card',
        defaultValue: 'Card',
    },
    {
        key: 'view_detail',
        defaultValue: 'View detail',
    },
    {
        key: 'view_edit_details',
        defaultValue: 'View/Edit detail',
    },
    {
        key: 'view_profile_check_verification',
        defaultValue: 'to view your profile and check your verification status',
    },
    {
        key: 'view_selected_slots',
        defaultValue: 'View only commonly selected slots',
    },
    {
        key: 'view_table',
        defaultValue: 'Table',
    },
    {
        key: 'view_transactions',
        defaultValue: 'View your past transactions',
    },
    {
        key: 'warning_dialog',
        defaultValue: 'Warning',
    },
    {
        key: 'warning_dialog2',
        defaultValue: 'Are you sure you want to remove this person from the project?',
    },
    {
        key: 'welcome_title',
        defaultValue: 'Welcome,',
    },
    {
        key: 'work_email',
        defaultValue: 'Work Email',
    },
    {
        key: 'work_experiences',
        defaultValue: 'Work experiences',
    },
    {
        key: 'workspace_email',
        defaultValue: "Workspace's email",
    },
    {
        key: 'workspace_information',
        defaultValue: "Workspace's information",
    },
    {
        key: 'workspace_title',
        defaultValue: 'Your Workspace',
    },
    {
        key: 'write_description',
        defaultValue: 'Type your question details here',
    },
    {
        key: 'write_description2',
        defaultValue: 'Write something',
    },
    {
        key: 'wrksp',
        defaultValue: 'Workspaces',
    },
    {
        key: 'wrksp_candidate',
        defaultValue: 'Talent Pool',
    },
    {
        key: 'wrksp_company',
        defaultValue: "Company's Name",
    },
    {
        key: 'wrksp_info',
        defaultValue: "Workspace's information",
    },
    {
        key: 'wrksp_info',
        defaultValue: "Workspace's information",
    },
    {
        key: 'wrksp_interview',
        defaultValue: 'Video Interviews',
    },
    {
        key: 'wrksp_invite',
        defaultValue: 'Invite user to this workspace',
    },
    {
        key: 'wrksp_list',
        defaultValue: 'List Workspace',
    },
    {
        key: 'wrksp_menu',
        defaultValue: 'Your workspace',
    },
    {
        key: 'wrksp_name',
        defaultValue: "Workspace's Name",
    },
    {
        key: 'wrksp_packs',
        defaultValue: 'Position Setting',
    },
    {
        key: 'wrksp_questions',
        defaultValue: 'Questions',
    },
    {
        key: 'wrksp_switch',
        defaultValue: 'Switch Workspace',
    },
    {
        key: 'wrksp_template',
        defaultValue: 'Email Templates',
    },
    {
        key: 'years_ago',
        defaultValue: 'years ago',
    },
    {
        key: 'your_acc',
        defaultValue: 'Your Account',
    },
    {
        key: 'your_comment',
        defaultValue: 'Your comment',
    },
    {
        key: 'your_screen',
        defaultValue: 'Your Screen',
    },
];
