import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { QuotaPackage, UserTransaction } from 'types/payment';
import api from './api';

interface PaymentState {
    isLoading: boolean;
    userTransaction: UserTransaction;
    quotaPackages: QuotaPackage[];
}

const initialState: PaymentState = {
    isLoading: false,
    userTransaction: null,
    quotaPackages: null,
};

const slice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setLoading: (state: PaymentState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        getAllQuotaPackages: (state: PaymentState, action: PayloadAction<QuotaPackage[]>) => {
            state.quotaPackages = action.payload;
        },
        updateUserTransaction: (state: PaymentState, action: PayloadAction<UserTransaction>) => {
            state.userTransaction = action.payload;
        },
    },
});

/**
 * Get all Quota packages
 */

export const getAllQuotaPackages =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.getAllQuotaPackages();
        dispatch(slice.actions.getAllQuotaPackages(response));
    };

/**
 * Get user transaction
 */

export const updateUserTransaction =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setLoading(true));
        const response: any = await api.getUserTransaction();
        dispatch(slice.actions.updateUserTransaction(response));
        dispatch(slice.actions.setLoading(false));
    };

export const { actions, reducer } = slice;
