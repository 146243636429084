import axiosClient from 'lib/axios';

const prefixUrl = 'flow';

const api = {
    /**
     * Get all Running flows
     * @param workspaceId
     */
    getAll: (workspaceId: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`);
    },
    /**
     * Get one Running flow
     * @param workspaceId
     */
    get: (workspaceId: string, flowId: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`);
    },

    /* Delete Running Flow  */
    delete: (workspaceId: string, flowId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`);
    },

    /* Activate/Deactivate a Running Flow  */
    update: (workspaceId: string, flowId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${flowId}/status`, request);
    },

    /* Change Flow Name  */
    changeFlowName: (workspaceId: string, flowId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`, request);
    },
};

export default api;
