import React, { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

interface RedirectOldLinkProps {
    type: 'AI_FLOW' | 'CANDIDATE';
}

const RedirectOldLink: FC<RedirectOldLinkProps> = ({ type }) => {
    const params = useParams();

    switch (type) {
        case 'AI_FLOW':
            return <Navigate to={`/workspace/${params.wp}/flows/advanced-flow/detail/${params.id}?tabValue=1`} replace />;
        case 'CANDIDATE':
            return <Navigate to={`/workspace/${params.wp}/candidates/candidate`} replace />;
    }
};

export default RedirectOldLink;
