import axiosClient from 'lib/axios';

const prefixUrl = 'flow-template';

const api = {
    /* Get all flow */
    getAll: (workspaceId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}`);
    },

    /* Get a flow */
    get: (workspaceId: string, flowId: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`);
    },

    /* Create a flow */
    create: (workspaceId: string, request: any) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /* Delete flow */
    delete: (workspaceId: string, flowId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${flowId}`);
    },
};

export default api;
