import { Autocomplete, Box, Button, Divider, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import HeadArrow from 'assets/icons/HeadArrow';
import api from 'features/Payment/api';
import { Form, Formik } from 'formik';
import { authSelector, workspaceSelector } from 'hooks/useSelector';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'store';
import { regionOptions } from 'utils/regionConstant';
import * as Yup from 'yup';

interface IContactInformationFormProps {
    contactRequest: any;
    onBack: () => void;
    onNext?: () => void;
}

const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const validate = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    workEmail: Yup.string().required().email(),
    phone: Yup.string().required().max(20, 'Max 20 characters').matches(phoneRegex, 'Enter valid phone number'),
    region: Yup.string().required(),
});

const ContactInformationForm: React.FunctionComponent<IContactInformationFormProps> = (props) => {
    const { onBack, onNext, contactRequest } = props;
    const { user } = useSelector(authSelector);
    const workspace = useSelector(workspaceSelector);

    const [regionSelected, setRegionSelected] = useState<any>(() => {
        const initCountryCode = workspace.activated?.defaultCountry;
        return regionOptions.find((region) => region.alpha2Code.toLowerCase() === initCountryCode?.toLowerCase());
    });
    const initialValues = {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        workEmail: user?.email || '',
        phone: user?.phoneNumber || '',
        region: workspace.activated?.defaultCountry || '',
    };
    const handleSubmit = async (values, { setErrors }) => {
        try {
            const minCompanySize = contactRequest.companySize.split('-')[0];
            const maxCompanySize = contactRequest.companySize.split('-')?.[1];
            const registerPremiumRequest: any = {
                firstName: values.firstName,
                lastName: values.lastName,
                workEmail: values.workEmail,
                phoneNumber: values.phone,
                countryCode: values.region,
                requestDescription: contactRequest.request,
                companyName: contactRequest.companyName,
                companySize: {
                    from: Number(minCompanySize),
                },
                jobTitle: contactRequest.jobTitle,
            };

            if (maxCompanySize) {
                registerPremiumRequest.companySize.to = Number(maxCompanySize);
            }

            await api.registerPremium(registerPremiumRequest);

            onNext();
        } catch (error) {
            console.log('The following error occurred', error);
            toast.error(error?.message || 'Some thing went wrong');
        }
    };

    return (
        <Box>
            <Formik initialValues={initialValues} validationSchema={validate} onSubmit={handleSubmit}>
                {({ values, errors, touched, isSubmitting, handleChange, handleBlur, setFieldValue, setErrors }) => {
                    return (
                        <Form>
                            <Box sx={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'row nowrap',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        width: 'fit-content',
                                    }}
                                    onClick={onBack}
                                >
                                    <HeadArrow
                                        sx={{ color: 'grey.400', transform: 'rotate(180deg)', height: 15, width: 15, mt: '2px', mr: 1 }}
                                        fontSize="small"
                                    />

                                    <Typography variant="subtitle1" color="textSecondary" fontWeight="600" sx={{ display: 'inline' }}>
                                        Back
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="h5" color="primary.main">
                                Contact our Sales team
                            </Typography>
                            <Typography variant="subtitle2" fontWeight="600" sx={{ mb: 1 }}>
                                Please confirm your contact information!
                            </Typography>
                            <Grid container>
                                <Grid item xs={8} container spacing={2}>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                            First Name*
                                        </Typography>
                                        <TextField
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                handleBlur(e);
                                                setFieldValue(e.target.name, e.target.value?.trim());
                                            }}
                                            error={Boolean(touched.firstName && errors.firstName)}
                                            helperText={touched.firstName && errors.firstName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                            Last Name*
                                        </Typography>
                                        <TextField
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                handleBlur(e);
                                                setFieldValue(e.target.name, e.target.value?.trim());
                                            }}
                                            error={Boolean(touched.lastName && errors.lastName)}
                                            helperText={touched.lastName && errors.lastName}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                            Work Email*
                                        </Typography>
                                        <TextField
                                            name="workEmail"
                                            value={values.workEmail}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                handleBlur(e);
                                                setFieldValue(e.target.name, e.target.value?.trim());
                                            }}
                                            error={Boolean(touched.workEmail && errors.workEmail)}
                                            helperText={touched.workEmail && errors.workEmail}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                            Phone Number*
                                        </Typography>
                                        <TextField
                                            name="phone"
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                handleBlur(e);
                                                setFieldValue(e.target.name, e.target.value?.trim());
                                            }}
                                            placeholder="e.g. +1(123)456-7890"
                                            error={Boolean(touched.phone && errors.phone)}
                                            helperText={touched.phone && errors.phone}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Typography variant="subtitle2" fontWeight="600" sx={{ mt: 2, mb: 1 }}>
                                            Country or region*
                                        </Typography>
                                        <Autocomplete
                                            value={regionSelected}
                                            options={regionOptions}
                                            groupBy={(option) => option.group}
                                            getOptionLabel={(option) => option.Country}
                                            isOptionEqualToValue={(option, value) => option.alpha2Code === value.alpha2Code}
                                            onChange={(_, region) => {
                                                setRegionSelected(region);
                                                setFieldValue('region', region?.alpha2Code);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="region"
                                                    error={Boolean(touched.region && errors.region)}
                                                    helperText={touched.region && errors.region}
                                                    onBlur={(e) => {
                                                        handleBlur(e);
                                                        setFieldValue(e.target.name, e.target.value?.trim());
                                                    }}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    value={values.region}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
                                        <img
                                            src="/static/images/payment/ContactSale.png"
                                            style={{ width: 'min(250px, 80%)', objectFit: 'contain', margin: 'auto' }}
                                            alt=""
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{ my: 4 }}>
                                <Box sx={{ my: 2, mx: 3, pb: 1 }} display={isSubmitting ? '' : 'none'}>
                                    <LinearProgress />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', textAlign: 'right' }}>
                                    <Button type="submit" variant="contained" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ContactInformationForm;

