import axiosClient from 'lib/axios';
import { QuestionIdsPack } from 'types/questionsPack';

const prefixUrl = 'question-packs';

const api = {
    /* Get all questionPacks */
    getAll: (workspaceId: string): Promise<QuestionIdsPack> => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`);
    },

    /* Get one question Pack using ID */
    getQuestionIdsPack: (workspaceId: string, positonId: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/${positonId}`);
    },

    /* Create Workspace Question Pack */
    create: (workspaceId: string, request: any) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}`, request);
    },

    /* Update Workspace Question Packs */
    update: (workspaceId: string, questionPackId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${questionPackId}`, request);
    },

    /* Update Status */
    status: (workspaceId: string, questionPackId: string, status: boolean) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${questionPackId}/status`, {
            status: status ? 'active' : 'deactive',
        });
    },

    /* Delete Workspace Question Pack  */
    delete: (workspaceId: string, questionPackId: string) => {
        return axiosClient.delete(`workspaces/${workspaceId}/${prefixUrl}/${questionPackId}`);
    },
};

export default api;
