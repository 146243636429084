import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleFilled from 'assets/icons/CheckCircleFilled';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { FC } from 'react';
import { QuotaPackage } from 'types/payment';

interface IStandardPaymentProps {
    quotaPackage: QuotaPackage;
    onSelectPackage: (packageId: string) => void;
    type: 'normal' | 'special';
}

const StandardPayment: FC<IStandardPaymentProps> = (props) => {
    const { quotaPackage, onSelectPackage, type } = props;
    const translatedWords = useApplyTranslation();

    const payment = {
        title: `${translatedWords.standard_package}`,
        primaryColor: '#0C6ABF',
        prices: '49',
        benefit: [
            `${translatedWords.offering1}`,
            `${translatedWords.offering2}`,
            `${translatedWords.offering3}`,
            `${translatedWords.offering4}`,
        ],
    };

    const useStyles = makeStyles({
        root: {
            '&:hover span': {
                backgroundColor: payment.primaryColor,
            },
            '&:hover button > span': { backgroundColor: 'transparent' },
        },
    });
    const classes = useStyles();

    return (
        <Card
            className={classes.root}
            sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                border: `2px solid ${payment.primaryColor}`,
                height: '100%',
            }}
        >
            <Typography
                textAlign="center"
                variant="h6"
                sx={{ mt: '45px', color: payment.primaryColor, fontSize: '20px', fontWeight: 'bold' }}
            >
                {payment.title}
            </Typography>
            <CardContent>
                <Box sx={{ minHeight: '100px' }}>
                    <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ transform: 'translateY(-10px)' }}>
                            <img
                                src="/static/images/payment/dollar.png"
                                alt=""
                                style={{ width: '11px', height: '19px', objectFit: 'contain' }}
                            />
                        </Box>
                        <Typography fontSize="40px" fontWeight="600" sx={{ mx: 1 }}>
                            {payment.prices}
                        </Typography>
                        <Typography color="textSecondary" fontWeight="600" fontSize="16px" sx={{ transform: 'translateY(20px)' }}>
                            per
                            <br />
                            package
                        </Typography>
                    </Box>
                </Box>
                {type === 'normal' && (
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: payment.primaryColor, fontSize: '15px', mb: 1 }}
                            onClick={() => {
                                onSelectPackage(quotaPackage.id);
                            }}
                            disabled={!quotaPackage}
                        >
                            {translatedWords.buynow_button}
                        </Button>
                    </Box>
                )}
                <Stack>
                    {payment.benefit.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', minWidth: '80%', mt: 1 }}>
                            <Box sx={{ mx: 4, display: 'flex' }}>
                                <CheckCircleFilled fontSize="small" sx={{ color: payment.primaryColor, m: 'auto' }} />
                            </Box>
                            <Typography fontWeight="600" fontSize="12px">
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            </CardContent>
            {type === 'special' && (
                <Box sx={{ width: '100%', textAlign: 'center', flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                    <Box
                        sx={{
                            fontSize: '15px',
                            width: '100%',
                            py: 2,
                            fontWeight: '600',
                            color: '#000',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (!quotaPackage) return;
                            onSelectPackage(quotaPackage.id);
                        }}
                        component="span"
                    >
                        {translatedWords.choose_button}
                    </Box>
                </Box>
            )}
        </Card>
    );
};

export default StandardPayment;

