import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Dialog, Divider, Drawer, Link, Theme, Tooltip, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import LightingIcon from 'assets/icons/LightingIcon';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { authSelector, workspaceSelector } from 'hooks/useSelector';
import PaymentDialog from 'components/payment/PaymentDialog';
import Scrollbar from 'components/Scrollbar';
import NavSection from 'layout/components/NavSection';
import { menus } from 'menu';
import layoutSlice from './slice';

interface DashboardSidebarProps {
    onMobileClose: () => void;
    openMobile: boolean;
}

export const SIDEBARSCROLLBAR = 'SideBarScrollBar';

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
    const { onMobileClose, openMobile } = props;
    const location = useLocation();
    const auth = useSelector(authSelector);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const workspace = useSelector(workspaceSelector);
    const dispatch = useDispatch();
    const translatedWords = useApplyTranslation();

    const handleToggleWorkspace = (event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch(layoutSlice.actions.toggleModalWorkspace(true));
    };

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    // Handle open payment dialog
    const [openPayment, setOpenPayment] = useState(false);
    const handlOpenPayment = () => {
        setOpenPayment(true);
    };
    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    // Add button upgrade plan into General menu
    const listMenu = menus(workspace?.activated?.id);

    const content = (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Scrollbar options={{ suppressScrollX: true }} id={SIDEBARSCROLLBAR}>
                    <Box sx={{ p: 2 }}>
                        <RouterLink to={`/workspace/${workspace?.activated?.id}`}>
                            <Box sx={{ height: 40, '& > img': { height: '100%', width: 'auto' } }}>
                                <img alt="Application Logo" src="/static/blue-full.svg" />
                            </Box>
                        </RouterLink>
                    </Box>

                    <Box sx={{ p: 2, pt: 1 }}>
                        <Box
                            sx={{
                                alignItems: 'center',
                                backgroundColor: 'primary.main',
                                borderRadius: 1,
                                p: 2,
                            }}
                        >
                            <Box color="primary.contrastText" sx={{ textAlign: 'left' }}>
                                <Tooltip title={workspace.activated?.name ?? 'name'} placement="right" arrow>
                                    <Typography variant="body2" noWrap gutterBottom>
                                        {translatedWords.wrksp_name}: <b>{workspace.activated?.name}</b>
                                    </Typography>
                                </Tooltip>
                                <Tooltip title={workspace.activated?.company ?? 'company'} placement="right" arrow>
                                    <Typography variant="body2" noWrap gutterBottom>
                                        {translatedWords.wrksp_company}: <b>{workspace.activated?.company}</b>
                                    </Typography>
                                </Tooltip>
                                <Tooltip title={workspace.activated?.email ?? 'email'} placement="right" arrow>
                                    <Typography variant="body2" noWrap gutterBottom>
                                        {translatedWords.email}: <b>{workspace.activated?.email}</b>
                                    </Typography>
                                </Tooltip>

                                <Link
                                    display="block"
                                    color="primary.contrastText"
                                    textAlign="right"
                                    variant="caption"
                                    href="#"
                                    onClick={handleToggleWorkspace}
                                    marginTop={1}
                                >
                                    ({translatedWords.wrksp_switch})
                                </Link>
                            </Box>
                        </Box>
                    </Box>

                    <Divider />

                    <Box sx={{ px: 2 }}>
                        {listMenu.map((section, index) => (
                            <NavSection key={index} pathname={location.pathname} {...section} />
                        ))}
                    </Box>
                </Scrollbar>

                <Divider />

                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="accent"
                        sx={{
                            width: '100%',
                            py: 1.5,
                            borderRadius: 5,
                            '&:hover': {
                                filter: 'brightness(1.1)',
                            },
                        }}
                        onClick={handlOpenPayment}
                        startIcon={<LightingIcon />}
                    >
                        {translatedWords[auth.user?.payment ? `buymore_button` : `upgrade_button`]}
                    </Button>
                </Box>
            </Box>

            <Dialog
                open={openPayment}
                onClose={(e, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClosePayment();
                    }
                }}
                disableEscapeKeyDown
                fullWidth
                maxWidth="lg"
            >
                <PaymentDialog onClose={handleClosePayment} />
            </Dialog>
        </>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: { backgroundColor: 'background.paper', height: '100% !important', width: 280 },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            PaperProps={{ sx: { backgroundColor: 'background.paper', width: 280 } }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default DashboardSidebar;

