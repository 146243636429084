/* eslint-disable array-callback-return */
import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Stack, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { Link as RouterLink } from 'react-router-dom';

import { useDispatch, useSelector } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import useMounted from 'hooks/useMounted';
import { draftFlowSelector, workspaceSelector } from 'hooks/useSelector';
import ConfirmModal from 'components/ConfirmModal';
import DashedArrow from 'components/DashedArrow';
import StepInfoBox from 'components/StepInfoBox';
import { deleteFlow, listFlows, updateFlowStatus } from '../slice';

const ListDraftFlow: FC = () => {
    const workspace = useSelector(workspaceSelector);
    const flowLists = useSelector(draftFlowSelector);
    const dispatch = useDispatch();
    const mounted = useMounted();

    // fetch Flows Data
    const [loadingFetchData, setLoadingFetchData] = useState(true);

    useEffect(() => {
        const getFlowsData = async (workspaceId: string) => {
            try {
                await dispatch(listFlows(workspaceId));
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingFetchData(false);
            }
        };

        if (workspace?.activated?.id) {
            getFlowsData(workspace.activated.id);
        }
    }, [workspace?.activated?.id]);

    // handle  Delete Flows
    const [openConfirm, setOpenConfirm] = useState(false);
    const [deleteFlowId, setDeleteFlowId] = useState('');

    const handleShowDeleteModals = (flowId) => {
        setOpenConfirm(true);
        setDeleteFlowId(flowId);
    };

    // Handle Activate Flow

    const [loadingActivate, setLoadingActivate] = useState(false);
    const activateFlowStatus = async (flowId) => {
        try {
            const request = {
                active: true,
            };
            setLoadingActivate(true);

            await dispatch(updateFlowStatus(workspace?.activated?.id, flowId, request));
            if (mounted.current) {
                toast.success('Successfully Activate Flow');
                setLoadingActivate(false);
            }
        } catch (error) {
            if (error.statusCode === 403) {
                toast.error('You do not have permission to perform this action on this workspace');
                setLoadingActivate(false);
            } else {
                toast.error(`Error: ${error.message}`);
                setLoadingActivate(false);
            }
        }
    };

    // Handle Deactivate Flow
    const [loadingDeActivate, setLoadingDeactivate] = useState(false);

    const deActivateFlowStatus = async (flowId) => {
        try {
            const request = {
                active: false,
            };
            setLoadingDeactivate(true);

            await dispatch(updateFlowStatus(workspace?.activated?.id, flowId, request));
            if (mounted.current) {
                toast.success('Successfully Deactivate Flow');
                setLoadingDeactivate(false);
            }
        } catch (error) {
            if (error.statusCode === 403) {
                toast.error('You do not have permission to perform this action on this workspace');
                setLoadingDeactivate(false);
            } else {
                toast.error(`Error: ${error.message}`);
                setLoadingDeactivate(false);
            }
        }
    };

    const handleDeleteFlows = async () => {
        try {
            await dispatch(deleteFlow(workspace?.activated?.id, deleteFlowId));

            if (mounted.current) {
                toast.success('Success');
            }
        } catch (error) {
            if (error.statusCode === 403) {
                toast.error('You do not have permission to view candidates on this Workspace.');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        }
        setOpenConfirm(false);
    };

    const translatedWords = useApplyTranslation();

    return (
        <>
            {loadingFetchData && (
                <Box sx={{ mt: 3 }} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Box>
            )}
            {flowLists.flows.length === 0 && !loadingFetchData && (
                <Box sx={{ mt: 5 }} style={{ textAlign: 'center' }}>
                    <Typography variant="button">
                        {translatedWords.reminder_2} <br />
                        {translatedWords.reminder_3}
                    </Typography>
                </Box>
            )}

            {!loadingFetchData && (
                <Box sx={{ mt: 3, ml: 1 }}>
                    {flowLists.flows.map((v, index) => {
                        const isAlreadySetup =
                            v?.applyCollection?.draft === false &&
                            (!v?.filter?.enable || v?.filter?.draft === false) &&
                            (!v?.autoInterview?.enable || (v?.autoInterview?.draft === false && v?.scoreAutoInterview?.draft === false));

                        return (
                            <Card key={index} style={{ width: '100%', overflowX: 'scroll' }} sx={{ mb: 2, mt: 2 }}>
                                <CardHeader title={v?.name} />
                                <Divider />
                                <CardContent>
                                    <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                                        <Box
                                            sx={{
                                                flexGrow: 1,
                                                overflowX: 'auto',
                                                '&::-webkit-scrollbar': {
                                                    height: '0.5em',
                                                },
                                            }}
                                        >
                                            <Grid
                                                container
                                                sx={{
                                                    display: 'flex',
                                                    flexWrap: 'nowrap',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box>
                                                    <StepInfoBox title={translatedWords.CV_collect} step={1} />
                                                </Box>
                                                <DashedArrow />
                                                <Box>
                                                    <StepInfoBox title={translatedWords.auto_filter} step={2} />
                                                </Box>
                                                <DashedArrow />
                                                <Box>
                                                    <StepInfoBox title={translatedWords.video_interview} step={3} />
                                                </Box>
                                                <DashedArrow />
                                                <Box>
                                                    <StepInfoBox title={translatedWords.human_score} step={4} />
                                                </Box>
                                                <DashedArrow />
                                                <Box>
                                                    <StepInfoBox title={translatedWords.Di_Inter_Schedule} step={5} />
                                                </Box>
                                                <DashedArrow />
                                                <Box>
                                                    <StepInfoBox title={'Offer Board'} step={6} />
                                                </Box>
                                            </Grid>
                                        </Box>

                                        <Stack direction="column" justifyContent="center" alignItems="center" gap={1} width={180}>
                                            {v.status === 'inactive' ? (
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    fullWidth
                                                    onClick={() => activateFlowStatus(v.id)}
                                                    disabled={loadingActivate || !isAlreadySetup}
                                                >
                                                    Activate
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    fullWidth
                                                    onClick={() => deActivateFlowStatus(v.id)}
                                                    disabled={loadingDeActivate}
                                                >
                                                    Deactivate
                                                </Button>
                                            )}
                                            <Button
                                                component={RouterLink}
                                                to={`/workspace/${workspace?.activated?.id}/flows/draft-advanced-flow/customize/${v.id}`}
                                                variant="outlined"
                                                color="warning"
                                                fullWidth
                                            >
                                                Customize
                                            </Button>
                                            <Button onClick={() => handleShowDeleteModals(v.id)} variant="outlined" color="error" fullWidth>
                                                Delete
                                            </Button>
                                        </Stack>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                    <ConfirmModal
                        open={openConfirm}
                        onClose={() => setOpenConfirm(false)}
                        onConfirm={handleDeleteFlows}
                        title={translatedWords.warning_dialog}
                        content={translatedWords.confirm_delete_draft_flow}
                    />
                </Box>
            )}
        </>
    );
};

export default ListDraftFlow;

