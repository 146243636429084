import axiosClient from 'lib/axios';
import { GetTicketResponse } from './types';


const api = {
    /**
     * Get  Dashboard Kaban board's ticket base on todoType
     * @param workspaceId
     * @param todoType
     */
    getKabanBoardTickets: (workspaceId: string, todoType: number) => {
        return axiosClient.get<any, GetTicketResponse>(`workspaces/${workspaceId}/todo?todoType=${todoType}`);
    },

    /**
     * Get  Dashboard Kaban board's ticket
     * @param workspaceId
     */
    changeTicketStatusFromTodoToProcessing: (workspaceId: string, id: string) => {
        return axiosClient.patch(`workspaces/${workspaceId}/todo/status/${id}`);
    },
};

export default api;

