import { scheduleApi } from 'features/ScheduleInterview/api';
import useMounted from 'hooks/useMounted';
import { workspaceSelector } from 'hooks/useSelector';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'store';
import { QueryPollingByTimeReq, QueryPollingReq, SchedulePolling } from 'types/interviewSchedule';

export interface PollingAction {
    getPollings: (workspaceId: string, queries: QueryPollingByTimeReq[]) => Promise<void>;
    addPolling: (polling: SchedulePolling) => void;
    updatePolling: (pollingId: string, update: SchedulePolling) => void;
    deletePolling: (pollingId: string) => void;
}

export const useGetPollingBaseOnTime = (
    query: QueryPollingReq
): {
    schedulePollings: SchedulePolling[];
    loading: boolean;
    actions: PollingAction;
} => {
    const mounted = useMounted();

    const [pollings, setPollings] = useState<SchedulePolling[]>([]);
    const [loading, setLoading] = useState(false);

    const handleGetPollings = async (workspaceId: string, queries: QueryPollingByTimeReq[]) => {
        try {
            setLoading(true);
            const promiseArr = queries.map((query) => {
                if (!query?.progress) return null;
                return scheduleApi.getPollingBaseOnTimes(workspaceId, query);
            });

            const responseArr = await Promise.all(promiseArr);
            if (mounted.current) {
                let newPollings = [];
                responseArr.forEach((response) => {
                    if (Array.isArray(response)) newPollings = newPollings.concat(response);
                });
                setPollings(newPollings);
            }
        } catch (error) {
            toast.error(error.message);
            console.dir(error);
        } finally {
            setLoading(false);
        }
    };

    const actions: PollingAction = {
        getPollings: handleGetPollings,
        addPolling: (polling: SchedulePolling) => {
            setPollings((prev) => [polling, ...prev]);
        },
        updatePolling: (pollingId: string, update: SchedulePolling) => {
            setPollings((prev) => {
                const newPollings = [...prev];
                return newPollings.map((_polling) => (_polling.id === pollingId ? { ..._polling, ...update } : _polling));
            });
        },
        deletePolling: (pollingId: string) => {
            setPollings((prev) => prev.filter((_polling) => _polling.id !== pollingId));
        },
    };

    return {
        schedulePollings: pollings,
        loading,
        actions,
    };
};
