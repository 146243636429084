import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Pagination } from 'types/general';
import { PollingProgress, SchedulePolling } from 'types/interviewSchedule';
interface DirectInterviewState {
    [PollingProgress.UNSCHEDULE]: {
        page: number;
        limit: number;
        pagination: Pagination;
        pollings: SchedulePolling[];
    };
    [PollingProgress.PINVITED]: {
        page: number;
        limit: number;
        pagination: Pagination;
        pollings: SchedulePolling[];
    };
    [PollingProgress.PCONFIRMED]: {
        page: number;
        limit: number;
        pagination: Pagination;
        pollings: SchedulePolling[];
    };
    [PollingProgress.CAINVITED]: {
        page: number;
        limit: number;
        pagination: Pagination;
        pollings: SchedulePolling[];
    };
    [PollingProgress.CACONFIRMED]: {
        page: number;
        limit: number;
        pagination: Pagination;
        pollings: SchedulePolling[];
    };
    [PollingProgress.LOCKED]: {
        rangeFrom: number;
        rangeTo: number;
        pollings: SchedulePolling[];
    };
}

const initialState: DirectInterviewState = {
    unschedule: {
        page: 1,
        limit: 10,
        pagination: { page: 1, limit: 10, totalCount: 0, totalPages: 0 },
        pollings: [],
    },
    pInvited: {
        page: 1,
        limit: 10,
        pagination: { page: 1, limit: 10, totalCount: 0, totalPages: 0 },
        pollings: [],
    },
    pConfirmed: {
        page: 1,
        limit: 10,
        pagination: { page: 1, limit: 10, totalCount: 0, totalPages: 0 },
        pollings: [],
    },
    caInvited: {
        page: 1,
        limit: 10,
        pagination: { page: 1, limit: 10, totalCount: 0, totalPages: 0 },
        pollings: [],
    },
    caConfirmed: {
        page: 1,
        limit: 10,
        pagination: { page: 1, limit: 10, totalCount: 0, totalPages: 0 },
        pollings: [],
    },
    locked: {
        rangeFrom: Date.now(),
        rangeTo: Date.now(),
        pollings: [],
    },
};

const slice = createSlice({
    name: 'directInterview',
    initialState,
    reducers: {
        getPollings: (state: DirectInterviewState, action: PayloadAction<{ pollings: SchedulePolling[]; progress: PollingProgress }>) => {
            const { pollings, progress } = action.payload;
            if (state[progress]) state[progress].pollings = pollings;
        },
        setPage: (state: DirectInterviewState, action: PayloadAction<{ progress: PollingProgress; page: number }>) => {
            const { progress, page } = action.payload;
            if (state[progress]) state[progress].page = page;
        },
        setLimit: (state: DirectInterviewState, action: PayloadAction<{ progress: PollingProgress; limit: number }>) => {
            const { progress, limit } = action.payload;
            if (state[progress]) state[progress].limit = limit;
        },
        setPagination: (state: DirectInterviewState, action: PayloadAction<{ progress: PollingProgress; pagination: Pagination }>) => {
            const { pagination, progress } = action.payload;
            if (state[progress]) state[progress].pagination = pagination;
        },
    },
});

export const { actions, reducer } = slice;
