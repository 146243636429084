import { authSelector } from 'hooks/useSelector';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'store';

interface GuestGuardProps {
    children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
    const { isAuthenticated } = useSelector(authSelector);
    const currentLocation = window.location.href;
    const url = new URL(currentLocation);
    const workspaceId = url.searchParams.get('workspaceId');
    const action = url.searchParams.get('action');

    if (isAuthenticated) {
        if (action === 'accept-owner') {
            return <Navigate to={`/authentication/${action}?workspaceId=${workspaceId}`} />;
        }
        return <Navigate to="/" />;
    }

    return <>{children}</>;
};

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default GuestGuard;
