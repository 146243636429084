import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleFilled from 'assets/icons/CheckCircleFilled';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { FC } from 'react';
import { QuotaPackage } from 'types/payment';

interface IPreferPaymentProps {
    quotaPackage: QuotaPackage;
    type: 'normal' | 'special';
    onSelectPackage: (packageId: string) => void;
}

const PreferPayment: FC<IPreferPaymentProps> = (props) => {
    const { quotaPackage, onSelectPackage, type } = props;
    const translatedWords = useApplyTranslation();
    const payment = {
        title: `${translatedWords.combo_package1}`,
        primaryColor: '#3ABB91',
        darkColor: '#2e9976',
        prices: '98',
        benefit: [
            `${translatedWords.offering5}`,
            `${translatedWords.offering6}`,
            `${translatedWords.offering3}`,
            `${translatedWords.offering4}`,
        ],
    };
    const useStyles = makeStyles({
        root: {
            '&:hover span': {
                backgroundColor: payment.primaryColor,
            },
            '&:hover button > span': { backgroundColor: 'transparent' },
        },
    });

    const classes = useStyles();

    return (
        <Card
            className={classes.root}
            sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                border: `2px solid ${payment.primaryColor}`,
                height: 'calc(100% + 20px)',
                transform: 'translateY(-40px)',
                mt: '20px',
            }}
        >
            <Box sx={{ height: '16px', width: '100%', backgroundColor: payment.primaryColor }}>
                <Typography fontSize="10px" color="#fff" textAlign="center" fontWeight="600">
                    {translatedWords.recommended}
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <img
                    src="/static/images/payment/star.png"
                    alt=""
                    style={{ width: '45px', height: '45px', objectFit: 'contain', transform: 'translateY(-2px)' }}
                />
            </Box>
            <Typography variant="h6" textAlign="center" sx={{ color: payment.primaryColor, fontSize: '20px', fontWeight: 'bold' }}>
                {payment.title}
            </Typography>
            <CardContent>
                <Box sx={{ minHeight: '100px', position: 'relative' }}>
                    <Box sx={{ position: 'absolute', top: '-10%', right: '5%', width: 'fit-content', height: '55px' }}>
                        <img
                            src="/static/images/payment/discount.png"
                            alt=""
                            style={{ width: '55px', height: '100%', objectFit: 'contain' }}
                        />
                        <Typography
                            color="#fff"
                            fontWeight="600"
                            fontSize="12px"
                            textAlign="center"
                            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                        >
                            {translatedWords.save_49}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ transform: 'translateY(-10px)' }}>
                            <img
                                src="/static/images/payment/dollar.png"
                                alt=""
                                style={{ width: '11px', height: '19px', objectFit: 'contain' }}
                            />
                        </Box>
                        <Typography fontSize="40px" fontWeight="600" sx={{ mx: 1 }}>
                            {payment.prices}
                        </Typography>
                        <Typography color="textSecondary" fontWeight="600" fontSize="16px" sx={{ transform: 'translateY(20px)' }}>
                            for 3
                            <br />
                            standard
                            <br />
                            packages
                        </Typography>
                    </Box>
                </Box>
                {type === 'normal' && (
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: payment.primaryColor,
                                fontSize: '15px',
                                '&:hover': { backgroundColor: payment.darkColor },
                                mb: 1,
                            }}
                            onClick={() => {
                                onSelectPackage(quotaPackage.id);
                            }}
                            disabled={!quotaPackage}
                        >
                            {translatedWords.buynow_button}
                        </Button>
                    </Box>
                )}
                <Stack>
                    {payment.benefit.map((item, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', minWidth: '80%', mt: 1 }}>
                            <Box sx={{ mx: 4, display: 'flex' }}>
                                <CheckCircleFilled fontSize="small" sx={{ color: payment.primaryColor, m: 'auto' }} />
                            </Box>
                            <Typography fontWeight="600" fontSize="12px">
                                {item}
                            </Typography>
                        </Box>
                    ))}
                </Stack>
            </CardContent>
            {type === 'special' && (
                <Box sx={{ width: '100%', textAlign: 'center', flex: 1, display: 'flex', alignItems: 'flex-end' }}>
                    <Box
                        sx={{
                            fontSize: '15px',
                            width: '100%',
                            py: 2,
                            fontWeight: '600',
                            color: '#000',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            if (!quotaPackage) return;
                            onSelectPackage(quotaPackage.id);
                        }}
                        component="span"
                    >
                        {translatedWords.choose_button}
                    </Box>
                </Box>
            )}
        </Card>
    );
};

export default PreferPayment;

