import { Avatar, Box, Button, CircularProgress, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import Calendar from 'assets/icons/Calendar';
import { NotificationContext } from 'context/notification';
import { workspaceSelector } from 'hooks/useSelector';
import { ElementType, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'store';
import { Notification, NotificationType } from 'types/notification';
import InfiniteScroll from 'react-infinite-scroll-component';

export const iconsMap: Record<NotificationType, ElementType> = {
    [NotificationType.CANDIDATE]: ContactMailIcon,
    [NotificationType.POLLING]: Calendar,
    [NotificationType.VIDEO_INTERVIEW]: ImportantDevicesIcon,
};

interface INotificationListProps {
    notifications: Notification[];
    onClose: () => void;
}

const NotificationList: React.FunctionComponent<INotificationListProps> = (props) => {
    const { notifications, onClose } = props;
    const workspace = useSelector(workspaceSelector);

    const { markAllRead, markNotificationRead, fetchMoreNotification, pagination } = useContext(NotificationContext) || {};
    const navigate = useNavigate();
    return (
        <>
            {notifications.length === 0 ? (
                <Box sx={{ p: 2 }}>
                    <Typography color="textPrimary" variant="subtitle2" textAlign="center">
                        There are no notifications
                    </Typography>
                </Box>
            ) : (
                <>
                    <List disablePadding>
                        <InfiniteScroll
                            dataLength={notifications.length}
                            next={() => {
                                fetchMoreNotification(workspace.activated?.id);
                            }}
                            hasMore={pagination.page < pagination.totalPages}
                            loader={
                                <Box
                                    sx={{
                                        minHeight: '100px',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CircularProgress />
                                </Box>
                            }
                            endMessage={
                                <Box sx={{ minHeight: '30vh', display: 'flex' }}>
                                    <Typography variant="body1" sx={{ m: 'auto' }}>
                                        You have seen it all
                                    </Typography>
                                </Box>
                            }
                            scrollableTarget="notification_container"
                        >
                            {notifications.map((notification) => {
                                const Icon = iconsMap[notification.notiType];

                                return (
                                    <ListItem key={notification.id} divider sx={{ opacity: notification.isRead ? 0.8 : 1 }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    backgroundColor: 'primary.main',
                                                    color: 'primary.contrastText',
                                                }}
                                            >
                                                {Icon && <Icon fontSize="small" />}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <Link
                                                    color="textPrimary"
                                                    sx={{ cursor: 'pointer' }}
                                                    underline="hover"
                                                    variant="subtitle2"
                                                    onClick={() => {
                                                        markNotificationRead(workspace.activated?.id, notification.id);
                                                        if (notification.link) {
                                                            navigate(notification.link, { replace: true });
                                                        }
                                                        onClose();
                                                    }}
                                                >
                                                    {notification.content}
                                                </Link>
                                            }
                                        />

                                        <Box
                                            sx={{
                                                width: '15px',
                                                height: '15px',
                                                borderRadius: '999px',
                                                backgroundColor: (theme) =>
                                                    notification.isRead ? 'transparent' : theme.palette.primary.main,
                                                flexShrink: 0,
                                            }}
                                        />
                                    </ListItem>
                                );
                            })}
                        </InfiniteScroll>
                    </List>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            p: 1,
                        }}
                    >
                        <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => {
                                markAllRead(workspace.activated?.id);
                            }}
                        >
                            Mark all as read
                        </Button>
                    </Box>
                </>
            )}
        </>
    );
};

export default NotificationList;

