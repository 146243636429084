import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { AppThunk } from 'store';
import { Interview } from 'types/interview';
import api from './api';

interface InterviewState {
    interviews: Interview[];
    samePositionInterviews: Interview[];
    isLoading: boolean;
    error: boolean;
}

const initialState: InterviewState = {
    interviews: [],
    samePositionInterviews: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'interview',
    initialState,
    reducers: {
        setLoading: (state: InterviewState, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },

        gets: (state: InterviewState, action: PayloadAction<Interview[]>) => {
            state.interviews = action.payload;
            state.isLoading = false;
        },

        getSamePositionInterviews: (state: InterviewState, action: PayloadAction<Interview[]>) => {
            state.samePositionInterviews = action.payload;
            state.isLoading = false;
        },

        create: (state: InterviewState, action: PayloadAction<Interview[]>) => {
            state.interviews.unshift(...action.payload);
            state.isLoading = false;
        },
        update: (state: InterviewState, action: PayloadAction<Interview>) => {
            const updatedInterview = action.payload;
            const updatedIndex = state.interviews.findIndex((interview) => interview.id === updatedInterview.id);

            state.interviews[updatedIndex] = updatedInterview;
        },
    },
});

export const setLoading =
    (isLoading: boolean): AppThunk =>
    (dispatch): void => {
        dispatch(slice.actions.setLoading(isLoading));
    };

/**
 * Get All Interviews
 * @param workspaceId
 * @param page
 * @param limit
 * @returns
 */
export const getInterviews =
    (workspaceId: string, page: number = 1, limit: number = 10, progress: string = null): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            dispatch(setLoading(true));
            const response: any = await api.getAll(workspaceId, page, limit, progress);
            dispatch(slice.actions.gets(response.interviews));
            return response;
        } catch (error) {
            dispatch(setLoading(false));

            throw error;
        }
    };

export const getSamePositionInterviews =
    (workspaceId: string, page: number = 1, limit: number = 10, progress: string = null, position: string = null): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            dispatch(setLoading(true));
            const response: any = await api.getAll(workspaceId, page, limit, progress, position);
            dispatch(slice.actions.getSamePositionInterviews(response.interviews));
            return response;
        } catch (error) {
            dispatch(setLoading(false));

            throw error;
        }
    };

export const searchInterviews =
    (workspaceId: string, keyword: string): AppThunk =>
    async (dispatch): Promise<any> => {
        try {
            dispatch(setLoading(true));

            const response: any = await api.search(workspaceId, keyword);
            dispatch(slice.actions.gets(response));
            return response;
        } catch (error) {
            dispatch(setLoading(false));

            throw error;
        }
    };

/**
 * Create Interview
 * @param workspaceId
 * @param request
 * @returns
 */
export const createInterview =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.create(workspaceId, request);
        return response;
    };

/**
 * Send Email Interview
 * @param request
 * @returns
 */
export const sendEmailInterview =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch, getState): Promise<any> => {
        try {
            const response: any = await api.sendEmail(workspaceId, request);
            const { interviews } = getState().interview;

            const updatedInterview = interviews?.find((interview) => interview.id === request.interviewId);
            if (updatedInterview) {
                const newInterview = cloneDeep(updatedInterview);
                if (!Array.isArray(newInterview.sendEmail)) newInterview.sendEmail = [];
                newInterview.sendEmail.push({
                    data: {
                        subject: request?.subject || '',
                        content: request?.content || '',
                        ...(request?.themeId ? { themeId: request.themeId || '' } : {}),
                        cc: request?.cc || [],
                        replyTo: request?.replyTo || [],
                        emailType: 'invite',
                    },
                    emailType: 'invite',
                    isOpen: false,
                    isSend: false,
                });

                dispatch(slice.actions.update(newInterview));
            }
            return response;
        } catch (error) {
            throw error;
        }
    };

export const makeResultDecision =
    (workspaceId: string, interviewId: string, request: any): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.makeDecision(workspaceId, interviewId, request);
        return response;
    };
export const sendEmailAfterDecision =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.sendEmailAfterDecision(workspaceId, request);
        return response;
    };

export const { reducer } = slice;
export default slice;

