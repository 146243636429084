import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { AdvancedFlow } from 'types/flow';
import { advancedFlowApi } from './api';

interface AdvancedFlowState {
    flows: AdvancedFlow[];
    isLoading: boolean;
    error: boolean;
}

const initialState: AdvancedFlowState = {
    flows: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'draftFlow',
    initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Flow Template */
        list: (state, action) => {
            state.flows = action.payload;
            state.isLoading = false;
        },

        /* Update Flow Status */
        update: (state, action) => {
            const flows = action.payload;
            const index = state.flows.findIndex((x) => x.id === flows.id);

            if (index > -1) {
                state.flows[index] = flows;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Create a Flow Template */
        create: (state, action) => {
            state.flows.unshift(action.payload);
            state.isLoading = false;
        },

        /* Remove a Flow Template */
        remove: (state, action) => {
            const flowId = action.payload;
            const index = state.flows.findIndex((x) => x.id === flowId);
            if (index > -1) {
                state.flows.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * Get Flow
 * @param workspaceId
 * @param flowId
 * @returns
 */

export const getFlow =
    (workspaceId: string, flowId: string): AppThunk =>
    async (dispatch): Promise<any> => {
        const response = await advancedFlowApi.getFlow(workspaceId, flowId);
        return response;
    };

/**
 * List all Flows
 * @param workspaceId
 * @returns
 */
export const listFlows =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(slice.actions.setLoading(true));
        const response: any = await advancedFlowApi.getAll(workspaceId);
        dispatch(slice.actions.list(response));
        dispatch(slice.actions.setLoading(false));
    };

/**
 * Create Flows
 * @param workspaceId
 * @param request
 * Request includes: "Flow Name and Draft = true"
 * @returns
 */
export const createFlow =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await advancedFlowApi.createFlow(workspaceId, request);
        dispatch(slice.actions.create(response));
        return response;
    };

/**
 * Delete Flows
 * @param id
 * @returns
 */

export const deleteFlow =
    (workspaceId: string, flowsId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await advancedFlowApi.deleteFlow(workspaceId, flowsId);
        dispatch(slice.actions.remove(flowsId));
    };

/**
 * Activate Flow Status
 * @param id
 * @param request
 * @returns
 */
export const updateFlowStatus =
    (workspaceId: string, flowId: string, request: { active: boolean }): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await advancedFlowApi.updateFlowStatus(workspaceId, flowId, request);
        dispatch(slice.actions.update(response));
        return response;
    };

/**
 * updateStepStatus
 * @param workspaceId
 * @param flowId
 * @param request
 * @returns
 */

export const updateStepStatus =
    (workspaceId: string, flowId: string, request: any): AppThunk =>
    async (dispatch): Promise<any> => {
        const response = await advancedFlowApi.updateStepStatus(workspaceId, flowId, request);
        return response;
    };

/**
 * updateFlow
 * @param workspaceId
 * @param flowId
 * @param request
 * @returns
 */

export const updateFlow =
    (workspaceId: string, flowId: string, request: Partial<AdvancedFlow>): AppThunk =>
    async (dispatch): Promise<any> => {
        const response = await advancedFlowApi.updateFlow(workspaceId, flowId, request);
        return response;
    };

export const { reducer } = slice;
