import moment from 'moment-timezone';
import momentTzData from 'moment-timezone/data/meta/latest.json';

// export async function return_random_key_token() {
//     const DETECT_IP_STRING = import.meta.env.VITE_APP_KEY_DETECT_COUNTRY;

//     const arr_token = DETECT_IP_STRING.split(',');

//     let index_random;
//     let data;

//     // check if key token expired
//     do {
//         index_random = Math.floor(Math.random() * arr_token.length);
//         const response = await fetch(`https://timezoneapi.io/api/ip?token=${arr_token[index_random]}`);
//         data = await response.json();
//     } while (!data);

//     return arr_token[index_random];
// }

export const detectCurrentCountry = async (defaultCountryCode?: string, defaultRegionTimezone?: string) => {
    try {
        const detected = {
            countryCode: defaultCountryCode,
            region: defaultRegionTimezone,
        };

        if (!defaultCountryCode && !defaultRegionTimezone) {
            // const key = await return_random_key_token();

            const response = await fetch(`${import.meta.env.VITE_APP_API_URL}/my-country`);
            const data = await response.json();

            const { countryCode, timezone } = data;
            if (timezone) {
                detected.region = timezone;
                if (countryCode) {
                    detected.countryCode = countryCode;
                } else {
                    detected.countryCode = getCountryCodeFromTz(timezone);
                }
            }
        }

        return detected;
    } catch (error) {
        console.error(error);
    }
};

// get list region tz
export const getListRegionTz = (countryCode: any) => {
    const listTZByCode = moment.tz.zonesForCountry(countryCode);

    const newCities = listTZByCode.map((city) => ({
        city_time_zone: city,
        timezone_offset: convertingOffsetToString(moment.tz?.(city).utcOffset() / 60),
    }));

    return newCities;
};

export const getCountryCodeFromTz = (timezone: string) => {
    const { zones } = momentTzData;
    return zones[timezone]?.countries[0];
};

// converting offset decimal to string (hh:mm)
export const convertingOffsetToString = (offset: any) => {
    let positiveOffset = Math.abs(offset);
    let truncOffset = Math.floor(positiveOffset * 60 - Math.trunc(positiveOffset) * 60);

    const truncPrefix = Math.trunc(offset);
    let emptyString = '';

    if (Math.abs(truncPrefix) < 10 && truncPrefix >= 0) {
        emptyString = `+0${truncPrefix}`;
    } else if (Math.abs(truncPrefix) < 10 && truncPrefix < 0) {
        emptyString = `-0${Math.abs(truncPrefix)}`;
    } else {
        emptyString = truncPrefix > 0 ? `+${truncPrefix.toString()}` : truncPrefix.toString();
    }

    return `${emptyString}:${truncOffset !== 0 ? truncOffset : '00'}`;
};

// converting offset string (hh:mm) to decimal
export const convertingOffsetToDecimal = (offset: any) => {
    const [prefix, suffix] = offset.split(':');

    if (suffix) {
        if (Number(prefix) >= 0) {
            return Number((Number(prefix) + Number(suffix) / 60).toFixed(2));
        }
        if (Number(prefix) <= 0) {
            return Number(-(Math.abs(Number(prefix)) + Number(suffix) / 60).toFixed(2));
        }
    } else {
        if (Number(prefix) === 0) {
            return Math.abs(Number(prefix));
        } else {
            return Number(prefix);
        }
    }
};
