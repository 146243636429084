import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { Question, QuestionRequest } from 'types/question';
import api from './api';

interface QuestionState {
    questions: Question[];
    isLoading: boolean;
    error: boolean;
}

const initialState: QuestionState = {
    questions: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'question',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Questions */
        list: (state, action) => {
            state.questions = action.payload;
            state.isLoading = false;
        },

        /* Create Question */
        create: (state, action) => {
            state.questions.unshift({ ...action.payload, isNew: true });
            state.isLoading = false;
        },

        /* Update Question */
        update: (state, action) => {
            const question = action.payload;
            const index = state.questions.findIndex((x) => x.id === question.id);

            if (index > -1) {
                state.questions[index] = { ...state.questions[index], ...question };
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove Question */
        remove: (state, action) => {
            const questionId = action.payload;
            const index = state.questions.findIndex((x) => x.id === questionId);
            if (index > -1) {
                state.questions.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List Question
 * @param workspaceId
 * @returns
 */
export const listQuestions =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response = await api.getAll(workspaceId);
            dispatch(slice.actions.list(response));
        } catch (error) {
            console.log(error.message);
        }
    };

/**
 * Get Media file of question
 * @param workspaceId
 * @param fileName
 * @returns
 */

export const getMedia =
    (workspaceId: string, fileName: string): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.getMedia(workspaceId, fileName);
        const file: any = new Blob([response], { type: `${response.type}` });
        return file;
    };

/**
 * Create Question
 * @param workspaceId
 * @param request
 * @returns
 */
export const createQuestion =
    (workspaceId: string, request: QuestionRequest): AppThunk =>
    async (dispatch): Promise<Question> => {
        const response: Question = await api.create(workspaceId, request);
        dispatch(slice.actions.create(response));
        return response;
    };

/**
 * Create Question
 * @param workspaceId
 * @param request
 * @returns
 */
export const createMultiQuestions =
    (workspaceId: string, request: { questions: QuestionRequest[] }): AppThunk =>
    async (dispatch, getState): Promise<string[]> => {
        const { activated } = getState().workspace;

        const response = await api.createMultiQuestions(workspaceId, request);
        request.questions.forEach((question, index) => {
            const newQuestion: Question = {
                ...question,
                id: response[index],
                ...(activated?.id ? { workspace: activated?.id || '' } : {}),
            };
            dispatch(slice.actions.create(newQuestion));
        });
        return response;
    };

/**
 * Import Question Template
 * @param workspaceId
 * @param id
 * @returns
 */
export const importQuestionTemplate =
    (workspaceId: string, questionTemplateId: string): AppThunk =>
    async (dispatch): Promise<Question> => {
        const response: Question = await api.importQuestionTemplateToWorkspace(workspaceId, questionTemplateId);
        dispatch(slice.actions.create(response));
        return response;
    };

/**
 * Update Question
 * @param id
 * @param request
 * @returns
 */
export const updateQuestion =
    (workspaceId: string, questionId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(workspaceId, questionId, request);
        dispatch(slice.actions.update(response));
        return response;
    };

/**
 * Update Video
 * @param workspaceId
 * @param questionId
 * @param request
 * @returns
 */

export const updateVideo =
    (workspaceId: string, questionId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.updateVideo(workspaceId, questionId, request);
        dispatch(slice.actions.update(response));
        return response;
    };

/**
 * Delete video file of question
 * @param workspaceId
 * @param question
 * @returns
 */

export const deleteQuestionVideo =
    (workspaceId: string, question: Question): AppThunk =>
    async (dispatch): Promise<any> => {
        const response: any = await api.deleteQuestionVideo(workspaceId, question.id);

        const newQuestion = { ...question };
        delete newQuestion.videoFile;
        delete newQuestion.videoFileCloudId;

        dispatch(slice.actions.update(newQuestion));

        return response;
    };

/**
 * Update Image
 * @param workspaceId
 * @param questionId
 * @param request
 * @returns
 */

export const updateImage =
    (workspaceId: string, questionId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.updateImage(workspaceId, questionId, request);
        dispatch(slice.actions.update(response));
        return response;
    };

/**
 * Delete Question
 * @param id
 * @returns
 */

export const deleteQuestion =
    (workspaceId: string, questionId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.delete(workspaceId, questionId);
        dispatch(slice.actions.remove(questionId));
    };

export const { reducer } = slice;
export default slice;
