import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { AppThunk } from 'store';
import { configTourUI } from 'utils/config';
import { detectCompleteTourUI } from './utils/detectCompleteTourUI';

interface DashboardState {
    isFirstAccess: boolean;
}

interface VideoInterviewState {
    isFirstAccess: boolean;
    isFirstCreateCandidate: boolean;
    isFirstCreateEmailTemplate: boolean;
    isFirstCreateInterview: boolean;
    isFirstViewResultInterview: boolean;
}

interface DirectInterviewState {
    isFirstAccess: boolean;
}

interface AdvancedFlowState {
    isFirstAccess: boolean;
    isFirstEncounterFlowDetail: boolean;
}

interface PositionState {
    isFirstAccess: boolean;
}

export interface TourUIState {
    advancedFlow: AdvancedFlowState;
    dashboard: DashboardState;
    directInterview: DirectInterviewState;
    isPauseTourUI: boolean;
    position: PositionState;
    videoInterview: VideoInterviewState;
}

const initialState: TourUIState = {
    advancedFlow: {
        isFirstAccess: false,
        isFirstEncounterFlowDetail: false,
    },
    dashboard: {
        isFirstAccess: false,
    },
    directInterview: {
        isFirstAccess: false,
    },

    isPauseTourUI: false,
    position: {
        isFirstAccess: false,
    },
    videoInterview: {
        isFirstAccess: false,
        isFirstCreateCandidate: false,
        isFirstCreateEmailTemplate: false,
        isFirstCreateInterview: false,
        isFirstViewResultInterview: false,
    },
};

const slice = createSlice({
    name: 'tourUI',
    initialState: initialState,
    reducers: {
        initTourUI: (state: TourUIState, action: PayloadAction<TourUIState>) => {
            state = Object.assign(state, action.payload);
        },

        firstAccessToWorkspace: (state, action: PayloadAction<string>) => {
            const workspaceId = action.payload;

            //Init workspace's tourUI state
            Object.keys(state.dashboard).forEach((key) => {
                state.dashboard[key] = true;
            });

            Object.keys(state.videoInterview).forEach((key) => {
                state.videoInterview[key] = true;
            });

            Object.keys(state.directInterview).forEach((key) => {
                state.directInterview[key] = true;
            });

            Object.keys(state.position).forEach((key) => {
                state.position[key] = true;
            });

            Object.keys(state.advancedFlow).forEach((key) => {
                state.advancedFlow[key] = true;
            });

            if (workspaceId) configTourUI.set(workspaceId, state);
        },
        updateDashboardState: (state: TourUIState, action: PayloadAction<{ workspaceId: string; newState: Partial<DashboardState> }>) => {
            const { workspaceId, newState } = action.payload;

            state.dashboard = { ...state.dashboard, ...newState };
            if (workspaceId) configTourUI.set(workspaceId, state);
        },
        updatePositionState: (
            state: TourUIState,
            action: PayloadAction<{ workspaceId: string; newState: Partial<VideoInterviewState> }>
        ) => {
            const { workspaceId, newState } = action.payload;
            state.position = { ...state.position, ...newState };

            if (workspaceId) configTourUI.set(workspaceId, state);
        },
        updateVideoInterviewState: (
            state: TourUIState,
            action: PayloadAction<{ workspaceId: string; newState: Partial<VideoInterviewState> }>
        ) => {
            const { workspaceId, newState } = action.payload;
            state.videoInterview = { ...state.videoInterview, ...newState };

            if (workspaceId) configTourUI.set(workspaceId, state);
        },

        updateDirectInterviewState: (
            state: TourUIState,
            action: PayloadAction<{ workspaceId: string; newState: Partial<DirectInterviewState> }>
        ) => {
            const { workspaceId, newState } = action.payload;

            state.directInterview = { ...state.directInterview, ...newState };
            if (workspaceId) configTourUI.set(workspaceId, state);
        },

        updateAdvancedFlowState: (
            state: TourUIState,
            action: PayloadAction<{ workspaceId: string; newState: Partial<AdvancedFlowState> }>
        ) => {
            const { workspaceId, newState } = action.payload;
            state.advancedFlow = { ...state.advancedFlow, ...newState };

            if (workspaceId) configTourUI.set(workspaceId, state);
        },

        updateIsPauseTourUi: (state: TourUIState, action: PayloadAction<boolean>) => {
            state.isPauseTourUI = action.payload;
        },

        confirmFinishedAllTourUI: (state: TourUIState, action: PayloadAction<string>) => {
            const workspaceId = action.payload;

            //Init workspace's tourUI state
            Object.keys(state.dashboard).forEach((key) => {
                state.dashboard[key] = false;
            });

            Object.keys(state.videoInterview).forEach((key) => {
                state.videoInterview[key] = false;
            });

            Object.keys(state.position).forEach((key) => {
                state.position[key] = false;
            });

            Object.keys(state.directInterview).forEach((key) => {
                state.directInterview[key] = false;
            });

            Object.keys(state.advancedFlow).forEach((key) => {
                state.advancedFlow[key] = false;
            });

            if (workspaceId) configTourUI.set(workspaceId, state);
        },
    },
});

const { actions, reducer } = slice;

export const initTourUI =
    (workspaceId: string): AppThunk =>
    async (dispatch, getState) => {
        let tourUIInitialState = cloneDeep(initialState);
        const { activated } = getState().workspace;

        const storedStates = configTourUI.get(workspaceId);

        if (storedStates) {
            tourUIInitialState = { ...initialState, ...storedStates };

            const { isCompleteWalkThrough: hasAccessAllFeatures } = detectCompleteTourUI(tourUIInitialState);

            if (hasAccessAllFeatures) configTourUI.remove(workspaceId);
        }

        //Pause tourUI if workspace has not setted timezone
        dispatch(
            actions.initTourUI({
                ...tourUIInitialState,
                isPauseTourUI: !activated?.defaultCountry && !activated?.defaultRegionTimezone ? true : false,
            })
        );
    };

/**
 * Dashboard Tour UI
 * Update first access dashboard section
 */
export const updateAccessDashboarState =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateDashboardState({ workspaceId: activated?.id, newState: { isFirstAccess: state } }));
    };

/**
 * Interivew Tour UI
 * Update first access interview section
 * Update first create interivew state consist of: first time submit candidate section, first time submit email template section and first time submit to create interivew
 * Reset all the tourUI states to true
 */
export const updateAccessInterviewState =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateVideoInterviewState({ workspaceId: activated?.id, newState: { isFirstAccess: state } }));
    };

export const updateFirstCreateCandidateState =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateVideoInterviewState({ workspaceId: activated?.id, newState: { isFirstCreateCandidate: state } }));
    };

export const updateFirstCreateEmailTemplateState =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateVideoInterviewState({ workspaceId: activated?.id, newState: { isFirstCreateEmailTemplate: state } }));
    };

export const updateFirstCreateInterviewState =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateVideoInterviewState({ workspaceId: activated?.id, newState: { isFirstCreateInterview: state } }));
    };

export const updateFirstViewResultInterview =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateVideoInterviewState({ workspaceId: activated?.id, newState: { isFirstViewResultInterview: state } }));
    };

export const resetVideoInterviewState = (): AppThunk => async (dispatch, getState) => {
    const { activated } = getState().workspace;
    const { videoInterview } = getState().tourUI;

    const newState = Object.keys(videoInterview).reduce<VideoInterviewState>((acc, curr: keyof VideoInterviewState) => {
        acc[curr] = true;
        return acc;
    }, cloneDeep(videoInterview));

    dispatch(
        actions.updateVideoInterviewState({
            workspaceId: activated?.id,
            newState,
        })
    );
};

/**
 * Position Tour UI
 * Update first access position section
 * Reset position states to true
 */
export const updateFirstAccessPosition =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updatePositionState({ workspaceId: activated?.id, newState: { isFirstAccess: state } }));
    };

export const resetPositionState = (): AppThunk => async (dispatch, getState) => {
    const { activated } = getState().workspace;

    const { position } = getState().tourUI;

    const newState = Object.keys(position).reduce<PositionState>((acc, curr: keyof PositionState) => {
        acc[curr] = true;
        return acc;
    }, cloneDeep(position));

    dispatch(
        actions.updatePositionState({
            workspaceId: activated?.id,
            newState,
        })
    );
};

/**
 * Advanced flow tour UI
 * Update first access running flow section
 * Update first encounter flow email state
 * Reset all advanced flow's states to true
 */

export const updateFirstAccessAdvancedFlow =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateAdvancedFlowState({ workspaceId: activated?.id, newState: { isFirstAccess: state } }));
    };

export const updateFirstEncounterFlowDetail =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateAdvancedFlowState({ workspaceId: activated?.id, newState: { isFirstEncounterFlowDetail: state } }));
    };

export const resetAdvancedFlowState = (): AppThunk => async (dispatch, getState) => {
    const { activated } = getState().workspace;

    const { advancedFlow } = getState().tourUI;

    const newState = Object.keys(advancedFlow).reduce<AdvancedFlowState>((acc, curr: keyof AdvancedFlowState) => {
        acc[curr] = true;
        return acc;
    }, cloneDeep(advancedFlow));

    dispatch(
        actions.updateAdvancedFlowState({
            workspaceId: activated?.id,
            newState,
        })
    );
};

/**
 * Direct interview tour UI
 * Update first access direct interview section
 * Reset all direct interview's states to true
 */
export const updateFirstAccessDirectInterview =
    (state: boolean): AppThunk =>
    async (dispatch, getState) => {
        const { activated } = getState().workspace;
        dispatch(actions.updateDirectInterviewState({ workspaceId: activated?.id, newState: { isFirstAccess: state } }));
    };

export const resetDirectInterviewState = (): AppThunk => async (dispatch, getState) => {
    const { activated } = getState().workspace;
    const { directInterview } = getState().tourUI;

    const newState = Object.keys(directInterview).reduce<DirectInterviewState>((acc, curr: keyof DirectInterviewState) => {
        acc[curr] = true;
        return acc;
    }, cloneDeep(directInterview));

    dispatch(
        actions.updateDirectInterviewState({
            workspaceId: activated?.id,
            newState,
        })
    );
};

/**
 * Update state for pausing tourUI process
 */
export const updateIsPauseTourUi =
    (state: boolean): AppThunk =>
    async (dispatch) => {
        dispatch(actions.updateIsPauseTourUi(state));
    };

export const firstAccessToWorkspace =
    (workspaceId: string): AppThunk =>
    async (dispatch) => {
        dispatch(actions.firstAccessToWorkspace(workspaceId));
    };

export const confirmFinishedAllTourUI = (): AppThunk => async (dispatch, getState) => {
    const { activated } = getState().workspace;
    dispatch(actions.confirmFinishedAllTourUI(activated?.id));
};

export default reducer;
