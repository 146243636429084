import { Button, Card, Typography, CardContent, CardActions, Box, useMediaQuery, useTheme } from '@mui/material';
import useApplyTranslation from 'hooks/useApplyTranslation';
import api from '../api';
import { workspaceSelector } from 'hooks/useSelector';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

interface KabanBoardTicketProps {
    position: string;
    action: string;
    candidate: string;
    id: string;
    component: string;
    componentID: string;
    positionId: string;
}

export default function KabanBoardTicket(props: KabanBoardTicketProps) {
    const translatedWords = useApplyTranslation();
    const workspace = useSelector(workspaceSelector);
    const navigate = useNavigate();
    const action = {
        decide: translatedWords.decide,
        scoring: translatedWords.scoring,
        setup: translatedWords.setup,
        fix: translatedWords.fix,
        select: translatedWords.select,
        schedule: translatedWords.schedule,
        lock: translatedWords.lock,
    };
    const title = {
        reconsiderCVs: translatedWords.reconsider_cvs, // decide applied cv
        scoringVideoInterview: translatedWords.scoring_video_interview, //scoring
        makeDecisionVideoInterview: translatedWords.make_decision_video_interview, //scoring
        schedulingFail: translatedWords.scheduling_fail,
        setUpDirectInterviewVenue: translatedWords.set_up_direct_interview_venue,
        makeDecisionDirectInterview: translatedWords.make_decision_direct_interview,
        setUpQuestionPack: translatedWords.set_up_question_pack,
        setUpScoreCard: translatedWords.set_up_score_card,
        scheduleDirectInterview: translatedWords.schedule_direct_interview,
        lockSchedule: translatedWords.lock_schedule,
    };

    const getTitle = (action: string, component: string) => {
        switch (action) {
            case 'decide':
                switch (component) {
                    case 'appliedCv':
                        return title['reconsiderCVs'];
                    case 'videoInterview':
                        return title['makeDecisionVideoInterview'];
                    case 'directInterview':
                        return title['makeDecisionDirectInterview'];
                    default:
                        return '';
                }
            case 'scoring':
                return title['scoringVideoInterview'];
            case 'fix':
                return title['schedulingFail'];
            case 'setup':
                switch (component) {
                    case 'directInterview':
                        return title['setUpDirectInterviewVenue'];
                    case 'position-qp':
                        return title['setUpQuestionPack'];
                    case 'position-sc':
                        return title['setUpScoreCard'];
                    default:
                        return '';
                }
            case 'schedule':
                switch (component) {
                    case 'directInterview':
                        return title['scheduleDirectInterview'];
                    default:
                        return '';
                }
            case 'lock':
                switch (component) {
                    case 'directInterview':
                        return title['lockSchedule'];
                    default:
                        return '';
                }
            default:
                return '';
        }
    };

    const buttonClicked = () => {
        switch (props.action) {
            case 'decide':
                api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
                    .then((res) => {
                        if (props.component === 'videoInterview') {
                            navigate(`interview/result/${props.componentID}/decision/${props.componentID}`);
                        } else if (props.component === 'directInterview') {
                            navigate(`schedule/position/${props.componentID}`);
                        } else if (props.component === 'appliedCv') {
                            navigate(`flows/advanced-flow/detail/${props.componentID}?tabValue=1`);
                        }
                    })
                    .catch((e) => console.error(e));
                break;
            case 'scoring':
                api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
                    //.then((res) => navigate(`interview/result/${props.componentID}`))
                    .then((res) => navigate(`interview`))
                    .catch((e) => console.error(e));
                break;
            case 'fix':
                api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
                    .then((res) => navigate(`schedule`))
                    .catch((e) => console.error(e));
                break;
            case 'setup':
                api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
                    .then((res) => {
                        if (props.component === 'directInterview') {
                        } else if (props.component === 'position-qp') {
                            navigate(`position?tabValue=0`);
                        } else if (props.component === 'position-sc') {
                            navigate(`position?tabValue=3`);
                        }
                    })
                    .catch((e) => console.error(e));
                break;
            case 'schedule':
                api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
                    .then((res) => navigate(`schedule`))
                    .catch((e) => console.error(e));
                break;
            case 'lock':
                api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
                    .then((res) => navigate(`schedule/?tabValue=4`))
                    .catch((e) => console.error(e));
                break;
            default:
                break;
        }
        // api.changeTicketStatusFromTodoToProcessing(workspace.activated.id, props.id)
        //     .then((res) => console.log(res))
        //     .catch((e) => console.error(e));
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Card
            className="KabanBoardTodoTicket"
            sx={{
                width: '100%',
                display: 'flex',
                flexFlow: matches ? 'column' : 'row',
                justifyContent: 'space-between',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
            }}
        >
            <CardContent>
                <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
                    {getTitle(props.action, props.component)}
                </Typography>
                <Typography color="text.secondary">{props.position}</Typography>
                {props.action === 'scoring' ? (
                    <Typography color="text.secondary">{props.candidate}</Typography>
                ) : (
                    <Typography color="text.secondary">{props.candidate}</Typography>
                )}
            </CardContent>
            <Box sx={{ display: 'flex', flexFlow: matches ? 'column' : 'row' }}>
                <Button
                    size="small"
                    variant="contained"
                    sx={{ alignSelf: 'center', mr: matches ? 0 : 2, mb: matches ? 2 : 0, whiteSpace: 'nowrap' }}
                    onClick={buttonClicked}
                >
                    {action[props.action]}
                </Button>
            </Box>
        </Card>
    );
}

