import axiosClient from 'lib/axios';

const prefixUrl = 'workspaces';

const api = {
    // Get All Workspaces By Current User
    getAll: () => {
        return axiosClient.get(`${prefixUrl}`);
    },

    // Get Workspace's information
    get: (id) => {
        return axiosClient.get(`${prefixUrl}/${id}`);
    },

    // Create workspace
    create: (request: any) => {
        return axiosClient.post(`recruiter/new-workspace`, request);
    },

    // Update workspace
    update: (id: string, request: any) => {
        return axiosClient.patch(`${prefixUrl}/${id}`, request);
    },

    // Leave workspace
    remove: (id) => {
        return axiosClient.delete(`${prefixUrl}/${id}`);
    },

    // Get Members
    getMembers: (workspaceId: string) => {
        return axiosClient.get(`${prefixUrl}/${workspaceId}/members`);
    },

    // Invite Member
    inviteMember: (workspaceId: string, request: any) => {
        return axiosClient.post(`${prefixUrl}/${workspaceId}/members`, request);
    },

    // Update Policy
    updatePolicy: (workspaceId: string, policyId: string, request: any) => {
        return axiosClient.patch(`${prefixUrl}/${workspaceId}/members/policy/${policyId}`, request);
    },

    // Remove Member
    removeMember: (workspaceId: string, recruiterId: string) => {
        return axiosClient.delete(`${prefixUrl}/${workspaceId}/members/${recruiterId}`);
    },

    /**
     * get Current Policy and Role
     *
     */
    getCurrentPolicy: (workspaceId: string, recruiterId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/members/${recruiterId}`);
    },

    changeOwner: (workspaceId: string, request: { otp: string; receiverId: string }) => {
        return axiosClient.patch(`/workspaces/${workspaceId}/members/owner`, request);
    },
    acceptOwner: (workspaceId: string, token: string) => {
        return axiosClient.patch(`/workspaces/${workspaceId}/members/accept-owner`, null, {
            headers: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        });
    },
    /** Get acount OTP for validate */
    sendTransferOwnerOTP: (workspaceId: string, request: { receiverId: string }) => {
        return axiosClient.post(`/workspaces/${workspaceId}/members/owner/otp`, request);
    },
};

export default api;
