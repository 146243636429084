import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from 'axios-jwt';

import axiosClient from 'lib/axios';

const prefixUrl = 'candidates';

const api = {
    /* Get All Candidates */
    gets: (workspaceId: string, page: number, limit: number) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}`, {
            params: { page: page, limit: limit },
        });
    },

    getEmployees: (workspaceId: string, page: number, limit: number) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/staff`, {
            params: { page: page, limit: limit },
        });
    },

    /* Get candidate using id */
    getCandidate: (workspaceId: string, candidateId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/${candidateId}`);
    },

    changeToEmployee: (workspaceId: string, candidateId: string) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/staffs/from-candidates`, {
            ids: [candidateId],
        });
    },

    changeToCandidate: (workspaceId: string, employeeId: string) => {
        return axiosClient.post(`/workspaces/${workspaceId}/${prefixUrl}/staffs/resign`, {
            candidateId: employeeId,
            reason: 'Test resigning',
        });
    },

    /**
     * Search Candidates
     * @param workspaceId
     * @param page
     * @param limit
     */
    search: (workspaceId: string, key: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/ts/search`, {
            params: {
                q: key,
            },
        });
    },

    searchEmployees: (workspaceId: string, key: string) => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/ts/staff/search`, {
            params: {
                q: key,
            },
        });
    },

    /* Get CV PDF */
    getCv: (workspaceId: string, filename: string): Promise<Blob> => {
        return axiosClient.get(`workspaces/${workspaceId}/${prefixUrl}/file/${filename}`, {
            responseType: 'blob',
        });
    },

    /* Import Candidates */
    importCv: (workspaceId: string, files) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/upload`, files, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    /** Import CSV file */
    importCsv: (workspaceId: string, request: FormData) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/upload-csv`, request, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    mappingCsvHeader: (workspaceId: string, request: { id: string; listHeader: string[] }) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}/upload-header-csv`, request);
    },

    /* Create Candidate */
    create: (workspaceId: string, request: any) => {
        return axiosClient.post(`workspaces/${workspaceId}/${prefixUrl}`);
    },

    /* Update Candidate */
    update: (workspaceId: string, candidateId: string, request: any) => {
        return axiosClient.patch(`workspaces/${workspaceId}/${prefixUrl}/${candidateId}`, request);
    },

    /**
     * get Current Quota of Self
     *
     */
    getQuota: () => {
        return axiosClient.get(`/quota`);
    },

    /**
     * get Current Quota of Workspace
     *
     */
    getQuotaWorkspace: (workspaceId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/quota`);
    },

    /**
     * get Current Policy and Role
     *
     */
    getCurrentPolicy: (workspaceId: string, recruiterId: string) => {
        return axiosClient.get(`/workspaces/${workspaceId}/members/${recruiterId}`);
    },

    /**
     * Manual-Filter-Advanced-Flow
     *
     */
    manualFilterCandidate: (workspaceId: string, threadId: string, request: any) => {
        return axiosClient.post(`/workspaces/${workspaceId}/thread/${threadId}/manual-filter/decision`, request);
    },

    /**
     * Reconsider Auto-Filter
     *
     */
    autoFilterReconsider: (workspaceId: string, threadId: string, request: { result: boolean }) => {
        return axiosClient.post(`/workspaces/${workspaceId}/thread/${threadId}/filter/decision`, request);
    },

    exportFile: (workspaceId: string, key = '') => {
        return axiosClient.get(`/workspaces/${workspaceId}/${prefixUrl}/ts/export-all-candidates`, {
            responseType: 'blob',
            params: {
                q: key,
            },
        });
    },
};

export const cachedApi = createApi({
    keepUnusedDataFor: 30,

    baseQuery: fetchBaseQuery({
        baseUrl: import.meta.env.VITE_APP_API_URL,
        credentials: 'include',
        prepareHeaders: (headers) => {
            const accessToken = getAccessToken();
            headers.set('Authorization', `Bearer ${accessToken}`);
            return headers;
        },
    }),

    endpoints: (builder) => ({
        getCandidates: builder.query<any, { workspaceId: string; page: number; limit: number }>({
            query: ({ workspaceId, page, limit }) => ({
                url: `workspaces/${workspaceId}/${prefixUrl}`,
                params: { page, limit },
            }),
        }),
        getEmployees: builder.query<any, { workspaceId: string; page: number; limit: number }>({
            query: ({ workspaceId, page, limit }) => ({
                url: `/workspaces/${workspaceId}/${prefixUrl}`,
                params: { page, limit },
            }),
        }),
        getQuotaWorkspace: builder.query<any, { workspaceId: string }>({
            query: ({ workspaceId }) => ({
                url: `/workspaces/${workspaceId}/quota`,
            }),
        }),
    }),
});

export default api;
