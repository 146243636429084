export interface Ticket {
    action: string;
    component: string;
    todoType: TodoType;
    id: string;
    workspace: string;
    positionName: string;
    componentId: string;
    candidateName: string;
    positionId: string;
}

export enum TodoType {
    NeedDoing = 0,
    Processing = 1,
}

export interface GetTicketResponse {
    docs: Ticket[];
    totalCount: number;
    totalPages: number;
    limit: number;
    page: number;
    next: number;
    prev: number;
}

export interface DashboardOpenPosition {
    flowId: string;
    position: string;
    isHiring: boolean;
    cvUntouched: number;
    videoUntouched: number;
}

