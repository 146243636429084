import React, { FC, useEffect } from 'react';
import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Helmet } from 'react-helmet-async';

import gtm from 'lib/gtm';

interface AppErrorBoundaryProps {
    resetErrorBoundary: () => void;
}

const AppErrorBoundary: FC<AppErrorBoundaryProps> = ({ resetErrorBoundary }) => {
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        gtm.push({ event: 'page_view' });
    }, []);

    return (
        <>
            <Helmet>
                <title>Error: Unexpected Error | RecruitGenius</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    px: 5,
                    py: 2,
                }}
            >
                <Typography align="center" variant={mobileDevice ? 'h3' : 'h2'} sx={{ color: 'error.main' }} gutterBottom>
                    An Unexpected Error Occurred
                </Typography>
                <Typography align="center" color="textSecondary" variant="subtitle1">
                    We're sorry, but an unexpected error has occurred while processing your request. Our team has been notified and is
                    actively investigating the issue. Please try again later!
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Box
                        alt="Under development"
                        component="img"
                        src={`/static/error/undraw_bug_fixing.svg`}
                        sx={{ height: 'auto', maxWidth: '100%', width: 600 }}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
                    <Button color="primary" variant="contained" startIcon={<RestartAltIcon />} onClick={resetErrorBoundary}>
                        Reset now
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AppErrorBoundary;

