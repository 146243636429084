/* eslint-disable react-hooks/rules-of-hooks */
import { CssBaseline, ThemeProvider } from '@mui/material';
import { authInit } from 'features/Authentication/slice';
import useInitLanguage from 'hooks/useInitLanguage';
import { authSelector, settingsSelector } from 'hooks/useSelector';
import AppWrapper from 'layout/AppWrapper';
import { restoreSettings } from 'layout/slice';
import { useGaTracker } from 'lib/ga-tracker';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { hotjar } from 'react-hotjar';
import { useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import './App.css';
import { createCustomTheme } from './assets/theme';
import SettingsDrawer from './components/SettingsDrawer';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import RTL from './layout/RTL';
import gtm from './lib/gtm';
import routes from './routes';
import { ErrorBoundary } from 'react-error-boundary';
import AppErrorBoundary from 'components/AppErrorBoundary';

const IsProd = import.meta.env.VITE_APP_PROD;

const HJID = import.meta.env.VITE_APP_HOTJAR_HJID;
const HJSV = import.meta.env.VITE_APP_HOTJAR_HJSV;

const App: FC = () => {
    const content = useRoutes(routes);
    const settings = useSelector(settingsSelector);
    const auth = useSelector(authSelector);
    const dispatch = useDispatch();

    useInitLanguage();

    useScrollReset();

    /* Run Google Analytics */
    if (IsProd === 'true') {
        useGaTracker();
    }

    useEffect(() => {
        gtm.initialize(gtmConfig);

        if (IsProd === 'true') {
            /* Hotjar */
            hotjar.initialize(+HJID, +HJSV);
        }

        /* Setting Init */
        dispatch(restoreSettings());

        /* Check Authenticated */
        dispatch(authInit());
    }, []);

    const Paddle = (window as any).Paddle;
    useEffect(() => {
        if (IsProd !== 'true') {
            Paddle?.Environment?.set('sandbox');
        }
    }, [Paddle]);

    const theme = createCustomTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme,
    });

    const fallbackRender = ({ error, resetErrorBoundary }) => {
        console.error(error);
        return <AppErrorBoundary resetErrorBoundary={resetErrorBoundary} />;
    };

    return (
        <ThemeProvider theme={theme}>
            <RTL direction={settings.direction}>
                <CssBaseline />
                <Toaster
                    position="top-center"
                    toastOptions={{
                        style: {
                            maxWidth: '500px',
                        },
                    }}
                />
                {/* <VideoInstruction /> */}
                {/* <SettingsDrawer /> */}
                <ErrorBoundary fallbackRender={fallbackRender}>
                    <AppWrapper>{auth.isInitialized ? content : <SplashScreen />}</AppWrapper>
                </ErrorBoundary>
            </RTL>
        </ThemeProvider>
    );
};

export default App;
