import React, { FC } from 'react';
import { Button } from '@mui/material';
import { GoogleLogin } from 'react-google-login';

import { useDispatch } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import { GoogleOAuth } from 'types/user';
import { googleLogin } from '../slice';

const CLIENT_ID = import.meta.env.VITE_APP_GL_CLIENT_ID;

interface GoogleAuthType {
    inviterData?: string;
}

const GoogleAuth: FC<GoogleAuthType> = (props) => {
    const { inviterData } = props;

    const dispatch = useDispatch();
    const translatedWords = useApplyTranslation();

    const googleSuccess = async (res) => {
        localStorage.setItem('google-token', JSON.stringify(res?.accessToken));

        const request: GoogleOAuth = {
            email: res?.profileObj?.email,
            token: res?.accessToken,
            firstName: res?.profileObj?.givenName,
            lastName: res?.profileObj?.familyName,
        };

        if (request.email && request.token) {
            await dispatch(googleLogin(request, inviterData));
        }
    };

    const googleFailure = (error) => {
        // Google Sign In was not successful. Try Again Please.
    };

    return (
        <GoogleLogin
            style={{ width: '500px' }}
            clientId={CLIENT_ID}
            render={(renderProps) => (
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    style={{
                        color: '#000',
                        background: '#fff',
                    }}
                    onClick={renderProps.onClick}
                    startIcon={<img src={'/static/google.png'} width="20px" height="20px" alt="google icon" />}
                >
                    {translatedWords.signin_google}
                </Button>
            )}
            onSuccess={googleSuccess}
            onFailure={googleFailure}
            cookiePolicy="single_host_origin"
            prompt="select_account"
        />
    );
};

export default GoogleAuth;
