import createSvgIcon from '@mui/material/utils/createSvgIcon';

const DashIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
        <g>
            <rect fill="none" fillRule="evenodd" height="24" width="24" />
            <g>
                <rect fillRule="evenodd" height="2" width="16" x="4" y="11" />
            </g>
        </g>
    </svg>,
    'DashIcon'
);

export default DashIcon;

