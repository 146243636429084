import { Avatar, Link, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { notificationApi } from 'api';
import { getAccessToken } from 'axios-jwt';
import { iconsMap } from 'features/Notification/components/NotificationList';
import useMounted from 'hooks/useMounted';
import { workspaceSelector } from 'hooks/useSelector';
import { cloneDeep } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useSelector } from 'store';
import { Notification, NotificationQuery } from 'types/notification';
import { NOTIFICATION_RECEIVE_EVENT } from 'utils/constants';
import { NotificationContext } from '.';
import { Pagination } from '../../types/general';

const NotificationItem: FC<{ notification: Notification }> = (props) => {
    const { notification } = props;
    const navigate = useNavigate();

    const Icon = iconsMap[notification.notiType];
    return (
        <ListItem disableGutters>
            <ListItemAvatar>
                <Avatar
                    sx={{
                        backgroundColor: 'primary.main',
                        color: 'primary.contrastText',
                    }}
                >
                    <Icon fontSize="small" />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Link
                        color="textPrimary"
                        sx={{ cursor: 'pointer' }}
                        underline="none"
                        variant="subtitle2"
                        onClick={() => {
                            navigate(notification.link, { replace: true });
                        }}
                    >
                        {notification.content}
                    </Link>
                }
            />
        </ListItem>
    );
};

interface IProviderProps {}

const Provider: React.FunctionComponent<IProviderProps> = ({ children }) => {
    const mounted = useMounted();
    const workspace = useSelector(workspaceSelector);

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [pagination, setPagination] = useState<Pagination>({
        page: page,
        limit: limit,
        totalCount: 0,
        totalPages: 0,
    });
    const [loading, setLoading] = useState(false);

    const getNotifications = useCallback(
        async (workspaceId: string, params?: NotificationQuery, isLoadMore?: boolean) => {
            try {
                //Do not show loading indicator when load more
                const loading = !isLoadMore;
                setLoading(loading);
                const response = await notificationApi.getAll(workspaceId, { page, limit, ...(params ? params : {}) });
                if (mounted.current) {
                    if (Array.isArray(response.notiList)) {
                        setNotifications((prev) => (isLoadMore ? [...prev, ...response.notiList] : response.notiList));
                        setPagination({
                            page: response.page,
                            limit: response.limit,
                            totalCount: response.totalCount,
                            totalPages: response.totalPages,
                        });
                    }
                }
            } catch (error) {
                console.log(error.message);
                toast.error(error.message);
            } finally {
                if (mounted.current) setLoading(false);
            }
        },
        [page, limit]
    );

    const fetchMoreNotification = useCallback(
        async (workspaceId: string, params?: NotificationQuery) => {
            try {
                const newPage = page + 1;
                await getNotifications(workspaceId, { ...(params ? params : {}), page: newPage }, true);
                setPage(newPage);
            } catch (error) {
                console.log(error);
            }
        },
        [getNotifications, page]
    );

    const markNotificationRead = useCallback(async (workspaceId: string, notificationId: string) => {
        try {
            await notificationApi.markReadOne(workspaceId, notificationId);
            if (mounted.current) {
                setNotifications((prev) => {
                    const newNotifications = cloneDeep(prev);
                    //Update read state of the target notification
                    return newNotifications.map((notification) => ({
                        ...notification,
                        isRead: notification.id === notificationId ? true : Boolean(notification.isRead),
                    }));
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    const markAllRead = useCallback(async (workspaceId: string) => {
        try {
            await notificationApi.markAllRead(workspaceId);
            if (mounted.current) {
                setNotifications((prev) => prev.map((notification) => ({ ...notification, isRead: true })));
                toast.success('Saved!');
            }
        } catch (error) {
            console.log(error.message);
            toast.error(error.message);
        }
    }, []);

    useEffect(() => {
        if (workspace.activated?.id) getNotifications(workspace.activated.id);
    }, [workspace.activated?.id]);

    //Handle socket for notifications
    useEffect(() => {
        if (workspace.activated?.id && (window as any).EventSourcePolyfill) {
            const token = getAccessToken();
            const eventSourceOptions: any = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const subscription = new (window as any).EventSourcePolyfill(
                `${import.meta.env.VITE_APP_API_URL}/workspaces/${workspace.activated.id}/notifications/events`,
                eventSourceOptions
            );
            subscription.onmessage = (message) => {
                if (message.data) {
                    const notifcation: Notification = JSON.parse(message.data);
                    setNotifications((prev) => [notifcation, ...prev]);
                    toast(<NotificationItem notification={notifcation} />);

                    // Handle dispatch global event for related component to refetch new correspond data
                    window.dispatchEvent(
                        new CustomEvent(NOTIFICATION_RECEIVE_EVENT, {
                            detail: notifcation,
                        })
                    );
                }
            };

            subscription.onerror = (error) => {};
        }
    }, [workspace.activated?.id]);

    return (
        <NotificationContext.Provider
            value={{
                loading,
                page,
                pagination,
                notifications,
                getNotifications,
                markNotificationRead,
                markAllRead,
                fetchMoreNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};

export default Provider;
