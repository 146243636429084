import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'store';
import { Email } from 'types/email';
import api from './api';

interface EmailState {
    emails: Email[];
    isLoading: boolean;
    error: boolean;
}

const initialState: EmailState = {
    emails: [],
    isLoading: false,
    error: false,
};

const slice = createSlice({
    name: 'email',
    initialState: initialState,
    reducers: {
        /* Set Status */
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        hasError: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },

        /* Get All Emails */
        list: (state, action) => {
            state.emails = action.payload;
            state.isLoading = false;
        },

        /* Create Email */
        create: (state, action) => {
            state.emails.unshift(action.payload);
            state.isLoading = false;
        },

        /* Update Email */
        update: (state, action) => {
            const email = action.payload;
            const index = state.emails.findIndex((x) => x.id === email.id);

            if (index > -1) {
                state.emails[index] = email;
            } else {
                state.error = true;
            }
            state.isLoading = false;
        },

        /* Remove Email */
        remove: (state, action) => {
            const index = state.emails.findIndex((x) => x.id === action.payload);
            if (index > -1) {
                state.emails.splice(index, 1);
            }

            state.isLoading = false;
        },
    },
});

/**
 * List Emails
 * @param workspaceId
 * @returns
 */
export const listEmails =
    (workspaceId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        try {
            const response = await api.getAll(workspaceId);
            dispatch(slice.actions.list(response));
        } catch (error) {
            console.log(error.message);
        }
    };

/**
 * Create Email
 * @param workspaceId
 * @param request
 * @returns
 */
export const createEmail =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.create(workspaceId, request);
        dispatch(slice.actions.create(response));
    };

/**
 * Update Email
 * @param id
 * @param request
 * @returns
 */
export const updateEmail =
    (workspaceId: string, emailId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.update(workspaceId, emailId, request);
        dispatch(slice.actions.update(response));
    };

/**
 * Delete Email
 * @param id
 *
 * @returns
 */
export const deleteEmail =
    (emailId: string, workspaceId): AppThunk =>
    async (dispatch): Promise<void> => {
        await api.delete(emailId, workspaceId);
        dispatch(slice.actions.remove(emailId));
    };

export const insertImage =
    (workspaceId: string, request: any): AppThunk =>
    async (dispatch): Promise<void> => {
        const response: any = await api.insertImages(workspaceId, request);
        return response;
    };

export const { reducer } = slice;
export default slice;
