import React, { FC, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormHelperText,
    Grid,
    IconButton,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Formik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { useDispatch, useSelector } from 'store';
import useApplyTranslation from 'hooks/useApplyTranslation';
import useMounted from 'hooks/useMounted';
import { authSelector, workspaceSelector } from 'hooks/useSelector';
import ArrowRightIcon from 'assets/icons/ArrowRight';
import PlusIcon from 'assets/icons/Plus';
import XIcon from 'assets/icons/X';
import authSlice from 'features/Authentication/slice';
import { firstAccessToWorkspace } from 'features/TourUI/slice';
import workspaceSlice, { createWorkspace } from 'features/Workspace/slice';
import settingsSlice from 'layout/slice';
import suggestNameByEmail from 'utils/domainSplit';
import SearchWorkspace from './SearchWorkspace';
import { WorkspaceType } from 'types/workspace';

interface ModalFormWorkspaceProps {
    hasWorkspaceParam: boolean;
}

const ModalFormWorkspace: FC<ModalFormWorkspaceProps> = ({ hasWorkspaceParam }) => {
    const dispatch = useDispatch();
    const mounted = useMounted();
    const navigate = useNavigate();
    const translatedWords = useApplyTranslation();
    const auth = useSelector(authSelector);
    const workspace = useSelector(workspaceSelector);

    const [searchResults, setSearchResults] = useState<WorkspaceType[]>(workspace.workspaces);

    /* Validate & Init Values */
    const validate = Yup.object().shape({
        name: Yup.string()
            .min(3, 'More than 3 letters')
            .max(40)
            .required('Name is required')
            .when('existed', {
                is: true,
                then: Yup.string().test({
                    message: () => 'Workspace name already exists',
                    test: (value) => {
                        return value && workspace.workspaces.findIndex((x) => x.name.toLowerCase() === value.toLowerCase()) > -1
                            ? false
                            : true;
                    },
                }),
            }),
        company: Yup.string().min(3).max(40).required('Company name is required'),
    });

    const initialValues = {
        name: workspace.workspaces.length === 0 ? suggestNameByEmail(auth.user.email) : '',
        company: workspace.workspaces.length === 0 ? suggestNameByEmail(auth.user.email) : '',
    };

    /* Handl Close Modal */
    const handleCloseModal = () => {
        dispatch(settingsSlice.actions.toggleModalWorkspace(false));
    };

    /**
     * Handle Select Workspace
     * @param value
     */
    const handleSelectWorkspace = (value) => {
        dispatch(workspaceSlice.actions.active(value));
        navigate(`/workspace/${value.id}`);
        handleCloseModal();
    };

    /**
     * Logout
     */
    const handleLogout = async (): Promise<void> => {
        try {
            dispatch(authSlice.actions.logout());
        } catch (err) {
            console.error(err);
            toast.error('Unable to logout.');
        }
    };

    /**
     * Handle Submit Form
     * @param values
     * @param param
     */
    const handleSubmitForm = async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
            const response: any = await dispatch(createWorkspace({ workspaceName: values.name, company: values.company }));

            // Update state when first access to workspace
            dispatch(firstAccessToWorkspace(response));

            if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
                navigate(`workspace/${response}`);
                handleCloseModal();
            }
        } catch (error) {
            toast.error('Something went wrong!');
            setStatus({ success: false });
            setErrors({ submit: error.message });
            setSubmitting(false);
        }
    };

    return (
        <Card>
            <CardHeader
                title={`${translatedWords.wrksp}`}
                action={
                    workspace.activated ? (
                        <IconButton onClick={handleCloseModal}>
                            <XIcon fontSize="small" />
                        </IconButton>
                    ) : (
                        <Button color="error" startIcon={<LogoutIcon />} onClick={handleLogout}>
                            {translatedWords.log_out}
                        </Button>
                    )
                }
            />
            <Divider />

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                    height: '80vh',
                }}
            >
                {!hasWorkspaceParam ? (
                    <Typography sx={{ my: 2 }} variant="h6" color="error" textAlign="center">
                        You don't have permission to access this workspace
                    </Typography>
                ) : (
                    <>
                        {workspace.workspaces.length > 0 && (
                            <>
                                <SearchWorkspace initialData={workspace.workspaces} setSearchResults={setSearchResults} />
                                <List sx={{ overflowY: 'auto', m: 2, py: 0 }}>
                                    {searchResults.map((item) => (
                                        <ListItem disableGutters key={item.id} sx={{ p: 0 }}>
                                            <Button
                                                onClick={() => handleSelectWorkspace(item)}
                                                sx={{
                                                    color: 'text.secondary',
                                                    flexGrow: 1,
                                                    py: 1.5,
                                                    px: 2,
                                                    '&:hover': {
                                                        backgroundColor: 'action.hover',
                                                    },
                                                    textAlign: 'left',
                                                }}
                                                endIcon={<ArrowRightIcon />}
                                            >
                                                <Typography sx={{ flexGrow: 1 }} color={item?.id === workspace.activated?.id && 'primary'}>
                                                    {item.name}
                                                </Typography>
                                            </Button>
                                        </ListItem>
                                    ))}
                                </List>
                                <Box sx={{ flexGrow: 1 }} />
                                <Divider>{translatedWords.create_wrksp}</Divider>
                            </>
                        )}
                        <Formik enableReinitialize initialValues={initialValues} validationSchema={validate} onSubmit={handleSubmitForm}>
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,
                                touched,
                                values,
                                setValues,
                            }): JSX.Element => (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} sx={{ mt: 0.5 }}>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={values.name}
                                                error={Boolean(touched.name && errors.name)}
                                                helperText={touched.name && errors.name}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    setValues({
                                                        ...values,
                                                        existed: true,
                                                    });
                                                }}
                                                onChange={handleChange}
                                                fullWidth
                                                size="small"
                                                label={translatedWords.wrksp_name + '*'}
                                                name="name"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                value={values.company}
                                                error={Boolean(touched.company && errors.company)}
                                                helperText={touched.company && errors.company}
                                                onBlur={(e) => {
                                                    handleBlur(e);
                                                    setFieldValue(e.target.name, e.target.value?.trim());
                                                }}
                                                onChange={handleChange}
                                                fullWidth
                                                size="small"
                                                label={translatedWords.wrksp_company + '*'}
                                                name="company"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                            <Button
                                                disabled={isSubmitting}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<PlusIcon />}
                                                type="submit"
                                            >
                                                {translatedWords.create_wrksp}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Box sx={{ mt: 1 }}>
                                        <FormHelperText error>{errors.submit}</FormHelperText>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default ModalFormWorkspace;

